import React from 'react';
import styled from 'styled-components';

const AboutPromo = () => {
  return (
    <Section id="hub-sobre-promocao">
      <Title>
        Sobre a promoção
      </Title>
      <Paragraph>
        Neque egestas amet, amet, feugiat orci ultricies tristique magna sollicitudin. 
        Pharetra, dui massa risus tincidunt sapien dui nec. Ac consectetur metus, 
        morbi iaculis purus lacus a malesuada blandit. Tortor tincidunt lorem arcu lacus, 
        pellentesque non. Vitae fermentum et ut sed consectetur nibh. Amet faucibus consectetur 
        amet, aliquet rhoncus, sem id neque ipsum. Nulla blandit consequat sit amet et.
      </Paragraph>
    </Section>
  )
}

export default AboutPromo;

const Section = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  max-width: 25rem;
  margin: 1.5rem auto;


  @media (min-width: 769px) {
    max-width: 50rem;
  }
`;

export const Title = styled.h1`
  width: 70%;
  font-size: 1.875rem;
  line-height: 115%;
  font-weight: bold;
  text-transform: uppercase;
  text-align: center;
  
  color: ${props => props.theme.color.text};
  margin: 0;
  margin-bottom: 1.5rem;

  @media (min-width: 769px) {
    width: 100%;
  }

  span {
    color: ${props => props.theme.color.orange};
    
    @media (min-width: 769px) {
      color: inherit;
    }
  }
`;

const Paragraph = styled.p`
  font-size: 1rem;
  line-height: 150%;
  font-weight: 500;
  color: ${props => props.theme.color.text};
  margin: 0;
`;