import React from "react";
import styled from "styled-components";

import LetteringTitle from "assets/banners/promoRimula/lettering-title.png";

export function BannerLettering() {
  return (
    <LetteringWrapper>
      <img src={LetteringTitle} alt="promoção shell rimula revisão premiada" draggable="false" />
    </LetteringWrapper>
  );

  // return (
  //   <LetteringWrapper>
  //     <HeadingPromocao>Promoção</HeadingPromocao>
  //     <HeadingGray>Shell Rimula</HeadingGray>
  //     <HeadingRed>
  //       Revisão <br />
  //       Premiada
  //     </HeadingRed>
  //   </LetteringWrapper>
  // );
}

const LetteringWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  img {
    width: 297px;
  }

  @media (min-width: ${props => props.theme.queries.lg}) {

    img {
      width: 412px; 
    }
  }
`;

const HeadingPromocao = styled.span`
  font-family: ${(props) => props.theme.Shell.Medium};
  font-size: 1rem;
  line-height: 1;
  color: ${(props) => props.theme.color.yellow};
  background-color: ${(props) => props.theme.color.shellGray};
  padding: 0 54px 4px;

  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 4px;
`;

const HeadingGray = styled.span`
  font-family: ${(props) => props.theme.FuturaFont};
  font-weight: bold;
  font-size: 2rem;
  line-height: 1;
  color: #4a4a4a;
`;

const HeadingRed = styled.strong`
  font-family: ${(props) => props.theme.FuturaFont};
  font-size: 3.5rem;
  font-weight: bold;
  line-height: 1;
  text-align: center;
  color: ${(props) => props.theme.color.red};
`;
