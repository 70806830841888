import React from 'react';
import styled from 'styled-components';

import {
  Accordion,
  AccordionItem,
  AccordionHeader,
  Box,
  AccordionIcon,
  AccordionPanel,
} from "@chakra-ui/core";

const Faq = () => {
  return(
    <Container id="hub-perguntas-frequentes">
      <Title>
        Perguntas Frequentes
      </Title>
      <Accordion allowMultiple>
        <CustomAccordionItem>
          <CustomAccordionHeader>
            <CustomBox>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do enden eiusmod 
              tempor incididunt ut labore et dolore magna aliqua?
            </CustomBox>
            <IconWrapper>
              <CustomIcon />
            </IconWrapper>
          </CustomAccordionHeader>
          <CustomAccordionPanel>
            <p>
              Lorem ipsum dolorem amet aussi consecteur. Sed ut perspiciatis unde omnis iste natus error 
              sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo 
              inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. 
            </p>
            <p>
              Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, 
              sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. 
              Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, 
              adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore 
              magnam aliquam quaerat voluptatem.
            </p>
            <p>
              Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut 
              aliquid ex ea commodi consequatur.
            </p>
          </CustomAccordionPanel>
        </CustomAccordionItem>
        <CustomAccordionItem>
          <CustomAccordionHeader>
            <CustomBox>
              Lorem ipsum dolor sit amet?
            </CustomBox>
            <IconWrapper>
              <CustomIcon />
            </IconWrapper>
          </CustomAccordionHeader>
          <CustomAccordionPanel>
            <p>
              Lorem ipsum dolorem amet aussi consecteur. Sed ut perspiciatis unde omnis iste natus error 
              sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo 
              inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. 
            </p>
          </CustomAccordionPanel>
        </CustomAccordionItem>
        <CustomAccordionItem>
          <CustomAccordionHeader>
            <CustomBox>
              Lorem ipsum dolor sit amet?
            </CustomBox>
            <IconWrapper>
              <CustomIcon />
            </IconWrapper>
          </CustomAccordionHeader>
          <CustomAccordionPanel>
            <p>
              Lorem ipsum dolorem amet aussi consecteur. Sed ut perspiciatis unde omnis iste natus error 
              sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo 
              inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. 
            </p>
          </CustomAccordionPanel>
        </CustomAccordionItem>
      </Accordion>
    </Container>
  )
}

export default Faq;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 50rem;
  margin: 0 auto;
  padding: 1rem 0;
`; 

const Title = styled.h1`
  font-size: 1.875rem;
  line-height: 2.5rem;
  font-weight: bold;
  text-transform: uppercase;
  text-align: center;
  
  color: ${props => props.theme.color.text};
  margin-top: 0;
  margin-bottom: 1.5rem;

  span {
    color: ${props => props.theme.color.orange};
    @media (min-width: 1024px) {
      color: inherit;
    }
  }
`;

const CustomAccordionItem = styled(AccordionItem)`
  margin: 0.5rem 0;
`;

const CustomAccordionHeader = styled(AccordionHeader)`
  display: flex;
  justify-content: space-between;
  border: 0;
  border-left: 7px solid ${props => props.theme.color.orange};
  border-bottom: 1px solid ${props => props.theme.color.mediumGray};
  padding: 0 !important;
  padding-left: 0.5rem !important;
  background: transparent;
  cursor: pointer;
  /* max-height: 9.75rem; */
`;

const CustomBox = styled(Box)`
  display: flex;
  justify-content: left;
  text-align: left;

  font-size: 1.375rem;
  line-height: 1.5rem;
  font-weight: 500;
  color: ${props => props.theme.color.text};
  padding: 1rem 0;
`;

const IconWrapper = styled.div`
  display: flex;
  flex-direction: center;
  align-items: center;
  justify-content: center;
  background-color: ${props => props.theme.color.orange};
  min-height: 70px;
  width: 34px;
  cursor: pointer;
  align-self: stretch;
`;

const CustomIcon = styled(AccordionIcon)`
  color: #FFF !important;
  width: 2.5rem !important;
  height: 2.5rem !important;
`;

const CustomAccordionPanel = styled(AccordionPanel)`
  display: flex;
  justify-content: left;
  align-items: center;
  flex-direction: column;

  font-size: 1.125rem;
  line-height: 1.5rem;
  color: ${props => props.theme.color.text};

  padding: 1rem 0 0 0.5rem !important;

  p {
    margin: 0;
    margin-bottom: 1rem;
    padding: 0;
  }
`;
