import React from "react";
import styled from "styled-components";
import { TextInputField } from "evergreen-ui";

export default function CustomInput(props) {
  return (
    <Input
      {...props}
      inputWidth={props.widthInput}
      inputHeight={props.height}
      name={props.name}
      type={props.type}
      onChange={props.onChange}
      value={props.value}
      placeholder={props.placeholder}
      autoComplete={props.autocomplete}
    ></Input>
  );
}

const Input = styled(TextInputField)`
  padding: 0;
  margin: 0;
  height: ${(props) =>
    props.height !== undefined ? `${props.height}px` : "45px"};
  opacity: 1;
  box-shadow: none;
  /* width: ${(props) =>
    props.widthInput !== undefined ? props.widthInput : "100%"}; */
  text-indent: 10px;
  border: 2px solid #ededed;
  transition: all 0.2s ease-in-out 0s;
  border-radius: 8px;
  font-size: 1rem;
  :focus {
    background: rgb(255, 255, 255);
    box-shadow: #267bcf 0px 0px 0px 2px inset;
    outline: none;
    border-radius: 9px;
  }
  outline: none;
  ::placeholder {
    color: #666;
  }
  :disabled {
    background: #f7f7f7;
    border: 1px solid #eee;
    cursor: no-drop;
  }
`;
