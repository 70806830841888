import { thunk, action, Action, Thunk } from "easy-peasy";
import {
  GetProfile,
  SaveProfile,
  EditProfile,
  EditProfilePassword,
} from "../services/profile";
import history from "../utils/history";
import { get } from "lodash";
import { toaster } from "evergreen-ui";
import { getSlug } from "utils/helper";

interface IProfile {
  name: string;
  email: string;
  newpassword: string;
  oldpassword: string;
  ad: Object;
  confirmPassword?: string;
  phone?: number;
  wallet_id?: string;
}

const ProfileValues: IProfile = {
  ad: {},
  name: "",
  email: "",
  newpassword: "",
  oldpassword: "",
  phone: null,
  wallet_id: null,
  confirmPassword: "",
};

export interface ProfileModel {
  isLoading: boolean;
  error: string;

  profile: IProfile;

  getProfile: Thunk<ProfileModel, void>;
  saveProfileName: Thunk<ProfileModel, void>;
  editProfile: Thunk<ProfileModel, Object>;
  editProfilePassword: Thunk<ProfileModel, Object>;
  setModel: Action<ProfileModel, string>;
  setName: Action<ProfileModel, string>;
  setEditProfilePassword: Action<ProfileModel, string>;
  setEditProfile: Action<ProfileModel, string>;
  setLoading: Action<ProfileModel, string>;
  setError: Action<ProfileModel, string>;
}

const profile: ProfileModel = {
  isLoading: false,
  error: "",
  profile: ProfileValues,
  getProfile: thunk(async (action: any) => {
    try {
      action.setLoading({ loading: true });
      const response = await GetProfile();
      action.setModel(response);
      action.setLoading({ loading: false });
    } catch (error) {
      if (error.response.status === 401) {
        action.setError({ message: "Erro ao carregar dados" });
      }
    }
  }),
  saveProfileName: thunk(async (action: any, payload: any) => {
    try {
      action.setLoading({ loading: true });
      const response = await SaveProfile(payload);
      action.setName(response);
      action.setLoading({ loading: false });
      history.push("/conta/meusdados");
    } catch (error) {
      if (error.response.status === 401) {
        action.setError({ message: "Erro ao carregar dados" });
      }
    }
  }),
  editProfile: thunk(async (action: any, payload: any) => {
    try {
      action.setLoading({ loading: true });
      const response = await EditProfile(payload);
      if (response.status > 399) {
        if (response.status === 401) {
          localStorage.removeItem("Hub-auth");
          const slug = getSlug();
          window.location.href = slug.url;
        }
      } else {
        action.setEditProfile(response.data);
        toaster.success("Atualizado com sucesso.", {
          duration: 4,
          id: "save-update-user",
        });
      }
      const responseProfile = await GetProfile();
      action.setModel(responseProfile);
      action.setLoading({ loading: false });
    } catch (error) {
      action.setLoading({ loading: false });
    }
  }),
  editProfilePassword: thunk(async (action: any, payload: any) => {
    try {
      action.setLoading({ loading: true });
      const response = await EditProfilePassword(payload);
      if (response.status > 399) {
        if (response.status === 401) {
          localStorage.removeItem("Hub-auth");
          const slug = getSlug();
          window.location.href = slug.url;
        }
      } else {
        action.setEditProfilePassword(response.data);
        toaster.success("Atualizado com sucesso.", {
          duration: 4,
          id: "save-update-user",
        });
      }
      action.setLoading({ loading: false });
    } catch (error) {
      action.setError({ message: "Erro ao carregar dados" });
      action.setLoading({ loading: false });
    }
  }),
  setModel: action((state: any, payload: any) => {
    const name = get(payload, "results.name", "");
    const email = get(payload, "results.email", "");
    const address = get(payload, "results.ad", {});

    state.profile.name = name;
    state.profile.email = email;
    state.profile.ad = address;
  }),
  setName: action((state: ProfileModel, payload: string) => {
    const name = get(payload, "name", "");

    state.profile.name = name;
  }),
  setEditProfile: action((state: ProfileModel, payload: any) => {
    const email = get(payload, "email", "");
    const name = get(payload, "name", "");
    const phone = get(payload, "phone", null);
    const wallet_id = get(payload, "wallet_id", null);

    state.profile.email = email;
    state.profile.name = name;
    state.profile.phone = phone;
    state.profile.wallet_id = wallet_id;
  }),
  setEditProfilePassword: action((state: ProfileModel, payload: any) => {
    const newpassword = get(payload, "password", "");

    state.profile.newpassword = newpassword;
  }),
  setLoading: action((state: { isLoading: boolean }, payload: any) => {
    const loading = get(payload, "loading", false);
    state.isLoading = loading;
  }),
  setError: action((state: { error: string }, payload: any) => {
    const message = get(payload, "message", false);
    state.error = message;
  }),
};

export default profile;
