import React from "react";
import styled from "styled-components";
import { GanhadoresTable as TableContainer } from "../styles";

interface TableData {
  nome: string;
  CPF: string;
  numeroDaSorte: string;
  premio: string;
}

export function GanhadoresTable({ tableData }: { tableData: TableData[] }) {
  // const dt = tableData.filter((data) => data.numeroDaSorte.includes('xxx'))
  // console.log(dt.length)

  const mockTableData: TableData[] = [
    {
      nome: "Alexander",
      CPF: "12*******09",
      numeroDaSorte: "1530807087",
      premio: "1 Par de Ingressos para o Grande Prêmio de SP 2024",
    },
    {
      nome: "Luciano",
      CPF: "12*******09",
      numeroDaSorte: "1530807087",
      premio: "1 Par de Ingressos para o Grande Prêmio de SP 2024",
    },
    {
      nome: "Lúcia",
      CPF: "12*******09",
      numeroDaSorte: "1530807087",
      premio: "1 Par de Ingressos para o Grande Prêmio de SP 2024",
    },
    {
      nome: "Márcio",
      CPF: "12*******09",
      numeroDaSorte: "1530807087",
      premio: "1 Par de Ingressos para o Grande Prêmio de SP 2024",
    },
    {
      nome: "Alexander",
      CPF: "12*******09",
      numeroDaSorte: "1530807087",
      premio: "1 Par de Ingressos para o Grande Prêmio de SP 2024",
    },
    {
      nome: "Luciano",
      CPF: "12*******09",
      numeroDaSorte: "1530807087",
      premio: "1 Par de Ingressos para o Grande Prêmio de SP 2024",
    },
    {
      nome: "Lúcia",
      CPF: "12*******09",
      numeroDaSorte: "1530807087",
      premio: "Produto Exclusivo Shell coleção Ferrari",
    },
    {
      nome: "Márcio",
      CPF: "12*******09",
      numeroDaSorte: "1530807087",
      premio: "Cupons de desconto no Shell Box",
    },
  ];

  return (
    <TableScrollerWrapper>
      <TableContainer>
        <thead>
          <tr>
            <th>Número da sorte</th>
            <th>Nome do ganhador</th>
            <th>CPF</th>
            <th>Prêmio</th>
          </tr>
        </thead>
        <tbody>
          {tableData.length > 1 &&
            tableData.map(({ CPF, nome, numeroDaSorte, premio }, index) => (
              <tr tabIndex={0} key={`${index}-${nome}-${CPF}`}>
                <td>{numeroDaSorte}</td>
                <td>{nome}</td>
                <td>{CPF}</td>
                <td className="prize">{premio}</td>
              </tr>
            ))}
        </tbody>
      </TableContainer>
    </TableScrollerWrapper>
  );
}

const TableScrollerWrapper = styled.section`
  display: flex;
  flex-direction: column;

  width: 100%;
  max-width: 868px;
  overflow-x: auto;

  table {
    tr {
      td.prize {
        font-size: 1rem;
      }
    }
  }

  @media (min-width: ${(props) => props.theme.queries.lg}) {
    table {
      tr {
        td.prize {
          max-width: 156px;
          font-size: 1.125rem;
        }
      }
    }
  }
`;
