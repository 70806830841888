import { thunk, action, Action, Thunk } from "easy-peasy";
import { createBrowserHistory } from "history";
import Smooch from "smooch";
import {
  login,
  signup,
  update,
  updatePassword,
  forgot,
} from "../services/auth";
import { get } from "lodash";
import { checkLocalStorage, setInLocalStorage } from "../utils/localstorage";
import { getSlug, refreshToken, translateChat } from "../utils/helper";
import { GetMe } from "../services/user";
import { StoreModel } from "./index";
import { useHistory } from "react-router-dom";

export interface AuthModel {
  token: any;
  url: string;
  isAuthenticated: boolean;
  isAuthLoading: boolean;
  isValidate: boolean;
  authError: string;
  loginError: string;
  registerError: string;
  authRecaptchaError: string;
  msgForgot: string;
  messageForgotPassword: string;
  intentForgotPassword: string;
  passwordError: string;
  user: Object | any;
  registerFromPromo: boolean;
  authenticateUser: Thunk<AuthModel, string>;
  forgotPassword: Thunk<AuthModel, any>;
  setRegisterFromPromo: Action<AuthModel, boolean>;

  tokenExternal: string;
  // changePassword: Thunk<AuthModel, string>;
  getMe: Thunk<AuthModel, void>;
  createUser: Thunk<AuthModel, string>;
  updateUser: Thunk<AuthModel, string, any, StoreModel>;
  getAuthExternal: Thunk<AuthModel, string>;
  changePassword: Thunk<AuthModel, string>;
  updateSession: Action<AuthModel, any>;
  updateAuth: Action<AuthModel, string>;
  updateIsValidate: Action<AuthModel, string>;
  updateIsAuthLoading: Action<AuthModel, string>;
  updateAuthError: Action<AuthModel, string>;
  registerAuthError: Action<AuthModel, string>;
  updatePasswordError: Action<AuthModel, string>;
  setMsgForgot: Action<AuthModel, string>;
  clearAuth: Action<AuthModel, any>;
  setAuthExternal: Action<AuthModel, void>;
  saveUrl: Action<AuthModel, any>;
  updateAuthRecaptchaError: Action<AuthModel, any>;
  updateMsgForgot: Action<AuthModel, any>;
}

// rehydrate the auth state from localStorage if it exist
export const initialState = checkLocalStorage("Hub-auth", {
  token: null,
  isAuthenticated: false,
  user: {},
});

const auth: AuthModel = {
  ...initialState,
  tokenExternal: "",
  isAuthLoading: false,
  passwordError: "",
  authError: "",
  loginError: "",
  authRecaptchaError: "",
  msgForgot: "",
  url: "",
  isValidate: false,
  intentForgotPassword: "success",
  authenticateUser: thunk(async (action: any, payload: any) => {
    action.updateIsAuthLoading({ loading: true });
    try {
      const response = await login(payload);
      if (response.status > 399) {
        action.updateAuthError({
          message: "Username or password incorrect.",
        });
        action.updateAuthRecaptchaError({
          message: "Username or password incorrect.",
        });
        action.updateIsAuthLoading({ loading: false });
      } else {
        action.updateAuth(response.data);
        action.updateIsAuthLoading({ loading: false });
        //@ts-ignore
        var dataLayer = window.dataLayer || [];
        dataLayer.push({
          event: "login",
        });
      }
    } catch (error) {
      let message = "falha em requisitar o servidor.";
      if (error.response) {
        const { status, data } = Object.assign({}, error).response;

        if (status > 399) {
          const errors = get(data, "errors", []);

          if (errors.length > 0) message = errors[errors.length - 1].message;
        }
      }
      action.updateAuthError({ message: message });
      action.updateIsAuthLoading({ loading: false });
    }
  }),
  createUser: thunk(async (action: any, payload: any) => {
    action.updateIsAuthLoading({ loading: true });
    try {
      const response = await signup(payload as any);
      if (response.status > 399) {
        action.updateIsAuthLoading({ loading: false });
        action.registerAuthError({
          message: "Ocorreu um erro, tente novamente.",
        });
        action.updateAuthRecaptchaError({
          message: "Ocorreu um erro, tente novamente.",
        });
      } else {
        console.log(response.status);
        action.updateAuth(response.data);
        action.updateIsAuthLoading({ loading: false });
        // window.scrollTo(0, 0);
        //@ts-ignore
        var dataLayer = window.dataLayer || [];
        dataLayer.push({
          event: "signup",
        });
      }
    } catch (error) {
      if (
        error.response &&
        error.response.data.errors &&
        error.response.data.errors[0].message
      ) {
        action.updateIsAuthLoading({ loading: false });
        action.registerAuthError({
          message: error.response?.data.errors[0]?.message,
        });
        action.updateAuthRecaptchaError({
          message: error.response?.data.errors[0]?.message,
        });
      } else {
        action.updateIsAuthLoading({ loading: false });
        action.registerAuthError({
          message: "Ocorreu um erro, tente novamente.",
        });
        action.updateAuthRecaptchaError({
          message: "Ocorreu um erro, tente novamente.",
        });
      }
    }
  }),
  updateUser: thunk(async (action: any, payload: any, { getStoreActions }) => {
    action.updateIsAuthLoading({ loading: true });
    try {
      const res = await update(payload);
      if (res.data && res.data.data) {
        action.updateAuthError({
          message: res.data.data,
        });
      }

      const response = await GetMe();
      getStoreActions().user.setModel(response.data);

      window.scrollTo(0, 0);
      //@ts-ignore
      var dataLayer = window.dataLayer || [];
      dataLayer.push({
        event: "signup",
      });
      action.updateIsAuthLoading({ loading: false });
    } catch (error) {
      if (
        error.response &&
        error.response.data.errors &&
        error.response.data.errors[0].message
      ) {
        action.updateIsAuthLoading({ loading: false });
        action.updateAuthError({
          message: error.response.data.errors[0]?.message,
        });
      } else {
        action.updateIsAuthLoading({ loading: false });
        action.updateAuthError({
          message: "Ocorreu um erro, tente novamente.",
        });
      }
    }
  }),
  changePassword: thunk(async (action: any, payload: any) => {
    action.updateIsAuthLoading({ loading: true });
    try {
      const res = await updatePassword(payload);
      if (res.data && res.data.data) {
        action.updatePasswordError({
          message: res.data.data,
        });
      }
      window.scrollTo(0, 300);
      action.updateIsAuthLoading({ loading: false });
    } catch (error) {
      if (
        error.response &&
        error.response.data.errors &&
        error.response.data.errors[0].message
      ) {
        action.updateIsAuthLoading({ loading: false });
        action.updatePasswordError({
          message: error.response?.data.errors[0]?.message,
        });
      } else {
        action.updateIsAuthLoading({ loading: false });
        action.updatePasswordError({
          message: "Ocorreu um erro, tente novamente.",
        });
      }
    }
  }),
  updateSession: action((state: any, payload: any) => {
    const auth = JSON.parse(localStorage.getItem("Hub-auth"));
    setInLocalStorage("Hub-auth", {
      isAuthenticated: auth.isAuthenticated,
      token: auth.token,
      user: payload.data,
    });

    state.user = payload.data;
  }),
  updateAuth: action((state: any, payload: any) => {
    const token = get(payload, "access_token", "");
    const user = payload;
    const isAuthenticated = true;

    // store the auth state offline
    setInLocalStorage("Hub-auth", {
      isAuthenticated,
      token,
      user,
    });

    state.isAuthenticated = isAuthenticated;
    state.token = token;
    state.user = user;

    const pushRoute = (url: string) => {
      if (window.location.pathname.includes("gp-sp-2023")) {
        return `/junte-troque/gp-sp-2023/numeros-da-sorte`
      }
      if (url?.includes("junte-troque")) {
        return `${url}/todos-produtos`;
      }
    };

    if (
      !window.location.search.includes("shellbox") &&
      !window.location.search.includes("junte-troque")
    ) {
      Smooch.init({
        integrationId: process.env.REACT_APP_CHAT_INTEGRATION_ID,
        customColors: {
          brandColor: "FBCE07",
          conversationColor: "FBCE07",
          actionColor: "FBCE07",
        },
        customText: translateChat(),
      }).then(function () {
        setTimeout(() => {
          const hist = createBrowserHistory({
            forceRefresh: true,
          });
          const slug = getSlug();
          hist.push(pushRoute(slug.url));
        }, 300);
      });
    } else {
      setTimeout(() => {
        const hist = createBrowserHistory({
          forceRefresh: true,
        });
        const slug = getSlug();
        hist.push(pushRoute(slug.url));
      }, 300);
    }
  }),

  forgotPassword: thunk(async (action: any, payload: any) => {
    try {
      const res = await forgot(payload);
      if (res.data.data) {
        action.updateMsgForgot({
          message: res.data.data,
          intent: "success",
        });
      }
      action.updateIsAuthLoading({ loading: false });
    } catch (error) {
      let message = "falha em requisitar o servidor.";
      const { status, data } = Object.assign({}, error).response;

      if (status > 399) {
        const errors = get(data, "errors", []);

        if (errors.length > 0) message = errors[errors.length - 1].message;
      }

      action.updateMsgForgot({ message: message });
      action.updateIsAuthLoading({ loading: false });
    }
  }),
  refreshTokenExternal: thunk(async (action: any, payload: any) => {
    try {
      await refreshToken();
      action.setAuthExternal();
    } catch (error) {
      //no-op
    }
  }),
  updateMsgForgot: action(
    (
      state: { messageForgotPassword: any; intentForgotPassword: any },
      payload: any
    ) => {
      state.messageForgotPassword = payload.message;
      state.intentForgotPassword = payload.intent;
    }
  ),
  updateIsAuthLoading: action((state: { isAuthLoading: any }, payload: any) => {
    const loading = get(payload, "loading", false);
    state.isAuthLoading = loading;
  }),
  updateAuthRecaptchaError: action(
    (state: { authRecaptchaError: any }, payload: any) => {
      const errorMessage = get(payload, "message", "");
      state.authRecaptchaError = errorMessage;
    }
  ),
  updateAuthError: action((state: any, payload: any) => {
    const errorMessage = get(payload, "message", "");
    state.loginError = errorMessage;
    state.authError = errorMessage;
  }),
  updatePasswordError: action((state: { passwordError: any }, payload: any) => {
    const errorMessage = get(payload, "message", "");
    state.passwordError = errorMessage;
  }),
  registerAuthError: action((state: any, payload: any) => {
    const errorMessage = get(payload, "message", "");
    state.registerError = errorMessage;
  }),
  setMsgForgot: action((state: any, payload: any) => {
    state.msgForgot = payload;
  }),
  setAuthExternal: action((state: any, payload: any) => {
    try {
      const user = JSON.parse(localStorage.getItem("Hub-auth"));
      if (user) {
        state.isAuthenticated = true;
        state.token = user.token;
        state.user = user;
      }
    } catch (error) {
      //no-op
    }
  }),
  saveUrl: action((state: any, payload: any) => {
    state.url = payload;
  }),
  clearAuth: action((state: any) => {
    localStorage.removeItem("Hub-auth");
    state.token = initialState.token;
    state.isAuthenticated = initialState.isAuthenticated;
    const isGrandeGp = window.location.pathname.includes("gp-sp-2023")

    const slug = getSlug();

    window.location.href = isGrandeGp ? `${slug.url}/gp-sp-2023` : slug.url;
  }),
  setRegisterFromPromo: action((state: any, payload: any) => {
    state.registerFromPromo = payload;
  }),
};

export default auth;
