import {
  Modal,
  ModalOverlay,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
} from "@chakra-ui/core";
import React from "react"
import styled from "styled-components";
import { theme } from "theme";
import useWindowSize from "hooks/useWIndowSize";

type CarouselModalProps = {
  isOpen: boolean;
  onClose: () => void;
  imageLarge: string;
  imageMobile: string;
}

export function CarouselModal({ isOpen, onClose, imageLarge, imageMobile }: CarouselModalProps) {
  const { width } = useWindowSize();

  const responsive = {
    desk: {
      breakpoint: { min: 1025, max: 4000 },
      items: 1
    },
    mobile: {
      breakpoint: { min: 0, max: 1024 },
      items: 1
    }
  }

  return (
    <Modal
      isOpen={isOpen}
      size={["30rem","30rem","30rem","30rem","30rem","35rem"]}
      isCentered
    >
      <ModalOverlay />
      <ModalContent borderRadius={4}>
        <ModalHeader></ModalHeader>
        <ModalCloseButton
          onClick={onClose}
          background={"transparent"}
          border={"none"}
          cursor={"pointer"}
          size="lg"
          color={theme.color.red}
        />
        <ModalBodyCarousel>
          <Content
          // imgDesktop={imageLarge}
          // imgMobile={imageMobile}
          >
            {window.location.pathname === "/" ? (
              // <a
              //   href="https://www.acelerecomgp.com.br/?utm_source=promoshell&utm_medium=media&utm_campaign=promoshell_aceleregp&utm_id=PromoShell"
              //   target="_blank"
              //   rel="noopener noreferer"
              //   aria-label="abre em uma nova guia"
              // >
                <img src={width >= 1024 ? imageLarge : imageMobile} alt="" />
              // </a>
            ) : (
              <img src={width >= 1024 ? imageLarge : imageMobile} alt="" />
            )}
            {/* <Carousel
              responsive={responsive}
              arrows={false}
            >
              {imageLarge?.map((img) => (
                <img src={img} alt="" />
              ))}
            </Carousel> */}

          </Content>
        </ModalBodyCarousel>
        <ModalFooter />
      </ModalContent>
    </Modal>
  )
}

const ModalBodyCarousel = styled(ModalBody)`
`;

const Content = styled.div`
  display: flex;
  width: 100%;

  /* max-height: 100vh; */
  /* background-image: url(${props => props.imgMobile});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat; */

  box-sizing: border-box;
  img {
    width: 100%;
  }
  
  @media (min-width: ${props => props.theme.queries.lg}) {
    width: 100%;
    /* background-image: url(${props => props.imgDesktop}); */
  }
`;