import React, { ReactNode } from "react"
import { NavLink } from "react-router-dom"

interface HeaderNavLinksProps {
  url: string
  children: ReactNode
  exact?: boolean
}

export function HeaderNavLink({ url, children, exact, ...rest }: HeaderNavLinksProps) {
  const isShellbox = window.location.search.includes("shellbox")

  if (isShellbox) {
    return (
      <NavLink
        exact={exact}
        to={`${url}?shellbox=${localStorage.getItem("query_shell")}&token=${localStorage.getItem("external_token")}`}
        {...rest}
      >
        {children}
      </NavLink>
    )
  }

  return (
    <NavLink
      exact={exact}
      to={url}
      {...rest}
    >
      {children}
    </NavLink>
  )
}