import { requestForm, request } from "../utils/api";

export const login = (payload) => {
  return requestForm({
    method: "post",
    baseUrl: "api",
    route: "token",
    payload,
  });
};

export const authExternal = (payload) => {
  return requestForm({
    method: "post",
    baseUrl: "api",
    route: "token/external",
    payload,
  });
};

export const signup = (payload) => {
  return request({
    method: "post",
    baseUrl: "api",
    route: "participant",
    payload,
  });
};

export const update = (payload) => {
  return request({
    method: "put",
    baseUrl: "api",
    route: "participant",
    payload,
  });
};

export const updatePassword = (payload) => {
  return request({
    method: "put",
    baseUrl: "api",
    route: "participant/changepassword",
    payload,
  });
};

export const forgot = async (payload) => {
  return request({
    method: "post",
    baseUrl: "api",
    route: `participant/forgetpassword`,
    payload: {
      DocumentNumber: payload.cpf,
      recaptcha: payload.recaptcha,
    },
  });
};
