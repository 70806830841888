import React from "react";
import styled from "styled-components";
import useWindowSize from "hooks/useWIndowSize";

//assets
import banner from "assets/banners/ofertas-shell-select-footer-banner.png";
import bannerMobile from "assets/banners/ofertas-shell-select-footer-banner-mobile.png";

const BannerFooter = () => {
  const { width } = useWindowSize();

  return (
    <Container>
      <Image src={width <= 440 ? bannerMobile : banner} alt="posto shell" draggable={false}/>
      <Content>
        <Column>
          <Title>saiba mais no site da shell</Title>
          <Description>Conheça os serviços dos Postos Shell.</Description>
          <Button href="https://www.shell.com.br/motoristas/lojas-shell-select.html" target="_blank">Acessar o Site</Button>
        </Column>
      </Content>
    </Container>
  )
}

export default BannerFooter;

const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);

  @media(max-width: ${props => props.theme.queries.medium}){
    grid-template-columns: repeat(1, 1fr);
  }
`;

const Image = styled.img`
  max-height: 334px;
  height: 100%;
  width: 100%;
`;

const Content = styled.div`
  background-color: ${props => props.theme.color.yellow};
  display: flex;
  align-items: center;

  @media(max-width: ${props => props.theme.queries.medium}){
    height: 334px;
  }

  @media(max-width: ${props => props.theme.queries.small}){
    height: 375px;
  }
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 106px;

  @media(max-width: ${props => props.theme.queries.medium}){
    padding: 66px 35px;
  }

  @media(max-width: ${props => props.theme.queries.small}){
    height: -webkit-fill-available;
  }
`;

const Title = styled.div`
  font-family: "Futura Bold";
  font-weight: 500;
  font-size: 25px;
  line-height: 40px;
  text-transform: uppercase;
  color: ${props => props.theme.color.gray};

  @media(max-width: ${props => props.theme.queries.small}){
    line-height: 30px;
  }
`;

const Description = styled.span`
  margin-top: 5px;
  font-family: "Futura LT Regular";
  font-weight: 500;
  font-size: 17px;
  line-height: 130%;
  color: ${props => props.theme.color.gray};

  @media(max-width: ${props => props.theme.queries.small}){
    margin-top: 8px;
  }
`;

const Button = styled.a`
  cursor: pointer;
  border: none;
  background-color: #622563;
  color: #fff;
  font-family: "Futura Bold";
  font-weight: 500;
  text-transform: uppercase;
  font-size: 17px;
  line-height: 23px;
  text-decoration: none;

  margin-top: 26px;
  width: 226px;
  height: 46px;

  display: flex;
  align-items: center;
  justify-content: center;
`;