import React, { useState } from "react";
import styled from "styled-components";
import Carousel from "react-multi-carousel";
import { Image, Button } from "@chakra-ui/core";
import "react-multi-carousel/lib/styles.css";
import useWindowSize from "hooks/useWIndowSize";

import chevronRed from "assets/icons/chevron-red.svg";
import chevronGray from "assets/icons/chevron-gray.svg";
import chevronBold from "assets/icons/chevron-bold.svg";
import arrow from "assets/icons/arrow.svg";

const CustomButtonGroupAsArrows = (props) => {
  const { width } = useWindowSize();
  const [arrowRed, setArrowRed] = useState(chevronRed);
  const [arrowRedLeft, setArrowRedLeft] = useState(chevronRed);

  return (
    <ContainerArrows>
      {props.carouselState.currentSlide === 0 ? (
        <NewButton
          onMouseOver={() => {
            if (width >= 1024) {
              setArrowRed(chevronRed);
              setArrowRedLeft(chevronRed);
            }
          }}
          onMouseOut={() => {
            if (width >= 1024) {
              setArrowRed(chevronRed);
              setArrowRedLeft(chevronRed);
            }
          }}
          onClick={props.previous}
          aria-label="Anterior"
          tabIndex={0}
          marginLeft="6px"
          marginRight="0"
          src={chevronGray}
        >
          Anterior
        </NewButton>
      ) : (
        <NewButton
          onClick={props.previous}
          aria-label="Anterior"
          tabIndex={0}
          onMouseOver={() => width >= 1024 && setArrowRedLeft(chevronBold)}
          onMouseOut={() => width >= 1024 && setArrowRedLeft(chevronRed)}
          transform={"rotate(180deg)"}
          src={arrowRedLeft}
          marginLeft="-5px"
          marginRight="0"
        >
          Anterior
        </NewButton>
      )}
      {props.carouselState.totalItems ===
      props.carouselState.currentSlide + props.carouselState.slidesToShow ? (
        <NewButton
          onClick={props.next}
          aria-label="Próximo"
          tabIndex={0}
          onMouseOver={() => {
            if (width >= 1024) {
              setArrowRed(chevronRed);
              setArrowRedLeft(chevronRed);
            }
          }}
          onMouseOut={() => {
            if (width >= 1024) {
              setArrowRed(chevronRed);
              setArrowRedLeft(chevronRed);
            }
          }}
          transform={"rotate(180deg)"}
          src={chevronGray}
          marginRight="6px"
          marginLeft="0"
        >
          Próximo
        </NewButton>
      ) : (
        <NewButton
          onClick={props.next}
          aria-label="Próximo"
          tabIndex={0}
          onMouseOver={() => width >= 1024 && setArrowRed(chevronBold)}
          onMouseOut={() => width >= 1024 && setArrowRed(chevronRed)}
          src={arrowRed}
          marginRight="-6px"
          marginLeft="0"
        >
          Próximo
        </NewButton>
      )}
    </ContainerArrows>
  );
};

const CustomButtonGroupAsArrows2 = (props) => {
  return (
    <ContainerArrowsType2>
      {props.carouselState.currentSlide === 0 ? (
        <ArrowButtonGray
          tabIndex={0}
          onClick={props.previous}
          aria-label="Anterior"
        >
          <ArrowIcon rotateArrow="180" src={arrow} />
        </ArrowButtonGray>
      ) : (
        <ArrowButtonOrange
          tabIndex={0}
          onClick={props.previous}
          aria-label="Anterior"
        >
          <ArrowIcon rotateArrow="180" src={arrow} />
        </ArrowButtonOrange>
      )}
      {props.carouselState.totalItems ===
      props.carouselState.currentSlide + props.carouselState.slidesToShow ? (
        <ArrowButtonGray tabIndex={0} onClick={props.next} aria-label="Próximo">
          <ArrowIcon src={arrow} />
        </ArrowButtonGray>
      ) : (
        <ArrowButtonOrange
          tabIndex={0}
          onClick={props.next}
          aria-label="Próximo"
        >
          <ArrowIcon src={arrow} />
        </ArrowButtonOrange>
      )}
    </ContainerArrowsType2>
  );
};

const CustomCarousel = (props) => {
  return (
    <WrapCarousel
      height={props.height}
      carouselListHeight={props.carouselListHeight}
    >
      <Carousel
        {...props}
        arrows={false}
        renderButtonGroupOutside={
          props.showArrows !== undefined ? props.showArrows : true
        }
        customButtonGroup={
          props.arrowsType === 2 ? (
            <CustomButtonGroupAsArrows2 />
          ) : (
            <CustomButtonGroupAsArrows />
          )
        }
        infinite={false}
        swipeable={props.swipeable !== undefined ? props.swipeable : false}
        draggable={props.draggable !== undefined ? props.draggable : true}
        responsive={props.responsive}
        containerClass="carousel-container"
        showDots={props.showDots !== undefined ? props.showDots : false}
        deviceType={props.deviceType}
        // partialVisible={props.partialVisible !== undefined ? props.partialVisible : false}
        dotListClass="custom-dot-list-style"
        itemClass="carousel-item-padding-40-px"
      >
        {props.children}
      </Carousel>
    </WrapCarousel>
  );
};

export default CustomCarousel;

const WrapCarousel = styled.div`
  height: ${(props) =>
    props.height !== undefined ? `${props.height}px` : "142px"};

  .react-multi-carousel-item {
    display: flex;
    justify-content: center;
  }

  .react-multi-carousel-list {
    height: ${(props) =>
      props.carouselListHeight !== undefined
        ? props.carouselListHeight
        : "100%"};
    z-index: 1;
  }

  /* arrow  */
  .react-multiple-carousel__arrow {
    z-index: 1;
    border-radius: 0;
    background-color: #fff;
  }

  .react-multiple-carousel__arrow--left {
    left: calc(1% + 1px);
  }
  .react-multiple-carousel__arrow--right {
    right: calc(1% + 1px);
  }
  .react-multiple-carousel__arrow:hover {
    background: rgba(0, 0, 0, 0.1);
  }

  /* DOTS */

  .react-multi-carousel-dot button {
    border: none !important;
    background: #c4c4c4;
  }

  .react-multi-carousel-dot--active button {
    background: ${(props) => props.theme.color.red};
  }

  .react-multi-carousel-dot-list {
    position: inherit;
  }
`;

const ContainerArrows = styled.div`
  max-width: 1400px;
  width: 100%;
  /* position: relative; */
  display: flex;
  justify-content: space-between;
  margin-top: -94px;
  button {
    z-index: 1;
    cursor: pointer;
    border: none;
  }
`;

const NewButton = styled(Button)`
  cursor: pointer;
  text-indent: -9999px;
  background-image: url(${(props) => props.src});
  background-repeat: no-repeat;
  padding: 0 !important;
  height: 50px !important;
  width: 22px !important;
  border: 2px solid transparent;
  background-color: transparent !important;
  :hover {
    box-shadow: none !important;
    background-color: transparent !important;
  }
`;

// Arrows Type 2

const ContainerArrowsType2 = styled(ContainerArrows)`
  margin-top: -320px;
  z-index: 10;
`;

const ArrowButtonGray = styled.button`
  background-color: ${(props) => props.theme.color.black} !important;
  width: 58px;
  height: 58px;
  opacity: 0.2;
`;

const ArrowButtonOrange = styled(ArrowButtonGray)`
  background-color: ${(props) => props.theme.color.orange} !important;
  opacity: 1;
  transition: 0.3s;
  &:hover {
    background-color: ${(props) => props.theme.color.red} !important;
  }
`;

const ArrowIcon = styled(Image)`
  height: 45px;
  width: 22px;
  transform: ${(props) =>
    props.rotateArrow ? `rotate(${props.rotateArrow}deg)` : "rotate(0deg)"};
`;
