import React from "react";
import styled from "styled-components";
import { withRouter } from "react-router-dom";
import queryString from "query-string";
import { Image, Box, useDisclosure } from "@chakra-ui/core";
import { theme } from "theme";

import BottomBannerShell from "modules/promocaoJunteTroque/Navbar/BottomBannerShell";
import LinksPromotion from "modules/common/Navbar/LinksPromotion";
// import ProductsModal from "modules/promocaoJunteTroque/PromotionHub/ProductsModal";
// import ProductsModalMobile from "modules/promocaoJunteTroque/PromotionHub/ProductsModalMobile";

import relogio from "assets/images/navbar/newAwards.png";
import { getSlug } from "utils/helper";

const BannerShellPromo = (props) => {
  const query = queryString.parse(props.location.search);
  const queryshellBox = query.shellbox;

  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <BoxPadding
      padding={queryshellBox?.includes("hiddenComponent") ? "0" : "3rem 0 0 0"}
      // padding={props.location.pathname !== "/" && "0"}
      // maxWidth={props.location.pathname !== "/"  && "100%"}
      display="flex"
      flexDirection="column"
    >
      {/* <ProductsModal
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
        type="modalBannerShell"
      />
      <ProductsModalMobile
        isOpen={isOpen}
        onClose={onClose}
        type="modalBannerShell"
      /> */}
      <Content backgroundColor={theme.color.yellow}>
        {/* {
          props.location.pathname === "/" && (
            <ActivePromo src={active} />
          )
        } */}
        {(window.location.pathname === getSlug().url ||
          window.location.pathname === `${getSlug().url}/meus-resgates` ||
          window.location.pathname === `${getSlug().url}/minhas-conchas`) && (
            <LinksPromotion />
          )}
        <Container paddingBottom={0}>
          <Column>
            <Junte>Junte</Junte>
            <Troque>e troque</Troque>
            <Curta>
              <b>Curta a vida no seu ritmo</b>
            </Curta>
          </Column>
          <ContainerImg>
            {/* <img src={ImageIveteClear} draggable="false" /> */}
            <WatchAndFone
              draggable="false"
              src={relogio}
              alt="Produtos da Promoção: Relógio e Fone"
            />
            <TextImg>Imagens ilustrativas</TextImg>
          </ContainerImg>
        </Container>
        {/* <ContainerButton>
          <MyButton
            backgroundColor={theme.color.white}
            rounded={0}
            border={0}
            color={theme.color.gray}
            fontSize={"14px"}
            fontWeight={"bold"}
            height="45px"
            cursor="pointer"
            className="redButton"
            onClick={onOpen}
          >
            CONHEÇA OS PRODUTOS
          </MyButton>
        </ContainerButton> */}
      </Content>
      {window.location.pathname === `${getSlug().url}` && <BottomBannerShell />}
      {window.location.pathname === `${getSlug().url}/` && (
        <BottomBannerShell />
      )}
    </BoxPadding>
  );
};

export default withRouter(BannerShellPromo);

const BoxPadding = styled(Box)`
  padding: 3rem 0rem 0rem 0rem;
`;

const Container = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  margin: 3rem auto 0 auto;
  max-width: 1210px;
  z-index: -1;

  @media (max-width: ${(props) => props.theme.queries.lg}) {
    max-width: 710px;
    padding-top: 3rem;
    /* padding: 2em 2em 0 2em; */
  }

  @media (max-width: ${(props) => props.theme.queries.medium}) {
    margin: 0 auto;
    margin-top: 32px;
  }
  @media (max-width: ${(props) => props.theme.queries.small}) {
    margin: 32px auto 0 auto;
    padding: 0em 1em 0 1em;
    flex-direction: column;
  }
  @media (max-width: ${(props) => props.theme.queries.i5}) {
    margin: 0;
    margin-top: 32px;
  }
`;

const Content = styled(Box)`
  height: 457px;
  @media (max-width: ${(props) => props.theme.queries.lg}) {
    height: 100%;
  }
`;

const Column = styled(Box)`
  margin-right: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (min-width: ${(props) => props.theme.queries.desk}) {
    margin-top: 2rem;
  }

  @media (max-width: ${(props) => props.theme.queries.lg}) {
    margin-left: 20px;
  }

  @media (max-width: ${(props) => props.theme.queries.small}) {
    margin: 0 auto;
  }
`;

const WatchAndFone = styled(Image)`
  padding: 2em 0 0em 0;

  @media (max-width: ${(props) => props.theme.queries.lg}) {
    padding: 0em 0 1em 0;
    height: 270px;
  }

  @media (max-width: ${(props) => props.theme.queries.small}) {
    margin-top: 1rem;
    height: 195px;
  }
`;

const Junte = styled.h2`
  font-family: "Futura LT Bold";
  font-weight: 500;
  margin: 0;
  padding: 0;
  font-size: 9rem;
  text-transform: uppercase;
  color: ${(props) => props.theme.color.gray};
  line-height: 9rem;
  @media (max-width: ${(props) => props.theme.queries.lg}) {
    font-size: 6rem;
    line-height: 5rem;
  }
  @media (max-width: ${(props) => props.theme.queries.small}) {
    font-size: 5rem;
    line-height: 4rem;
  }
  @media (max-width: ${(props) => props.theme.queries.i5}) {
    font-size: 4.5rem;
    line-height: 3.5rem;
  }
`;

const Troque = styled.h2`
  font-family: "Futura LT Bold";
  font-weight: 500;
  margin: 0;
  padding: 0;
  font-size: 5.6rem;
  text-transform: uppercase;
  color: ${(props) => props.theme.color.red};
  line-height: 6rem;
  @media (max-width: ${(props) => props.theme.queries.lg}) {
    font-size: 3.7rem;
    line-height: 4.5rem;
  }
  @media (max-width: ${(props) => props.theme.queries.small}) {
    font-size: 3.1rem;
    line-height: 3.5rem;
  }
  @media (max-width: ${(props) => props.theme.queries.i5}) {
    font-size: 2.8rem;
  }
`;

const Curta = styled.h3`
  margin: 0 0 0 0.5rem;
  padding: 0;
  /* font-size: 2.05rem; */
  font-size: 2rem;
  font-weight: 500;
  text-transform: uppercase;
  color: ${(props) => props.theme.color.gray};

  @media (-webkit-min-device-pixel-ratio: 2) {
    :not(:root:root) {
      font-size: 2rem;
      margin: 0;
    }
  }

  @media (max-width: ${(props) => props.theme.queries.lg}) {
    margin: 0 0 0 0.2rem;
    font-size: 1.34rem;
  }

  @media (-webkit-min-device-pixel-ratio: 2) and (max-width: ${(props) => props.theme.queries.lg}) {
    :not(:root:root) {
      font-size: 1.19rem;
    }
  }

  @media (max-width: ${(props) => props.theme.queries.small}) {
    font-size: 1.12rem;
    margin: 0;
  }

  @media (-webkit-min-device-pixel-ratio: 2) and (max-width: ${(props) => props.theme.queries.small}) {
    :not(:root:root) {
      font-size: 1rem;
    }
  }

  @media (max-width: ${(props) => props.theme.queries.i5}) {
    font-size: 1rem;
  }

  @media (-webkit-min-device-pixel-ratio: 2) and (max-width: ${(props) => props.theme.queries.i5}) {
    :not(:root:root) {
      font-size: 0.9rem;
    }
  }
`;

const TextImg = styled.span`
  font-family: "Futura LT Regular";
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 14px;
  color: #867217;
  margin-top: -20px;

  @media (max-width: ${(props) => props.theme.queries.lg}) {
    margin-top: -22px;
  }
  @media (max-width: ${(props) => props.theme.queries.small}) {
    margin-top: -18px;
  }
`;

const ContainerImg = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  margin-bottom: 10px;

  img {
    width: 100%;
    height: auto;
    padding-top: 41px;
    margin: auto;

    @media (max-width: ${(props) => props.theme.queries.lg}) {
      padding-top: 10px;
      margin: 0 auto;
      /* position: relative;
         top: 10px; */
    }
    @media (max-width: ${(props) => props.theme.queries.small}) {
      /* left: 30px; */
    }
    @media (max-width: ${(props) => props.theme.queries.i5}) {
      left: -5px;
    }
  }
`;
