import React from "react";
import styled from "styled-components";
import FinishedPromotions from "../PromosEncerradas/FinishedPromotions";
import Banner from "../RoadPromo/Banner/Banner";
import VideoSection from "../RoadPromo/SecaoVideo/VideoSection";

// Imagens
import ImageCaminhaoRecordar from "assets/images/hub/RoadPromotions/encerrada_caminhao_recordar.jpg";
import ImageCreditosVoce from "assets/images/hub/RoadPromotions/encerrada_creditos_voce.jpg";
import ImageHerois from "assets/images/hub/RoadPromotions/encerrada_herois.jpg";
import ImagePoetasEstrada from "assets/images/hub/RoadPromotions/encerrada_poetas_estrada.jpg";
import PromoSorteACaminho from "assets/banners/PromosEncerradas/promo-sorte-caminho.png";

const RoadPromotions = () => {
  const imageList = [
    {
      url: PromoSorteACaminho,
      alt: "Promoção sorte a caminho de casa",
    },
    {
      url: ImageHerois,
      alt: "Promoção Heróis da estrada",
    },
    {
      url: ImagePoetasEstrada,
      alt: "Promoção poetas da estrada",
    },
    {
      url: ImageCaminhaoRecordar,
      alt: "Promoção um caminhão pra recordar",
    },
    {
      url: ImageCreditosVoce,
      alt: "Promoção créditos pra você",
    },
  ];

  return (
    <div>
      <Banner />
      {/* <Content>
        <VideoSection />
      </Content> */}
      <CarouselWrapper>
        <FinishedPromotions imageList={imageList} />
      </CarouselWrapper>
    </div>
  );
};

export default RoadPromotions;

const Content = styled.div`
  max-width: 1400px;
  margin: 65px auto 88px auto;
  padding: 0 2em;

  @media (max-width: 1200px) {
    padding: 0 1.5em;
  }

  @media (max-width: ${(props) => props.theme.queries.small}) {
    margin: 65px auto 62px auto;
  }
`;

const CarouselWrapper = styled.section`
  display: flex;
  
  @media (min-width: 1200px) {
    margin-top: 3rem;
  }
`
