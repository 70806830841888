import styled from "styled-components"
import { Accordion, AccordionHeader, AccordionIcon, AccordionItem, AccordionPanel, Box } from "@chakra-ui/core";

export const DuvidasWrapper = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  background: ${props => props.theme.color.lightGray50};
  width: 100%;
`

export const DuvidasContainer = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  width: 90%;
  max-width: 425px;
  margin: 1.5rem auto 0;

  @media (min-width: ${props => props.theme.queries.lg}) {
    max-width: unset;
  }
`

export const CustomAcordion = styled(Accordion)`
  padding-top: 0;
  margin-top: 0;
  margin: 0 auto;
  max-width: 830px;

  @media (max-width: ${(props) => props.theme.queries.small}) {
    .rah-animating--down {
      min-height: 200px !important;
    }

    .rah-static--height-auto {
      min-height: 200px !important;
    }
  }

  .css-vppuuz {
    transition: height 0.5s ease, opacity 0.5s ease-in-out,
      transform 0.5s ease-in-out;
  }

  button {
    transition: all 0.3s !important;
    height: unset;
    .btn-chevron {
      background-color: ${(props) => props.theme.color.yellow};
      color: ${(props) => props.theme.color.shellPurple};
      border-radius: 0 8px 8px 0;
      svg {
        path {
          fill: ${(props) => props.theme.color.gray};
        }
      }
    }
    &:hover {
      background-color: #fff;
    }
  }

  button[aria-expanded="true"] {
    .btn-chevron {
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
      svg {
        width: 12px;
        height: 12px;
        transition: 0.5s;
      }
    }
  }
  button[aria-expanded="false"] {
    .btn-chevron {
      svg {
        transition: 0.5s;
      }
    }
  }
`;

export const MyAccordionHeader = styled(AccordionHeader)`
  cursor: pointer;
  height: 70px;
  background-color: #fff;
  border-top: 0;
  border-bottom: none;
  border-left: none;
  border-right: 0px;
  padding: 0px !important;
  border-radius: 8px;
  @media (max-width: ${(props) => props.theme.queries.small}) {
    height: 100%;
  }
`;

export const ContainerIcon = styled.div`
  display: flex;
  flex-direction: center;
  align-items: center;
  justify-content: center;
  background-color: #e98624;
  min-height: 70px;
  width: 34px;
  cursor: pointer;
  align-self: stretch;
`;

export const MyAccordionIcon = styled(AccordionIcon)`
  color: white !important;
  width: 1.5rem !important;
  height: 1.5rem !important;
`;

export const MyAccordionBox = styled(Box)`
  font-size: 1.125rem;
  line-height: 140%;
  color: ${(props) => props.theme.color.darkGray};
  padding: 0 1.125rem;
  font-family: ${(props) => props.theme.Shell.Book};
  @media (min-width: ${(props) => props.theme.queries.medium}) {
    font-size: 1.375rem;
    padding: 0 0.563rem;
  }
`;

export const MyAccordionPanel = styled(AccordionPanel)`
  background-color: #fff;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  padding: 0.5rems 1rem;
  color: ${(props) => props.theme.color.text};

  span {
    font-family: Arial;
    font-size: 1rem;
    font-weight: normal;
    line-height: 140%;
    color: ${(props) => props.theme.color.text};
  }
  a {
    color: #58595b;
    text-decoration: underline;
    transition: 0.3s;
    white-space: nowrap;
    cursor: pointer;
    &:hover {
      color: #e98624;
    }
  }
  border-right: 34px solid ${(props) => props.theme.color.lightYellow};

  @media (min-width: ${(props) => props.theme.queries.medium}) {
    span {
      font-size: 1.125rem;
    }
  }
`;

export const CustomAccordionItem = styled(AccordionItem)`
  font-family: "Helveitca neue";
  border-radius: 8px;
  background-color: #fff;
`;