import { thunk, action, Action, Thunk } from "easy-peasy";
import {
  GetCategories,
  GetFaq,
  GetProducts,
  GetRestrictedSliderCards,
  GetShell,
  GetSliderCards,
} from "services/anoTodo";
import { get } from "lodash";
import { GetHubBanner } from "services/hub";

export interface ProductsProps {
  name: string;
  description: string;
  featured: boolean;
  feature_img: string;
  imgSmall: {
    arquivo: string;
    descricao: string;
    order: number;
    tipo: string;
  }[];
  imgLarge: {
    arquivo: string;
    descricao: string;
    order: number;
    tipo: string;
  }[];
  shellCost: number;
  price: string;
  priceShells: string;
  productId: number;
  tag: {
    cor_fundo: string;
    cor_titulo: string;
    titulo: string;
  }[];
  order: number;
  manual: string;
  indisponivel: boolean;
}

type ProductResponseProps = {
  conchas: string;
  descricao: string;
  destaque: boolean;
  id_produto: string;
  imagem_destaque: string;
  large_images: {
    arquivo: string;
    descricao: string;
    order: number;
    tipo: string;
  }[];
  small_images: {
    arquivo: string;
    descricao: string;
    order: number;
    tipo: string;
  }[];
  manual: string;
  nome: string;
  ordem: number;
  preco_com_conchas: string;
  preco_sem_conchas: string;
  tags: {
    cor_fundo: string;
    cor_titulo: string;
    titulo: string;
  }[];
  indisponivel: boolean;
};

type CarouselCardProps = {
  descricao: string;
  imagem_fundo: string;
  nova_janela: boolean;
  ordem: number;
  titulo: string;
  url: string;
  sso: boolean;
  imagem_popup_desktop: string;
  imagem_popup_responsiva: string;
  popup: boolean;
  produto_id_sso: number;
};

type FaqProps = {
  pergunta: string;
  resposta: string;
};

type HubBannerProps = {
  encerrada: boolean;
  imagem_desktop: string;
  imagem_responsiva: string;
  imagem_encerrada: string;
  tipo: string;
};

type CategoryProductsProps = {
  nome: string;
  ordem: number;
  cor_fundo: string;
  cor_fonte: string;
  imagem_desktop: string;
  imagem_mobile: string;
  produtos: ProductResponseProps[];
};

export interface AnoTodoModel {
  isLoading: boolean;
  isProductLoading: boolean;
  isLoadingCards: boolean;
  isLoadingCatProducts: boolean;
  advertisementLoading: boolean;
  messageError: string;
  shellError: any;
  shells: {
    accumulated: number;
    balance: number;
    redeemed: number;
  };
  carouselCards: CarouselCardProps[];
  carouselRestrictedCards: CarouselCardProps[];
  isPromoFooter: boolean;

  products: ProductsProps[];
  featuredProducts: ProductsProps[];
  categoryProducts: CategoryProductsProps[];
  productsSlide: ProductsProps[];
  faqContent: FaqProps[];
  hubBanner: HubBannerProps;
  buttonClickCount: number;

  setShells: Action<AnoTodoModel, any>;
  setProducts: Action<AnoTodoModel, any>;
  setCarouselCards: Action<AnoTodoModel, any>;
  setCarouselRestrictedCards: Action<AnoTodoModel, any>;
  setFeaturedProducts: Action<AnoTodoModel, any>;
  setLoading: Action<AnoTodoModel, any>;
  setLoadingProducts: Action<AnoTodoModel, any>;
  setLoadingCategoryProducts: Action<AnoTodoModel, any>;
  setLoadingCards: Action<AnoTodoModel, any>;
  setError: Action<AnoTodoModel, any>;
  setProductFree: Action<AnoTodoModel, any>;
  setFAQ: Action<AnoTodoModel, any>;
  setIsPromoFooter: Action<AnoTodoModel, void>;
  setHubBanner: Action<AnoTodoModel, any>;
  setCategoryProducts: Action<AnoTodoModel, any>;
  setButtonClickCount: Action<AnoTodoModel, number>;

  getShells: Thunk<AnoTodoModel, void>;
  getFAQ: Thunk<AnoTodoModel, void>;
  getProducts: Thunk<AnoTodoModel, void>;
  getCarouselCards: Thunk<AnoTodoModel, void>;
  getCarouselRestricted: Thunk<AnoTodoModel, void>;
  // checkPremiumProduct: Thunk<AnoTodoModel, void>;
  loadAll: Thunk<AnoTodoModel, void>;
  getIsPromoFooter: Thunk<AnoTodoModel, void>;
  getHubBanner: Thunk<AnoTodoModel, void>;
  getCategoriesProducts: Thunk<AnoTodoModel, void>;
}

const anoTodo: AnoTodoModel = {
  isLoading: false,
  isProductLoading: false,
  isLoadingCards: false,
  isLoadingCatProducts: false,
  advertisementLoading: false,
  messageError: "",
  shellError: "",
  shells: null,
  carouselCards: null,
  carouselRestrictedCards: null,
  products: null,
  featuredProducts: null,
  productsSlide: null,
  faqContent: null,
  isPromoFooter: null,
  hubBanner: null,
  categoryProducts: null,
  buttonClickCount: 1,

  // Setters
  setButtonClickCount: action((state, payload: number) => {
    state.buttonClickCount = payload;
  }),
  setShells: action((state: any, payload: any) => {
    state.shells = payload.data;
  }),
  setProducts: action((state: { products: ProductsProps[] }, payload: any) => {
    const products: ProductsProps[] = payload?.map(
      (item: ProductResponseProps) => {
        return {
          name: item.nome,
          description: item.descricao,
          featured: item.destaque,
          feature_img: item.imagem_destaque,
          imgSmall: item.small_images,
          imgLarge: item.large_images,
          shellCost: Number(item.conchas),
          price: item.preco_sem_conchas,
          priceShells: item.preco_com_conchas,
          productId: Number(item.id_produto),
          tag: item.tags,
          order: item.ordem,
          manual: item.manual,
          indisponivel: item.indisponivel,
        };
      }
    );
    const filteredProducts = products.filter((product) => !product.featured);
    // const auth = localStorage.getItem("Hub-auth");
    state.products = filteredProducts.sort((prodA, prodB) =>
      prodA.order > prodB.order ? 1 : -1
    );
  }),
  setFeaturedProducts: action(
    (state: { featuredProducts: ProductsProps[] }, payload: any) => {
      const products: ProductsProps[] = payload?.map(
        (item: ProductResponseProps) => {
          return {
            name: item.nome,
            description: item.descricao,
            featured: item.destaque,
            feature_img: item.imagem_destaque,
            imgSmall: item.small_images,
            imgLarge: item.large_images,
            shellCost: Number(item.conchas),
            price: item.preco_sem_conchas,
            priceShells: item.preco_com_conchas,
            productId: Number(item.id_produto),
            tag: item.tags,
            order: item.ordem,
            manual: item.manual,
            indisponivel: item.indisponivel,
          };
        }
      );
      const filteredProducts = products.filter(
        (product) => product?.featured === true
      );
      state.featuredProducts = filteredProducts.sort((prodA, prodB) =>
        prodA.order > prodB.order ? 1 : -1
      );
    }
  ),
  setCategoryProducts: action(
    (
      state: { categoryProducts: CategoryProductsProps[] },
      payload: CategoryProductsProps[]
    ) => {
      const productsOrdered = payload.map((item) => {
        return {
          ...item,
          produtos: item.produtos.sort((prodA, prodB) =>
            prodA.ordem > prodB.ordem ? 1 : -1
          ),
        };
      });
      const orderedCatProd = productsOrdered.sort((catProdA, catProdB) =>
        catProdA.ordem > catProdB.ordem ? 1 : -1
      );
      state.categoryProducts = orderedCatProd;
    }
  ),
  setCarouselCards: action(
    (state: { carouselCards: CarouselCardProps[] }, payload: any) => {
      const cards = payload?.sort((cardA, cardB) =>
        cardA?.ordem > cardB?.ordem ? 1 : -1
      );
      state.carouselCards = cards;
    }
  ),
  setCarouselRestrictedCards: action(
    (state: { carouselRestrictedCards: CarouselCardProps[] }, payload: any) => {
      const cards = payload?.sort((cardA, cardB) =>
        cardA?.ordem > cardB?.ordem ? 1 : -1
      );
      let authCards: CarouselCardProps[] = cards;
      /* 
      Quando logado, adiciona um elemento vazio no começo do array, 
      para ser substituído pelo card de conchas 
    */
      authCards?.push({
        descricao: null,
        imagem_fundo: null,
        nova_janela: false,
        ordem: 0,
        titulo: null,
        url: null,
        sso: false,
        imagem_popup_desktop: null,
        imagem_popup_responsiva: null,
        popup: false,
        produto_id_sso: null,
      });
      state.carouselRestrictedCards = authCards;
    }
  ),
  setLoading: action((state: { isLoading: boolean }, payload: any) => {
    const loading = get(payload, "loading", false);
    state.isLoading = loading;
  }),
  setLoadingProducts: action(
    (state: { isProductLoading: boolean }, payload: any) => {
      state.isProductLoading = payload;
    }
  ),
  setLoadingCategoryProducts: action(
    (state: { isLoadingCatProducts: boolean }, payload: boolean) => {
      state.isLoadingCatProducts = payload;
    }
  ),
  setLoadingCards: action(
    (state: { isLoadingCards: boolean }, payload: any) => {
      state.isLoadingCards = payload;
    }
  ),
  setError: action((state: { messageError: string }, payload: any) => {
    const message = get(payload, "message", false);
    state.messageError = message;
  }),
  setProductFree: action((state: any, payload: any) => {
    state.productFree = payload;
  }),
  setFAQ: action((state: { faqContent: FaqProps[] }, payload: any) => {
    state.faqContent = payload;
  }),
  setIsPromoFooter: action(
    (state: { isPromoFooter: boolean }, payload: any) => {
      state.isPromoFooter = payload;
    }
  ),
  setHubBanner: action((state: { hubBanner: HubBannerProps }, payload: any) => {
    state.hubBanner = {
      encerrada: payload?.encerrada,
      imagem_desktop: payload?.imagem_desktop,
      imagem_encerrada: payload?.imagem_encerrada,
      imagem_responsiva: payload?.imagem_responsiva,
      tipo: payload?.tipo,
    };
  }),

  // Getters
  getCarouselCards: thunk(async (action: any, payload: any) => {
    action.setLoadingCards(true);
    try {
      const res = await GetSliderCards();
      action.setCarouselCards(res?.data);
      action.setLoadingCards(false);
    } catch (err) {
      console.error(err);
      action.setLoadingCards(false);
    }
  }),
  getCarouselRestricted: thunk(async (action: any, payload: any) => {
    action.setLoadingCards(true);
    try {
      const res = await GetRestrictedSliderCards();
      action.setCarouselRestrictedCards(res.data);
      action.setLoadingCards(false);
    } catch (err) {
      console.error(err);
      action.setLoadingCards(false);
    }
  }),
  loadAll: thunk(async (action: any, payload: any) => {
    action.setLoading({ loading: true });
    try {
      const resShell = await GetShell();
      action.setShells(resShell.data);

      const resProduct = await GetProducts();
      action.setProducts(resProduct.data);

      action.setLoading({ loading: false });
    } catch (err) {
      console.error(err);
      action.setError({
        message: "Não foi possivel obter os dados, tente novamente mais tarde!",
      });
      action.setLoading({ loading: false });
    }
  }),
  getShells: thunk(async (action: any, payload: any) => {
    action.setLoading({ loading: true });
    try {
      const res = await GetShell();
      action.setShells(res.data);
      action.setLoading({ loading: false });
    } catch (err) {
      console.error(err);
      action.setError({
        message: "Não foi possivel obter os dados, tente novamente mais tarde!",
      });
      action.setLoading({ loading: false });
    }
  }),
  getProducts: thunk(async (action: any, payload: any) => {
    action.setLoadingProducts(true);
    try {
      const res = await GetProducts();
      action.setFeaturedProducts(res.data);
      action.setProducts(res.data);

      action.setLoadingProducts(false);
    } catch (err) {
      console.error(err);
      action.setError({
        message: "Não foi possivel obter os dados, tente novamente mais tarde!",
      });
      action.setLoadingProducts(false);
    }
  }),
  getCategoriesProducts: thunk(async (action: any, payload: any) => {
    action.setLoadingCategoryProducts(true);
    const resCatProducts = await GetCategories();
    action.setCategoryProducts(resCatProducts?.data);
    action.setLoadingCategoryProducts(false);
  }),
  getFAQ: thunk(async (action: any, payload: any) => {
    action.setLoading(true);
    try {
      const res = await GetFaq();
      action.setFAQ(res.data);
      action.setLoading(false);
    } catch (error) {
      console.error(error);
      action.setLoading(false);
    }
  }),
  getIsPromoFooter: thunk(async (action: any, payload: any) => {
    try {
      const resPromoFooter = JSON.parse(sessionStorage.getItem("promo_footer"));
      action.setIsPromoFooter(resPromoFooter);
    } catch (err) {
      console.warn(err);
    }
  }),
  getHubBanner: thunk(async (action: any, payload: any) => {
    try {
      if (
        window.location.host.includes("qa") ||
        window.location.host.includes("dev") ||
        window.location.host.includes("localhost")
      ) {
        const resHubBanner = await GetHubBanner(101);
        action.setHubBanner(resHubBanner?.data);
      } else {
        const resHubBanner = await GetHubBanner(134);
        action.setHubBanner(resHubBanner?.data);
      }
    } catch (err) {
      console.error(err);
    }
  }),
};

export default anoTodo;
