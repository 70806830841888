import React, { useEffect } from "react"
import styled from "styled-components"
import { useActions, useStore } from "configureStore"
import useWindowSize from "hooks/useWIndowSize"
import { CarouselWebdoorSlide } from "./CarouselSlide"
import Carousel from "react-multi-carousel"
import "react-multi-carousel/lib/styles.css";
import { Box, Spinner } from "@chakra-ui/core"
import { theme } from "theme"

export function CarouselWebdoorBanner() {
  const isLoading = useStore((state) => state.hub.isCarouselLoading)
  const getWebdoorCarousel = useActions((action) => action.hub.getWebdoorCarousel)
  const webdoorCarousel = useStore((state) => state.hub.webdoorCarousel)
  const { width } = useWindowSize()

  useEffect(() => {
    getWebdoorCarousel()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isLoading) {
    return (
      <Box
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
        marginTop={["3.5rem", "3.5rem", "3.5rem", "6.5rem"]}
      >
        <Spinner
          margin={"0 auto"}
          size="xl"
          color={theme.color.orange}
          emptyColor={theme.color.lightGray200}
          thickness="6px"
        />
      </Box>
    )
  }

  return (
    <Container>
      <Carousel
        responsive={{
          desktop: {
            breakpoint: { max: 4000, min: 1200 },
            items: 1,
            slidesToSlide: 1
          },
          mobile: {
            breakpoint: { max: 1199, min: 0 },
            items: 1,
            slidesToSlide: 1
          }
        }}
        arrows={false}
        minimumTouchDrag={30}
        showDots
        renderDotsOutside
      >
        {webdoorCarousel?.map((slide) => (
          <SlideContainer key={slide.imagem_desktop}>
            <CarouselWebdoorSlide
              href={slide.url}
              isNewTab={slide.nova_janela}
            >
              <img
                src={width <= 540 ? slide.imagem_responsiva : slide.imagem_desktop}
                draggable="false"
                alt=""
              />
            </CarouselWebdoorSlide>
          </SlideContainer>
        ))}
      </Carousel>
    </Container>
  )
}

const Container = styled.section`
  width: 100%;
  margin-top: 2.5rem;
  padding-bottom: 1.75rem;
  position: relative;

  li.react-multi-carousel-dot {
    button {
      background-color: #e2d7e2;
      border: 1px solid #000;
      width: 10px;
      height: 10px;
      &:focus {
        outline: auto;
      }
    }
  }
  li.react-multi-carousel-dot--active {
    button {
      background-color: ${(props) => props.theme.color.yellow};
    }
  }
  
  @media (min-width: ${props => props.theme.queries.lg}) {
    margin-top: 5.5rem;

    li.react-multi-carousel-dot {
      button {
        width: 14px;
        height: 14px;
        margin: 0 3px;
      }
    }
  }
`
const SlideContainer = styled.article`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;

  img {
    display: block;
    width: 100%;
    margin: 0 auto;
  }

  a,div.slide-item {
    display: flex;
    width: 100%;
    cursor: pointer;
    border: 0;
    background: transparent;
    padding: 0;
    margin: 0;
  }
`