import React, { useEffect } from "react";
import styled from "styled-components";
import { auth as useAuth } from "hooks/auth";
import { NavigationAnoTodo } from "../components/Navigation/NavigationAnoTodo";
import {
  Accordion,
  AccordionHeader,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Spinner,
} from "@chakra-ui/core";
import { useActions, useStore } from "configureStore";

export default function HelpAnoTodo() {
  const { auth } = useAuth();
  const isAuthenticated = auth.isAuthenticated;
  const isShellbox = window.location.search.includes("shellbox");
  const getFAQ = useActions((action) => action.anoTodo.getFAQ);
  const faq = useStore((state) => state.anoTodo.faqContent);
  const isLoading = useStore((state) => state.anoTodo.isLoading);

  document.title = "Promoshell | Dúvidas";

  useEffect(() => {
    getFAQ();
  }, [getFAQ]);

  return (
    <Container isAuth={isAuthenticated} isShellbox={isShellbox}>
      <HeaderWrapper>
        <NavigationAnoTodo />
        <h1>Dúvidas</h1>
      </HeaderWrapper>
      <Box
        as={"main"}
        display={"flex"}
        flexDir={"column"}
        justifyContent={"center"}
        alignItems={"flex-start"}
        w={"90%"}
        m={"0 auto"}
        p={"38px 0"}
      >
        {isLoading ? (
          <Spinner />
        ) : (
          faq?.map((faq, index) => (
            <CustomAcordion
              defaultIndex={0}
              width={"100%"}
              allowMultiple
              key={index}
            >
              <CustomAccordionItem marginBottom={"30px"} height="100%">
                <MyAccordionHeader>
                  <MyAccordionBox flex="1" textAlign="left">
                    {faq?.pergunta}
                  </MyAccordionBox>
                  <ContainerIcon className={"btn-chevron"}>
                    <MyAccordionIcon />
                  </ContainerIcon>
                </MyAccordionHeader>
                <MyAccordionPanel pb={4}>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: faq?.resposta,
                    }}
                  />
                </MyAccordionPanel>
              </CustomAccordionItem>
            </CustomAcordion>
          ))
        )}
      </Box>
    </Container>
  );
}

const Container = styled(Box)`
  margin-top: ${(props) => (!props.isShellbox ? "40px" : "0")};
  background-color: ${(props) => props.theme.color.lightGray50};

  padding-bottom: ${(props) =>
    props.isAuth || props.isShellbox ? "3rem" : "0"};
  margin-bottom: ${(props) => (props.isShellbox ? "2rem" : "0")};
`;

const HeaderWrapper = styled.header`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  box-sizing: border-box;

  background-color: ${(props) => props.theme.color.shellPurple};
  padding: 8px 0 0.75rem;

  h1 {
    font-family: ${(props) => props.theme.Shell.Book};
    font-weight: 400;
    font-size: 1.875rem;
    color: #fff;
    margin: 0;
    margin-top: 1.5rem;
  }

  @media (min-width: ${(props) => props.theme.queries.lg}) {
    padding: 4px 0 1.75rem;

    h1 {
      font-size: 2.25rem;
      margin-top: 3.75rem;
    }
  }
`;

const MyAccordionHeader = styled(AccordionHeader)`
  cursor: pointer;
  height: 70px;
  background-color: #fff;
  border-top: 0;
  border-bottom: none;
  border-left: none;
  border-right: 0px;
  padding: 0px !important;
  border-radius: 3px;
  @media (max-width: ${(props) => props.theme.queries.small}) {
    height: 100%;
  }
`;

const ContainerIcon = styled.div`
  display: flex;
  flex-direction: center;
  align-items: center;
  justify-content: center;
  background-color: #e98624;
  min-height: 70px;
  width: 34px;
  cursor: pointer;
  align-self: stretch;
`;

const MyAccordionIcon = styled(AccordionIcon)`
  color: white !important;
  width: 1.5rem !important;
  height: 1.5rem !important;
`;

const MyAccordionBox = styled(Box)`
  font-size: 1.125rem;
  line-height: 140%;
  color: ${(props) => props.theme.color.shellPurple};
  padding: 0 1.125rem;
  font-family: ${(props) => props.theme.Shell.Book};
  @media (min-width: ${(props) => props.theme.queries.medium}) {
    font-size: 1.375rem;
    padding: 0 0.563rem;
  }
`;

const MyAccordionPanel = styled(AccordionPanel)`
  background-color: #fff;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  padding: 0.5rems 1rem;

  span {
    font-family: Arial;
    font-size: 1rem;
    font-weight: normal;
    line-height: 140%;
    color: ${(props) => props.theme.color.darkGray};
  }
  a {
    color: #58595b;
    text-decoration: underline;
    transition: 0.3s;
    white-space: nowrap;
    cursor: pointer;
    &:hover {
      color: #e98624;
    }
  }
  border-right: 34px solid ${(props) => props.theme.color.lightYellow};

  @media (min-width: ${(props) => props.theme.queries.medium}) {
    span {
      font-size: 1.125rem;
    }
  }
`;

const CustomAcordion = styled(Accordion)`
  padding-top: 0;
  margin-top: 0;
  margin: 0 auto;
  max-width: 900px;

  @media (max-width: ${(props) => props.theme.queries.small}) {
    .rah-animating--down {
      min-height: 200px !important;
    }

    .rah-static--height-auto {
      min-height: 200px !important;
    }
  }

  .css-vppuuz {
    transition: height 0.5s ease, opacity 0.5s ease-in-out,
      transform 0.5s ease-in-out;
  }

  button {
    transition: all 0.3s !important;
    height: unset;
    .btn-chevron {
      background-color: ${(props) => props.theme.color.yellow};
      color: ${(props) => props.theme.color.shellPurple};
      border-radius: 3px;
      svg {
        path {
          fill: ${(props) => props.theme.color.purple};
        }
      }
    }
    &:hover {
      background-color: #fff;
      .btn-chevron {
        background-color: ${(props) => props.theme.color.shellPurple};
        color: ${(props) => props.theme.color.yellow};
        svg {
          path {
            fill: ${(props) => props.theme.color.yellow};
          }
        }
      }
    }
  }

  button[aria-expanded="true"] {
    .btn-chevron {
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
      svg {
        width: 12px;
        height: 12px;
        transition: 0.5s;
      }
    }
  }
  button[aria-expanded="false"] {
    .btn-chevron {
      svg {
        transition: 0.5s;
      }
    }
  }
`;

const CustomAccordionItem = styled(AccordionItem)`
  font-family: "Helveitca neue";
  border-radius: 3px;
  background-color: #fff;
`;
