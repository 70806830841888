import React, { ReactNode } from "react";
import { LayoutContainer, LayoutContent } from "./styles";
import { PromoHeader2024 } from "../components/PromoHeader";
import { useLocation } from "react-router-dom";
import { getSlug } from "utils/helper";
import { ButtonWhatsApp } from "./ButtonWhatsApp";

interface LayoutGpProps {
  children: ReactNode;
}

export function LayoutGp2024({ children }: LayoutGpProps) {
  const location = useLocation();
  const isShellbox = window.location.search.includes("shellbox");
  const { url: slug } = getSlug();
  // const { width } = useWindowSize();
  // const isMobile320 = width <= 320;

  return (
    <LayoutContainer id={"layout-gp"} $isShellbox={isShellbox}>
      {/* {isMobile320 ? <></> : <ButtonWhatsApp />} */}
      <ButtonWhatsApp />
      <PromoHeader2024 />
      <LayoutContent $isHome={location.pathname === slug}>
        {children}
      </LayoutContent>
      {/* <PromoFooter /> */}
    </LayoutContainer>
  );
}
