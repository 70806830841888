import React from "react"
import { ButtonShellboxDownload, JunteTroqueContainer, JunteTroqueContainerMobile, JunteTroqueLettering } from "./styles"
import useWindowSize from "hooks/useWIndowSize"
import { GrDownload } from "react-icons/gr"

import ImageJunteTroqueModal from "assets/images/anoTodo/junte-troque-modal.png"
import ImageJunteTroqueModalMobile from "assets/images/anoTodo/junte-troque-modal-mobile.png"
import { useActions } from "configureStore"

export function JunteTroqueModal() {
  const { width } = useWindowSize()
  const shellboxDownloadLink = "https://shellbox.onelink.me/RT9I/49c873d1"
  
  if (width < 1024) {
    return (
      <JunteTroqueContainerMobile>
        <a
          href={shellboxDownloadLink}
          aria-label="baixar shell box (abre em uma nova guia)"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={ImageJunteTroqueModalMobile} alt="" draggable="false" />
        </a>
      </JunteTroqueContainerMobile>
    )
  }

  return (
    <JunteTroqueContainer>
      <JunteTroqueLettering>
        <h1>
          Baixe o Shell Box <br />
          e comece a resgatar <br />
          produtos agora mesmo
        </h1>
        <p>
          Clique no card da Junte&Troque na tela inicial do aplicativo <br />
          e tenha acesso a regate de produtos exclusivos, status dos <br />
          pedidos realizados e muito mais.
        </p>
        <ButtonShellboxDownload
          href={shellboxDownloadLink}
          aria-label="baixar shell box (abre em uma nova guia)"
          target="_blank"
          rel="noopener noreferrer"
        >
          <GrDownload size={28} />
          Baixar Shell Box
        </ButtonShellboxDownload>
      </JunteTroqueLettering>
      <img src={ImageJunteTroqueModal} alt="" draggable="false" />
    </JunteTroqueContainer>
  )
}