import React from "react";
import styled from "styled-components";
import { withRouter } from "react-router-dom";
import { Tabs } from "@chakra-ui/core";

const CustomTab = (props) => {
  return <MyTabs {...props}>{props.children}</MyTabs>;
};

export default withRouter(CustomTab);

const MyTabs = styled(Tabs)`
  display: none;
  @media (max-width: ${(props) => props.theme.queries.medium}) {
    background-color: white;
    display: block !important;

    .css-1dxxh68 {
      justify-content: center;
      border-bottom: 1px solid !important;
      border-color: ${(props) => props.theme.color.lightGray} !important;
      height: 48px;
      margin: 0 10px;
    }
    button {
      color: ${(props) => props.theme.color.gray};
      background-color: ${(props) => props.theme.color.white};
      border: none;
      border-bottom: 1px solid ${(props) => props.theme.color.white};
      width: 220px;
      font-size: 11px;
    }
    .css-w6ysom {
      margin-bottom: 4px;
    }
    .css-w6ysom[aria-selected="true"] {
      color: ${(props) => props.theme.color.gray};
      opacity: 1;
      border-bottom: 4px solid ${(props) => props.theme.color.yellow};
      width: 220px;
    }
    .css-w6ysom:focus {
      box-shadow: inherit;
    }
  }
  @media (max-width: ${(props) => props.theme.queries.small}) {
    button {
      width: 110px;
      text-align: center;
    }
    .css-w6ysom[aria-selected="true"] {
      width: 110px;
    }
  }
`;
