import React from "react";
import styled from "styled-components";

type IconProps = {
  width?: string;
  height?: string;
};

export function IconNewWindow({ width, height }: IconProps) {
  return (
    <GraphicSVG
      viewBox="0 0 24 24"
      height={height || "32"}
      width={width || "32"}
      focusable="false"
      role="img"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      className="StyledIconBase-ea9ulj-0 bWRyML"
    >
      <title>Windows icon</title>
      <path d="M16 7H4c-1.103 0-2 .897-2 2v10c0 1.103.897 2 2 2h12c1.103 0 2-.897 2-2V9c0-1.103-.897-2-2-2zM4 19v-8h12V9l.002 10H4z"></path>
      <path d="M22 5c0-1.103-.897-2-2-2H7c-1.103 0-2 .897-2 2h13.001c1.101 0 1.996.895 1.999 1.994L20.002 15H20v2c1.103 0 2-.897 2-2V8.007L22.001 8V6L22 5.99V5z"></path>
    </GraphicSVG>
  );
}

const GraphicSVG = styled.svg`
  margin-bottom: -2px;
`;
