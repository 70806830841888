import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Box, Text } from "@chakra-ui/core";
import CarouselWebdoor from "./CarouselWebdoor";
import { getSlug } from "utils/helper";
import { useRouter } from "hooks/useRouter";
import { auth as useAuth } from "hooks/auth";
import { useActions, useStore } from "configureStore";
import { NavigationAnoTodo } from "../../Navigation/NavigationAnoTodo";
import { GrDownload } from "react-icons/gr"

//assets
import CardImg from "assets/images/anoTodo/card-item.png";

export function WebdoorAnoTodo() {
  const [loading, setLoading] = useState(false);
  const router = useRouter();
  const { auth } = useAuth();
  const isAuthenticated = auth.isAuthenticated;
  const isShellbox = window.location.search.includes("shellbox");
  const setIsOpen = useActions((action) => action.home.setIsOpen);

  const getShells = useActions((action) => action.anoTodo.getShells);
  const getMe = useActions((action) => action.user.getMe);

  useEffect(() => {
    const _auth = localStorage.getItem("Hub-auth");
    if (isShellbox && !_auth) {
      setLoading(true);
      let count = 0;

      const interval = setInterval(() => {
        const retryAuth = localStorage.getItem("Hub-auth");
        if (retryAuth) {
          getMe();
          getShells();
          clearInterval(interval);
          setTimeout(() => {
            setLoading(false);
          }, 1000);
        } else {
          if (count > 7) {
            setLoading(false);
            clearInterval(interval);
            return;
          }
          count++;
        }
      }, 1000);

      return () => {
        clearInterval(interval);
      };
    }
    // @ts-ignore
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isAuthenticated) {
      getShells();
      getMe();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isShellbox, isAuthenticated]);

  return (
    <HeaderWrapper>
      <NavigationAnoTodo />

      <CarouselWebdoor />

      {!isAuthenticated && !isShellbox && (
        <ButtonWrapper>
          <Box
            d={"flex"}
            flexDirection={["column", "column", "column", "row"]}
            justifyContent={"space-between"}
            alignItems={["flex-start", "flex-start", "flex-start", "center"]}
            w={"100%"}
            maxW={["280px", "280px", "320px", "1044px"]}
          >
            <p>
              Para ter acesso aos seus pedidos e resgatar produtos, acesse o Shell Box.
            </p>
            <ButtonDownloadApp
              href="https://shellbox.onelink.me/RT9I/49c873d1"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="baixar shell box (abre em uma nova guia)"
            >
              <GrDownload />
              Baixar Shell Box
            </ButtonDownloadApp>
          </Box>
        </ButtonWrapper>
      )}
    </HeaderWrapper>
  );
}

const HeaderWrapper = styled.header`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  background-color: ${(props) => props.theme.color.shellPurple};
  padding: 8px 0 0;

  @media (min-width: ${(props) => props.theme.queries.medium}) {
    padding: 4px 0 0;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  background: ${(props) => props.theme.color.yellow};
  width: 100%;
  padding: 1.25rem;

  p {
    font-family: ${props => props.theme.Shell.Medium};
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.2;
    color: ${props => props.theme.color.darkGray};
    margin: 0;
  }

  @media (min-width: ${props => props.theme.queries.desk}) {
    p {
      font-size: 1.5rem;
    }
  }
`;

const ButtonDownloadApp = styled.a`
  all: unset;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 192px;
  height: 40px;
  border-radius: 2px;
  margin-top: 1.5rem;
  text-decoration: none;

  background: ${props => props.theme.color.red};
  color: ${props => props.theme.color.white};
  border: 2px solid transparent;
  cursor: pointer;
  transition: all 200ms;

  svg {
    margin-right: 6px;
    path {
      stroke: ${props => props.theme.color.white};
    }
  }

  &:hover, &:focus {
    background: ${props => props.theme.color.white};
    color: ${props => props.theme.color.red};
    border-color: ${props => props.theme.color.red};

    svg {
      path {
        stroke: ${props => props.theme.color.red};
      }
    }
  }

  @media (min-width: ${props => props.theme.queries.desk}) {
    margin-top: unset;
  }
`