import { createBrowserHistory } from "history";
import { toQueryParams } from "./http";

const history = createBrowserHistory();
const shove = history.push;

history.push = (params: any) => {
  window && window.scrollTo(0,0);
  if (typeof params === "string") {
    shove(params);
  } else {
    const { query } = params;
    shove({
      ...params,
      search: toQueryParams(query || {}),
    });
  }
};

export default history;
