import React from "react";
import styled from "styled-components";

// Assets
import IconCard from "assets/icons/curtaVida/credit-card.svg";
import IconGigt from "assets/icons/curtaVida/gift.svg";
import IconMobile from "assets/icons/curtaVida/mobile.svg";
import IconChange from "assets/icons/curtaVida/troca.svg";

export function CardsComoFunciona() {
  return (
    <Section>
      <ContentWrapper>
        <CardPromo tabIndex={0}>
          <IconContainer>
            <img src={IconMobile} alt="" draggable="false" />
          </IconContainer>
          <span>Passo 1</span>
          <strong>
            Junte conchas. A cada R$30 = 1 concha
          </strong>
          <p>
            Pagando com o app Shell Box nos postos Shell e
            Lojas Shell Select participantes, você acumula
            1 concha* a cada R$30 reais gastos.
          </p>
        </CardPromo>

        <CardPromo tabIndex={0}>
          <IconContainer>
            <img src={IconChange} alt="" draggable="false" />
          </IconContainer>
          <span>Passo 2</span>
          <strong>
            Ganhe conchas extras
          </strong>
          <p>
            Nas compras a partir de R$60 sempre que abastecer
            com <span>V-Power</span> ou pagar com Mastercard no
            app <span>Shell Box</span>, você garante 1 concha extra*.
          </p>
        </CardPromo>

        <CardPromo tabIndex={0}>
          <IconContainer>
            <img src={IconGigt} alt="" draggable="false" />
          </IconContainer>
          <span>Passo 3</span>
          <strong>Troque por descontos em produtos exclusivos</strong>
          <p>
            Junte & Troque o ano todo, são diversos produtos
            para colecionar. Fique de olho nas novidades!
          </p>
        </CardPromo>

        <CardPromo tabIndex={0}>
          <IconContainer>
            <img src={IconCard} alt="" draggable="false" />
          </IconContainer>
          <span>Passo 4</span>
          <strong>
            Pague com cartão em até 10x sem juros, Boleto ou Pix
          </strong>
          <p>
            Escolha como pagar e onde receber os seus produtos.
            Acompanhe o saldo de conchas no app Shell Box e o
            fluxo de entrega aqui no Junte & Troque.
          </p>
        </CardPromo>
      </ContentWrapper>
    </Section>
  )
}

const Section = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  margin: 4.75rem auto 3rem;
  width: 80%;
  max-width: 20rem;

  @media (min-width: ${props => props.theme.queries.lg}) {
    margin-top: 160px;
    margin-bottom: 70px;
    width: 100%;
    max-width: 80rem;
  }
`;

const ContentWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-template-rows: repeat(4, 1fr);
  margin: 40px auto 0;
  padding: 2.25rem 0 0;
  width: 100%;
  place-items: center;

  @media (min-width: ${(props) => props.theme.queries.medium}) {
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(1, 1fr);
    grid-column-gap: 18px;
    max-width: 65rem;
    margin-top: 70px;
  }
`;

const CardPromo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  width: 100%;
  max-width: 20rem;
  height: 322px;
  
  background: #fff;
  padding: 1rem 1rem;
  margin: 0;
  margin-bottom: 56px;
  
  border-radius: 4px;
  
  position: relative;
  box-sizing: border-box;
  box-shadow: 0px 2px 4px rgba(64, 64, 64, 0.15);
  
  &:last-child {
    margin-bottom: unset;
  }
  // Passo 1
  span {
    font-family: ${props => props.theme.helvetica};
    font-weight: bold;
    font-size: 0.75rem;
    line-height: ${(props) => props.theme.fontSize.text};
    color: ${(props) => props.theme.color.text};
    margin: 3rem 0 1.25rem;
    border-radius: 3px;
    background: #D9D9D9;
    padding: 2px;
    width: 52px;
    box-sizing: border-box;
  }
  // Título roxo
  strong {
    margin-bottom: 1.25rem;
    font-size: 1.375rem;
    line-height: 120%;
    font-weight: bold;
    font-family: ${props => props.theme.Shell.Bold};
    color: ${(props) => props.theme.color.shellPurple};
  }
  // Texto do corpo
  p {
    font-family: Arial;
    font-size: 0.875rem;
    line-height: 1rem;
    color: ${(props) => props.theme.color.gray};
    margin: 0;
    align-self: flex-start;

    & > span {
      font-family: inherit;
      font-size: inherit;
      font-weight: inherit;
      color: inherit;
      white-space: nowrap;
      margin: 0;
      padding: 0;
      background-color: transparent;
      border-radius: 0;
    }
  }

  @media (min-width: ${(props) => props.theme.queries.lg}) {
    margin-bottom: unset;
    max-width: unset;

    br {
      display: none;
    }
  }
`;

const IconContainer = styled.div`
  width: 5.125rem;
  height: 5.125rem;

  display: flex;
  justify-content: center;
  align-items: center;

  background-color: ${(props) => props.theme.color.shellPurple};
  border: 3px solid #fff;
  border-radius: 1000px;
  color: ${props => props.theme.color.yellow};

  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 5;
  box-sizing: border-box;

  img {
    max-width: 3.5rem;
    max-height: 3rem;
  }
`;