import React from "react";
import styled from "styled-components";
import Carousel from "react-multi-carousel";
import { useRouter } from "hooks/useRouter";
import { BsChevronRight } from "react-icons/bs";
import { Link, withRouter } from "react-router-dom";
import { getSlug } from "utils/helper";
// Assets
import ImageProdutos from "assets/images/curtaVida/banner-shellbox.png";
import ImageConchas from "assets/images/onda/conchas-shellbox.png";
import ImageVpowerMaster from "assets/images/onda/vpower-master-shellbox.svg";
import ImagePremios from "assets/images/curtaVida/webview-products.png";
import IconShell from "assets/icons/onda/newShell.svg";
import ImgWebviewFriday from "assets/banners/img_webview_friday.png";

function ShellboxBannerMenu() {
  const router = useRouter();
  const responsive = {
    desktop: {
      breakpoint: { max: 4000, min: 1024 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 1023, min: 0 },
      items: 1,
    },
  };

  return (
    <Container pathname={router.location.pathname}>
      {router.location.pathname.includes("/minhas-conchas") && (
        <ContentWrapper>
          <Carousel
            arrows={false}
            showDots
            renderDotsOutside
            responsive={responsive}
          >
            <CustomLink
              to={() => {
                if (
                  localStorage.getItem("query_shell") !== "null" &&
                  localStorage.getItem("query_shell")?.length > 1
                ) {
                  return `${getSlug().url}?shellbox=${localStorage.getItem(
                    "query_shell"
                  )}&token=${localStorage.getItem("external_token")}`;
                } else {
                  return `${getSlug().url}`;
                }
              }}
            >
              <Card>
                <img src={ImagePremios} alt="Produtos" draggable="false" />
                <TextContainer>
                  {/* <TextRed>
                    <strong>promoshell</strong>
                  </TextRed> */}
                  <TextBlackBold>
                    junte<b>&</b>troque
                  </TextBlackBold>
                  <StrongRedSmall>
                    curta a vida <br />
                    onde estiver
                  </StrongRedSmall>
                </TextContainer>
              </Card>
            </CustomLink>
            <Card>
              <img src={ImageConchas} alt="Conchas" draggable="false" />
              <TextContainer>
                <TextBlack>
                  <StrongRedSmall>A CADA R$ 30</StrongRedSmall>
                  * no
                  <br /> Shell Box =<StrongRedSmall>1 concha</StrongRedSmall>
                </TextBlack>
              </TextContainer>
            </Card>
            <Card>
              <img
                src={ImageVpowerMaster}
                alt="Vpower e mastercard"
                draggable="false"
              />
              <TextContainer>
                <TextBlack fontSize={"0.813rem"}>
                  <StrongRedSmall fontSize={"0.813rem"}>
                    JUNTE CONCHAS EXTRAS
                  </StrongRedSmall>
                  <br />a partir de R$ 60{" "}
                  <StrongRedSmall fontSize={"0.813rem"} notUppercase>
                    com <br />
                    Shell V-Power e Mastercard
                  </StrongRedSmall>
                </TextBlack>
              </TextContainer>
            </Card>
            <Card>
              <img src={ImagePremios} alt="Produtos" draggable="false" />
              <TextContainer width={"50%"}>
                <TextBlack fontSize={"0.813rem"}>
                  Junte conchas para ter acesso a{" "}
                  <StrongRedSmall fontSize={"0.813rem"}>
                    PRODUTOS EXCLUSIVOS SHELL
                  </StrongRedSmall>
                </TextBlack>
              </TextContainer>
            </Card>
          </Carousel>
          <LinkSaibaMais
            to={() => {
              if (
                localStorage.getItem("query_shell") !== "null" &&
                localStorage.getItem("query_shell")?.length > 1
              ) {
                return `${getSlug().url}?shellbox=${localStorage.getItem(
                  "query_shell"
                )}&token=${localStorage.getItem("external_token")}`;
              } else {
                return `${getSlug().url}`;
              }
            }}
          >
            Saiba Mais <BsChevronRight />
          </LinkSaibaMais>
        </ContentWrapper>
      )}
      <FooterMenu>
        <ButtonWrapper>
          <FooterButton
            to={() => {
              if (
                localStorage.getItem("query_shell") !== "null" &&
                localStorage.getItem("query_shell")?.length > 1
              ) {
                return `${
                  getSlug().url
                }/minhas-conchas?shellbox=${localStorage.getItem(
                  "query_shell"
                )}&token=${localStorage.getItem("external_token")}`;
              } else {
                return `${getSlug().url}/minhas-conchas`;
                // return `${getSlug().url}/minhas-conchas?shellbox`; // teste shellbox "/minhas-conchas"
              }
            }}
            className={
              router.location.pathname === `${getSlug().url}/minhas-conchas` &&
              "active"
            }
          >
            <img src={IconShell} alt="" />
            Conchas
          </FooterButton>
          <FooterButton
            to={() => {
              if (
                localStorage.getItem("query_shell") !== "null" &&
                localStorage.getItem("query_shell")?.length > 1
              ) {
                return `${getSlug().url}?shellbox=${localStorage.getItem(
                  "query_shell"
                )}&token=${localStorage.getItem("external_token")}`;
              } else {
                return `${getSlug().url}`;
                // return `${getSlug().url}?shellbox`; // teste shellbox "/"
              }
            }}
            className={
              router.location.pathname === `${getSlug().url}` && "active"
            }
          >
            <img src={IconShell} alt="" />
            Sobre a promo
          </FooterButton>
          <FooterButton
            to={() => {
              if (
                localStorage.getItem("query_shell") !== "null" &&
                localStorage.getItem("query_shell")?.length > 1
              ) {
                return `${
                  getSlug().url
                }/meus-pedidos?shellbox=${localStorage.getItem(
                  "query_shell"
                )}&token=${localStorage.getItem("external_token")}`;
              } else {
                return `${getSlug().url}/meus-pedidos`;
                // return `${getSlug().url}/meus-pedidos?shellbox`; // teste shellbox "/meus-pedidos"
              }
            }}
            className={
              router.location.pathname === `${getSlug().url}/meus-pedidos` &&
              "active"
            }
          >
            <img src={IconShell} alt="" />
            Meus pedidos
          </FooterButton>
        </ButtonWrapper>
      </FooterMenu>
    </Container>
  );
}

export default withRouter(ShellboxBannerMenu);

const Container = styled.div`
  width: 100%;
  background: ${(props) => props.theme.color.yellow};
  border-radius: 0 0 0.5rem 0.5rem;
  padding: ${(props) =>
    props.pathname.includes("/meus-pedidos") ||
    props.pathname.includes("/duvidas") ||
    props.pathname.includes("/minhas-conchas") ||
    props.pathname.includes("/regulamento")
      ? "0"
      : "8px 4px"};

  position: absolute;
  box-sizing: border-box;
  z-index: 5;
`;

const ContentWrapper = styled.div`
  height: 5.75rem;
  width: 100%;
  position: relative;

  li.react-multi-carousel-dot {
    button {
      background: #fff;
      opacity: 0.65;
      border-color: #fff;
      width: 5px;
      height: 5px;

      @media (min-width: ${(props) => props.theme.queries.medium}) {
        width: 12px;
        height: 12px;
      }
    }
  }
  li.react-multi-carousel-dot--active {
    button {
      opacity: 1;
    }
  }
`;

const Card = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 0 auto;
  width: 90%;
  max-width: 22rem;

  img {
    width: 100%;
    max-width: 7.375rem;
    height: 100%;
    max-height: 5.5rem;
    margin-left: -0.5rem;
  }
`;

const TextContainer = styled.div`
  width: ${(props) => props.width};
  display: flex;
  flex-direction: ${(props) =>
    props.flexDirection ? props.flexDirection : "column"};
  justify-content: center;
  align-items: center;

  margin: 0 auto;
  z-index: 5;
`;

const TextContainerFriday = styled.div`
  width: ${(props) => props.width};
  display: flex;
  flex-direction: ${(props) =>
    props.flexDirection ? props.flexDirection : "column"};
  justify-content: center;

  margin: 0 auto;
  z-index: 5;

  @media (min-width: 375px) {
    margin: 0 auto 0 1rem;
  }
  @media (max-width: 320px) {
    margin: 0 auto;
  }
`;

const CustomLink = styled(Link)`
  text-decoration: none;
`;

const LinkSaibaMais = styled(Link)`
  font-family: Helvetica;
  font-weight: 500;
  font-size: 0.75rem;
  line-height: 1rem;
  position: absolute;
  right: 0;
  bottom: 0;
  text-decoration: none;
  color: ${(props) => props.theme.color.shellBlack};
`;

const TextBlack = styled.span`
  font-family: "Futura LT regular";
  font-weight: 800;
  font-size: ${(props) => (props.fontSize ? props.fontSize : "0.938rem")};
  line-height: 1rem;
`;

const TextBlackBold = styled.span`
  font-family: "Futura LT Bold";
  font-size: 1.125rem;
  line-height: 1.5rem;
  text-transform: uppercase;
  color: ${(props) => props.theme.color.shellBlack};

  b {
    font-size: 1rem;
    color: ${(props) => props.theme.color.red};
  }
`;
const TextBlackBoldFriday = styled.span`
  font-family: "Futura LT Bold";
  font-size: 0.8rem;
  line-height: 1.25rem;
  text-transform: uppercase;
  color: ${(props) => props.theme.color.shellBlack};

  span {
    color: ${(props) => props.theme.color.red};
  }

  b {
    font-size: 1rem;
    color: ${(props) => props.theme.color.red};
  }

  @media (max-width: 375px) {
    font-size: 0.75rem;
  }

  @media (max-width: 320px) {
    font-size: 0.63rem;
  }
`;

const StrongRedSmall = styled.strong`
  font-size: ${(props) => (props.fontSize ? props.fontSize : "0.938rem")};
  line-height: 1rem;
  font-family: "Futura LT Bold";
  color: ${(props) => props.theme.color.red};
  text-transform: ${(props) => (props.notUppercase ? "none" : "uppercase")};

  @media (min-width: 420px) {
    br {
      display: none;
    }
  }
`;

const StrongRedSmallFriday = styled.strong`
  font-size: ${(props) => (props.fontSize ? props.fontSize : "0.813rem")};
  line-height: 1rem;
  font-family: "Futura LT Bold";
  color: ${(props) => props.theme.color.red};
  text-transform: ${(props) => (props.notUppercase ? "none" : "uppercase")};

  @media (min-width: 420px) {
    br {
      display: none;
    }
  }
  @media (max-width: 375px) {
    font-size: 0.688rem;
  }
  @media (max-width: 320px) {
    font-size: 0.625rem;
  }
`;

const TextRed = styled.span`
  font-size: ${(props) => (props.fontSize ? props.fontSize : "1.125rem")};
  line-height: 1.25rem;
  color: ${(props) => props.theme.color.red};
  font-family: "Futura LT Regular";
  text-transform: uppercase;
  margin: 0;

  strong {
    font-size: inherit;
    font-family: "Futura LT Bold";
  }
`;

const FooterMenu = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 3rem;

  background: #fff;
  position: fixed;
  left: -1px;
  bottom: 0vh;
`;

const ButtonWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 5.75rem);
  margin: 0 auto;
  height: 100%;
`;

const FooterButton = styled(Link)`
  padding: 8px 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  font-family: Helvetica;
  font-weight: 500;
  font-size: 0.75rem;
  line-height: 0.75rem;
  text-decoration: none;
  color: ${(props) => props.theme.color.mediumGray3};
  box-sizing: border-box;

  img {
    width: 1.25rem;
    height: 1.125rem;
    margin-bottom: 1.6px;
    filter: grayscale(1) opacity(0.45);
  }

  &.active {
    color: ${(props) => props.theme.color.red};
    background: ${(props) => props.theme.color.yellow};
    img {
      filter: unset;
    }
  }
`;
