import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components";
import { withRouter, NavLink, useLocation } from "react-router-dom";
import "@material/react-text-field/dist/text-field.css";
import { Image, Box, useDisclosure, Button } from "@chakra-ui/core";
import queryString from "query-string";
import { useStore, useActions } from "configureStore";
import { theme } from "theme";
import useWindowSize from "hooks/useWIndowSize";
import BannerShellPromo from "modules/promocaoJunteTroque/Navbar/BannerShellPromo";
import { TopBar } from "modules/common/UI";
import MenuMobile from "modules/common/Navbar/Menu";
import ModalLogin from "modules/common/Navbar/ModalLogin";
import UpdatePersonalDataModal from "modules/common/UpdataPersonalDataModal";

import menu from "assets/icons/hamburguer.svg";
import closeIcon from "assets/icons/close.svg";
import userIcon from "assets/icons/user-icon.svg";
import logoPromo from "assets/icons/logo-promo-shell.svg";

import { store, initialData } from "configureStore";
import { getSlug } from "utils/helper";
import IconBandeira from "assets/images/hub/icon-bandeira.png";

const Navbar = (props) => {
  const query = queryString.parse(props.location.search);
  const queryshellBox = query.shellbox;
  const { pathname } = useLocation();

  const { width } = useWindowSize();
  const isOpened = useStore((state) => state.home.isOpen);
  const setIsOpen = useActions((action) => action.home.setIsOpen);
  const user = useStore((state) => state.user.user);
  const isAuthenticated = useStore((state) => state.auth.isAuthenticated);
  const clearAuth = useActions((action) => action.auth.clearAuth);

  // Efeito de delay do botão "Entrar"
  const [opacityEffect, setOpacityEffect] = useState(0);
  const [isEffectActive, SetIsEffectActive] = useState(false);

  useEffect(() => {
    if (opacityEffect <= 1 && isEffectActive) {
      setTimeout(() => {
        setOpacityEffect(opacityEffect + 0.1);
      }, 50);
    }
  }, [isEffectActive, opacityEffect]);

  const { isOpen, onOpen, onClose } = useDisclosure();
  const close = () => setIsOpen(false);

  useEffect(() => {
    close();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const btnRef = useRef();

  const location = props.history.location.pathname;
  const [openMenu, setOpenMenu] = useState(false);

  const open = () => {
    if (!isOpened) {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
    setIsOpen(!isOpened);
  };

  const scrollToTop = () => {
    if (typeof window !== "undefined") {
      window.scrollTo(0, 0);
    }
  };

  return (
    <>
      <Content
        display={queryshellBox?.includes("hiddenComponent") ? "none" : "flex"}
      >
        <ContentBox>
          <Box display="flex" alignItems="center">
            <MenuIcon
              borderBottom={`3px solid ${theme.color.mediumGray2}`}
              borderRight={`2px solid ${theme.color.mediumGray2}`}
              height={"45px"}
              width={"44px"}
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              onClick={() =>
                !openMenu ? setOpenMenu(true) : setOpenMenu(false)
              }
            >
              {!openMenu ? (
                <Image
                  src={menu}
                  alt="hamburger-icon"
                  width="21px"
                  height="8px"
                />
              ) : (
                <Image
                  src={closeIcon}
                  alt="hamburger-icon"
                  width="16px"
                  height="16px"
                />
              )}
            </MenuIcon>
            <Logo
              tabIndex={0}
              aria-label="promoshell"
              onClick={() => {
                scrollToTop();
                if (
                  localStorage.getItem("query_shell") !== "null" &&
                  localStorage.getItem("query_shell")?.length > 1
                ) {
                  props.history.push(
                    `/?shellbox=${localStorage.getItem("query_shell")}`
                  );
                } else {
                  props.history.push(`/`);
                }
              }}
            >
              <img src={logoPromo} alt="promoshell" />
              {/* <h1>
                promo<span>shell</span>
              </h1> */}
            </Logo>
          </Box>
          {isAuthenticated ? (
            <WrapLinks mLeft={props.auth ? 0 : "0"}>
              <CustomNavLink
                aria-label="promocões da cidade"
                to={"/"}
                onClick={() => {
                  scrollToTop();
                }}
              >
                <BarLink
                  height={2}
                  width={50}
                  className={`${location === "/" && "activeLink"} barLink`}
                />
                <span>
                  Campanhas
                  <br /> de Cidade
                </span>
              </CustomNavLink>
              <CustomNavLink
                aria-label="Campanhas de óleos lubrificantes"
                onClick={() => {
                  scrollToTop();
                }}
                to={"/campanhas-de-lubrificantes"}
              >
                <BarLink
                  height={2}
                  width={50}
                  className={`${
                    location === "/campanhas-de-lubrificantes" && "activeLink"
                  } barLink`}
                />
                <span>
                  Campanhas de
                  <br /> Óleos Lubrificantes
                </span>
              </CustomNavLink>
              <CustomNavLink
                aria-label="promocões de rodovia"
                onClick={() => {
                  scrollToTop();
                }}
                to={"/promocoes-de-rodovia"}
              >
                <BarLink
                  height={2}
                  width={50}
                  className={`${
                    location === "/promocoes-de-rodovia" && "activeLink"
                  } barLink`}
                />
                <span>
                  Campanhas
                  <br /> de Rodovia
                </span>
              </CustomNavLink>
              <CustomNavLink
                aria-label="benefícios app shell box"
                onClick={() => {
                  scrollToTop();
                }}
                to={"/beneficios-app-shell-box"}
              >
                <BarLink
                  height={2}
                  width={50}
                  className={`${
                    location === "/beneficios-app-shell-box" && "activeLink"
                  } barLink`}
                />
                <span>
                  benefícios <br />
                  shell box
                </span>
              </CustomNavLink>
              {/* <CustomNavLink
                aria-label="ofertas shell select"
                onClick={() => {
                  scrollToTop();
                }}
                to={"/ofertas-shell-select"}
              >
                <BarLink
                  height={2}
                  width={50}
                  className={`${location === "/ofertas-shell-select" && "activeLink"
                    } barLink`}
                />
                <span>
                  OFERTAS
                  <br /> SHELL SELECT
                </span>
              </CustomNavLink> */}
              {/* <LinkGp
                aria-label="promo acelere com gp"
                onClick={() => {
                  scrollToTop();
                }}
                href={"https://www.acelerecomgp.com.br?utm_source=promoshell&utm_medium=media&utm_campaign=promoshell_aceleregp&utm_id=PromoShell"}
              >
                <BarLink
                  height={2}
                  width={50}
                  className={`barLink`}
                />
                <span>
                  PROMO
                  <br /> ACELERE COM GP
                </span>
              </LinkGp> */}
            </WrapLinks>
          ) : (
            <WrapLinks mLeft={props.auth ? 0 : "0"}>
              <CustomNavLink
                aria-label="promocões da cidade"
                to={"/"}
                onClick={(e) => {
                  e.preventDefault();
                  scrollToTop();
                  if (
                    localStorage.getItem("query_shell") !== "null" &&
                    localStorage.getItem("query_shell")?.length > 1
                  ) {
                    props.history.push(
                      `/?shellbox=${localStorage.getItem("query_shell")}`
                    );
                  } else {
                    props.history.push(`/`);
                  }
                }}
              >
                <BarLink
                  height={2}
                  width={50}
                  className={`${location === "/" && "activeLink"} barLink`}
                />
                <span>
                  Campanhas
                  <br /> de Cidade
                </span>
              </CustomNavLink>
              <CustomNavLink
                aria-label="promocões de rodovia"
                to={"/promocoes-de-rodovia"}
                onClick={() => {
                  scrollToTop();
                }}
              >
                <BarLink
                  height={2}
                  width={50}
                  className={`${
                    location === "/promocoes-de-rodovia" && "activeLink"
                  } barLink`}
                />
                <span>
                  Campanhas
                  <br /> de Rodovia
                </span>
              </CustomNavLink>
              <CustomNavLink
                aria-label="Campanhas de óleos lubrificantes"
                onClick={() => {
                  scrollToTop();
                }}
                to={"/campanhas-de-lubrificantes"}
              >
                <BarLink
                  height={2}
                  width={50}
                  className={`${
                    location === "/campanhas-de-lubrificantes" && "activeLink"
                  } barLink`}
                />
                <span>
                  Campanhas de
                  <br /> Óleos Lubrificantes
                </span>
              </CustomNavLink>
              <CustomNavLink
                aria-label="benefícios app shell box"
                to={"/beneficios-app-shell-box"}
                onClick={() => {
                  scrollToTop();
                }}
              >
                <BarLink
                  height={2}
                  width={50}
                  className={`${
                    location === "/beneficios-app-shell-box" && "activeLink"
                  } barLink`}
                />
                <span>
                  Benefícios <br />
                  Shell Box
                </span>
              </CustomNavLink>
              {/* <CustomNavLink
                aria-label="ofertas shell select"
                to={"/ofertas-shell-select"}
                onClick={() => {
                  scrollToTop();
                }}
              >
                <BarLink
                  height={2}
                  width={50}
                  className={`${location === "/ofertas-shell-select" && "activeLink"
                    } barLink`}
                />
                <span>
                  Ofertas
                  <br /> Shell Select
                </span>
              </CustomNavLink> */}
              {/* <LinkGp
                aria-label="promo acelere com gp"
                onClick={() => {
                  scrollToTop();
                }}
                href={"https://www.acelerecomgp.com.br?utm_source=promoshell&utm_medium=media&utm_campaign=promoshell_aceleregp&utm_id=PromoShell"}
              >
                <BarLink
                  height={2}
                  width={50}
                  className={`barLink`}
                //   location === "/ofertas-shell-select" && "activeLink"
                />
                <span>
                  PROMO
                  <br /> ACELERE COM GP
                </span>
              </LinkGp> */}
            </WrapLinks>
          )}
        </ContentBox>
        <UpdatePersonalDataModal isOpen={isOpen} onClose={onClose} />
      </Content>

      {window.location.pathname === "/" ? (
        <Box>{/* <SliderHome />
          <BannerShell /> */}</Box>
      ) : (
        window.location.pathname === "/promocao-junte-e-troque" && (
          <BannerShellPromo />
        )
      )}
      {window.location.pathname === `/promocao-junte-e-troque/` && (
        <BannerShellPromo />
      )}
      {window.location.pathname === "/cadastro" && (
        <TopBar title={isAuthenticated ? "Meu Cadastro" : "Cadastre-se"} />
      )}
      {window.location.pathname === "/minhas-promocoes" && (
        <TopBar title="CAMPANHAS" />
      )}
      {window.location.pathname === "/duvidas" && <TopBar title="Dúvidas" />}
      {window.location.pathname === "/termos-de-uso" && (
        <TopBar title="Termos de uso" />
      )}
      {window.location.pathname === "/politica-de-privacidade" && (
        <TopBar title="Política de privacidade" />
      )}
      {window.location.pathname === "/regulamento" && (
        <TopBar title="Regulamento" />
      )}
      {window.location.pathname === "/esqueceu-a-senha" && (
        <TopBar title="Recuperar senha" />
      )}
      {window.location.pathname === "/sobre-o-promoshell" && (
        <TopBar title="Sobre o PromoShell" />
      )}
      <MenuMobile ref={btnRef} onClose={setOpenMenu} isOpen={openMenu} />
    </>
  );
};

export default withRouter(Navbar);

const Content = styled(Box)`
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 0 auto;
  background: ${(props) => props.theme.color.white};
  box-shadow: 8px 5px 8px 1px rgba(0, 0, 0, 0.1);
  z-index: 99;
  position: fixed;
  top: 0;
  width: 100%;
  height: 87px;
  /* @media (max-width: ${(props) => props.theme.queries.medium}) {
    height: 43px;
    padding: 0 0em;
  } */
  @media (max-width: ${(props) => props.theme.queries.medium}) {
    border-bottom: 3px solid ${(props) => props.theme.color.orange};
    height: 43px;
    padding: 0;
  }
`;

const ContentBox = styled(Box)`
  max-width: 1450px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media (max-width: 1440px) {
    max-width: 97.5%;
  }
  /* @media (max-width: ${(props) => props.theme.queries.medium}) {
    max-width: 94%;
  } */
  @media (max-width: ${(props) => props.theme.queries.medium}) {
    max-width: 100%;
  }
`;

const WrapLinks = styled(Box)`
  display: flex;
  align-items: center;
  margin-left: ${(props) => props.mLeft};
  @media (max-width: ${(props) => props.theme.queries.lg}) {
    margin-left: 0%;
  }
  @media (max-width: ${(props) => props.theme.queries.medium}) {
    display: none;
  }
  .activeLink {
    background: linear-gradient(to right, #e98624 50%, #e98624 50%);
  }
`;

const BarLink = styled(Box)`
  height: 3px !important;
  margin-bottom: 0.5rem;
  transition: 0.3s;

  background: linear-gradient(to right, #e98624 50%, #eaeaea 50%);
  background-size: 200% 100%;
  background-position: right bottom;
  transition: all 0.2s ease-out;

  @media (max-width: ${(props) => props.theme.queries.medium}) {
    display: none !important;
  }
`;

const CustomNavLink = styled(NavLink)`
  color: ${(props) => props.theme.color.text};
  margin-right: 68px;
  text-decoration: none;
  font-family: "Futura LT Bold";
  font-size: 13px;
  line-height: 112%;
  transition: 0.2s;

  :hover,
  :focus {
    .barLink {
      background-position: left bottom;
    }
  }
  span {
    margin: 0.4rem 0 0 0;
    font-weight: 600;
    text-decoration: none;
    color: ${(props) => props.theme.color.gray};
  }
  @media (max-width: ${(props) => props.theme.queries.lg}) {
    span {
      margin: 0;
      font-size: 10px;
    }
  }

  @media (max-width: ${(props) => props.theme.queries.medium}) {
    margin-right: 33px;
  }
`;

const Logo = styled.div`
  margin-left: 20px;
  margin-top: 5px;

  img {
    max-width: 250px;
    max-height: 80px;
  }

  @media (max-width: ${(props) => props.theme.queries.medium}) {
    img {
      max-width: 111px;
      max-height: 40px;
    }
  }
`;

const MenuIcon = styled(Box)`
  display: none;
  cursor: pointer;
  @media (max-width: ${(props) => props.theme.queries.medium}) {
    display: flex;
  }
`;

//auth true

const BoxWelcome = styled(Box)`
  display: flex;
  align-items: center;

  .boxUser {
    padding: 0 !important;
    height: auto !important;
    border: none;
    background-color: white !important;
    font-weight: 500 !important;
    font-family: ${theme.FuturaFont};

    :hover,
    :focus {
      div {
        color: ${(props) => props.theme.color.orange};
      }
      .iconUser {
        border: 3px solid ${(props) => props.theme.color.orange} !important;
      }
    }
  }
`;

const WelcomeUser = styled(Box)`
  margin-right: 5px;
  font-family: ${theme.FuturaFont};
  cursor: pointer;
  color: #525f7f;
  font-weight: 500 !important;

  @media (max-width: ${(props) => props.theme.queries.small}) {
    padding: 0;
    margin: 0 0.2rem 0 0;
    font-size: 12px;
    text-align: right;
    line-height: 10px;
    color: ${(props) => props.theme.color.gray};
  }
`;

const ImageUser = styled.img`
  cursor: pointer;
  margin-top: 2px;
  border-radius: 10rem;
  border: 3px solid transparent;
  transition: 0.3s;

  @media (max-width: ${(props) => props.theme.queries.small}) {
    margin-top: 0;
  }
`;

const MyButton = styled(Button)`
  font-family: "Futura LT Bold";
  margin-left: 25px;
  height: 33px !important;
  width: 108px;
  @media (max-width: ${(props) => props.theme.queries.small}) {
    margin-left: 0px;
    width: 55px;
    padding: 0 !important;
    height: 45px !important;
    display: flex;
    align-items: center;
    border: none;
    font-size: 10px !important;
  }
  @media (max-width: ${(props) => props.theme.queries.i5}) {
    width: 45px;
  }
`;

const LoginButton = styled(Button)`
  @media (max-width: ${(props) => props.theme.queries.medium}) {
    width: 90px;
    height: 30px !important;
    font-size: 12px !important;
  }
  @media (max-width: ${(props) => props.theme.queries.small}) {
    width: 90px;
    height: 43px !important;
    font-size: 12px !important;
  }
`;

const LinkGp = styled.a`
  background: url(${IconBandeira});
  background-size: contain;
  width: 150px;

  color: ${(props) => props.theme.color.yellow};
  margin-right: 68px;
  text-decoration: none;
  font-family: "Futura LT Bold";
  font-size: 13px;
  line-height: 112%;
  transition: 0.2s;
  text-transform: uppercase;
  padding: 0.5rem;

  :hover,
  :focus {
    .barLink {
      background-position: left bottom;
    }
  }
`;
