import React from "react";
import styled from "styled-components";
import { VideoComponent } from "../VideoComponent";

export function SectionComoFunciona() {
  return (
    <Section>
      <h1>como funciona</h1>
      <VideoComponent url="https://youtu.be/IU1uy96rUp8" />
    </Section>
  )
}

const Section = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  margin-bottom: -74px;
  
  h1 {
    font-family: ${props => props.theme.Shell.Book};
    font-weight: 400;
    font-size: 1.875rem;
    line-height: 100%;
    color: #fff;
    text-align: center;
    text-transform: capitalize;
    letter-spacing: 1px;
    margin: 1.5rem auto ${props => props.theme.fontSize.text};
  }
  
  @media (min-width: ${props => props.theme.queries.lg}) {
    margin-bottom: -12rem;
    h1 {
      font-size: 2.25rem;
      margin-top: 3.75rem;
      margin-bottom: 2.875rem;
    }
  }
`;