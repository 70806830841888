import styled from "styled-components";
import React from "react";
import WifiIcon from "assets/icons/wifi.svg";

const Fallback = () => {
  return (
    <Wrapper>
      <ImageBox src={WifiIcon} alt="Icone Wifi" />
      <BoxDescription>
        Não foi possível carregar os dados. Tente acessar novamente em breve
      </BoxDescription>
    </Wrapper>
  );
};

export default Fallback;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 6em 31em;
  margin-top: 15px;

  @media (max-width: 1300px) {
    padding: 6em 21em;
  }
  @media (max-width: 1050px) {
    padding: 6em 16em;
  }
  @media (max-width: 769px) {
    padding: 6em 10em;
  }
  @media (max-width: 500px) {
    padding: 6em 1em;
  }
`;

const ImageBox = styled.img`
  margin-top: 20px;
  height: 200px;
  width: 200px;
`;

const BoxDescription = styled.div`
  font-family: Arial;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: #58595b;
  margin-top: 15px;
`;
