import React from "react";
import {
  SorteioCardIcon,
  SorteioSectionCard,
  SorteioSectionContainer,
  SorteioSectionTitle,
  WrapperSectionCardMobile,
} from "../../Home/SorteioSection/styles";

import IconMobile from "assets/icons/onda/mobile.svg";
import IconBomba from "assets/images/grandeGp/bomba.svg";
import IconResgate from "assets/icons/troca.svg";
import IconCalendario from "assets/icons/calendario.svg";
import { RenderCarousel } from "../../Home/SorteioSection/CarouselRenderer";
import useWindowSize from "hooks/useWIndowSize";
//@ts-ignore
import RulesF1Manual from "assets/files/regulamento-resgate.pdf";

export function RedeemTicketsCardsSection() {
  const { width } = useWindowSize();
  // const isMobile375 = width <= 375;

  return (
    <SorteioSectionContainer>
      <SorteioSectionTitle>
        Veja os passos para resgatar os ingressos
      </SorteioSectionTitle>

      <RenderCarousel>
        <SorteioSectionCard>
          <SorteioCardIcon>
            <img src={IconMobile} alt="Ícone de um celular" />
          </SorteioCardIcon>
          <span>passo 1</span>
          <strong>Baixe o aplicativo</strong>
          <p>Disponível para IOS e Android.</p>
        </SorteioSectionCard>
        <SorteioSectionCard>
          <SorteioCardIcon>
            <img src={IconBomba} alt="Ícone de uma bomba de gasolina" />
          </SorteioCardIcon>
          <span>passo 2</span>
          <strong>Participe do Shell Box Clube</strong>
          <p>
            Você precisa ler e concordar com o regulamento para participar do
            Shell Box Clube e das promoções do Shell Box.
          </p>
        </SorteioSectionCard>
        <SorteioSectionCard>
          <SorteioCardIcon>
            <img src={IconResgate} alt="Ícone de trevo de quatro folhas" />
          </SorteioCardIcon>
          <span>passo 3</span>
          <strong>Acesse o Shell Box Clube</strong>
          <p>
            Clique no banner “experiências” no menu do Shell Box Clube e garanta
            seu par de ingressos para a arquibancada exclusiva Shell. Para
            resgatar os ingressos, você precisa de 50 stix + investimento em
            dinheiro.
          </p>
        </SorteioSectionCard>
        <SorteioSectionCard>
          <SorteioCardIcon>
            <img src={IconCalendario} alt="Ícone de bandeira quadriculada" />
          </SorteioCardIcon>
          <span>passo 4</span>
          <strong>Fique atento às datas dos lotes</strong>
          <p>
            Serão 4 lotes disponíveis nas segundas-feiras às 12h (horário de
            Brasília), conforme calendário abaixo. Garanta seus ingressos!
          </p>
        </SorteioSectionCard>
      </RenderCarousel>

      <span>
        {"*"}
        <a
          href={RulesF1Manual}
          download={"RG Resgate Raízen F1 Resgate Aprovado"}
          target="_blank"
          rel="noopener noreferrer"
        >
          Confira o regulamento para resgate de ingressos
        </a>
        e saiba mais.
      </span>
    </SorteioSectionContainer>
  );
}
