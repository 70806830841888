import React from "react";
import styled from "styled-components";
import { Button, Icon, Pane, Text } from "evergreen-ui";

export default function CustomOutlineButton(props) {
  return (
    <MyButton onClick={props.handleButton} {...props}>
      {props.icon ? (
        <Pane display="flex" alignItems="center">
          <Icon
            icon={props.icon}
            size={20}
            marginRight={props.title && 5}
            color="#425A70"
          />
          <Text>{props.title}</Text>
        </Pane>
      ) : (
        props.title
      )}
    </MyButton>
  );
}

const MyButton = styled(Button)`
  box-shadow: none;
  background-image: none;
  color: ${(props) => props.theme.color.black};
  border: 2px solid #eee;
  font-weight: bold;
  width: ${(props) => props.btnWidth};
  height: ${(props) =>
    props.btnHeight !== undefined ? props.btnHeight : "36px"};
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    opacity: 0.8;
    background-image: none !important;
  }
`;
