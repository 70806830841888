import React from "react";
import ReactDOM from "react-dom";
import { StoreProvider } from "easy-peasy";
import { ThemeProvider, theme as themeChakra } from "@chakra-ui/core";
import { ThemeProvider as NewThemeProvider } from "styled-components";
import "animate.css/animate.min.css";
import "react-multi-carousel/lib/styles.css";
import "react-app-polyfill/ie9";
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import { store } from "./configureStore";
import { GlobalStyle } from "./configureStyle";
import { theme } from "./theme";
import Routes from "./routes/index";
import history from "./utils/history";
import "./index.css";
import { setInLocalStorage } from "./utils/localstorage";
import { setSlug, setShellbox } from "utils/helper";
import { QueryClient, QueryClientProvider } from "react-query";

const slug = setSlug();
setShellbox(false);

if (
  window.location.search.includes("shellbox") &&
  window.location.search.includes("token")
) {
  setShellbox(true);
  const auth = localStorage.getItem("Hub-auth");
  if (auth) {
    localStorage.removeItem("Hub-auth");
  }
  const token = window.location.search.split("token=")[1];
  localStorage.setItem("external_token", token);

  fetch(`${process.env.REACT_APP_BASE_URL}${slug.endpoint}`)
    .then((res) => res.json())
    .then((res) => {
      localStorage.setItem("promo_key", res?.subscription_key);
      localStorage.setItem("promo_google_maps", res?.react_app_google_maps_api);
      localStorage.setItem("promo_recaptcha", res?.react_app_recaptcha);
      sessionStorage.setItem("promo_footer", res?.rodape_alternativo);

      const params = new URLSearchParams();
      params.append("consumerToken", token.toString());
      fetch(`${process.env.REACT_APP_BASE_URL}/api/token/external`, {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          SubscriptionKey:
            res?.subscription_key || localStorage.getItem("promo_key") || "",
        },
        body: params,
      })
        .then((res) => res.json())
        .then((res) => {
          if (res?.access_token) {
            setInLocalStorage("Hub-auth", {
              isAuthenticated: true,
              token: res.access_token,
              user: res,
            });
            setShellbox(false);
          }
        })
        .finally(() => {
          setShellbox(false);
        });
    })
    .finally(() => {
      setShellbox(false);
    });
} else {
  try {
    fetch(`${process.env.REACT_APP_BASE_URL}${slug.endpoint}`, {
      method: "GET",
    })
      .then((res) => res.json())
      .then((res) => {
        localStorage.setItem("promo_key", res?.subscription_key);
        localStorage.setItem(
          "promo_google_maps",
          res?.react_app_google_maps_api
        );
        localStorage.setItem("promo_recaptcha", res?.react_app_recaptcha);
        sessionStorage.setItem("promo_footer", res?.rodape_alternativo);
      });
  } catch (err) {
    console.error(err);
  }
}

const themeChak = {
  ...themeChakra,
  ...{
    fonts: {
      heading: `Futura LT Regular, Arial, sans-serif"`,
      body: `Futura LT Regular, Arial, sans-serif"`,
      mono: `Futura LT Regular, Arial, sans-serif`,
    },
  },
};

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

ReactDOM.render(
  <QueryClientProvider client={queryClient}>
    <NewThemeProvider theme={theme}>
      <ThemeProvider theme={themeChak}>
        <StoreProvider store={store}>
          <GlobalStyle />
          <Routes history={history} />
        </StoreProvider>
      </ThemeProvider>
    </NewThemeProvider>
  </QueryClientProvider>,
  document.getElementById("root")
);

if (process.env.NODE_ENV !== "production") {
  //@ts-ignore
  if (module.hot) {
    //@ts-ignore
    module.hot.accept();
  }
}
