import axios from "axios";
import get from "lodash/get";
import { auth as useAuth } from "../hooks/auth";
import { getSlug, refreshToken } from "./helper";
import { setInLocalStorage } from "./localstorage";

export const api = axios.create({
  headers: {
    "Content-Type": "application/json",
  },
  timeout: 20000,
});

export const apiForm = axios.create({
  headers: {
    "Content-Type": "application/x-www-form-urlencoded",
  },
  timeout: 20000,
});

api.interceptors.request.use(
  function (config) {
    const { auth, cachedAuth } = useAuth(false);
    const isAuth =
      !!get(auth, "token", false) || !!get(cachedAuth, "token", false);

    if (
      config.url.includes("login") ||
      config.url.includes("register") ||
      config.url.includes("recover") ||
      config.url.includes("change-password")
    ) {
      delete config.headers.Authorization;
    } else {
      if (isAuth) {
        const token =
          get(auth, "token", false) || get(cachedAuth, "token", false);
        config.headers.Authorization = `Bearer ${token}`;
      }
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

let loop = 0;
let isRefreshing = false;
let subscribers = [];

function subscribeTokenRefresh(cb) {
  subscribers.push(cb);
}

function onRefreshed(token) {
  subscribers.map((cb) => cb(token));
}

api.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    const originalRequest = error.config;
    if (error.response) {
      if (
        (error.response.status === 401 ||
          error.response.status === 403 ||
          error.response.status === 422) &&
        loop < 1
      ) {
        if (
          !window.location.pathname.includes("login") &&
          !window.location.pathname.includes("register") &&
          !window.location.pathname.includes("recover") &&
          !window.location.pathname.includes("change-password") &&
          !window.location.pathname.includes(
            "nao-foi-possivel-carregar-conteudo"
          ) &&
          window.location.search.includes("shellbox") &&
          !originalRequest.url.includes("/api/participant") &&
          error.response.status === 422
        ) {
          loop++;
          if (!isRefreshing) {
            isRefreshing = true;
            refreshToken();
            const token = localStorage.getItem("external_token");
            const params = new URLSearchParams();
            params.append("consumerToken", token.toString());

            fetch(`${process.env.REACT_APP_BASE_URL}/api/token/external`, {
              method: "POST",
              headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                SubscriptionKey: localStorage.getItem("promo_key") || "",
              },
              body: params,
            })
              .then((res) => res.json())
              .then((data) => {
                isRefreshing = false;
                if (data?.access_token) {
                  onRefreshed(data.access_token);
                  const cache = {
                    isAuthenticated: true,
                    token: data.access_token,
                    user: data,
                  };
                  setInLocalStorage("Hub-auth", cache);
                  subscribers = [];
                  loop = 0;
                } else {
                  localStorage.removeItem("Hub-auth");
                  localStorage.removeItem("external_token");
                  window.open(
                    "/nao-foi-possivel-carregar-conteudo?shellbox=hiddenComponent",
                    "_self"
                  );
                }
              })
              .catch(() => {
                localStorage.removeItem("Hub-auth");
                localStorage.removeItem("external_token");
                window.open(
                  "/nao-foi-possivel-carregar-conteudo?shellbox=hiddenComponent",
                  "_self"
                );
              });
          }

          return new Promise((resolve) => {
            subscribeTokenRefresh((token) => {
              originalRequest.headers.Authorization = `Bearer ${token}`;
              resolve(axios(originalRequest));
            });
          });
        }
        // else if (
        //   !window.location.pathname.includes("login") &&
        //   !window.location.pathname.includes("register") &&
        //   !window.location.pathname.includes("recover") &&
        //   !window.location.pathname.includes("change-password") &&
        //   !window.location.pathname.includes(
        //     "nao-foi-possivel-carregar-conteudo"
        //   ) &&
        //   !originalRequest.url.includes("/api/participant") &&
        //   !originalRequest.url.includes("/api/balanco/") &&
        //   !originalRequest.url.includes("/api/order/history") &&
        //   error.response.status === 422
        // ) {
        //   localStorage.removeItem("Hub-auth");
        //   localStorage.removeItem("external_token");
        //   window.location.href = getSlug().url;
        // }
        else if (
          !window.location.pathname.includes("login") &&
          !window.location.pathname.includes("register") &&
          !window.location.pathname.includes("recover") &&
          !window.location.pathname.includes("change-password") &&
          !window.location.pathname.includes(
            "nao-foi-possivel-carregar-conteudo"
          ) &&
          !window.location.search.includes("shellbox") &&
          error.response.status === 401
        ) {
          localStorage.removeItem("Hub-auth");
          localStorage.removeItem("external_token");
          window.location.href = getSlug().url;
        }
      }
    }
    return Promise.reject(error);
  }
);

export const request = (req: any) => {
  if (
    window.location.href.includes("homolog") ||
    window.location.href.includes("localhost")
  ) {
    api.defaults.headers.common["Skip-Recaptcha"] = true;
  }
  if (req.route === "token") {
    for (const [key, value] of req.payload.entries()) {
      if (
        key === "recaptcha" &&
        !window.location.href.includes("localhost") &&
        !window.location.href.includes("promoshell-dev")
      ) {
        api.defaults.headers.common["Skip-Recaptcha"] = false;
        api.defaults.headers.common["Recaptcha"] = value.toString();
      }
    }
    req.payload.delete("recaptcha");
  } else if (
    (req.route === "participant/forgetpassword" ||
      req.route === "participant") &&
    (req.method === "post" || req.method === "put")
  ) {
    if (req.payload.recaptcha) {
      api.defaults.headers.common["Skip-Recaptcha"] = false;
      api.defaults.headers.common["Recaptcha"] = req.payload.recaptcha;
      delete req.payload.recaptcha;
    }
  }
  api.defaults.headers.common["SubscriptionKey"] =
    localStorage.getItem("promo_key") || "";

  return api({
    url: `${process.env.REACT_APP_BASE_URL}/${req.baseUrl}/${req.route}`,
    data: req.payload || null,
    method: req.method,
    headers: Boolean(req.headers)
      ? { ...req.headers }
      : {
          "Content-Type": "application/json",
        },
  } as any);
};

export const requestForm = (req: any) => {
  if (
    window.location.href.includes("homolog") ||
    window.location.href.includes("localhost")
  ) {
    apiForm.defaults.headers.common["Skip-Recaptcha"] = true;
  }
  if (req.route === "token") {
    for (const [key, value] of req.payload.entries()) {
      if (
        key === "recaptcha" &&
        !window.location.href.includes("localhost") &&
        !window.location.href.includes("promoshell-dev")
      ) {
        apiForm.defaults.headers.common["Skip-Recaptcha"] = false;
        apiForm.defaults.headers.common["Recaptcha"] = value.toString();
      }
    }
    req.payload.delete("recaptcha");
  } else if (
    (req.route === "participant/forgetpassword" ||
      req.route === "participant") &&
    (req.method === "post" || req.method === "put")
  ) {
    if (req.payload.recaptcha) {
      apiForm.defaults.headers.common["Skip-Recaptcha"] = false;
      apiForm.defaults.headers.common["Recaptcha"] = req.payload.recaptcha;
      delete req.payload.recaptcha;
    }
  }
  apiForm.defaults.headers.common["SubscriptionKey"] =
    localStorage.getItem("promo_key") || "";

  return apiForm({
    url: `${process.env.REACT_APP_BASE_URL}/${req.baseUrl}/${req.route}`,
    data: req.payload || null,
    method: req.method,
  } as any);
};
