import { Box } from "@chakra-ui/core";
import { CustomTab } from "modules/common/UI";
import { NavLink } from "react-router-dom";
import styled from "styled-components"

export const NewLoginContainer = styled.dialog`
  display: none;
  width: 100%;
  max-width: 678px;
  min-height: 360px;

  position: absolute;
  top: 3rem;
  left: auto;
  box-shadow: 0 2px 3px 2px rgba(0,0,0,0.5);

  animation: .3s fadeIn forwards;
  border: 10px solid ${props => props.theme.color.orange};
  &:focus-within {
    outline: 3px solid ${props => props.theme.color.blueOutline};
  }

  @keyframes fadeIn {
    from {opacity: 0}
    to {opacity: 1}
  }

  @media (min-width: ${props => props.theme.queries.lg}) {
    display: flex;
  }
`

export const NewLoginFormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  p {
    font-family: ${props => props.theme.Shell.Book};
    font-size: 1rem;
    line-height: normal;
    color: ${props => props.theme.color.text};
    margin: 0;
  }
  form {
    display: flex;
    flex-direction: column;

    a {
      color: ${props => props.theme.color.blue};
      font-family: ${props => props.theme.Shell.Bold};
      font-size: 0.875rem;
      font-weight: 600;
      margin: 0.5rem 0;
      text-decoration: none;

      &:hover,
      &:focus {
        text-decoration: underline;
        opacity: 0.8;
      }
    }
  }
  @media (min-width: ${props => props.theme.queries.lg}) {
    padding-right: 1.5rem;
    border-right: 2px solid ${props => props.theme.color.lightGray};
  }
`

export const LoginInputWrapper = styled(Box)`
  #input {
    width: 100%;
    label {
      font-family: Futura LT Regular !important;
    }
    .mdc-floating-label {
      color: ${(props) => props.theme.color.gray} !important;
      letter-spacing: 0.03em !important;
      font-weight: normal !important;
      font-size: 16px !important;
      line-height: 14px !important;
    }

    .mdc-floating-label--float-above {
      color: ${(props) => props.theme.color.darkGray} !important;
      font-weight: 600 !important;
      font-size: 14px !important;
      line-height: 14px !important;
    }
    input {
      color: ${(props) => props.theme.color.gray} !important;
      font-size: 20px !important;
      font-weight: 0 !important;
    }
  }

  #error {
    width: 100%;
    label {
      font-family: Futura LT Regular !important;
    }
    .mdc-floating-label {
      color: ${(props) => props.theme.color.red} !important;
      letter-spacing: 0.03em !important;
      font-weight: normal !important;
      font-size: 16px !important;
      line-height: 14px !important;
    }

    .mdc-floating-label--float-above {
      color: ${(props) => props.theme.color.red} !important;
      font-weight: 600 !important;
      font-size: 14px !important;
      line-height: 14px !important;
    }
    input {
      color: ${(props) => props.theme.color.red} !important;
      font-size: 20px !important;
      font-weight: normal !important;
    }
  }
`;

export const CustomReCaptcha = styled(Box)`
  iframe {
    width: 100%;
    margin: 0;
  }
`;

export const LoginSubmitButton = styled.button`
  width: 100%;
  font-family: ${props => props.theme.Shell.Bold};
  font-size: 1.125rem;
  font-weight: 700;
  line-height: 1;
  text-transform: uppercase;
  color: ${props => props.theme.color.text};
  border: 3px solid ${props => props.theme.color.yellow};
  border-radius: 12px;
  
  background: ${props => props.theme.color.yellow};
  margin-top: auto;
  padding: 1rem 1.5rem;
  
  cursor: pointer;
  transition: .2s background;
  
  &:not(:disabled):focus,
  &:not(:disabled):hover {
    background: ${props => props.theme.color.white};
    border-color: ${props => props.theme.color.red};
  }
  &:focus {
    outline: 2px solid ${props => props.theme.color.blueOutline};
  }
  &:disabled {
    opacity: 0.75;
    cursor: not-allowed;
  }
`
export const SignInButton = styled(NavLink)`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  font-family: ${props => props.theme.Shell.Bold};
  font-size: 1rem;
  font-weight: 700;
  line-height: 1;
  text-transform: uppercase;
  color: ${props => props.theme.color.text};
  border: 3px solid ${props => props.theme.color.yellow};
  border-radius: 12px;
  
  background: ${props => props.theme.color.yellow};
  margin-top: auto;
  padding: 1rem;
  
  cursor: pointer;
  transition: .2s background;
  text-decoration: none;
  
  &:focus,
  &:hover {
    background: ${props => props.theme.color.white};
    border-color: ${props => props.theme.color.red};
  }
  &:focus {
    outline: 2px solid ${props => props.theme.color.blueOutline};
  }
`

export const NewLoginSignInWrapper = styled.aside`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;

  h2 {
    color: ${props => props.theme.color.text};
    font-family: ${props => props.theme.Shell.Book};
    font-size: 2.25rem;
    font-weight: 200;
    line-height: 1;
    margin: 1.5rem 0 0;
  }
  p {
    font-family: ${props => props.theme.Shell.Book};
    font-size: 1rem;
    line-height: normal;
    color: ${props => props.theme.color.text};
    margin: 0;
  }
  @media (min-width: ${props => props.theme.queries.lg}) {
    padding-left: 2rem;
  }
`

export const ErrorMessage = styled.p`
  width: 100%;
  background: ${props => props.theme.color.red};
  padding: 0.5rem;
  color: ${props => props.theme.color.white} !important;
  font-family: Arial, sans-serif !important;
  font-size: 1.125rem !important;
  line-height: 1;
  margin: 0 auto;
  text-align: center;
`

export const NewLoginMobileWrapper = styled.aside`
  display: flex;
  flex-direction: column;
  margin: 1rem auto;
  padding: 0 1rem;
`
export const WrapTab = styled(Box)`
  margin-bottom: 1rem;
`;

export const MyCustomTabs = styled(CustomTab)`
  div {
    margin: -0.8rem 0 0 0 !important;
  }
  @media (max-width: ${(props) => props.theme.queries.medium}) {
    button {
      width: 50%;
      text-align: center;

      &[aria-selected="true"] {
        color: ${props => props.theme.color.red};
      }

      &:hover, &:focus {
        text-decoration: underline;
        box-shadow: 0 0 0 2px ${props => props.theme.color.red};
        color: ${props => props.theme.color.red};
      }
    }
  }
`;