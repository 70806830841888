import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Link as LinkScroll } from 'react-scroll';

import IconShell from 'assets/icons/concha-shell.svg'

export default function Navbar() {
  const [ selected, setSelected ] = useState(0);

  return (
    <Navigation>
      <img src={IconShell} alt="" />
      <PromoTitle>promoção select lorem ipsum sorvete</PromoTitle>
      <Menu>
        <LinkScroll
          to="hub-sobre-promocao"
          spy={true}
          smooth={true}
          offset={-125}
          duration={500}
          tabIndex={0}
          // className={selected === 0 && "active"} 
          id="hub-navbar-1"
          onClick={() => setSelected(0)}
        >
          <span>sobre a promoção</span>
        </LinkScroll>
        <LinkScroll
          to="hub-como-participar"
          spy={true}
          offset={-110}
          smooth={true}
          duration={500}
          tabIndex={0}
          // className={selected === 1 && "active"} 
          id="hub-navbar-2"
          onClick={() => setSelected(1)}
        >
          <span>como participar</span>
        </LinkScroll>
        <LinkScroll
          to="hub-perguntas-frequentes"
          spy={true}
          offset={-125}
          smooth={true}
          duration={500}
          tabIndex={0}
          // className={selected === 2 && "active"} 
          id="hub-navbar-3"
          onClick={() => setSelected(2)}
        >
          <span>perguntas frequentes</span>
        </LinkScroll>
        <LinkScroll
          to="postos-participantes"
          spy={true}
          offset={-130}
          smooth={true}
          duration={500}
          tabIndex={0}
          // className={selected === 3 && "active"} 
          id="hub-navbar-4"
          onClick={() => setSelected(3)}
        >
          <span>lojas participantes</span>
        </LinkScroll>
      </Menu>

    </Navigation>
  )
}

const Navigation = styled.nav`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 4.125rem;
  width: 100%;

  background: #FFF;
  margin-top: -2.75rem;

  position: fixed;
  z-index: 10;

  @media (max-width: 768px) {
    display: none;
  }
  @media (max-width: 1024px) {
    padding: 0 1rem;
  }
`;

const PromoTitle = styled.h1`
  font-size: 1.25rem;
  line-height: 0.813rem;
  font-weight: 700;
  text-transform: uppercase;
  color: ${props => props.theme.color.text};
  margin: 0;
  margin-left: 1rem;
  margin-right: 3rem;

  @media (max-width: 1350px) {
    font-size: 0.85rem;
  }

  @media (max-width: 1024px) {
    font-size: 1rem;
    margin: 0 0.5rem;
    width: 20%;
  }
`;

const Menu = styled.div`
  display: grid;
  grid: 1fr / repeat(4, 1fr);
  max-height: 2.375rem;
  max-width: 52.5rem;

  border: 1px solid ${props => props.theme.color.text};

  a {
    display: flex;
    justify-content: center;
    
    font-size: 0.813rem;
    line-height: 0.688rem;
    font-weight: 700;
    text-decoration: none;
    text-transform: uppercase;
    color: ${props => props.theme.color.text};
    margin: 0;

    padding: 0.5rem 1.5rem;
    /* border: 0; */
    border-bottom: 3px solid transparent;
    transition: 0.2s;
    cursor: pointer;


    @media (max-width: 1024px) {
      font-size: 0.75rem;
      padding: 0.5rem 0.75rem;
    }

    &:hover {
      /* color: ${props => props.theme.color.orange}; */
      border-bottom: 3px solid ${props => props.theme.color.orange};
    }
    &:focus {
      border-bottom: 3px solid ${props => props.theme.color.text};
    }

    &.active {
      border-bottom: 3px solid ${props => props.theme.color.text};
    }
  }
`;