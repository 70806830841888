import React, { ReactNode, useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { useActions, useStore } from "configureStore";
import { IconNewWindow } from "modules/common/IconNewWindow";
import { getSlug } from "utils/helper";

interface FooterSmartLinkProps {
  href: string
  children: string
  isExternal?: boolean
}

export function FooterSmartLink({
  href,
  children,
  isExternal
}: FooterSmartLinkProps) {
  const slug = getSlug().url
  const [rootLink, setRootLink] = useState(slug)
  const [queryShellbox, setQueryShellbox] = useState(localStorage.getItem("query_shell"))
  const [shellboxToken, setShellboxToken] = useState(localStorage.getItem("external_token"))
  const setActive = useActions((actions) => actions.loading.setActive);
  const isAuth = useStore((store) => store.auth.isAuthenticated);

  const isShellbox = window.location.search.includes("shellbox")
  const route = `${rootLink}${href}`

  function handleWebviewLinkClick() {
    try {
      // @ts-ignore
      WebViewJavascriptInterface.openExternalLink(href);
    } catch (_) {
      console.log("not in webview")
      window.open(
        href,
        "_blank",
        "noopener"
      );
      window.focus();
    }
    setActive(true);
  }

  function handleWebviewInternalLink() {
    if (queryShellbox !== "null" && queryShellbox?.length > 1) {
      return `${route}?shellbox=${queryShellbox}&token=${shellboxToken}`
    }
    return route
  }

  useEffect(() => {
    const query = localStorage.getItem("query_shell")
    const token = localStorage.getItem("external_token")

    setQueryShellbox(query)
    setShellboxToken(token)
  }, [isShellbox, isAuth]);

  useEffect(() => {
    if (window.location.pathname.includes("gp-sp-2023")) {
      setRootLink(`${slug}/gp-sp-2023`)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.pathname]);

  if (isShellbox && !isExternal) {
    return (
      <NavLink to={handleWebviewInternalLink}>
        {children}
      </NavLink>
    )
  }

  if (!isShellbox && isExternal) {
    return (
      <a
        href={href}
        target="_blank"
        rel="noopener noreferrer"
        aria-label={`${children} (abre em uma nova guia)`}
      >
        {children}
        <IconNewWindow width="16px" height="16px" />
      </a>
    )
  }

  if (isExternal) {
    return (
      <button
        aria-label={`${children}, abre em uma nova guia`}
        onClick={handleWebviewLinkClick}
      >
        {children}
        <IconNewWindow width="16px" height="16px" />
      </button>
    )
  }

  return (
    <NavLink to={route}>
      {children}
    </NavLink>
  )
}