import React from 'react';
import styled from 'styled-components';
import useWindowSize from 'hooks/useWIndowSize';
import { ActivePromotionStamp } from 'modules/hub/ActivePromotionStamp';

// import ImageBanner from 'assets/images/hub/RoadPromotions/banner_sorte_estrada.png';
// import ImageBannerMobile from 'assets/images/hub/RoadPromotions/banner_sorte_estrada_mobile.png';
import ImageSeloCaminhao from 'assets/images/hub/RoadPromotions/selo.png';
import BannerRachaConta from 'assets/banners/rodovias.png';
import BannerRachaContaMobile from 'assets/banners/rodovias-mobile.png';
import BannerEncerramento from 'assets/images/rachaConta/rodovias-fim.png'
import BannerEncerramentoMobile from 'assets/images/rachaConta/rodovias-fim-mobile.png'

const Banner = () => {
  const { width } = useWindowSize();

  return (
    <Container>
      <a href={"/promocoes-de-rodovia"} >
        {/* <SeloExclusivo
          src={ImageSeloCaminhao}
          draggable="false"
        /> */}
        <ActivePromotionStamp
          left={"85%"}
          top={"0.5rem"}
          responsiveLeftPosition={"75%"}
          responsiveTopPosition={"2.875rem"}
        />
        <img
          className='banner'
          src={width <= 768 ? BannerEncerramentoMobile : BannerEncerramento}
          alt="Promoção Shell Racha a Conta"
          draggable="false"
        />
      </a>
    </Container>
  )
}

export default Banner;

const Container = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  img.banner {
    width: 100%;
    height: auto;
  }
  @media (min-width: ${props => props.theme.queries.medium}) {
    top: 4.75rem;
    left: 0;
  }
`;

const SeloExclusivo = styled.img`
  position: absolute;
  top: 2rem;
  z-index: 10;
  width: 10rem;
  @media (min-width: ${props => props.theme.queries.medium}) {
    top: 0;
    left: 65%;
  }
  @media (min-width: ${props => props.theme.queries.lg}) {
    top: 0;
    left: 70%;
  }
`;