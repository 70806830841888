import React from "react"
import styled from "styled-components";
import { auth as useAuth } from "hooks/auth";
import { NavigationAnoTodo } from "../components/Navigation/NavigationAnoTodo";
import { Box } from "@chakra-ui/core";
import { SectionComoFunciona } from "../components/ComoFunciona/SectionComoFunciona";
import { CardsComoFunciona } from "../components/ComoFunciona/CardsComoFunciona";
import ConchasExtrasComoFunciona from "../components/ComoFunciona/ConchasExtrasComoFunciona";
import FooterAnoTodo from "../components/FooterAnoTodo";
import SectionAbastecaDiesel from "../components/ComoFunciona/SectionAbastecaDiesel";

export default function ComoFunciona() {
  const { auth } = useAuth();
  const isAuthenticated = auth.isAuthenticated;
  const isShellbox = window.location.search.includes("shellbox");

  return (
    <Container
      isAuth={isAuthenticated}
      isShellbox={isShellbox}
    >
      <HeaderWrapper>
        <NavigationAnoTodo />
        <SectionComoFunciona />
      </HeaderWrapper>
      <Box
        as={"main"}
        display={"flex"}
        flexDir={"column"}
        justifyContent={"center"}
        alignItems={"flex-start"}
        w={"100%"}
      >
        <CardsComoFunciona />
        <ConchasExtrasComoFunciona />
        <SectionAbastecaDiesel />
      </Box>
    </Container>
  )
}

const Container = styled(Box)`
  margin-top: ${(props) => (!props.isShellbox ? "40px" : "0")};
  background-color: ${props => props.theme.color.lightGray50};
  
  padding-bottom: ${props => props.isAuth || props.isShellbox ? "3rem" : "0"};
`;

const HeaderWrapper = styled.header`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  
  background-color: ${props => props.theme.color.shellPurple};
  padding-top: 8px;
  
  @media (min-width: ${props => props.theme.queries.lg}) {
    padding: 4px 0 2rem;
  }
`;