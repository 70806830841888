import React from "react";
import { Modal, ModalContent, ModalBody } from "@chakra-ui/core";
import styled from "styled-components";

const ModalFullScreen = (props) => {
  return (
    <CustomModal
      onClose={props.onClose}
      finalFocusRef={props.ref}
      isOpen={props.isOpen}
      scrollBehavior={"inside"}
      draggable="false"
      blockScrollOnMount={true}
    >
      <ModalContent>
        <ModalBody padding={0} paddingBottom="100px">
          {props.children}
        </ModalBody>
      </ModalContent>
    </CustomModal>
  );
};

export default ModalFullScreen;

const CustomModal = styled(Modal)`
  .css-89ulqu {
    padding: 1rem 1.3rem 0rem 1.3rem !important;
    height: 130% !important;
  }
`;
