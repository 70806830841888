import React from "react";
import styled from "styled-components";
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";
import { get } from "lodash";
import { auth as useAuth } from "hooks/auth";
import { Image, Box, Text, Tab, TabList } from "@chakra-ui/core";
import { theme } from "theme";
import { CustomTab } from "modules/common/UI";
import { Link as LinkScroll } from "react-scroll";

import concha from "assets/images/concha.svg";
import { getSlug } from "utils/helper";

const LinksPromotion = (props) => {
  const { auth, cachedAuth } = useAuth(
    window.location.search.includes("shellbox") ? false : true
  );

  const isAuthenticated =
    (!!get(auth, "token", false) || !!get(cachedAuth, "token", false)) &&
    !window.location.pathname.includes("login") &&
    !window.location.pathname.includes("register") &&
    !window.location.pathname.includes("resetPassword");

  const location = props.history.location.pathname;

  const getPromotionSlug = () => {
    switch (getSlug().url) {
      case '/promocao-junte-e-troque':
        return 'promoção Junte e troque'
      case '/promocao-junte-e-ganhe':
        return 'promoção Junte e ganhe'
      case '/menos15':
        return 'promoção Menos15'
      case '/promocao-junte-e-troque-2021':
        return 'promoção junte e troque'
    }
  }

  return (
    <Box>
      <ContainerPromotion
        height={isAuthenticated ? "89px" : "auto"}
        paddingTop={isAuthenticated ? "0" : "1.4rem"}
        paddingBottom={isAuthenticated ? "0" : "1.4rem"}
        display="flex"
        alignItems="center"
      >
        <WrapPromotion>
          <IconShell src={concha} alt="Concha" />
          <Promotion>{getPromotionSlug()}</Promotion>
        </WrapPromotion>
        {isAuthenticated ? (
          <WrapLinks
            width="541px"
            marginLeft={"1.5rem"}
            border={`1px solid ${theme.color.gray}`}
            alignItems="center"
            justifyContent="space-evenly"
          >
            <BannerLink
              to={() => {
                if (
                  localStorage.getItem("query_shell") !== "null" &&
                  localStorage.getItem("query_shell")?.length > 1
                ) {
                  return `${getSlug().url
                    }/minhas-conchas?shellbox=${localStorage.getItem(
                      "query_shell"
                    )}&token=${localStorage.getItem("external_token")}`;
                } else {
                  return `${getSlug().url}/minhas-conchas`;
                }
              }}
              tabIndex={0}
              id="link-1"
            >
              <Text
                zIndex={9}
                padding={"0 13px 0 10px"}
                margin={"0 0 -3px 0"}
                fontWeight="bold"
                fontFamily="Futura"
              >
                Minhas Conchas
              </Text>
              <BgActive
                className="select-1"
                width={location === `${getSlug().url}/minhas-conchas` && "100%"}
              />
            </BannerLink>
            <Pipe height={"12px"} backgroundColor={theme.color.gray} />
            <BannerLink
              tabIndex={0}
              id="link-2"
              to={() => {
                if (
                  localStorage.getItem("query_shell") !== "null" &&
                  localStorage.getItem("query_shell")?.length > 1
                ) {
                  return `${getSlug().url}?shellbox=${localStorage.getItem(
                    "query_shell"
                  )}&token=${localStorage.getItem("external_token")}`;
                } else {
                  return getSlug().url;
                }
              }}
            >
              <Text
                zIndex={9}
                padding={"0 13px 0 10px"}
                margin={"0 0 -2px 0"}
                fontWeight="bold"
                fontFamily="Futura"
              >
                Sobre a promoção
              </Text>
              <BgActive
                className="select-2"
                width={location === getSlug().url && "100%"}
              />
            </BannerLink>
            <Pipe height={"12px"} backgroundColor={theme.color.gray} />
            <BannerLink
              tabIndex={0}
              id="link-3"
              to={() => {
                if (
                  localStorage.getItem("query_shell") !== "null" &&
                  localStorage.getItem("query_shell")?.length > 1
                ) {
                  return `${getSlug().url
                    }/meus-resgates?tab=0&menu&shellbox=${localStorage.getItem(
                      "query_shell"
                    )}&token=${localStorage.getItem("external_token")}`;
                } else {
                  return `${getSlug().url}/meus-resgates?tab=0&menu`;
                }
              }}
            >
              <Text
                zIndex={9}
                padding={"0 13px 0 10px"}
                margin={"0 0 -2px 0"}
                fontWeight="bold"
                fontFamily="Futura"
              >
                meus resgates
              </Text>
              <BgActive
                className="select-3"
                width={location === `${getSlug().url}/meus-resgates` && "100%"}
              />
            </BannerLink>
          </WrapLinks>
        ) : (
          <WrapLinks
            marginLeft={["15px", "15px", "15px", "0px"]}
            width="707px"
            border={`1px solid ${theme.color.gray}`}
            alignItems="center"
            justifyContent="space-evenly"
          >
            <BannerLinkScroll
              to="root"
              spy={true}
              smooth={true}
              offset={-185}
              duration={500}
              tabIndex={0}
              id="link-1"
            >
              <Text
                zIndex={9}
                padding={"0 13px 0 10px"}
                margin={"0 0 -2px 0"}
                fontWeight="bold"
                fontFamily="Futura"
              >
                sobre a promoção
              </Text>
              <BgActive className="select-1" />
            </BannerLinkScroll>
            <Pipe height={"12px"} backgroundColor={theme.color.gray} />
            <BannerLinkScroll
              to="como-participar"
              spy={true}
              smooth={true}
              offset={-185}
              duration={500}
              tabIndex={0}
              id="link-2"
            >
              <Text
                zIndex={9}
                padding={"0 13px 0 10px"}
                margin={"0 0 -2px 0"}
                fontWeight="bold"
                fontFamily="Futura"
              >
                como participar
              </Text>
              <BgActive className="select-2" />
            </BannerLinkScroll>
            <Pipe height={"12px"} backgroundColor={theme.color.gray} />
            <BannerLinkScroll
              to="premiacao"
              spy={true}
              smooth={true}
              offset={-185}
              duration={500}
              tabIndex={0}
              id="link-3"
            >
              <Text
                zIndex={9}
                padding={"0 13px 0 10px"}
                margin={"0 0 -2px 0"}
                fontWeight="bold"
                fontFamily="Futura"
              >
                prêmios
              </Text>
              <BgActive className="select-3" />
            </BannerLinkScroll>
            <Pipe height={"12px"} backgroundColor={theme.color.gray} />
            <BannerLinkScroll
              to="postos-participantes"
              spy={true}
              smooth={true}
              offset={-185}
              duration={500}
              tabIndex={0}
              id="link-4"
            >
              <Text
                zIndex={9}
                padding={"0 13px 0 10px"}
                margin={"0 0 -2px 0"}
                fontWeight="bold"
                fontFamily="Futura"
              >
                postos participantes
              </Text>
              <BgActive className="select-4" />
            </BannerLinkScroll>
          </WrapLinks>
        )}
      </ContainerPromotion>
      {isAuthenticated && (
        <WrapTab>
          <CustomTab
            defaultIndex={
              window?.location?.pathname === `${getSlug().url}/minhas-conchas`
                ? 0
                : window?.location?.pathname === getSlug().url
                  ? 1
                  : 2
            }
          >
            <TabList>
              <Tab
                tabIndex={0}
                onClick={() => {
                  if (
                    localStorage.getItem("query_shell") !== "null" &&
                    localStorage.getItem("query_shell")?.length > 1
                  ) {
                    props.history.push(
                      `${getSlug().url
                      }/minhas-conchas?shellbox=${localStorage.getItem(
                        "query_shell"
                      )}`
                    );
                  } else {
                    props.history.push(`${getSlug().url}/minhas-conchas`);
                  }
                }}
              >
                MINHAS CONCHAS
              </Tab>
              <Tab
                tabIndex={0}
                onClick={() => {
                  if (
                    localStorage.getItem("query_shell") !== "null" &&
                    localStorage.getItem("query_shell")?.length > 1
                  ) {
                    props.history.push(
                      `${getSlug().url}?shellbox=${localStorage.getItem(
                        "query_shell"
                      )}`
                    );
                  } else {
                    props.history.push(getSlug().url);
                  }
                }}
              >
                SOBRE A PROMOÇÃO
              </Tab>
              <Tab
                tabIndex={0}
                onClick={() => {
                  if (
                    localStorage.getItem("query_shell") !== "null" &&
                    localStorage.getItem("query_shell")?.length > 1
                  ) {
                    props.history.push(
                      `${getSlug().url
                      }/meus-resgates?tab=0&menu&shellbox=${localStorage.getItem(
                        "query_shell"
                      )}`
                    );
                  } else {
                    props.history.push(`${getSlug().url}/meus-resgates?tab=0&menu`);
                  }
                }}
              >
                MEUS RESGATES
              </Tab>
            </TabList>
          </CustomTab>
        </WrapTab>
      )}
    </Box>
  );
};

export default withRouter(LinksPromotion);

const ContainerPromotion = styled(Box)`
  justify-content: center;
  position: fixed;
  width: 100vw;
  background-color: ${theme.color.yellow};
  margin-top: -3px;
  z-index: 10;
  /* padding-bottom: 20px; */
  @media (max-width: ${(props) => props.theme.queries.medium}) {
    justify-content: flex-start;
    height: 57px !important;
    padding: 0em 0 0em 0em !important;
    border-bottom: 1px solid ${(props) => props.theme.color.gray};
  }
  /* @media (max-width: ${(props) => props.theme.queries.i5}) {
    padding: 1em 0 1em 0.5em;
  }  */
  @media (max-width: ${(props) => props.theme.queries.medium}) {
    padding-top: 1rem;
    display: none;
    justify-content: flex-start;
  }
  @media (max-width: ${(props) => props.theme.queries.small}) {
    position: relative;
    z-index: 9;
  }
`;

const WrapPromotion = styled(Box)`
  display: flex;
  align-items: center;
  margin-right: 2rem;
  margin-top: 1px;
  @media (max-width: ${(props) => props.theme.queries.medium}) {
    margin-right: 0;
    padding-left: 1em;
  }
  @media (max-width: ${(props) => props.theme.queries.small}) {
    margin-right: 0;
    padding-top: 1.5em;
    padding-bottom: 1em;
  }
`;

const Promotion = styled.h2`
  font-size: 21px;
  font-weight: bold;
  color: ${(props) => props.theme.color.gray};
  text-transform: uppercase;
  font-family: Futura;
  margin: 0;
  line-height: 13px;
  @media (max-width: ${(props) => props.theme.queries.lg}) {
    font-size: 15px !important;
  }
  @media (max-width: ${(props) => props.theme.queries.medium}) {
    font-size: 14px !important;
  }
  @media (max-width: ${(props) => props.theme.queries.i5}) {
    font-size: 13px !important;
  }
`;

const BannerLinkScroll = styled(LinkScroll)`
  margin-top: 2px;
  display: flex;
  flex-direction: column;
  text-decoration: none;
  font-size: 13px;
  font-weight: bold;
  text-align: center;
  text-transform: uppercase;
  color: ${(props) => props.theme.color.gray};
  cursor: pointer;
  @media (max-width: ${(props) => props.theme.queries.lg}) {
    font-size: 11px;
  }
  @media (max-width: ${(props) => props.theme.queries.medium}) {
    font-size: 9px;
    :not(:root:root) {
      font-size: 8px;
    }
  }
`;

const BannerLink = styled(Link)`
  margin-top: 2px;
  display: flex;
  flex-direction: column;
  text-decoration: none;
  font-size: 13px;
  font-weight: bold;
  text-align: center;
  text-transform: uppercase;
  color: ${(props) => props.theme.color.gray};
  cursor: pointer;
  @media (max-width: ${(props) => props.theme.queries.lg}) {
    font-size: 11px;
  }
  @media (max-width: ${(props) => props.theme.queries.medium}) {
    font-size: 9px;
    :not(:root:root) {
      font-size: 8px;
    }
  }
`;

const WrapLinks = styled(Box)`
  display: flex;
  width: ${(props) =>
    props.widthLinks !== "undefined" ? props.widthLinks : "707px"};
  height: 38px;

  #link-1 {
    :hover,
    :focus {
      outline: none;
      .select-1 {
        width: 100%;
      }
    }
  }
  #link-2 {
    :hover,
    :focus {
      outline: none;
      .select-2 {
        width: 100%;
      }
    }
  }
  #link-3 {
    :hover,
    :focus {
      outline: none;
      .select-3 {
        width: 100%;
      }
    }
  }

  #link-4 {
    :hover,
    :focus {
      outline: none;
      .select-4 {
        width: 100%;
      }
    }
  }

  @media (max-width: ${(props) => props.theme.queries.lg}) {
    width: 611px !important;
    height: 29px;
  }
  @media (max-width: ${(props) => props.theme.queries.medium}) {
    width: 450px !important;
  }
  @media (max-width: ${(props) => props.theme.queries.small}) {
    display: none;
  }
`;

const IconShell = styled(Image)`
  margin-right: 15px;
  width: 35px;
  height: 34px;
  @media (max-width: ${(props) => props.theme.queries.lg}) {
    width: 33px;
    height: 31px;
    margin-right: 10px;
  }
  @media (max-width: ${(props) => props.theme.queries.medium}) {
    margin-right: 6px;
    height: 25px;
    width: 35px;
  }
  @media (max-width: ${(props) => props.theme.queries.small}) {
    width: 30px;
  }
`;

const WrapTab = styled(Box)`
  display: none;
  @media (max-width: ${(props) => props.theme.queries.small}) {
    display: block;
  }
`;

const BgActive = styled(Box)`
  background-color: #fcdf76;
  height: 27px;
  margin-top: -20px;
  top: -0px;
  width: 0%;
  transition: 0.3s;
  :hover {
    width: 100%;
  }
  @media (max-width: ${(props) => props.theme.queries.lg}) {
    /* height: 24px;
    margin-top: -19px; */
    height: 22px;
    margin-top: -17px;
  }
  @media (max-width: ${(props) => props.theme.queries.medium}) {
    height: 21px;
    margin-top: -14px;
  }
`;

const Pipe = styled(Box)`
  width: 2px;
  @media (max-width: ${(props) => props.theme.queries.medium}) {
    width: 1px;
  }
`;
