import React from "react";
import styled from "styled-components";
import { Button } from "@chakra-ui/core";

export default function CustomButton(props) {
  return (
    <MyButton
      onClick={props.handleButton}
      bgcolor={props.bgcolor}
      type={props.type}
      {...props}
    >
      {props.children}
    </MyButton>
  );
}

const MyButton = styled(Button)`
  background-color: ${(props) => props.bgcolor} !important;
  border: none;
  min-width: 320px;
  cursor: pointer;
`;
