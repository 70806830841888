import React from "react";
import styled from "styled-components";
import dataPostosAgosto from "utils/postos-gp-08-2024.json";
import { PostosParticipantesAnoTodo } from "modules/promoAnoTodo/components/PostosParticipantes/PostosParticipantesAnoTodo";

export default function PostosParticipantesGp2024() {
  return (
    <PostosContainer>
      <PostosParticipantesAnoTodo data={dataPostosAgosto} />
    </PostosContainer>
  );
}

const PostosContainer = styled.section`
  display: flex;
  flex-direction: column;
  width: 100%;

  button {
    background: ${(props) => props.theme.color.blue};
    border-radius: 8px;

    &:hover,
    &:focus {
      background: ${(props) => props.theme.color.darkBlue};
    }
  }
`;
