import React, { useState } from "react";
import styled from "styled-components";
import queryString from "query-string";
import { withRouter } from "react-router-dom";
import { Box, Button } from "@chakra-ui/core";
import { theme } from "theme";
import { useRouter } from "hooks/useRouter";
import { useStore, useActions } from "configureStore";
import { getSlug } from "utils/helper";
import BannerPromotionEnded from "./BannerPromotionEnded";
import BannerPromotionHelp from "./BannerPromotionHelp";

const BottomBannerShell = (props) => {
  const query = queryString.parse(props.location.search);
  const queryshellBox = query.shellbox;

  const isAuthenticated = useStore((state) => state.auth.isAuthenticated);
  const promotionsKey = useStore((state) => state.home.promotions);
  const isOpen = useStore((state) => state.home.isOpen);
  const setIsOpen = useActions((action) => action.home.setIsOpen);
  const router = useRouter();

  const [bg] = useState(theme.color.red);
  const [bg35shell] = useState("transparent");

  const scrollToTop = () => {
    if (typeof window !== "undefined") {
      window.scrollTo(0, 0);
    }
  };

  const open = () => {
    if (!isOpen) {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
    setIsOpen(!isOpen);
  };
  // const close = () => setIsOpen(false);

  return (
    <Container backgroundColor={bg}>
      <Content
        margin={"0 auto"}
        maxWidth={"1130px"}
        pathname={window.location.pathname}
      >
        {/* <WrapTexts>
          <ColText>
            <YellowSquare>
              A cada <b>R$30,00 = </b>
              <span>1</span> concha
            </YellowSquare>
            <CompleteCard>complete a cartela com</CompleteCard>
            <ValueShell>
              <Box backgroundColor={bg35shell} marginRight={1}>
                <b>35 conchas</b> <TextPlus>+</TextPlus>
              </Box>
              <BoxValues>
                <BoxValuesFirstRow>
                  <TextRsUp>6x R$</TextRsUp> <TextPriceUp>9</TextPriceUp>
                  <TextDecimalUp>,90</TextDecimalUp>
                </BoxValuesFirstRow>
                <BoxValuesSecondRow>
                  <TextOr>OU </TextOr> */}
        {/* <TextinstallmentBot> 6x </TextinstallmentBot>{" "} */}
        {/* <TextRsBot>R$</TextRsBot>
                  <TextPriceBot>59</TextPriceBot>
                  <TextDecimalBot>,40</TextDecimalBot>
                </BoxValuesSecondRow>
              </BoxValues>
            </ValueShell>
          </ColText> */}
        {/*  */}
        {/* <Box margin={3}>
            <EqualIcon marginBottom={1} backgroundColor={theme.color.yellow} />
            <EqualIcon marginBottom={0} backgroundColor={theme.color.yellow} />
          </Box> */}
        {/*  */}
        {/* <ColTextMobile>
            <TextYellowAndWhite>
              um <b>relógio smartwatch</b>
            </TextYellowAndWhite>
            <TextYellowAndWhite>
              ou <b>mini fone sem fio</b>
            </TextYellowAndWhite>
            <Exclusive>exclusivos shell</Exclusive>
          </ColTextMobile>
        </WrapTexts> */}

        {window.location.pathname?.includes(getSlug().url.replace("/", "")) &&
        queryshellBox?.length > 0 ? (
          //window.innerWidth >= 768 ? (
          <>
            <BannerPromotionHelp pathname={window.location.pathname} />
          </>
        ) : (
          // <BtnIWant
          //   className="eu-quero"
          //   backgroundColor={theme.color.yellow}
          //   rounded={0}
          //   border={0}
          //   _hover={{ boxShadow: "none" }}
          //   _focus={{ boxShadow: "none" }}
          //   textTransform="uppercase"
          //   cursor="pointer"
          //   color={theme.color.gray}
          //   onClick={() => {
          //     const slug = "promocao-junte-e-troque";
          //     if (isAuthenticated) {
          //       if (promotionsKey.length === 0) {
          //         router.push(`/promocao-junte-e-troque/minhas-conchas`);
          //       }
          //       promotionsKey?.find((item) => {
          //         if (item?.slug === slug) {
          //           if (
          //             localStorage.getItem("query_shell") !== "null" &&
          //             localStorage.getItem("query_shell")?.length > 1
          //           ) {
          //             router.push(
          //               `/${
          //                 item?.slug
          //               }/minhas-conchas?shellbox=${localStorage.getItem(
          //                 "query_shell"
          //               )}&token=${localStorage.getItem("external_token")}`
          //             );
          //             localStorage.setItem("promo_key", item?.subscription_key);
          //           } else {
          //             router.push(`/${item?.slug}/minhas-conchas`);
          //           }
          //         } else {
          //           router.push(`/promocao-junte-e-troque/minhas-conchas`);
          //         }
          //       });
          //     } else {
          //       if (window.location.pathname !== "/promocao-junte-e-troque") {
          //         if (
          //           localStorage.getItem("query_shell") !== "null" &&
          //           localStorage.getItem("query_shell")?.length > 1
          //         ) {
          //           router.push(
          //             `/promocao-junte-e-troque?shellbox=${localStorage.getItem(
          //               "query_shell"
          //             )}&token=${localStorage.getItem("external_token")}`
          //           );
          //         } else {
          //           router.push(`/promocao-junte-e-troque`);
          //         }
          //       } else {
          //         open();
          //       }

          //       scrollToTop();
          //     }
          //   }}
          // >
          //   Eu quero!
          // </BtnIWant>

          // <ButtonPromoExtended>
          //   <span>promoção prorrogada até o término do estoque</span>
          // </ButtonPromoExtended>
          <>
            <BannerPromotionHelp pathname={window.location.pathname} />
          </>
        )}
      </Content>
      {/* <BannerBlackFridayPromo /> */}
    </Container>
  );
};

export default withRouter(BottomBannerShell);

const Container = styled(Box)`
  transition: 0.7s;
  display: flex;
  z-index: 1;

  @media (max-width: 1366px) {
    justify-content: center;
  }
  @media (max-width: ${(props) => props.theme.queries.lg}) {
    flex-direction: column-reverse;
    justify-content: center;
  }
  @media (max-width: ${(props) => props.theme.queries.medium}) {
  }
`;

const Content = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* width: 79%; */
  width: 90%;
  padding-top: 16px;
  padding-bottom: 16px;

  @media (max-width: 1366px) {
    padding-left: 20px;
    padding-right: 30px;
  }
  @media (max-width: ${(props) => props.theme.queries.lg}) {
    padding: 1em 0;
    margin: 0 auto;
    width: ${(props) => (props.pathname === getSlug().url ? "70%" : "73%")};
  }
  @media (max-width: 960px) {
    width: ${(props) => (props.pathname === getSlug().url ? "73%" : "77%")};
    flex-direction: column;
  }
  @media (max-width: ${(props) => props.theme.queries.medium}) {
    flex-direction: column;
    width: 95%;
  }
  @media (max-width: ${(props) => props.theme.queries.small}) {
    flex-direction: column;
  }
`;

const WrapTexts = styled(Box)`
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  width: 680px;
  margin-left: auto;
  margin-right: 2em;

  @media (max-width: 1366px) {
    width: 640px;
    margin: 0 auto;
  }
  @media (max-width: ${(props) => props.theme.queries.lg}) {
    /* width: 465px; */
    width: 520px;
    margin: 0 auto;
  }

  @media (max-width: ${(props) => props.theme.queries.medium}) {
    justify-content: center;
    margin: 0 auto;
  }

  @media (-webkit-max-device-pixel-ratio: 2) and (max-width: ${(props) =>
      props.theme.queries.small}) {
    width: auto;
  }

  @media (max-width: ${(props) => props.theme.queries.small}) {
    width: 280px;
    flex-direction: column;
  }
`;

const ButtonPromoExtended = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 343px;
  height: 68px;
  padding: 5px 10px;
  font-size: 18px !important;
  font-weight: bold;
  text-transform: uppercase;
  text-align: center;
  margin-left: 47px;
  background-color: ${(props) => props.theme.color.yellow};
  color: ${(props) => props.theme.color.gray};

  span {
    width: 280px;
  }

  @media (max-width: ${(props) => props.theme.queries.lg}) {
    margin-left: 40px;
    font-size: 14px !important;
    width: 225px;
    height: 47px !important;
  }
  @media (max-width: ${(props) => props.theme.queries.medium}) {
    margin-top: 20px;
  }
  @media (-webkit-max-device-pixel-ratio: 2) and (max-width: ${(props) =>
      props.theme.queries.small}) {
    width: auto;
  }
  @media (max-width: ${(props) => props.theme.queries.small}) {
    margin: 13px 0 0px 0;
    max-width: 267px !important;
    width: 270px !important;
    height: 64px !important;
  }
`;

const BtnIWant = styled(Button)`
  width: 343px;
  height: 68px !important;
  font-size: 24px !important;
  margin-left: 47px;

  :hover,
  :focus {
    background-color: ${(props) => props.theme.color.white};
    color: ${(props) => props.theme.color.darkGray};
    border: 3px solid ${(props) => props.theme.color.white};
  }

  @media (max-width: ${(props) => props.theme.queries.lg}) {
    margin-left: 40px;
    font-size: 18px !important;
    width: 215px;
    height: 47px !important;
  }
  @media (max-width: ${(props) => props.theme.queries.medium}) {
    height: 47px !important;
    margin-left: 20px;
    width: 170px;
  }

  @media (-webkit-max-device-pixel-ratio: 2) and (max-width: ${(props) =>
      props.theme.queries.small}) {
    width: auto;
  }

  @media (max-width: ${(props) => props.theme.queries.small}) {
    margin: 13px 0 0px 0;
    max-width: 267px !important;
    width: 270px !important;
    height: 64px !important;
  }
`;

//box texts

const ColText = styled(Box)`
  display: flex;
  flex-direction: column;
`;

const ColTextMobile = styled(ColText)`
  @media (max-width: ${(props) => props.theme.queries.small}) {
    width: 312px;
  }
  @media (-webkit-max-device-pixel-ratio: 2) and (max-width: ${(props) =>
      props.theme.queries.small}) {
    width: auto;
  }
  @media (-webkit-max-device-: 2) and (max-width: ${(props) =>
      props.theme.queries.i5}) {
    width: auto;
  }
  @media (max-width: 320px) {
    width: 273px !important;
  }
`;

const YellowSquare = styled(Box)`
  letter-spacing: -0.03em;
  width: 250px;
  padding: 0 0rem 0 0.5rem;
  margin-bottom: 0.3rem;
  background-color: ${(props) => props.theme.color.yellow};
  text-transform: uppercase;
  font-size: 1.06rem;
  color: ${(props) => props.theme.color.gray};
  span {
    color: ${(props) => props.theme.color.red};
    font-weight: bolder;
    font-size: 1.5rem;
    line-height: 1.1;
  }

  @media (-webkit-max-device-pixel-ratio: 2) {
    padding: 0 0.7rem 0 0.7rem;
    font-size: 1.03rem;
  }

  @media (max-width: ${(props) => props.theme.queries.lg}) {
    margin-bottom: 0.1rem;
    font-size: 0.75rem;
    width: 182px;
    span {
      font-size: 1rem;
    }
  }

  /* @media (-webkit-min-device-pixel-ratio: 2) and (max-width: ${(props) =>
    props.theme.queries.lg}) {
    font-size: .8rem;
    padding: 0 0rem 0 0.3rem;
    width: 210px;
  } */

  @media (max-width: ${(props) => props.theme.queries.medium}) {
    width: 210px;
    font-size: 0.9rem;
    padding: 0 0.2rem 0 0.2rem;
  }

  @media (-webkit-min-device-pixel-ratio: 2) and (max-width: ${(props) =>
      props.theme.queries.medium}) {
    font-size: 0.9rem;
    padding: 0 0.2rem 0 0.2rem;
    width: 215px;
  }

  @media (max-width: ${(props) => props.theme.queries.small}) {
    width: auto;
    font-size: 17.5px;
  }
`;

const CompleteCard = styled(Box)`
  color: ${(props) => props.theme.color.yellow};
  font-size: 1.3125rem;
  letter-spacing: -0.7px;
  text-transform: uppercase;

  @media (max-width: ${(props) => props.theme.queries.lg}) {
    letter-spacing: 0em;
  }
  @media (-webkit-max-device-pixel-ratio: 2) and (max-width: ${(props) =>
      props.theme.queries.lg}) {
    font-size: 0.9rem;
  }
  @media (max-width: ${(props) => props.theme.queries.medium}) {
    font-size: 1rem;
  }
  @media (max-width: ${(props) => props.theme.queries.small}) {
    font-size: 19.5px;
  }
`;

const ValueShell = styled(Box)`
  display: flex;
  align-items: baseline;
  letter-spacing: 0.02em;
  color: ${(props) => props.theme.color.white};
  b {
    text-transform: uppercase;
    font-size: 1.3rem;
  }
  /* span {
    color: ${(props) => props.theme.color.yellow};
    font-size: 1.4rem;
    font-weight: bold;
  } */

  @media (-webkit-max-device-pixel-ratio: 2) {
    b {
      font-size: 1.2rem;
      @media (max-width: ${(props) => props.theme.queries.lg}) {
        font-size: 0.9rem;
      }
      @media (max-width: ${(props) => props.theme.queries.small}) {
        font-size: 1.1rem;
      }
    }
  }

  @media (max-width: ${(props) => props.theme.queries.lg}) {
    font-size: 0.75rem;
    b,
    span {
      font-family: "Futura LT Bold";
      /* font-size: 0.93rem !important; */
    }
  }

  @media (max-width: ${(props) => props.theme.queries.small}) {
    align-items: center;
    width: auto;
    height: 50px;
    b {
      font-size: 1.2rem;
    }
  }

  @media (-webkit-max-device-pixel-ratio: 2) and (max-width: ${(props) =>
      props.theme.queries.small}) {
    width: auto;
  }
`;

const TextPlus = styled.span`
  color: ${(props) => props.theme.color.yellow};
  font-size: 1.4rem;
  font-weight: bold;

  @media (max-width: ${(props) => props.theme.queries.lg}) {
    font-family: "Futura LT Bold";
    font-size: 0.9rem;
  }
  @media (max-width: ${(props) => props.theme.queries.small}) {
    font-size: 25px !important;
  }
`;

const TextYellowAndWhite = styled(Box)`
  text-transform: uppercase;
  color: ${(props) => props.theme.color.yellow};
  font-size: 1.3rem;
  b {
    font-family: "Futura LT Bold";
    color: ${(props) => props.theme.color.white};
  }
  @media (-webkit-max-device-pixel-ratio: 2) and (max-width: ${(props) =>
      props.theme.queries.lg}) {
    font-size: 0.9rem;
  }
  @media (max-width: ${(props) => props.theme.queries.small}) {
    font-size: 18px;
  }
  @media (max-width: 320px) {
    font-size: 17px;
  }
`;

const Exclusive = styled(Box)`
  text-transform: uppercase;
  color: ${(props) => props.theme.color.yellow};
  font-size: 1.3rem;
  margin-left: 2.5rem;
  font-weight: 600;

  @media (max-width: ${(props) => props.theme.queries.lg}) {
    margin-left: 1.5rem;
  }

  @media (-webkit-max-device-pixel-ratio: 2) and (max-width: ${(props) =>
      props.theme.queries.lg}) {
    font-size: 1rem;
  }

  @media (max-width: ${(props) => props.theme.queries.lg}) {
    margin-left: 1rem;
  }

  @media (max-width: ${(props) => props.theme.queries.small}) {
    font-size: 18px;
  }
`;

const EqualIcon = styled(Box)`
  width: 30px;
  height: 0.5rem;
  @media (max-width: ${(props) => props.theme.queries.lg}) {
    width: 20px;
    height: 0.3rem;
  }
  @media (max-width: ${(props) => props.theme.queries.small}) {
    width: 36px;
    height: 0.5rem;
    :first-child {
      margin-bottom: 0.4rem;
    }
  }
`;

const BoxValues = styled.div`
  display: flex;
  flex-direction: column;
`;

const BoxValuesFirstRow = styled.span`
  display: flex;
  flex-direction: row;
  color: #fff;
  @media (max-width: ${(props) => props.theme.queries.small}) {
    align-items: center;
  }
`;

const BoxValuesSecondRow = styled.span`
  display: flex;
  flex-direction: row;
  color: #fff;
  margin-top: 10px;
  @media (max-width: ${(props) => props.theme.queries.lg}) {
    margin-top: 5px;
  }
`;

const TextRsUp = styled.span`
  font-family: "Futura LT Regular" !important;
  font-size: 17.1409px;
  line-height: 18px;
  text-transform: uppercase;
  color: #ffffff;
  display: flex;
  align-items: flex-end;
  margin-top: 4px;
  @media (max-width: ${(props) => props.theme.queries.medium}) {
    font-size: 0.9rem;
    padding-right: 0.2rem;
  }
`;

const TextPriceUp = styled.span`
  font-style: normal;
  font-weight: bold;
  font-size: 30.3261px;
  line-height: 18px;
  text-transform: uppercase;
  color: #ffffff;
  @media (max-width: ${(props) => props.theme.queries.lg}) {
    font-size: 1.2rem;
  }
`;

const TextDecimalUp = styled.span`
  font-style: normal;
  font-weight: bold;
  font-size: 15.1631px;
  line-height: 21px;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  color: #ffffff;
  margin-top: -8px;
`;

const TextOr = styled.span`
  font-family: "Futura LT Regular" !important;
  font-style: normal;
  font-weight: normal;
  font-size: 15.2659px;
  line-height: 10px;
  text-transform: uppercase;
  color: #ffffff;
  margin-right: 6px;
  margin-top: 1px;

  @media (max-width: ${(props) => props.theme.queries.lg}) {
    font-size: 0.7rem;
    margin-right: 4px;
  }
`;

const TextPriceBot = styled.span`
  font-style: normal;
  font-weight: bold;
  font-size: 16.7198px;
  line-height: 10px;
  text-transform: uppercase;
  color: #ffffff;
`;

const TextRsBot = styled.span`
  font-family: "Futura LT Regular";
  font-style: normal;
  font-weight: normal;
  font-size: 9.45033px;
  line-height: 10px;
  text-transform: uppercase;
  color: #ffffff;
  display: flex;
  align-items: flex-end;
  margin-top: 3px;
`;

const TextDecimalBot = styled.span`
  font-style: normal;
  font-weight: bold;
  font-size: 8.35991px;
  line-height: 12px;
  text-transform: uppercase;
  color: #ffffff;
  margin-top: -3px;
`;
