import { useActions } from "configureStore"
import React, { ReactNode, useState } from "react"

interface Props {
  children: ReactNode
  href: string
  isNewTab: boolean
}

export function CarouselWebdoorSlide({ children, href, isNewTab }: Props) {
  const [cursorPos, setCursorPos] = useState<{ x: number, y?: number }>({ x: 0, y: 0 })
  const setActive = useActions((action) => action.loading.setActive)
  const isShellbox = window.location.search.includes("shellbox")
  const isHrefInternal = href.startsWith("/")
  const internalLink = isShellbox
    ? `${href}?shellbox=${localStorage.getItem("query_shell")}&token=${localStorage.getItem("external_token")}`
    : href;

  function handleWebviewExternalLink() {
    try {
      // @ts-ignore
      WebViewJavascriptInterface.openExternalLink(href);
    } catch (_) {
      window.open(
        href,
        "_blank",
        "noopener noreferrer"
      );
      window.focus();
    }
    setActive(true);
  }

  function handleSlideClick(cursorXPos: number) {
    const cursorMovement = cursorPos.x - cursorXPos

    if (cursorMovement >= -5 && cursorMovement <= 5) {
      if (isShellbox && isNewTab) {
        handleWebviewExternalLink()
      }
      const link = document.createElement("a")
      link.href = isHrefInternal ? internalLink : href

      if (!isShellbox && isNewTab) {
        link.target = "_blank"
        link.rel = "noreferrer noopener"
        setActive(true)
      }
      link.click()
    }
  }

  return (
    <div
      tabIndex={0}
      className="slide-item"
      onMouseDown={(e) => setCursorPos({ x: e.pageX })}
      onMouseUp={(e) => handleSlideClick(e.pageX)}
      onKeyDown={(e) => {
        if (e.key === "Enter") {
          handleSlideClick(0)
        }
      }}
    >
      {children}
    </div>
  )
}