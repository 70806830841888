import React, { useState } from 'react';
import styled from 'styled-components';
import useWindowSize from 'hooks/useWIndowSize';

import BannerDesktop from 'assets/images/hub/OffersDetail/banner-desktop.png';
import BannerMobile from 'assets/images/hub/OffersDetail/banner-mobile.png';

const BannerOffer = (props) => {
  const { width } = useWindowSize()

  return(
    <Section>
      <img 
        src={width < 768 ? BannerMobile : BannerDesktop}
        alt="Banner Ofertas" 
      />
    </Section>
  )
}

export default BannerOffer;

const Section = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 5.5rem;

  @media (max-width: 768px) {
    margin-top: 2.5rem;
  }

  img {
    width: 100%;
    max-height: 30rem;
  }
`;