import React from "react";
import styled from "styled-components";

interface IOutlineButton {
  label: string;
  handleButton: any;
}

const OutlineButton = ({
  label,
  handleButton
}:IOutlineButton) => {
  return (
    <Button onClick={handleButton}>
      {label}
    </Button>
  )
}

export default OutlineButton;

const Button = styled.button`
  cursor: pointer;
  padding: 14px 16px;
  font-family: "Futura LT Regular";
  font-size: 17px;
  line-height: 14px;
  min-width: 293px;
  background-color: transparent;
  border: 1px solid ${props => props.theme.color.gray};
  text-transform: uppercase;

  display: flex;
  align-items: center;
  justify-content: center;
`;