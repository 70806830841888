import React, { useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { Box } from "@chakra-ui/core";
import chevronOrange from "assets/icons/chevron-orange.png"

import ModalAjuda from "./ModalAjuda/ModalAjuda";
import ModalAjudaMobile from "./ModalAjuda/ModalAjudaMobile";

const BannerPromotionHelp = (props) => {
  const [ showDamaged, setShowDamaged ] = useState(false);
  const [ showSeeMore, setShowSeeMore ] = useState(false);

  return (
    <Content
      margin={"0 auto"}
      maxWidth={"1130px"}
      pathname={window.location.pathname}
    >
      <PromotionEnded>
        <h1>Promoção finalizada!</h1>
      </PromotionEnded>
      <TextWrapper>
        <p>Fez o resgate de produtos e está com alguma dúvida?</p>
        <List>
          <ListItem>
            <img src={chevronOrange} alt="seta"/>
            <div> O produto está danificado ou com algum defeito de uso? <u onClick={() => setShowDamaged(true)}>Veja como proceder</u></div>
          </ListItem>
          <ListItem>
            <img src={chevronOrange} alt="seta"/>
            <div> Gostaria de solicitar devolução ou cancelamento da compra? <u onClick={() => setShowSeeMore(true)}>Saiba mais a respeito</u></div>
          </ListItem>
        </List>
        <Link to="/promocao-junte-e-troque/duvidas">Ver mais dúvidas frequentes</Link>
      </TextWrapper>

      {/* modals damaged*/}
      <ModalAjuda
        isOpen={showDamaged}
        onClose={() => setShowDamaged(false)}
      >
        <h3>Como proceder caso o produto esteja danificado ou com algum defeito de uso?</h3>
        <p>Poxa, não era para isso ter acontecido :(</p>
        <p>Mas fique tranquilo, que vamos te trazer a melhor solução! Para realizarmos a sua tratativa, pedimos que entre em contato diretamente pelo e-mail: promoshell@scoregroup.com.br . O prazo para resposta é de 5 dias úteis.</p>
        <p>Se após esse prazo, você não receber uma resposta, entra em contato com a gente direto no app na opção “Ajuda” no menu, combinado?</p>
        <p>Importante: a troca do produto com defeito será realizada em até 90 (noventa) dias corridos, após o recebimento de sua solicitação de troca.</p>
      </ModalAjuda>
      <ModalAjudaMobile
        isOpen={showDamaged}
        onClose={() => setShowDamaged(false)}
      >
        <h3>Como proceder caso o produto esteja danificado ou com algum defeito de uso?</h3>
        <p>Poxa, não era para isso ter acontecido :(</p>
        <p>Mas fique tranquilo, que vamos te trazer a melhor solução! Para realizarmos a sua tratativa, pedimos que entre em contato diretamente pelo e-mail: promoshell@scoregroup.com.br . O prazo para resposta é de 5 dias úteis.</p>
        <p>Se após esse prazo, você não receber uma resposta, entra em contato com a gente direto no app na opção “Ajuda” no menu, combinado?</p>
        <p>Importante: a troca do produto com defeito será realizada em até 90 (noventa) dias corridos, após o recebimento de sua solicitação de troca.</p>
      </ModalAjudaMobile>

      {/* modals see more*/}
      <ModalAjuda
        isOpen={showSeeMore}
        onClose={() => setShowSeeMore(false)}
      >
        <h3>Como faço para solicitar uma devolução ou cancelamento da compra?</h3>
        <p>A troca do seu produto pode ser solicitada em até 7 (sete) dias corridos, após o recebimento do pedido, através do e-mail promoshell@scoregroup.com.br</p>
        <p>Caso deseje fazer o cancelamento da compra, o reembolso do valor pago será realizado em até 30 (trinta) dias úteis, após a confirmação do cancelamento e informação dos dados.</p>
        <p>Obs.: o produto deve ser devolvido em sua embalagem original, com a Nota Fiscal, manual e acessórios, conforme orientação da equipe de atendimento.</p>
        <p>Se tiver mais alguma dúvida, você também pode consultar direto no app na opção “Ajuda” no menu.</p>
      </ModalAjuda>
      <ModalAjudaMobile
        isOpen={showSeeMore}
        onClose={() => setShowSeeMore(false)}
      >
        <h3>Como faço para solicitar uma devolução ou cancelamento da compra?</h3>
        <p>A troca do seu produto pode ser solicitada em até 7 (sete) dias corridos, após o recebimento do pedido, através do e-mail promoshell@scoregroup.com.br</p>
        <p>Caso deseje fazer o cancelamento da compra, o reembolso do valor pago será realizado em até 30 (trinta) dias úteis, após a confirmação do cancelamento e informação dos dados.</p>
        <p>Obs.: o produto deve ser devolvido em sua embalagem original, com a Nota Fiscal, manual e acessórios, conforme orientação da equipe de atendimento.</p>
        <p>Se tiver mais alguma dúvida, você também pode consultar direto no app na opção “Ajuda” no menu.</p>
      </ModalAjudaMobile>
    </Content>
  );
};

export default BannerPromotionHelp;

const Content = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: auto;

  @media (max-width: ${(props) => props.theme.queries.small}) {
    flex-direction: column;
    width: ${(props) =>
      props.pathname === "/promocao-junte-e-troque" ? "100%" : "100%"};
  }
`;

const PromotionEnded = styled.div`
  width: 35%;
  display: flex;
  justify-content: center;
  align-items: center;

  padding: 1rem;
  margin-right: 30px;
  border-right: 1px solid rgb(255, 255, 255, 0.4);

  h1 {
    font-size: 28px;
    font-weight: bold;
    text-transform: uppercase;
    text-align: center;
    color: ${(props) => props.theme.color.yellow};
  }

  @media (max-width: ${(props) => props.theme.queries.small}) {
    border-right: none;
    border-bottom: 1px solid rgb(255, 255, 255, 0.4);
    height: 30%;
    width: 90%;
    margin: 0;
    padding: 1rem 0;

    h1 {
      margin: 0;
    }
  }
`;

const TextWrapper = styled.div`
  width: 60%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  p {
    font-size: 1rem;
    color: #fff;
    text-transform: uppercase;
    font-weight: 800;
    margin: 10px 0 5px 0;
  }

  a {
    font-size: .9rem;
    color: #fff;
    font-weight: 500;
    margin: 8px 0 0 0;
    cursor: pointer;
    text-decoration: underline;
  }

  @media (max-width: ${(props) => props.theme.queries.small}) {
    width: 90%;
  }
`;

const List = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
`;

const ListItem = styled.li`
  color: #fff;
  margin-top: 5px;
  display: -webkit-inline-box;

  img{
    width: 8px;
    height: 13px;
    margin-right: .3rem;
  }

  div {
    padding-bottom:.3rem;
    font-weight: 400;
    font-size: .9rem;

    u {
      cursor: pointer;
    }
  }

  @media(max-width: ${props => props.theme.queries.medium}){
    align-items: flex-start;
  }
`;