import { thunk, action, Action, Thunk } from "easy-peasy";
import { GetShell, GetUrlShop } from "../services/shell";
import { get } from "lodash";
import { StoreModel } from "./index";

export interface ShellModel {
  isLoading: boolean;
  messageError: string;

  shells: any;
  shop: any;

  getShells: Thunk<ShellModel, void>;
  setShells: Action<ShellModel, any>;
  getUrlShop: Thunk<ShellModel, Object, any, StoreModel>;
  setShop: Action<ShellModel, any>;

  setLoading: Action<ShellModel, string>;
  setClearUrl: Action<ShellModel, any>;
  setError: Action<ShellModel, string>;
}

const shell: ShellModel = {
  shop: {
    expiresIn: "",
    expiresOn: "",
    redirectURL: window.location.host.includes("promo.shell.com.br")
      ? "www.lojapromoshell.com.br/sso"
      : "shellnacaixahomologacao20201.appspot.com/sso",
    token: "",
    tokenType: "",
  },
  isLoading: false,
  messageError: "",
  shells: {},
  getShells: thunk(async (action: any, payload: any) => {
    action.setLoading({ loading: true });
    try {
      const res = await GetShell();
      action.setShells(res.data);
      action.setLoading({ loading: false });
    } catch (error) {
      action.setError({
        message: "Não foi possivel obter os dados, tente novamente mais tarde!",
      });
      action.setLoading({ loading: false });
    }
  }),
  setShells: action((state: any, payload: any) => {
    state.shells = payload.data;
  }),
  getUrlShop: thunk(async (action: any, payload: any, { getStoreActions }) => {
    getStoreActions().loading.setActive(true);

    try {
      const res = await GetUrlShop();
      action.setShop(res.data);
      if (payload?.redirect) {
        setTimeout(() => {
          const form = document.getElementById(payload.form) as any;
          form.submit();

          getStoreActions().loading.setActive(false);
        }, 1000);
      }
    } catch (error) {
      getStoreActions().loading.setActive(false);
    }
  }),
  setShop: action((state: any, payload: any) => {
    state.shop = payload?.data;
  }),
  setLoading: action((state: { isLoading: boolean }, payload: any) => {
    const loading = get(payload, "loading", false);
    state.isLoading = loading;
  }),
  setError: action((state: { messageError: string }, payload: any) => {
    const message = get(payload, "message", false);
    state.messageError = message;
  }),
  setClearUrl: action((state: any, payload: any) => {
    state.shop = payload;
  }),
};

export default shell;
