import { GetBenefitsContent } from "services/shellboxBenefits";
import { action, Action, thunk, Thunk } from "easy-peasy";
import { get } from "lodash";

export interface BenefitsContentItems {
  imagem_desktop: string;
  imagem_mobile: string;
  texto_botao: string;
  texto_descritivo: string;
  texto_rodape: string;
  titulo_linha1: string;
  titulo_linha2: string;
  url_botao: string;
}

export interface ShellboxBenefitsModel {
  isLoading: boolean;
  content: BenefitsContentItems;

  getContent: Thunk<ShellboxBenefitsModel, void>;
  setContent: Action<ShellboxBenefitsModel, any>;

  setLoading: Action<ShellboxBenefitsModel, any>;
}

export const shellboxBenefits: ShellboxBenefitsModel = {
  isLoading: false,
  content: null,
  getContent: thunk(async (action: any, payload: any) => {
    action.setLoading({ loading: true });
    try {
      const res = await GetBenefitsContent();
      action.setContent(res.data[0])
    } catch (error) {
      console.error(error);
    }
    action.setLoading({ loading: false })
  }),
  setContent: action((state: any, payload: any) => {
    state.content = payload;
  }),
  setLoading: action((state: { isLoading: boolean }, payload: any) => {
    const loading = get(payload, "loading", false);
    state.isLoading = loading;
  }),
}