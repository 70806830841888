import React from "react";
import styled from "styled-components";
import useWindowSize from "hooks/useWIndowSize";
import { Box, Spinner } from "@chakra-ui/core";
import { useStore } from "configureStore";

//assets
import ImgCoin from "assets/images/anoTodo/coin.svg";
import IconShell from "assets/icons/shell-icon.svg" ;

export function ShellValueLabel() {
  const { width } = useWindowSize();
  const isLoading = useStore((state) => state.anoTodo.isLoading);
  const shells = useStore((state) => state.anoTodo.shells?.balance);

  return (
    <ShellWrapper>
      <Box className="yellowBlock">
        <img src={IconShell} alt="" />
      </Box>
      <Box
        display={"flex"}
        flexDir={["column", "column", "column", "row"]}
      >
        <strong>{isLoading ? (<Spinner size={"xs"}/>) : shells}</strong>
        <span>{width >= 1024 ? "Minhas conchas:" : "conchas:"} </span>
      </Box>
    </ShellWrapper>
  )
}

const ShellWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 52px;
  height: 22px;

  padding-right: 6px;
  background: ${(props) => props.theme.color.lightYellow};
  font-family: ${(props) => props.theme.Shell.Bold};

  border-radius: 20px;
  box-sizing: border-box;

  position: relative;
  
  div.yellowBlock {
    display: flex;
    justify-content: center;
    align-items: center;
    /* width: 22px;
    height: 22px; */

    border-radius: 100px;
    background-color: ${props => props.theme.color.yellow};
    padding: 1px;
    margin-right: 4px;
    img {
      width: 12px;
      height: 12px;
      padding: 4px;
      box-sizing: unset;
    }
  }

  strong {
    font-size: ${props => props.theme.fontSize.normal};
    line-height: 0.688rem;
    color: ${props => props.theme.color.shellPurple};
  }

  span {
    font-size: 0.438rem;
    line-height: 100%;
    color: ${props => props.theme.color.grayPurple};
    font-family: ${(props) => props.theme.Shell.Medium};
    text-transform: uppercase;

    position: absolute;
    top: -11px;
    left: 50%;
    transform: translateX(-50%);
  }

  @media (min-width: ${(props) => props.theme.queries.lg}) {

    span {
      font-size: ${props => props.theme.fontSize.normal};
      line-height: 85%;
      text-transform: unset;
      width: 7rem;
      
      top: unset;
      left: unset;
      bottom: 50%;
      right: 100%;
      transform: translateY(50%);
    }
  }
`;