import React, { useState, useRef, useEffect, useCallback } from "react";
import styled, { css } from "styled-components";
import postosJson from "utils/postos.json";
import estados from "utils/estados.json";
import { uniqBy, sortBy } from "lodash";
import useDebounce from "hooks/useDebounce";
import useWindowSize from "hooks/useWIndowSize";
import { useRouter } from "hooks/useRouter";
import { removeAccents } from "utils/helper";
import ReactSelect from "react-select";

//assets
import shellStation from "assets/images/menos15/posto-shell.png";
import shellStationMobile from "assets/images/menos15/posto-shell-mobile.png";
import chevronDown from "assets/icons/chevron-down-blue.svg";

interface IParticipatingStations {
  data?: any[];
}

const ParticipatingStations = ({ data }: IParticipatingStations) => {
  const postos = data || postosJson;
  const states = sortBy(estados, "nome", "desc");
  const { width } = useWindowSize();

  const isDetalheOfertas = window.location.pathname.includes(
    "ofertas-shell-select"
  );

  const [statesFiltered, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [district, setDistrict] = useState([]);
  const [stations, setStations] = useState("");

  const [stateValue, setStateValue] = useState("");
  const debouncedState = useDebounce(statesFiltered, 200);

  const [cityValue, setCityValue] = useState("");
  const debouncedCity = useDebounce(cities, 200);

  const [districtValue, setDistrictValue] = useState("");
  const debouncedDistrict = useDebounce(district, 200);

  const router = useRouter();

  useEffect(() => {
    if (debouncedState) {
      // getSuggestionsState(stateValue);
      setStates(debouncedState);
      // scrollTopDiv();
    }
  }, [debouncedState]);

  useEffect(() => {
    if (debouncedCity) {
      // getSuggestionsCity(cityValue);
      setCities(debouncedCity);
      // scrollTopDiv();
    }
  }, [debouncedCity]);

  useEffect(() => {
    if (debouncedDistrict) {
      setDistrict(debouncedDistrict);
      // scrollTopDiv();
    }
  }, [debouncedDistrict]);

  const [suggestionsValues, setSuggestionsValues] = useState([]);
  const [suggestionType, setSuggestionType] = useState(1);

  const suggestionsRef = useRef(null);
  const [showSuggestions, setShowSuggestions] = useState(false);

  const selectInputRef = useRef(null);

  const getSuggestionsStations = useCallback(() => {
    const stationsFiltered = postos.filter(
      (item) =>
        removeAccents(item["Cidade"]?.toString()?.toLowerCase()) ===
        removeAccents(cityValue?.toLowerCase())
    );

    const _station = stationsFiltered?.filter(
      (item) =>
        removeAccents(
          item["Bairro"].toLowerCase().slice(0, districtValue.length)
        ) === removeAccents(districtValue?.toLowerCase())
    );

    if (stateValue && cityValue && districtValue) {
      setSuggestionsValues(_station);
      setShowSuggestions(true);
      // document.getElementById("suggestions").focus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // const scrollTopDiv = () => {
  //   document.getElementById("suggestions").scrollTop = 0;
  // };

  function filterCities() {
    setShowSuggestions(false);
    setSuggestionsValues([]);

    const stateSigla =
      Boolean(stateValue) &&
      states.find((item) => stateValue.split("-")[0].includes(item.sigla))
        ?.sigla; //encontra o estado pela sigla e retorna a sigla.

    const stationsFiltered =
      Boolean(stateSigla) &&
      postos.filter(
        (item) =>
          item["Estado"]?.toString()?.toLowerCase() ===
          stateSigla?.toLowerCase()
      ); //filtra os postos pelo estado
    const uniqStations = uniqBy(stationsFiltered, (i) =>
      removeAccents(i["Cidade"]?.toLowerCase())
    ); //remove os nomes duplicados das cidades.

    const citiesFiltered = sortBy(uniqStations, "Cidade", "asc").map(
      (station) => {
        return { value: station.Cidade, label: station.Cidade };
      }
    );

    setCities(citiesFiltered);
  }

  function filterDistricts() {
    setShowSuggestions(false);
    setSuggestionsValues([]);

    const stationsFiltered =
      Boolean(cityValue) &&
      postos.filter(
        (item) =>
          item["Cidade"]?.toString()?.toLowerCase() === cityValue?.toLowerCase()
      ); //filtra os postos pelo estado

    const uniqDistricts = uniqBy(stationsFiltered, (i) =>
      removeAccents(i["Bairro"]?.toLowerCase())
    );

    const districstFiltered = sortBy(uniqDistricts, "Bairro", "asc").map(
      (station) => {
        return { value: station.Bairro, label: station.Bairro };
      }
    );

    setDistrict(districstFiltered);
  }

  useEffect(() => {
    setShowSuggestions(false);
    if (width <= 768) {
      setTimeout(() => {
        getSuggestionsStations();
      }, 200);
    }
  }, [districtValue, getSuggestionsStations, width]);

  return (
    <Container
      pathname={router.pathname}
      tabIndex={0}
      id="postos-participantes"
    >
      <Title fontSize={"20px"}>
        {isDetalheOfertas ? (
          <>Lojas Participantes</>
        ) : (
          <>Postos e lojas Shell select Participantes</>
        )}
      </Title>
      <ContainerImage>
        <BoxSelections ref={suggestionsRef}>
          <WrapInput inputWidth="139px">
            <CustomSelect
              options={statesFiltered}
              placeholder="Estado"
              onChange={(e) => {
                setStateValue(e.value);
                // clearInputs()
              }}
              onFocus={() => {
                setStates(
                  sortBy(states, "Estado", "asc")?.map((item, idx) => {
                    return {
                      value: `${item?.sigla} - ${item?.nome}`,
                      label: `${item?.sigla} - ${item?.nome}`,
                    };
                  })
                );
              }}
            />
            <CustomSelect
              options={cities}
              placeholder="Cidade"
              onChange={(e) => setCityValue(e.value)}
              onFocus={() => filterCities()}
              noOptionsMessage={() => "Nenhum resultado encontrado."}
            />
            <CustomSelect
              options={district}
              placeholder="Bairro"
              onChange={(e) => {
                setDistrictValue(e.value);
              }}
              onFocus={() => filterDistricts()}
              noOptionsMessage={() => "Nenhum resultado encontrado."}
            />
          </WrapInput>

          <Button onClick={() => getSuggestionsStations()}>ok</Button>

          {/* suggestions */}
          <BoxSuggestions show={showSuggestions} id="suggestions">
            {sortBy(
              suggestionsValues,
              (i) => removeAccents(i["Razão Social"]?.toLowerCase()),
              "asc"
            )?.map((item, idx) => (
              <SuggestionItem
                tabIndex={0}
                key={idx}
                onClick={() => setShowSuggestions(false)}
                onKeyPress={(e) =>
                  e.key.toLowerCase() === "enter" && setShowSuggestions(false)
                }
              >
                <div>{item["Razão Social"]}</div>
                <span>{item["Endereço"]}</span>
                <span>
                  {item["Cidade"]} - {item["Estado"]}
                </span>
              </SuggestionItem>
            ))}
          </BoxSuggestions>
        </BoxSelections>
      </ContainerImage>
    </Container>
  );
};

export default ParticipatingStations;

const Container = styled.div`
  margin-top: 76px;
  ${(props) =>
    props.pathname.includes("promocao-junte-e-troque-2021") &&
    css`
      background-color: ${(props) => props.theme.color.lightGray};
      margin: 0;
      @media (min-width: ${(props) => props.theme.queries.medium}) {
        padding-bottom: 3.75rem;
      }
    `}
`;

const Title = styled.h2`
  font-family: ${(props) => props.theme.Shell.Bold};
  font-style: normal;
  font-weight: bold;
  font-size: ${(props) => (props.fontSize === null ? "20px" : props.fontSize)};
  line-height: 20px;
  text-align: center;
  text-transform: uppercase;
  color: ${(props) => props.theme.color.gray};

  margin: 0;

  @media (max-width: 768px) {
    width: 80%;
    line-height: 2rem;

    margin: 0 auto;
  }
`;

const ContainerImage = styled.div`
  margin-top: 30px;
  height: 486px;
  width: 100%;
  background-image: url(${shellStation});
  background-size: cover;
  background-position: left;
  background-repeat: no-repeat;

  //center content ⬇️
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: ${(props) => props.theme.queries.small}) {
    height: 626px;
    background-image: url(${shellStationMobile});
  }
`;

const BoxSelections = styled.div`
  margin-top: 20px;
  display: flex;
  position: relative;

  @media (max-width: ${(props) => props.theme.queries.medium}) {
    margin-top: 47px;
    flex-direction: column;
    width: 90%;
  }
`;

const WrapInput = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;

  position: relative;
  margin-right: 6px;
  cursor: pointer;

  img {
    position: absolute;
    right: 15px;
    width: 24px;
    height: 12px;
    top: 40%;
  }

  @media (min-width: ${(props) => props.theme.queries.lg}) {
    flex-direction: row;
  }
`;

const Button = styled.button`
  background-color: ${(props) => props.theme.color.red};
  width: 53px;
  height: 53px;

  font-family: Futura;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 11px;
  text-align: center;
  text-transform: uppercase;
  color: #ffffff;
  cursor: pointer;
  border: none;
  transition: 0.2s;

  :hover,
  :focus {
    /* background-color: ${(props) => props.theme.color.orange}; */
    background: #fff;
    color: ${(props) => props.theme.color.red};
    border: 1px solid ${(props) => props.theme.color.red};
  }

  @media (max-width: ${(props) => props.theme.queries.medium}) {
    margin-top: 0.5rem;
  }

  @media (max-width: ${(props) => props.theme.queries.small}) {
    display: none;
  }
`;

const BoxSuggestions = styled.div`
  position: absolute;
  top: ${(props) => (props.top ? props.top : `184px`)};
  width: 100%;
  max-height: 387px;
  overflow-y: auto;
  background-color: ${(props) => props.theme.color.white};
  padding: 0.5rem 1rem 1rem 1rem;

  display: ${(props) => (props.show ? "block" : "none")};
  border: 1px solid #eaeaea;
  box-sizing: border-box;
  box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.15);

  @media (min-width: ${(props) => props.theme.queries.medium}) {
    top: 60px;
  }
`;

const SuggestionItem = styled.div`
  padding: 1rem 0;
  border-bottom: 0.5px solid #58595b;
  cursor: pointer;
  display: flex;
  flex-direction: column;

  div {
    font-family: Futura;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 11px;
    text-transform: uppercase;
    color: #404040;
    margin-bottom: 0.3rem;
  }

  span {
    margin-top: 0.3rem;
    font-family: Arial;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    color: #58595b;
  }

  @media (max-width: ${(props) => props.theme.queries.small}) {
    div {
      line-height: 18px;
    }
  }
`;

const EmptyState = styled.div`
  text-align: center;
  margin: 3rem 0 2rem 0;

  color: ${(props) => props.theme.color.gray};
  font-size: 1rem;
`;

const CustomSelect = styled(ReactSelect)`
  display: flex;

  height: 53px !important;
  margin-right: 6px;

  box-sizing: border-box;
  box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.15);

  font-family: ${(props) => props.theme.Shell.Medium};
  font-weight: bold;
  font-size: 1.125rem !important;
  line-height: 100%;
  color: #3297c9;
  text-indent: 12px;
  text-transform: uppercase;
  cursor: pointer;

  ::placeholder {
    font-family: Arial;
    font-weight: bold;
    font-size: 20px;
    color: #3297c9;
  }

  div[class*="ValueContainer"],
  div[class^="ValueContainer"] {
    width: 16rem;
    div[class*="-singleValue"],
    div[class^="-singleValue"] {
      font-size: 1.125rem;
      overflow: unset !important;
      color: ${(props) => props.theme.color.blue};
    }
  }

  div[class*="IndicatorsContainer"],
  div[class^="IndicatorsContainer"] {
    width: 2.75rem;
  }

  div[class*="-menu"],
  div[class^="-menu"] {
    color: ${(props) => props.theme.color.text};
  }

  @media (max-width: ${(props) => props.theme.queries.medium}) {
    margin-right: 0px;
    margin-top: 6px;
    width: 100%;

    div[class*="control"],
    div[class^="control"] {
      width: 100%;
    }
  }
`;
