import React from "react";
import styled from "styled-components";
// import { useStore } from "configureStore";
// import useScrollToModalLogin from "hooks/useScrollToModalLogin";
// import { useRouter } from "hooks/useRouter";
import { getSlug } from "utils/helper";
import { NavLink } from "react-router-dom";
import useWindowSize from "hooks/useWIndowSize";
// Assets
import ImageTickets from "assets/images/grandeGp/tickets.svg";

export function RedeemTickets2024() {
  const slug = getSlug().url;
  const { width } = useWindowSize();
  const isMobile = width < 1280;
  const isRedeemTickets = window.location.pathname.includes(
    "resgate-de-ingressos"
  );
  // const isAuth = useStore((state) => state.auth.isAuthenticated);
  // const router = useRouter();
  // const scrollToModaLogin = useScrollToModalLogin();

  // function handleRedeemTicket() {
  //   if (!isAuth) {
  //     scrollToModaLogin();
  //   } else {
  //     router.push(slug);
  //   }
  // }

  return (
    <RedeemTicketsContainer>
      <RedeemTicketsRedBlock>
        <strong>Deseja resgatar ingressos?</strong>
        {/* Hack de espaçamento */}
        <p />
        {!isMobile && !isRedeemTickets && (
          <p>
            É possível resgatar o par de ingresso através do Shell Box Clube.
          </p>
        )}
        {isRedeemTickets ? (
          <a
            href="https://shellbox.onelink.me/RT9I/301b26?af_qr=true"
            target="_blank"
            rel="noopener noreferrer"
          >
            <button>Baixar o App</button>
          </a>
        ) : (
          <NavLink to={`${slug}/resgate-de-ingressos`}>
            <button tabIndex={-1}>Saiba Mais</button>
          </NavLink>
        )}
      </RedeemTicketsRedBlock>
      <RedeemTicketsImageBlock>
        <img
          src={ImageTickets}
          alt="Ilustração de uma mão segurando um par de ingressos"
        />
      </RedeemTicketsImageBlock>
    </RedeemTicketsContainer>
  );
}

const RedeemTicketsContainer = styled.section`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 425px;

  @media (min-width: ${(props) => props.theme.queries.desk}) {
    max-width: 1068px;
    flex-direction: row;
  }
`;

const RedeemTicketsRedBlock = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1.5rem;
  width: 100%;
  background: ${(props) => props.theme.color.red};
  padding: 2.25rem 1.5rem;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;

  strong {
    font-size: 1.5rem;
    font-weight: 700;
    line-height: normal;
    font-family: ${(props) => props.theme.Shell.Bold};
    color: ${(props) => props.theme.color.white};
    text-align: center;
    margin: 0;
  }

  p {
    font-size: 1.125rem;
    line-height: 1.2;
    font-family: ${(props) => props.theme.Shell.Book};
    font-weight: 400;
    color: ${(props) => props.theme.color.white};
    padding: 0;
    margin: 0;
  }

  button {
    font-family: ${(props) => props.theme.Shell.Bold};
    font-size: 0.875rem;
    font-weight: 700;
    line-height: 68.75%;
    padding: 0.875rem 1.25rem;
    border-radius: 8px;
    background: ${(props) => props.theme.color.yellow};
    text-decoration: none;
    text-transform: uppercase;
    color: ${(props) => props.theme.color.text};
    border: 2px solid transparent;
    transition: all 0.2s ease-in-out;
    box-sizing: border-box;
    cursor: pointer;

    &:hover,
    &:focus {
      background: ${(props) => props.theme.color.white};
    }

    /* 340px */
    @media (min-width: 21.25rem) {
      font-size: 1rem;
    }

    /* 375px */
    @media (min-width: ${(props) => props.theme.queries.ix}) {
      padding: 0.875rem 2.25rem;
    }
  }

  @media (min-width: ${(props) => props.theme.queries.desk}) {
    align-items: flex-start;
    width: 100%;
    max-width: 534px;
    height: 264px;
    padding: 1rem 3.75rem;
    gap: 4px;

    border-top-left-radius: 12px;
    border-bottom-left-radius: 12px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;

    p {
      margin-bottom: 24px;
    }
  }
`;

const RedeemTicketsImageBlock = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  background: ${(props) => props.theme.color.lightGray200};
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;

  img {
    display: block;
    width: 100%;
    margin-top: 0.875rem;
  }

  @media (min-width: ${(props) => props.theme.queries.desk}) {
    border-top-right-radius: 12px;
    border-bottom-right-radius: 12px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;

    img {
      width: unset;
      margin-top: 0;
    }
  }
`;
