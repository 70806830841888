import React, { useEffect } from "react";
import styled from "styled-components";
import { DoubleBannerHub } from "../CityPromotions/DoubleBannerHub";
import FinishedPromotions from "../PromosEncerradas/FinishedPromotions";
import { useActions, useStore } from "configureStore";
import { CarouselWebdoorBanner } from "../CityPromotions/CarouselBanner";
import { CarouselModal } from "modules/promoAnoTodo/components/Home/Webdoor/CarouselModal";
import { useDisclosure } from "@chakra-ui/core";

// Imagens
import PromoImgJunteTroque from "assets/images/hub/PromocoesCidade/encerrada-junte-troque.png";
import PromoImgShellNaCaixa from "assets/images/hub/PromocoesCidade/encerrada-shell-na-caixa.jpg";
import PromoImgEmbarque from "assets/images/hub/PromocoesCidade/encerrada-embarque-sonho.png";
import PromoJunteGanhe from "assets/images/hub/PromocoesCidade/encerrada-junte-ganhe.png";
import PromoMenos15 from "assets/banners/PromosEncerradas/promo-menos15.png";
import PromoJT2021 from "assets/images/hub/PromocoesCidade/jt-2021.png";
import PromoDesktop from "assets/banners/promoGpSP2024/banner-hub.png";
import PromoMobile from "assets/banners/promoGpSP2024/banner-hub-mobile.png";
import useWindowSize from "hooks/useWIndowSize";
import { NavLink } from "react-router-dom";

interface ImageListProps {
  url: string;
  alt: string;
  href?: string;
}

export default function HubHome() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const getWebdoorDoubleBanners = useActions(
    (action) => action.hub.getWebdoorCarousel
  );
  const { width } = useWindowSize();
  const isMobile = width < 1024;
  const gethubBanners = useActions((action) => action.anoTodo.getHubBanner);
  const hubBanners = useStore((state) => state.anoTodo.hubBanner);
  const imageList: ImageListProps[] = [
    {
      url: hubBanners?.imagem_encerrada,
      alt: "Promoção Junte e Troque Curta a vida onde estiver",
    },
    {
      url: PromoJT2021,
      alt: "promoção Junte e Troque 2021",
    },
    {
      url: PromoMenos15,
      alt: "promoção Menos15",
    },
    {
      url: PromoJunteGanhe,
      alt: "promoção Junte e ganhe",
    },
    {
      url: PromoImgJunteTroque,
      alt: "promoção Junte troque",
    },
    {
      url: PromoImgShellNaCaixa,
      alt: "promoção shell na caixa",
    },
    {
      url: PromoImgEmbarque,
      alt: "promoção embarque num sonho",
    },
  ];

  useEffect(() => {
    gethubBanners();
    // getWebdoorDoubleBanners();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <main>
      {/* <DoubleBannerHub /> */}
      <CarouselWebdoorBanner />

      {/* <CarouselModal
        isOpen={isOpen}
        onClose={onClose}
        imageLarge={PromoDesktop}
        imageMobile={PromoMobile}
        /> */}

      {/* <a href={`/gp-sp-2024`}>
        <BannerWrapper>
          <img src={isMobile ? PromoMobile : PromoDesktop} alt="" />
        </BannerWrapper>
      </a> */}
      <FinishedPromotions imageList={imageList} />
    </main>
  );
}

const Content = styled.div`
  max-width: 1400px;
  margin: 0 auto;
  padding: 0 2em;

  @media (max-width: 1200px) {
    padding: 0 1.5em;
  }
`;

const BannerWrapper = styled.section`
  display: flex;
  width: 100%;
  background-color: #e1071a;

  margin-top: 42px;

  img {
    width: 100%;
    max-width: 360px;
    margin: 0 auto;
    height: auto;
  }

  @media (min-width: ${(props) => props.theme.queries.lg}) {
    margin-top: 86px;

    img {
      max-width: unset;
    }
  }
`;
