import { thunk, action, Action, Thunk } from "easy-peasy";
import { GetSubscriptionKey, GetProducts } from "../services/home";
import { GetPromo } from "../services/shell";
import { get } from "lodash";

interface HomeInterface {
  isOpen: boolean;
}

export interface HomeModel {
  isLoading: boolean;
  error: string;

  isOpen: boolean;
  promotions: any;
  selectedPromotionKey: string;
  urlQuery: string;
  maintenance: boolean;

  setIsOpen: Action<HomeInterface, any>;
  getSubscriptionKey: Thunk<HomeModel, void>;
  getPromo: Thunk<HomeModel, void>;
  getProductsHome: Thunk<HomeModel, void>;
  setSubscription: Action<HomeModel, any>;
  setSelectPromotionKey: Action<HomeModel, any>;
  setLoading: Action<HomeModel, any>;
  setUrlQuery: Action<HomeModel, any>;
  setMaintenance: Action<HomeModel, any>;

  setError: Action<HomeModel, string>;
}

const home: HomeModel = {
  urlQuery: "",
  isLoading: false,
  selectedPromotionKey: "",
  promotions: [],
  isOpen: false,
  maintenance: false,
  error: "",
  setIsOpen: action((state: any, payload: any) => {
    if (window.innerWidth <= 768) {
      if (payload) {
        document.querySelector("html").style.overflow = "hidden";
        document.querySelector("html").style.position = "fixed";
        document.querySelector("body").style.overflow = "hidden";
        document.querySelector("body").style.position = "fixed";
        document.querySelector("body").style.width = "100%";
      } else {
        document.querySelector("html").style.overflow = "auto";
        document.querySelector("html").style.position = "inherit";
        document.querySelector("body").style.overflow = "auto";
        document.querySelector("body").style.position = "inherit";
        document.querySelector("body").style.width = "inherit";
      }
    }
    state.isOpen = payload;
  }),
  getSubscriptionKey: thunk(async (action: any) => {
    try {
      action.setLoading({ loading: true });
      const response = await GetSubscriptionKey();
      action.setSubscription(response);
      if (
        localStorage.getItem("promo_key")?.length === 0 ||
        localStorage.getItem("promo_google_maps")?.length === 0 ||
        localStorage.getItem("promo_recaptcha")?.length === 0
      ) {
        setTimeout(() => window.location.reload(), 300);
      }
      action.setLoading({ loading: false });
    } catch (error) {
      // window.location.reload();
    }
  }),
  getPromo: thunk(async (action: any) => {
    try {
      const response = await GetPromo();
      action.setMaintenance(response.data.maintenance);
    } catch (error) {
      // window.location.reload();
    }
  }),
  getProductsHome: thunk(async (action: any) => {
    try {
      const response = await GetProducts();
    } catch (error) {
      // window.location.reload();
    }
  }),
  setLoading: action((state: { isLoading: any }, payload: any) => {
    const loading = get(payload, "loading", false);
    state.isLoading = loading;
  }),
  setSubscription: action((state: any, payload: any) => {
    const data = get(payload, "data", []);
    if (
      typeof localStorage.getItem("promo_key") === "object" ||
      typeof localStorage.getItem("promo_google_maps") === "object" ||
      typeof localStorage.getItem("promo_recaptcha") === "object"
    ) {
      localStorage.setItem("promo_key", data[0]?.subscription_key);
      localStorage.setItem(
        "promo_google_maps",
        data[0]?.react_app_google_maps_api
      );
      localStorage.setItem("promo_recaptcha", data[0]?.react_app_recaptcha);
    }
    state.promotions = data;
  }),
  setSelectPromotionKey: action((state: any, payload: string) => {
    state.selectedPromotionKey = payload;
  }),
  setUrlQuery: action((state: any, payload: string) => {
    state.urlQuery = payload;
  }),
  setError: action((state: { error: any }, payload: any) => {
    const message = get(payload, "message", false);
    state.error = message;
  }),
  setMaintenance: action((state: any, payload: any) => {
    state.maintenance = payload;
  }),
};

export default home;
