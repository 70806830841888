import { getSlug } from "utils/helper";
import { request } from "../utils/api";

export const GetShell = () => {
  return request({
    method: "get",
    baseUrl: "api",
    route: `balanco${"/"}`,
  });
};

export const GetUrlShop = () => {
  return request({
    method: "post",
    baseUrl: "api",
    route: "token/session",
  });
};

export const GetPromo = () => {
  return request({
    method: "get",
    baseUrl: "api",
    route: `${getSlug().endpoint.replace("/api/", "")}`,
  });
};
