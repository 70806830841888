import { getSlug } from "utils/helper";
import { request } from "../utils/api";

export const GetSubscriptionKey = () => {
  return request({
    method: "get",
    baseUrl: "api",
    route: `${getSlug().endpoint.replace("/api/", "")}`,
  });
};

export const GetProducts = () => {
  return request({
    method: "get",
    baseUrl: "api",
    route: `advertisement`,
  });
};
