import React from "react";
import styled from "styled-components";
import { QrCodeSection2024 } from "modules/promoGrandeGP2024/components/Home/QrCode";
import { SorteioSection2024 } from "modules/promoGrandeGP2024/components/Home/SorteioSection";
import { RedeemTickets2024 } from "modules/promoGrandeGP2024/components/Home/RedeemTickets";
import { ParticipationPeriod } from "modules/promoGrandeGP2024/components/Home/ParticipationPeriod";

export default function HomeGP2024() {
  return (
    <HomeContainer>
      <QrCodeSection2024 />
      {/* <Gp2024VideoSection /> */}
      <SorteioSection2024 />
      <TextParagraph>
        Períodos de participação dos sorteios encerrados, mas você pode garantir
        seu lugar através dos lotes de vendas que vão até 14/10 e V-iver a
        emoção das pistas conosco.{" "}
        <a href="https://promo.shell.com.br/gp-sp-2024/resgate-de-ingressos">
          Veja como resgatar ingressos.
        </a>
      </TextParagraph>
      <ParticipationPeriod />
      <RedeemTickets2024 />
    </HomeContainer>
  );
}

const HomeContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 3.125rem;

  width: 90%;
  max-width: 1270px;

  margin: 40px auto 1.875rem;

  @media (min-width: ${(props) => props.theme.queries.desk}) {
    gap: 75px;
    margin: 5rem auto 4.625rem;
  }
`;

const TextParagraph = styled.p`
  width: 100%;
  max-width: 622px;
  margin: -32px auto 0;
  font-family: ${(props) => props.theme.Shell.Book};
  font-size: 1rem;
  line-height: 1.25;
  text-align: left;
  color: ${(props) => props.theme.color.text};

  a {
    color: ${(props) => props.theme.color.red};
    white-space: nowrap;
  }

  @media (min-width: ${(props) => props.theme.queries.desk}) {
    font-size: 1.125rem;
  }
`;
