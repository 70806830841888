import React, { useEffect, useState } from "react";
import styled from "styled-components";

type ProductLabelProps = {
  bgColor: string;
  titleColor: string;
  title: string;
};

export function ProductCardLabel({
  title,
  bgColor,
  titleColor,
}: ProductLabelProps) {
  const [label, setLabel] = useState("");

  useEffect(() => {
    setLabel(title);
  }, [title]);

  return (
    <StatusLabel color={titleColor} bg={bgColor}>
      {label}
    </StatusLabel>
  );
}

const StatusLabel = styled.div`
  display: block;

  font-size: 0.438rem;
  line-height: 100%;
  font-family: ${(props) => props.theme.Shell.Bold};
  font-weight: 700;
  text-transform: uppercase;
  white-space: nowrap;

  border-radius: 2px;
  color: ${(props) => (props.color ? props.color : "#fff")};
  background: ${(props) => (props.bg ? props.bg : "transparent")};
  padding: 3px 6px 3px;

  position: absolute;
  top: 4px;
  left: 4px;
  z-index: 4;

  @media (min-width: ${(props) => props.theme.queries.lg}) {
    top: 8px;
    left: 8px;
    font-size: 0.563rem;
    padding-top: 5px;
    padding-bottom: 4px;
  }
`;
