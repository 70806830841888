import { thunk, action, Action, Thunk } from "easy-peasy";
import { GetFaq, GetWebdoorCarousel, ListCategories, ListLocales, ListPromoHits } from "../services/hub";
import { get } from "lodash";

export interface WebdoorCarouselProps {
  imagem_desktop: string,
  imagem_responsiva: string,
  descricao: string,
  url: string,
  nova_janela: boolean,
  ordem: number
}

interface WebdoorDoubleBanner {
  firstBanner: WebdoorCarouselProps
  secondBanner: WebdoorCarouselProps
}

export interface HubModel {
  isLoading: boolean;
  isLoadingCategories: boolean;
  isLoadingLocales: boolean;
  isCarouselLoading: boolean;
  isFaqLoading: boolean;
  error: string;

  categories: any[];
  listCategories: Thunk<HubModel, void>;
  setCategories: Action<HubModel, void>;

  locales: any[];
  listLocales: Thunk<HubModel, void>;
  setLocales: Action<HubModel, void>;

  promoHits: any;
  listPromoHits: Thunk<HubModel, any>;
  setPromoHits: Action<HubModel, any>;

  faq: {
    pergunta: string
    resposta: string
  }[]
  getFaq: Thunk<HubModel, number>;
  setFaq: Action<HubModel, any>;
  setFaqLoading: Action<HubModel, boolean>;

  webdoorCarousel: WebdoorCarouselProps[]
  getWebdoorCarousel: Thunk<HubModel, void>
  setWebdoorCarousel: Action<HubModel, WebdoorCarouselProps[]>

  webdoorDoubleBanner: WebdoorDoubleBanner[]
  setwebdoorDoubleBanner: Action<HubModel, WebdoorCarouselProps[]>

  setLoading: Action<HubModel, string>;
  setCarouselLoading: Action<HubModel, boolean>;
  setError: Action<HubModel, string>;
}

const hub: HubModel = {
  isLoading: false,
  isLoadingCategories: false,
  isLoadingLocales: false,
  isCarouselLoading: false,
  isFaqLoading: false,
  error: "",
  categories: [],
  locales: [],
  promoHits: [],
  faq: [],
  webdoorCarousel: [],
  webdoorDoubleBanner: [],

  listCategories: thunk(async (action: any, payload: any) => {
    try {
      action.setLoading({ loading: true, type: "categories" });
      const response = await ListCategories();
      action.setCategories(response.data);
      action.setLoading({ loading: false, type: "categories" });
    } catch (error) {
      action.setLoading({ loading: false, type: "categories" });
      action.setError({ message: "Ocorreu um error!" });
    }
  }),
  setCategories: action((state: any, payload: any) => {
    state.categories = payload || [];
  }),

  listLocales: thunk(async (action: any, payload: any) => {
    try {
      action.setLoading({ loading: true, type: "locales" });
      const response = await ListLocales();
      action.setLocales(response.data);
      action.setLoading({ loading: false, type: "locales" });
    } catch (error) {
      action.setLoading({ loading: false, type: "locales" });
      action.setError({ message: "Ocorreu um error!" });
    }
  }),
  setLocales: action((state: any, payload: any) => {
    state.locales = payload || [];
  }),

  listPromoHits: thunk(async (action: any, payload: any) => {
    try {
      action.setLoading({ loading: true });
      const response = await ListPromoHits(payload);
      action.setPromoHits(response.data);
      action.setLoading({ loading: false });
    } catch (error) {
      action.setLoading({ loading: false });
      action.setError({ message: "Ocorreu um error!" });
    }
  }),
  setPromoHits: action((state: any, payload: any) => {
    state.promoHits = payload || [];
  }),

  getFaq: thunk(async (action, payload) => {
    action.setFaqLoading(true)
    const response = await GetFaq(payload)
    action.setFaq(response.data)
    action.setFaqLoading(false)
  }),
  setFaq: action((state, payload) => {
    state.faq = payload
  }),
  setFaqLoading: action((state, payload) => {
    state.isFaqLoading = payload
  }),

  getWebdoorCarousel: thunk(async (action, payload) => {
    try {
      action.setCarouselLoading(true)
      const response = await GetWebdoorCarousel()
      action.setWebdoorCarousel(response.data)
      action.setwebdoorDoubleBanner(response.data)
      action.setCarouselLoading(false)
    } catch (err) {
      console.error(err)
      action.setCarouselLoading(false)
    }
  }),
  setWebdoorCarousel: action((state, payload) => {
    const slidesOrdered = payload.sort((slideA, slideB) => slideA.ordem > slideB.ordem ? 1 : -1)
    state.webdoorCarousel = slidesOrdered
  }),

  setwebdoorDoubleBanner: action((state, payload) => {
    const slidesOrdered = payload.sort((slideA, slideB) => slideA?.ordem > slideB?.ordem ? 1 : -1)
    const doubleBannerList: WebdoorDoubleBanner[] = []
    for (let i = 0; i < slidesOrdered?.length; i += 2) {
      const doubleBanner = {
        firstBanner: slidesOrdered[i],
        secondBanner: slidesOrdered[i + 1]
      }
      doubleBannerList.push(doubleBanner)
    }
    state.webdoorDoubleBanner = doubleBannerList
  }),

  setCarouselLoading: action((state, payload) => {
    state.isCarouselLoading = payload
  }),
  setLoading: action((state: any, payload: any) => {
    const loading = get(payload, "loading", false);
    const type = get(payload, "type", false);

    switch (type) {
      case "categories":
        state.isLoadingCategories = loading;
        break;
      case "locales":
        state.isLoadingLocales = loading;
        break
      default:
        state.isLoading = loading;
        break;
    }
  }),
  setError: action((state: { error: string }, payload: any) => {
    const message = get(payload, "message", false);
    state.error = message;
  }),
};

export default hub;
