import React from "react";
import styled from "styled-components";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton
} from "@chakra-ui/core";
import useWindowSize from "hooks/useWIndowSize";

const ModalAjuda = (props) => {
  const { width } = useWindowSize();

  const handleLink = (url) => {
    if (window.location.search.includes("shellbox")) {
      try {
        // @ts-ignore
        WebViewJavascriptInterface.openExternalLink(url);
      } catch (_) {
        window.open(
          url,
          "_blank",
          "noopener"
        );
        window.focus();
      }
    } else {
      window.open(url);
    }
  }

  return (
    <>
      {width > 768 && (
        <Modal
          onClose={props.onClose}
          isOpen={props.isOpen}
          isCentered
          blockScrollOnMount={true}
          size={"937px"}
        >
          <CustomOverlay />
          <ContainerContent>
            <CustomClose />
            <ContainerInfo >
              <Title>Dúvidas</Title>
              <Description>
                <Paragraph tabIndex={0}>{props.children}</Paragraph>
              </Description>
            </ContainerInfo>
          </ContainerContent>
        </Modal>
      )}
    </>
  );
};

export default ModalAjuda;

const CustomOverlay = styled(ModalOverlay)`
  background-color: ${(props) => props.theme.color.white} !important;
  opacity: 0.6;
`;

const ContainerContent = styled(ModalContent)`
  background-color: ${(props) => props.theme.color.white} !important;
  padding: 42px 0 !important;
  max-height: 639px;
`;

const CustomClose = styled(ModalCloseButton)`
  z-index: 10;
  margin: 1rem;
  padding: 1rem;
  border: none;
  cursor: pointer;
  background-color: transparent;
  :hover {
    background-color: transparent !important;
  }
  svg {
    padding: 1rem;
    height: 30px;
    width: 30px;
    path {
      fill: ${(props) => props.theme.color.darkGray};
    }
    :hover {
      path {
        fill: ${(props) => props.theme.color.red};
      }
    }
  }
`;

const ContainerInfo = styled.div`
  padding: 2em;
  padding-top: 1em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Title = styled.h1`
  font-weight: 700;
  font-size: 24px;
  line-height: 14px;
  color: ${props => props.theme.color.gray};
  text-transform: uppercase;
  margin: 0;
`;

const Description = styled.div`
  margin: 36px 84px 0 84px;
  overflow-y: auto;
  max-height: 400px;
`;

const Paragraph = styled.p`
  width: 100%;
  font-size: 16px;
  font-weight: normal;
  line-height: 20px;
  margin: 0;

  p {
    margin: 0;
    margin-bottom: 35px;
    font-family: Arial, Helvetica, sans-serif;
  }

  h3 {
    margin-bottom: 35px;
    font-weight: 700;
    color: ${props => props.theme.color.gray};
    font-size: 1.1rem;
  }
`;