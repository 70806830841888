import { createGlobalStyle } from "styled-components";
import { theme } from "./theme";

export const GlobalStyle = createGlobalStyle`
  * {
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
  }

  html {
    line-height: 1.15; /* 1 */
    -webkit-text-size-adjust: 100%; /* 2 */
    background: ${(props) => props.theme.color.white};
  }

  body {
    margin: 0;
  }

  main {
    display: block;
  }

  h1 {
    font-size: 2em;
    margin: 0.67em 0;
  }

  table{
    border-spacing: 0;
  }

  hr {
    box-sizing: content-box; /* 1 */
    height: 0; /* 1 */
    overflow: visible; /* 2 */
  }

  pre {
    font-family: monospace, monospace; /* 1 */
    font-size: 1em; /* 2 */
  }

  a {
    background-color: transparent;
  }

  abbr[title] {
    border-bottom: none; /* 1 */
    text-decoration: underline; /* 2 */
    text-decoration: underline dotted; /* 2 */
  }

  b,
  strong {
    font-weight: bolder;
  }

  code,
  kbd,
  samp {
    font-family: monospace, monospace; /* 1 */
    font-size: 1em; /* 2 */
  }

  small {
    font-size: 80%;
  }

  sub,
  sup {
    font-size: 75%;
    line-height: 0;
    position: relative;
    vertical-align: baseline;
  }

  sub {
    bottom: -0.25em;
  }

  sup {
    top: -0.5em;
  }

  img {
    border-style: none;
  }

  button,
  input,
  optgroup,
  select,
  textarea {
    font-family: inherit; /* 1 */
    font-size: 100%; /* 1 */
    line-height: 1.15; /* 1 */
    margin: 0; /* 2 */
  }

  button,
  input { /* 1 */
    overflow: visible;
    font-family: Futura,"futura-medium", Arial, sans-serif;
  }

  button,
  select { /* 1 */
    text-transform: none;
  }

  button,
  [type="button"],
  [type="reset"],
  [type="submit"] {
    -webkit-appearance: button;
  }

  button::-moz-focus-inner,
  [type="button"]::-moz-focus-inner,
  [type="reset"]::-moz-focus-inner,
  [type="submit"]::-moz-focus-inner {
    border-style: none;
    padding: 0;
  }

  button:-moz-focusring,
  [type="button"]:-moz-focusring,
  [type="reset"]:-moz-focusring,
  [type="submit"]:-moz-focusring {
    outline: 1px dotted ButtonText;
  }

  fieldset {
    padding: 0.35em 0.75em 0.625em;
  }

  legend {
    box-sizing: border-box; /* 1 */
    color: inherit; /* 2 */
    display: table; /* 1 */
    max-width: 100%; /* 1 */
    padding: 0; /* 3 */
    white-space: normal; /* 1 */
  }

  progress {
    vertical-align: baseline;
  }

  textarea {
    overflow: auto;
  }

  [type="checkbox"],
  [type="radio"] {
    box-sizing: border-box; /* 1 */
    padding: 0; /* 2 */
  }

  [type="number"]::-webkit-inner-spin-button,
  [type="number"]::-webkit-outer-spin-button {
    height: auto;
  }

  [type="search"] {
    -webkit-appearance: textfield; /* 1 */
    outline-offset: -2px; /* 2 */
  }

  [type="search"]::-webkit-search-decoration {
    -webkit-appearance: none;
  }

  ::-webkit-file-upload-button {
    -webkit-appearance: button; /* 1 */
    font: inherit; /* 2 */
  }

  details {
    display: block;
  }

  summary {
    display: list-item;
  }

  template {
    display: none;
  }

  [hidden] {
    display: none;
  }

  select, input, textarea {
    font-size: 16px!important;
    font-family: Futura, Arial, sans-serif;
  }

  html {
    margin: 0;
    padding: 0;
    font-family: Futura, Arial, sans-serif;
    text-size-adjust: 100%;
    width: 100%;
    height: 100vh;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #525f7f;
    background-color: white;
  }

  body {
    font-family: Futura, Arial, sans-serif;
    color: rgba(0, 0, 0, 0.85);
    font-weight: 400;
    letter-spacing: 0.1px;
    word-wrap: break-word;
    font-kerning: normal;
    -webkit-font-smoothing: antialiased;
    background-color: white;
    margin: 0;
  }

  .css-18poma1 {
    font-family: Futura !important;
  }

  .mdc-line-ripple {
    height: 1px !important;
  }
  .mdc-text-field {
    background-color: white !important;
  }
  .mdc-text-field:hover, .mdc-text-field:focus {
    background-color: white !important;
  }
  .mdc-text-field__input {
    border-bottom-color: #BDBDBD !important;
    padding: 40px 6px 8px !important;
    @media (max-width: ${(props) => props.theme.queries.medium}) {
      font-size: 0.9rem;
      line-height: 1.15rem;
    }
  }
  .mdc-text-field::before, .mdc-text-field::after {
    background-color: white !important;
  }
  .mdc-text-field .mdc-line-ripple {
    background-color: #BDBDBD !important;
  }
  .mdc-text-field:not(.mdc-text-field--disabled) .mdc-floating-label {
    color: ${theme.color.text};
    font-weight: 400;
  }
  .mdc-floating-label {
    font-family: ${theme.Shell.Book} !important;
    font-size: 16px !important;
  }
  .mdc-floating-label--float-above {
    color: ${theme.color.text} !important;
    font-family: ${theme.Shell.Book} !important;
  }
  .mdc-text-field .mdc-floating-label {
    left: 4px;
    top: 22px;
  }
  .mdc-text-field .mdc-floating-label:not(.mdc-floating-label--float-above) {
    color: ${theme.color.text} !important;
    font-family: ${theme.Shell.Book} !important;
  }
  .mdc-text-field .mdc-text-field__input {
    caret-color: ${theme.color.text};
  }

  .css-1n3bvex {
    position: fixed;
    left: 0;
    top: 45px !important;
    width: 100%;
    height: 100% !important;
    z-index: 1400;
    min-height: 100vh !important;
    max-height: 100% !important;
    overflow: hidden;
  }

  .css-1v76zxk {
    max-width:100% !important;
  }

  .css-89ulqu {
    padding: 1rem 1.3rem 3rem 1.3rem !important;
    height: 120% !important;
    @media(max-width: ${(props) => props.theme.queries.small}){
      height: 120% !important; 
    }
  }

  .css-ijlnep {
    outline: 0;
    max-width: 100% !important; 
    width: 100% !important;
    height:100% !important;
    position: fixed;
  }

  .css-556rku {
    section{
      display:flex;
      justify-content:center;
    }
  }

  .css-1dedo85{
    z-index: 99999 !important;
  }

  .css-1dx3tna:disabled{
    opacity:.8 !important;
  }

  .css-taapg0{
    top: -5px !important;
  }

  .css-19ewlhq {
    overflow-y: hidden !important;
  }
  .css-17tqb2q{
    height: 90vh !important;
    overflow-y: auto;
  }
`;
