import React from "react";
import {
  Button,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerOverlay,
  useDisclosure,
  Image,
  Box,
} from "@chakra-ui/core";
import styled from "styled-components";
import menuHamburguerIcon from "assets/icons/hamburguerPromoGpSp2023.svg";
import closeYellow from "assets/icons/closeYellow.svg";
import { PromoHeaderTitle } from "../styles";
import { theme } from "theme";
import MenuHamburguerLinks from "../MenuHamburguerLinks";

const MenuHamburguerMobile2024 = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = React.useRef();

  return (
    <>
      <Button ref={btnRef} background="transparent" border="0" onClick={onOpen}>
        <Image src={menuHamburguerIcon} width="1.5rem" height="1.25rem" />
      </Button>
      <Drawer
        isOpen={isOpen}
        placement="right"
        onClose={onClose}
        finalFocusRef={btnRef}
        size="full"
      >
        <DrawerOverlay />
        <DrawerContent marginTop="2.89rem">
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            width="100%"
            backgroundColor={theme.color.red}
            padding="1rem"
          >
            <ContainerTitle>
              <PromoHeaderTitle>Grande Prêmio de SP 2024</PromoHeaderTitle>
            </ContainerTitle>
            <Button
              backgroundColor="transparent"
              width="2.5rem"
              border={0}
              onClick={onClose}
            >
              <Image src={closeYellow} width="1.5rem" height="1.5rem" />
            </Button>
          </Box>
          <DrawerBody padding={0}>
            <ContainerLinks
              display="flex"
              flexDirection="column"
              width="100%"
              textDecoration="none"
            >
              <MenuHamburguerLinks label="Como participar" path={""} />
              <MenuHamburguerLinks label="Ganhadores" path="/ganhadores" />
              <MenuHamburguerLinks
                label="Resgate de Ingressos"
                path="/resgate-de-ingressos"
              />
              <MenuHamburguerLinks
                label="Postos Participantes"
                path="/postos-participantes"
              />
            </ContainerLinks>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
};
export default MenuHamburguerMobile2024;

const ContainerLinks = styled(Box)`
  a {
    text-decoration: none;
  }
`;

const ContainerTitle = styled(Box)`
  width: 11.625rem;

  @media (min-width: ${theme.queries.medium}) {
    width: 90%;
  }
`;
