import React from "react";
import styled from "styled-components";
import { NavigationAnoTodo } from "../components/Navigation/NavigationAnoTodo";
import { Box } from "@chakra-ui/core";
import Rules from "modules/common/Pages/rules";
import { useStore } from "configureStore";

export default function RulesAnoTodo() {
  const isAuthenticated = useStore((state) => state.auth.isAuthenticated);
  const isShellbox = window.location.search.includes("shellbox");
  document.title = "Promoshell | Regulamento"

  return (
    <Container
      isAuth={isAuthenticated}
      isShellbox={isShellbox}
    >
      <HeaderWrapper>
        <NavigationAnoTodo />
        <h1>Regulamento</h1>
      </HeaderWrapper>
      <Box
        as={"main"}
        display={"flex"}
        flexDir={"column"}
        justifyContent={"center"}
        alignItems={"flex-start"}
        w={"90%"}
        p={["13px 0 1rem","13px 0 1rem","13px 0 1rem","1.563rem 0 1rem"]}
        m={"0 auto"}
        maxW={"65rem"}
      >
        <Rules />
      </Box>
    </Container>
  )
}

const Container = styled(Box)`
  margin-top: ${(props) => (!props.isShellbox ? "40px" : "0")};
  background-color: ${props => props.theme.color.lightGray50};
  
  padding-bottom: ${props => props.isAuth || props.isShellbox ? "3rem" : "0"};
  margin-bottom: ${props => props.isShellbox ? "2rem" : "0"};
`;

const HeaderWrapper = styled.header`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  box-sizing: border-box;
  
  background-color: ${props => props.theme.color.shellPurple};
  padding: 8px 0 0.75rem;
  
  h1 {
    font-family: ${props => props.theme.Shell.Book};
    font-weight: 400;
    font-size: 1.875rem;
    color: #fff;
    margin: 0;
    margin-top: 1.5rem;
  }
  
  @media (min-width: ${props => props.theme.queries.lg}) {
    padding: 4px 0 1.75rem;
    
    h1 {
      font-size: 2.25rem;
      margin-top: 3.75rem;
    }
  }
`;