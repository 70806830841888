import React from "react";
import useWindowSize from "hooks/useWIndowSize";
import { BannerLettering } from "../components/BannerLettering";
import {
  ButtonCTA,
  Container,
  GrayBlock,
  HeaderContent,
  HeaderSection,
  InfoColoredText,
  InfoRedText,
  LetteringContainer,
  MainSection,
} from "./styles";
import { Box } from "@chakra-ui/core";

import BannerRimula from "assets/banners/promoRimula/banner-rimula.png";
import BannerRimulaMobile from "assets/banners/promoRimula/banner-rimula-mobile.png";
import ImageOil from "assets/banners/promoRimula/oil.png";
import IconRimula from "assets/banners/promoRimula/icon-rimula.svg";
import IconShellbox from "assets/images/box-shell.svg";
import { theme } from "theme";
import { ActivePromotionStamp } from "modules/hub/ActivePromotionStamp";

export default function PromoRimula2023() {
  const { width } = useWindowSize();

  return (
    <Container>
      <HeaderSection>
        <ActivePromotionStamp
          left={"calc(100% - 195px)"}
          responsiveLeftPosition="5%"
          top="-3px"
          responsiveTopPosition="0px"
        />
        {width >= 1024 && (
          <img src={IconRimula} alt="ícone shell rimula" draggable="false" />
        )}
        <HeaderContent>
          <img src={width < 1024 ? BannerRimulaMobile : BannerRimula} alt="" />

          <LetteringContainer>
            <BannerLettering />
            <ButtonCTA
              href={"http://www.shellrimularevisaopremiada.com.br"}
              target="_blank"
              rel="noopener noreferer"
            >
              participar agora
            </ButtonCTA>
            <GrayBlock>
              <img src={ImageOil} alt="" />
            </GrayBlock>
          </LetteringContainer>
        </HeaderContent>
      </HeaderSection>
      <MainSection>
        <Box
          width={"100%"}
          display={"flex"}
          flexDir={["column", "column", "column", "row"]}
          justifyContent={["center", "center", "center", "space-evenly"]}
        >
          <InfoColoredText>
            <p>
              Compre <InfoRedText>40L</InfoRedText> <br />
              em{" "}
              <InfoRedText>
                produtos <br />
                participantes
              </InfoRedText>{" "}
              <br />
              <br />
              e concorra a <br />
              <InfoRedText>R$</InfoRedText>
              <InfoRedText className="xl">15mil</InfoRedText> <br />
              em revisão para <br />o seu caminhão*
            </p>
          </InfoColoredText>

          {width >= 1024 && ( // Gray line
            <Box
              width={"3px"}
              height={"26.325rem"}
              mx={"4rem"}
              backgroundColor={theme.color.lightGray}
            />
          )}

          <InfoColoredText>
            <p>
              e centenas <br />
              de <InfoRedText>prêmios</InfoRedText> <br />
              <InfoRedText>instantâneos</InfoRedText> <br />
              <Box
                display={"flex"}
                justifyContent={"center"}
                alignItems={"flex-end"}
                my={"1rem"}
              >
                <img src={IconShellbox} alt="" />
                <div>
                  <InfoRedText>R$</InfoRedText>
                  <InfoRedText className="large">700</InfoRedText> <br />
                </div>
              </Box>
              em vale-combustível <br />
              no app Shell Box
            </p>
          </InfoColoredText>
        </Box>
        <ButtonCTA
          href={"http://www.shellrimularevisaopremiada.com.br/"}
          target="_blank"
          rel="noopener noreferer"
          className="variant"
        >
          participar agora
        </ButtonCTA>
      </MainSection>
    </Container>
  );
}
