import { setInLocalStorage } from "./localstorage";

export const setShellbox = (status) => {
  //@ts-ignore
  window.shellbox = status;

  return status;
};

interface Slug {
  endpoint: string;
  url: string;
}

export const getSlug = (): Slug => {
  try {
    const slug = sessionStorage.getItem("slug");
    if (slug) {
      return JSON.parse(slug);
    }

    if (window.location.search.includes("shellbox")) {
      return {
        endpoint: "/api/promocoes/67/",
        url: `/promocao-junte-e-troque-2022-1${window.location.search}`,
      };
    }
    return {
      endpoint: "/api/promocoes/67/",
      url: "/",
    };
  } catch (error) {
    if (window.location.search.includes("shellbox")) {
      return {
        endpoint: "/api/promocoes/67/",
        url: `/promocao-junte-e-troque-2022-1${window.location.search}`,
      };
    }
    return {
      endpoint: "/api/promocoes/67/",
      url: "/",
    };
  }
};

export const setSlug = (): Slug => {
  try {
    let slug = {
      endpoint: "/api/promocoes/134/",
      url: "/",
    };

    if (window.location.pathname.includes("junte-troque")) {
      if (window.location.origin === "https://promo.shell.com.br") {
        slug.endpoint = "/api/promocoes/134/";
        slug.url = "/junte-troque";
      } else {
        slug.endpoint = "/api/promocoes/101/"; // QA
        slug.url = "/junte-troque";
      }
    } else {
      if (window.location.origin === "https://promo.shell.com.br") {
        slug.endpoint = "/api/promocoes/134/";
        slug.url = "/junte-troque";
      } else {
        slug.endpoint = "/api/promocoes/101/"; // QA
        slug.url = "/junte-troque";
      }
    }

    // grande gp sp
    // if (window.location.pathname.includes("gp-sp-2023")) {
    //   if (window.location.origin === "https://promo.shell.com.br") {
    //     slug.endpoint = "/api/promocoes/167/";
    //     slug.url = "/junte-troque";
    //   } else {
    //     slug.endpoint = "/api/promocoes/134/";
    //     slug.url = "/junte-troque";
    //   }
    // }
    // Grande gp sp 2024
    if (window.location.pathname.includes("gp-sp-2024")) {
      if (window.location.origin === "https://promo.shell.com.br") {
        slug.endpoint = "/api/promocoes/167/";
        slug.url = "/gp-sp-2024";
      } else {
        slug.endpoint = "/api/promocoes/134/";
        slug.url = "/gp-sp-2024";
      }
    }

    sessionStorage.setItem("slug", JSON.stringify(slug));

    return slug;
  } catch (error) {
    return {
      endpoint: "/api/promocoes/134/",
      url: "/junte-troque",
    };
  }
};

export const refreshToken = async () => {
  const slug = setSlug();

  try {
    const promoKey = localStorage.getItem("promo_key");
    let responseKeys = null;
    let jsonKeys = null;
    if (!promoKey) {
      responseKeys = await fetch(
        `${process.env.REACT_APP_BASE_URL}${slug.endpoint}`
      );
      jsonKeys = await responseKeys.json();

      localStorage.setItem("promo_key", jsonKeys?.subscription_key);
      localStorage.setItem(
        "promo_google_maps",
        jsonKeys?.react_app_google_maps_api
      );
      localStorage.setItem("promo_recaptcha", jsonKeys?.react_app_recaptcha);
    }

    const token = localStorage.getItem("external_token");
    const params = new URLSearchParams();
    params.append("consumerToken", token.toString());

    const response = await fetch(
      `${process.env.REACT_APP_BASE_URL}/api/token/external`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          SubscriptionKey:
            jsonKeys?.subscription_key ||
            localStorage.getItem("promo_key") ||
            "",
        },
        body: params,
      }
    );
    const json = await response.json();

    if (json?.access_token) {
      const cache = {
        isAuthenticated: true,
        token: json.access_token,
        user: json,
      };
      setInLocalStorage("Hub-auth", cache);

      return cache;
    }
  } catch (error) {
    //nop
  }
};

export const selectPercentage = (code: string = "1015") => {
  return `${code?.toString()?.slice(2, 4)}%`; //pega os dois ultimos numeros q sao os valores da porcentagem e concatena
};

export const selectAmountForLiter = (code: string = "1005") => {
  return `0,${code?.toString()?.slice(2, 4)}`; //pega os dois ultimos numeros q sao os valores em centavos por litro
};

export const isProduct = (code: number) => {
  switch (code) {
    case 1015:
      return true;
    case 1520:
      return true;
    case 2025:
      return true;
    default:
      return false;
  }
};

export const isGasoline = (code: number) => {
  switch (code) {
    case 1005:
      return true;
    case 1510:
      return true;
    case 2015:
      return true;
    default:
      return false;
  }
};

export const removeAccents = (str) => {
  let accents =
    "ÀÁÂÃÄÅàáâãäåßÒÓÔÕÕÖØòóôõöøÈÉÊËèéêëðÇçÐÌÍÎÏìíîïÙÚÛÜùúûüÑñŠšŸÿýŽž";
  let accentsOut =
    "AAAAAAaaaaaaBOOOOOOOooooooEEEEeeeeeCcDIIIIiiiiUUUUuuuuNnSsYyyZz";
  if (str) {
    str = str.split("");
    str.forEach((letter, index) => {
      let i = accents.indexOf(letter);
      if (i !== -1) {
        str[index] = accentsOut[i];
      }
    });
    return str.join("");
  }
};

export const translateChat = () => {
  return {
    actionPaymentCompleted: "Pagamento finalizado",
    actionPaymentError:
      "Ocorreu um erro ao processar o cartão. <br> Por favor, tente novamente ou use um cartão diferente.",
    actionPostbackError:
      "Ocorreu um erro ao processar sua ação. Por favor, tente novamente.",
    clickToRetry: "Mensagem não enviada. Clique para tentar novamente.",
    clickToRetryForm:
      "Formulário não enviado. Clique em qualquer lugar do formulário para tentar novamente.",
    connectNotificationText:
      "Sincronize sua conversa e continue nos enviando mensagens por meio de seu aplicativo favorito.",
    connectNotificationSingleText:
      "Seja notificado quando receber uma resposta.",
    conversationListHeaderText: "Minhas conversas",
    conversationListPreviewAnonymousText: "Agora mesmo",
    conversationListPreviewCarouselText: "{user} enviou uma mensagem",
    conversationListPreviewFileText: "{user} enviou um arquivo",
    conversationListPreviewFormText: "{user} enviou um formulário",
    conversationListPreviewFormResponseText: "{user} preencheu um formulário",
    conversationListPreviewImageText: "{user} enviou uma imagem",
    conversationListPreviewLocationRequestText:
      "{user} enviou uma solicitação de localização",
    conversationListPreviewUserText: "Você",
    conversationListRelativeTimeJustNow: "Agora mesmo",
    conversationListRelativeTimeMinute: "1 minuto atrás",
    conversationListRelativeTimeMinutes: "{value} minutos atrás",
    conversationListRelativeTimeHour: "1 hora atrás",
    conversationListRelativeTimeHours: "{value} horas atrás",
    conversationListRelativeTimeYesterday: "Ontem",
    conversationListTimestampFormat: "DD/MM/YY",
    conversationTimestampHeaderFormat: "DD MMMM YYYY, h:mm A",
    couldNotConnect: "Desconetado. Você não receberá mensagens.",
    couldNotConnectRetry: "Reconectando...",
    couldNotConnectRetrySuccess: "Você está online novamente!",
    couldNotLoadConversations: "Não foi possível carregar as conversas.",
    emailChangeAddress: "Mudar meu email",
    emailDescription:
      "Para ser notificado por e-mail quando obtiver uma resposta, digite seu endereço de e-mail.",
    emailFieldLabel: "Email",
    emailFieldPlaceholder: "Seu endereço de email",
    emailFormButton: "Enviar",
    emailLinkingErrorMessage: "Por favor, envie um endereço de e-mail válido.",
    fetchHistory: "Caregar mais",
    fetchingHistory: "Recuperando histórico...",
    fileTooLargeError: "Limite máximo de tamanho de arquivo excedido ({size})",
    fileTypeError: "Tipo de arquivo não suportado.",
    formErrorInvalidEmail: "Email inválido",
    formErrorNoLongerThan: "Deve conter no máximo ({caracteres}) caracteres",
    formErrorNoShorterThan: "Deve conter pelo menos ({characters}) caracteres",
    formErrorUnknown: "Isso não parece muito certo",
    formFieldSelectPlaceholderFallback: "Escolha um...",
    frontendEmailChannelDescription:
      "Para falar conosco por e-mail, basta enviar uma mensagem para nosso endereço de e-mail e responderemos em breve:",
    headerText: "Como podemos ajudar?",
    imageClickToReload: "Clique para recarregar a imagem.",
    imageClickToView: "Clique para ver a imagem {size}.",
    imagePreviewNotAvailable: "Pré-visualização não disponível.",
    inputPlaceholder: "Digite uma mensagem...",
    inputPlaceholderBlocked: "Preencha o formulário acima...",
    introAppText:
      "Envie-nos uma mensagem abaixo ou de seu aplicativo favorito.",
    lineChannelDescription:
      "Para falar conosco usando o LINE, escaneie este código QR usando o aplicativo LINE e envie-nos uma mensagem.",
    linkError:
      "Ocorreu um erro ao tentar gerar um link para este canal. Por favor, tente novamente.",
    linkChannelPageHeader: "Sincronize sua conversa",
    locationNotSupported:
      "Seu navegador não é compatível com serviços de localização ou foi desativado. Por favor digite sua localização.",
    locationSecurityRestriction:
      "Este site não pode acessar sua localização. Por favor digite sua localização.",
    locationSendingFailed: "Não foi possível enviar localização",
    locationServicesDenied:
      "Este site não pode acessar sua localização. Permita o acesso em suas configurações ou digite seu local.",
    messageIndicatorTitlePlural: "({count}) Novas mensagens",
    messageIndicatorTitleSingular: "({count}) Nova mensagem",
    messageRelativeTimeDay: "{value}d atrás",
    messageRelativeTimeHour: "{value}h atrás",
    messageRelativeTimeJustNow: "Agora mesmo",
    messageRelativeTimeMinute: "{value}m atrás",
    messageTimestampFormat: "h:mm A",
    messageDelivered: "Entregue",
    messageSeen: "Visto",
    messageSending: "Enviando...",
    messengerChannelDescription:
      "Conecte sua conta do Facebook Messenger para ser notificado quando você receber uma resposta e continue a conversa no Facebook Messenger.",
    newConversationButtonText: "Nova conversa",
    notificationSettingsChannelsDescription:
      "Sincronize esta conversa conectando-se ao seu aplicativo de mensagens favorito para continuar a conversa do seu jeito.",
    notificationSettingsChannelsTitle: "Outros Canais",
    notificationSettingsConnected: "Conectado",
    notificationSettingsConnectedAs: "Conectado como {username}",
    prechatCaptureGreetingText:
      "Olá 👋 \nPara começar, gostaríamos de saber um pouco mais sobre você:",
    prechatCaptureNameLabel: "Seu nome",
    prechatCaptureNamePlaceholder: "Digite seu nome...",
    prechatCaptureEmailLabel: "Email",
    prechatCaptureEmailPlaceholder: "nome@empresa.com",
    prechatCaptureConfirmationText: "Obrigado! Em que podemos ajudá-lo?",
    prechatCaptureMailgunLinkingConfirmation:
      "Você será notificado aqui e pelo-mail {email} assim que respondermos.",
    sendButtonText: "Enviar",
    settingsHeaderText: "Configurações",
    shareLocation: "Localização",
    smsBadRequestError:
      "Não foi possível nos comunicarmos com este número. Tente novamente ou use um diferente.",
    smsCancel: "Cancelar",
    smsChangeNumber: "Mudar meu número",
    smsChannelDescription:
      "Conecte seu número de SMS para ser notificado quando você receber uma resposta e continue a conversa por SMS.",
    smsChannelPendingDescription:
      "Verifique suas mensagens em {number} para confirmar seu número de telefone.",
    smsContinue: "Enviar",
    smsInvalidNumberError: "Envie um número de telefone válido.",
    smsLinkCancelled: "O vínculo com {appUserNumber} foi cancelado.",
    smsLinkPending: "Pendente",
    smsPingChannelError:
      "Ocorreu um erro ao enviar uma mensagem para o seu número.",
    smsSendText: "Me envie uma mensagem",
    smsStartTexting: "Começar a enviar mensagens",
    smsTooManyRequestsError:
      "Uma conexão para esse número foi solicitada recentemente. Tente novamente em {minutes} minutos.",
    smsTooManyRequestsOneMinuteError:
      "Uma conexão para esse número foi solicitada recentemente. Tente novamente em 1 minuto.",
    smsUnhandledError: "Algo deu errado. Por favor, tente novamente.",
    syncConversation: "Sincronizar conversa",
    tapToRetry: "Mensagem não enviada. Toque para tentar novamente.",
    tapToRetryForm:
      "Formulário não enviado. Toque em qualquer lugar do formulário para tentar novamente.",
    telegramChannelDescription:
      "Conecte sua conta do Telegram para ser notificado quando você receber uma resposta e continue a conversa no Telegram",
    unsupportedMessageType: "Tipo de mensagem não suportada.",
    unsupportedActionType: "Tipo de ação sem suporte.",
    uploadDocument: "Arquivo",
    uploadInvalidError: "Arquivo inválido.",
    uploadPhoto: "Imagem",
    uploadVirusError:
      "Um vírus foi detectado em seu arquivo e ele foi rejeitado",
    viberChannelDescription:
      "Conecte sua conta do Viber para ser notificado quando você receber uma resposta e continue a conversa no Viber. Para começar, leia o QR Code usando o aplicativo Viber.",
    viberChannelDescriptionMobile:
      "Conecte sua conta do Viber para ser notificado quando você receber uma resposta e continue a conversa no Viber. Para começar, instale o aplicativo Viber e toque em Conectar.",
    viberQRCodeError:
      "Ocorreu um erro ao buscar seu QR Code do Viber. Por favor, tente novamente.",
    wechatChannelDescription:
      "Conecte sua conta do WeChat para ser notificado quando você receber uma resposta e continue a conversa no WeChat. Para começar, leia este QR Code usando o aplicativo WeChat.",
    wechatChannelDescriptionMobile:
      "Conecte sua conta do WeChat para ser notificado quando você receber uma resposta e continue a conversa no WeChat. Para começar, salve esta imagem de QR Code e faça upload do <a href='weixin://dl/scan'> Leitor de QR Code </a>.",
    wechatQRCodeError:
      "Ocorreu um erro ao buscar seu QR Code do WeChat. Por favor, tente novamente.",
    whatsappChannelDescriptionDesktop:
      "Sincronize sua conta com o WhatsApp lendo o QR Code ou clicando no link abaixo. \nEm seguida, envie a mensagem pré-preenchida para validar a solicitação de sincronização. (Seu código: {{code}}).",
    whatsappChannelDescriptionMobile:
      "Sincronize sua conta com o WhatsApp clicando no link abaixo. \nEm seguida, envie a mensagem pré-preenchida para validar a solicitação de sincronização. (Seu código: {{code}}).",
    whatsappLinkingError:
      "Ocorreu um erro ao buscar suas informações de vínculo do WhatsApp. Por favor, tente novamente.",
  };
};
