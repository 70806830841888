import React from "react";
import styled from "styled-components";
import useWindowSize from "hooks/useWIndowSize";

//assets
// import bannerMobile from "assets/banners/coxinha-mobile.png";
// import banner from "assets/banners/coxinha-desktop.png";
import bannerMobile from "assets/banners/header-delicias-mobile.png";
import banner from "assets/banners/header-delicias.png";
import bannerAlt from "assets/banners/header-delicias-alt.png";
import { ActivePromotionStamp } from "../ActivePromotionStamp";

const Banner = () => {
  const { width } = useWindowSize();

  return (
    <Container>
      <img src={width <= 440 ? bannerMobile : banner} alt="" />
      {/* <ActivePromotionStamp
        top={"0.5rem"}
        left={"23.5%"}
        responsiveTopPosition={"1.5%"}
        responsiveLeftPosition={"50%"}
      /> */}
    </Container>
  );
};

export default Banner;

const Container = styled.header`
  margin-top: 2rem;
  width: 100%;
  /* height: 496px; */
  background-image: url(${(props) => props.src});
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;

  position: relative;

  @media (min-width: ${(props) => props.theme.queries.small}) {
    margin-top: 2.75rem;
  }

  @media (min-width: 769px) {
    margin-top: 5.5rem;
  }

  @media (min-width: ${(props) => props.theme.queries.desk}) {
    height: 100%;
  }

  @media (min-width: 2000px) {
    background-size: cover;
  }

  img {
    width: 100%;
    height: inherit;
  }
`;

const ButtonSaibaMais = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;

  font-size: 1.125rem;
  line-height: 1.5rem;
  font-weight: 700;
  text-decoration: none;

  width: 10.5rem;
  height: 3rem;

  border: 0;
  background: ${(props) => props.theme.color.red};
  color: #fff;
  text-transform: uppercase;

  position: absolute;
  top: 12rem;

  cursor: pointer;
  margin: 1.25rem;
  transition: 0.2s;

  &:hover,
  &:focus {
    background: ${(props) => props.theme.color.orange};
  }

  @media (min-width: 768px) {
    font-size: 1.5rem;

    left: 1rem;
    top: 26rem;

    width: 26rem;
    height: 3.5rem;
  }
  @media (min-width: 1280px) {
    left: 8rem;
  }
`;
