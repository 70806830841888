import styled, { css } from "styled-components"

export const LayoutContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;

  margin: 2.75rem auto 0;
  ${props => props.$isShellbox && css`
    margin: -5px auto 0;
    gap: 0;
  `}
`

export const LayoutContent = styled.main`
  display: flex;
  flex-direction: column;
  width: 100%;
  background: ${props => props.$isHome ? props.theme.color.lightGray : props.theme.color.white};
`