import React from "react";
import styled from "styled-components";
import { Box, Drawer, DrawerContent, DrawerCloseButton } from "@chakra-ui/core";
import { theme } from "theme";
import useWindowSize from "hooks/useWIndowSize";

const ModalInfoMobile = (props) => {
  const { width } = useWindowSize();

  return (
    <>
      {width <= 768 && (
        <Container>
          <CustomDrawer
            onClose={props.onClose}
            isOpen={props.isOpen}
            size={"full"}
          >
            <DrawerContent
              backgroundColor={theme.color.yellow}
              className={
                props.type.includes("fone") ? "modal-fone" : "modal-relogio"
              }
              display="flex"
              alignContent="center"
            >
              <CustomClose onClick={props.onClose} />
              <ContainerInfo>
                <Title>{props.title}</Title>
                <br />
                <br />
                <Description>{props.description}</Description>
              </ContainerInfo>
            </DrawerContent>
          </CustomDrawer>
        </Container>
      )}
    </>
  );
};

export default ModalInfoMobile;

const Container = styled(Box)`
  display: none !important;
  @media (max-width: ${(props) => props.theme.queries.medium}) {
    display: block;
  }
  width: 100%;
`;

const CustomDrawer = styled(Drawer)``;

const CustomClose = styled(DrawerCloseButton)`
  background-color: transparent !important;
  position: fixed;
  z-index: 99999;
  border: none !important;
  margin-top: 1rem;
  margin-right: 1rem;
  button {
    position: fixed !important;
    z-index: 99999 !important;
  }
  svg {
    width: 22px;
    height: 22px;
    path {
      fill: ${(props) => props.theme.color.red};
    }
  }
  :focus {
    box-shadow: none !important;
  }
`;

const ContainerInfo = styled.div`
  padding: 2em;
`;

const Title = styled.span`
  font-size: 22px;
`;

const Description = styled.span`
  font-size: 18px;
`;
