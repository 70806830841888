import React from "react";
import styled from "styled-components";
import { Box, Image } from "@chakra-ui/core";
import { withRouter } from "react-router-dom";
import { ModalFullScreen } from "modules/common/UI";
import { theme } from "theme";
import chevron from "assets/images/navbar/chevron-right.svg";
import IconBandeiraMobile from "assets/images/hub/icon-bandeira-mobile.png";
import IconArrow from "./IconArrow";

const Menu = (props) => {
  return (
    <ModalFullScreen
      onClose={props.onClose}
      ref={props.ref}
      isOpen={props.isOpen}
    >
      <MyList>
        <MyLine
          onClick={() => {
            props.history.push("/");
            props.onClose();
          }}
        >
          <Label>Campanhas de Cidade</Label>
          <Image src={chevron} color={theme.color.orange} />
        </MyLine>
        <MyLine
          onClick={() => {
            props.history.push("/promocoes-de-rodovia");
            props.onClose();
          }}
        >
          <Label>Campanhas de Rodovia</Label>
          <Image src={chevron} color={theme.color.orange} />
        </MyLine>
        <MyLine
          onClick={() => {
            props.history.push("/campanhas-de-lubrificantes");
            props.onClose();
          }}
        >
          <Label>Campanhas de Óleos Lubrificantes</Label>
          <Image src={chevron} color={theme.color.orange} />
        </MyLine>
        <MyLine
          onClick={() => {
            props.history.push("/ofertas-shell-select");
            props.onClose();
          }}
        >
          <Label>Ofertas Shell Select</Label>
          <Image src={chevron} color={theme.color.orange} />
        </MyLine>
        <MyLine
          onClick={() => {
            props.history.push("/beneficios-app-shell-box");
            props.onClose();
          }}
        >
          <Label>Benefícios App Shell Box</Label>
          <Image src={chevron} color={theme.color.orange} />
        </MyLine>
        {/* <MyLink
          href="https://www.acelerecomgp.com.br?utm_source=promoshell&utm_medium=media&utm_campaign=promoshell_aceleregp&utm_id=PromoShell"
          onClick={() => {
            props.onClose();
          }}
        >
          <Label color={(props) => props.theme.color.yellow}>Promo Acelere com GP</Label>
          <IconArrow />
        </ MyLink> */}
      </MyList>
    </ModalFullScreen>
  );
};

export default withRouter(Menu);

const MyList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`;

const MyLine = styled.li`
  height: 60px;
  border-bottom: 1px solid ${(props) => props.theme.color.mediumGray};
  padding: 0 1.2em;
  display: flex;
  align-items: center;
  justify-content: space-between;
  & > svg {
    path {
      stroke: ${props => props.theme.color.orange};
    }
  }
`;

const Label = styled(Box)`
  font-weight: 500;
  font-size: 20px;
  line-height: 14px;
  color: ${(props) => props.color ? props.color : props.theme.color.gray};
`;

const MyLink = styled.a`
  height: 4.5rem;

  padding: 0 1.2em;
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-decoration: none;
  background: url(${IconBandeiraMobile});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  font-family: ${props => props.theme.Shell.Bold};
  
  ${Label} {
    margin-bottom: 4px;
  }

  & > svg {
    path {
      stroke: ${props => props.theme.color.white} !important;
    }
  } 
`;