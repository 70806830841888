import React from "react";
import styled from "styled-components";
import { Link, withRouter } from "react-router-dom";
import { Box } from "@chakra-ui/core";
import { getSlug } from "utils/helper";
import useWindowSize from "hooks/useWIndowSize";
import { useRouter } from "hooks/useRouter";

//Assets
import IconBomba from "assets/images/anoTodo/icons/bomba.svg";
import IconInterrogacoa from "assets/images/anoTodo/icons/interrogacao.svg";
import IconPresente from "assets/images/anoTodo/icons/presente.svg";

const ShellboxBottomMenu = () => {
  const slug = getSlug().url;
  const { width } = useWindowSize();
  const router = useRouter();

  function handleAnoTodoMobile() {
    const isAnoTodo = window.location.pathname.includes("junte-troque");

    if (width <= 768 && isAnoTodo) {
      return "block";
    } else {
      return "none";
    }
  }

  return (
    <Box
      w={"100%"}
      pos={"fixed"}
      bottom={"0"}
      zIndex={100}
      p={"8px 0"}
      bg={"#EAEAEA"}
      boxShadow={"0px -5px 10px rgba(0, 0, 0, 0.15)"}
      boxSizing={"border-box"}
      h={"68px"}
      display={handleAnoTodoMobile()}
    >
      <ShellboxNavigation>
        <NavLink
          to={() => {
            if (
              localStorage.getItem("query_shell") !== "null" &&
              localStorage.getItem("query_shell")?.length > 1
            ) {
              return `${slug}/todos-produtos?shellbox=${localStorage.getItem(
                "query_shell"
              )}&token=${localStorage.getItem("external_token")}`;
            } else {
              return `${slug}/todos-produtos`;
            }
          }}
        >
          <img src={IconPresente} alt="" />
          todos os produtos
        </NavLink>
        <NavLink
          to={() => {
            if (
              localStorage.getItem("query_shell") !== "null" &&
              localStorage.getItem("query_shell")?.length > 1
            ) {
              return `${slug}/como-funciona?shellbox=${localStorage.getItem(
                "query_shell"
              )}&token=${localStorage.getItem("external_token")}`;
            } else {
              return `${slug}/como-funciona`;
            }
          }}
        >
          <img src={IconInterrogacoa} alt="" />
          como funciona
        </NavLink>
        <NavLink
          to={() => {
            if (
              localStorage.getItem("query_shell") !== "null" &&
              localStorage.getItem("query_shell")?.length > 1
            ) {
              return `${slug}/postos-participantes?shellbox=${localStorage.getItem(
                "query_shell"
              )}&token=${localStorage.getItem("external_token")}`;
            } else {
              return `${slug}/postos-participantes`;
            }
          }}
        >
          <img src={IconBomba} alt="" />
          postos e lojas <br /> participantes
        </NavLink>
      </ShellboxNavigation>
    </Box>
  );
};
export default withRouter(ShellboxBottomMenu);

const ShellboxNavigation = styled.nav`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 90%;
  max-width: 20rem;
  margin: 0 auto;

  box-sizing: border-box;
`;

const NavLink = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  width: 6rem;
  height: 100%;
  font-size: 0.438rem;
  line-height: 120%;
  color: ${(props) => props.theme.color.text};
  text-decoration: none;
  text-transform: uppercase;

  box-sizing: border-box;
  margin: 0 auto;

  img {
    margin-bottom: 8px;
    width: 100%;
    height: 24px;
  }

  &:last-child {
    /* width: 65px; */
    img {
      margin-bottom: 4px;
    }
  }
`;
