import React, { useEffect, useState } from "react";
import styled from "styled-components";

type VideoProps = {
  url: string;
}

export function VideoComponent({ url }: VideoProps) {
  const [embedUrl, setEmbedUrl] = useState("");

  useEffect(() => {
    if (url.includes("/embed")) {
      setEmbedUrl(url.split("embed/")[1])
    } else if (url.includes(".be/")) {
      setEmbedUrl(url.split(".be/")[1])
    } else {
      setEmbedUrl(url.split("com/")[1])
    }
  }, [url])

  return (
    <Video
      src={`https://www.youtube.com/embed/${embedUrl}`}
      title="YouTube video player"
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
    ></Video>
  )
}

const Video = styled.iframe`
  width: 90%;
  max-width: 25rem;
  height: 10rem;

  @media (min-width: ${props => props.theme.queries.ix}) {
    height: 13rem;
  }
  @media (min-width: ${props => props.theme.queries.lg}) {
    max-width: 65rem;
    height: 26rem;
  }
  @media (min-width: ${props => props.theme.queries.desk}) {
    max-width: 65rem;
    height: 32rem;
  }
`;