import React from "react";
import {
  Button,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerOverlay,
  useDisclosure,
  Image,
  Box,
} from "@chakra-ui/core";
import styled from "styled-components";
import menuHamburguerIcon from "assets/icons/hamburguerPromoGpSp2023.svg";
import closeYellow from "assets/icons/closeYellow.svg";
import { PromoHeaderTitle } from "../styles";
import { theme } from "theme";
import MenuHamburguerLinks from "../MenuHamburguerLinks";
import { useStore } from "configureStore";

const MenuHamburguerMobile = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = React.useRef();
  const isAuth = useStore((state) => state.auth.isAuthenticated);

  return (
    <>
      <Button
        ref={btnRef}
        background="transparent"
        border="0"
        onClick={onOpen}
      >
        <Image src={menuHamburguerIcon} width="1.5rem" height="1.25rem" />
      </Button>
      <Drawer
        isOpen={isOpen}
        placement="right"
        onClose={onClose}
        finalFocusRef={btnRef}
        size="full"
      >
        <DrawerOverlay />
        <DrawerContent marginTop="2.89rem">
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            width="100%"
            backgroundColor={theme.color.red}
            padding="1rem"
          >
            <ContainerTitle>
              <PromoHeaderTitle>
                grande prêmio de são paulo 2023
              </PromoHeaderTitle>
            </ContainerTitle>
            <Button backgroundColor="transparent" width="2.5rem" border={0} onClick={onClose}>
              <Image src={closeYellow} width="1.5rem" height="1.5rem" />
            </Button>
          </Box>
          <DrawerBody padding={0}>
            <ContainerLinks
              display="flex"
              flexDirection="column"
              width="100%"
              textDecoration="none"
            >
              <MenuHamburguerLinks label="Como participar" path="/gp-sp-2023" />
              {isAuth && (
                <MenuHamburguerLinks
                  label="Meus Números da Sorte"
                  path="/gp-sp-2023/numeros-da-sorte"
                />
              )}
              <MenuHamburguerLinks
                label="Resgate de Ingressos"
                path="/gp-sp-2023/resgate-de-ingressos"
              />
              <MenuHamburguerLinks
                label="Ganhadores"
                path="/gp-sp-2023/ganhadores"
              />
              <MenuHamburguerLinks
                label="Postos Participantes"
                path="/gp-sp-2023/postos-participantes"
              />
            </ContainerLinks>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
};
export default MenuHamburguerMobile;

const ContainerLinks = styled(Box)`
  a {
    text-decoration: none;
  }
`;

const ContainerTitle = styled(Box)`
  width: 11.625rem;

  @media(min-width: ${theme.queries.medium}) {
    width: 90%;
  }
`;
