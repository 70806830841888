import React, { useCallback, useEffect, useState } from "react";
import { GetUrlShop } from "services/shell";
import styled from "styled-components";
import { useActions, useStore } from "configureStore";
import { Box, Spinner } from "@chakra-ui/core";
import { ProductsProps } from "models/anoTodo";
import { useSSO } from "hooks/useSSO";
import { auth as useAuth } from 'hooks/auth'
import { get } from "lodash";
import Cookies from 'js-cookie'

let count = 0;
let calledLoad = false;
const cookieTokenName = "shell-sso-token";
const cookieURLName = "shell-sso-redirectURL";

export default function SectionYellowCards() {
  const [gridLines, setGridLines] = useState<number>();
  const [cardList, setCardList] = useState<ProductsProps[]>();
  const [session, setSession] = useState(null);
  const productsFeatured = useStore((state) => state.anoTodo.featuredProducts);
  const isLoading = useStore((state) => state.anoTodo.isProductLoading);
  const buttonClickCount = useStore((state) => state.anoTodo.buttonClickCount);
  const setButtonClickCount = useActions((action) => action.anoTodo.setButtonClickCount);
  const setActive = useActions((action) => action.loading.setActive)
  const sessionStorageSSOKey = "productSSO"
  const formElementId = "formBoxYellow"
  const { auth, cachedAuth } = useAuth()
  const isAuth =
    (!!get(auth, "token", false) || !!get(cachedAuth, "token", false)) &&
    !window.location.pathname.includes("login") &&
    !window.location.pathname.includes("register") &&
    !window.location.pathname.includes("resetPassword");
  const forceUpdate = React.useReducer(() => ({}), {})[1] as () => void;
  const isShellbox = window.location.search.includes("shellbox")

  useEffect(() => {
    setCardList(productsFeatured);
  }, [productsFeatured])

  useEffect(() => {
    setGridLines(Math.ceil(cardList?.length));
  }, [cardList])

  useEffect(() => {
    async function load() {
      const cookieToken = Cookies.get(cookieTokenName);
      const cookieURL = Cookies.get(cookieURLName);

      if (
        cookieToken !== "undefined" &&
        cookieToken !== "" &&
        Boolean(!!cookieToken) &&
        cookieURL !== "undefined" &&
        cookieURL !== "" &&
        Boolean(!!cookieURL)
      ) {
        setSession({
          token: cookieToken,
          redirectURL: cookieURL,
        });
      } else {
        while (count < 3) {
          try {
            if (session) {
              count = 3;
              break;
            }
            const res = await GetUrlShop();
            setSession(res?.data?.data);
            count = 3;

            Cookies.set(cookieTokenName, res?.data?.data?.token, {
              expires: res.data.data.expiresIn / 60 / 1440,
            });
            Cookies.set(cookieURLName, res?.data?.data?.redirectURL, {
              expires: res.data.data.expiresIn / 60 / 1440,
            });
          } catch (error) {
            count++;
          }
          await new Promise((resolve) => setTimeout(resolve, 1000));
        }
      }
    }

    if (
      isAuth &&
      !isLoading &&
      (cardList?.length > 0 || productsFeatured?.length > 0)
    ) {
      calledLoad = true;
      load();
    }

    if (!calledLoad) {
      forceUpdate();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isAuth,
    isShellbox,
    isLoading,
    cardList,
    productsFeatured,
  ]);

  const getActionForm = useCallback(
    (productId: any) => {
      return `https://${session?.redirectURL || "shelljunte-troque.shop"
        }?acesso=1&webview=1&produto=${productId}`;
    },
    [session]
  );

  function formSubmitting() {
    setButtonClickCount(0)
    setActive(true)
  }

  return (
    <Container gridLines={gridLines}>
      {cardList?.map((card, index) => (
        <CardsHighlight
          index={index}
          key={card?.name}
          bgImg={card?.feature_img}
        >
          {isLoading ? (
            <Box
              w={"100%"}
              h={"100%"}
              d={"flex"}
              justifyContent={"center"}
              alignItems={"center"}
            >
              <Spinner
                thickness="4px"
                speed="0.65s"
                emptyColor="gray.200"
                color="yellow.500"
                size="xl"
              />
            </Box>
          ) : (
            <>
              {/* SSO */}
              <form
                id={`prdHighligh-${card?.productId}`}
                action={`${getActionForm(card?.productId)}`}
                onSubmit={formSubmitting}
                style={{ display: "none" }}
                method="post"
                target="_self"
              >
                <input
                  type="hidden"
                  name="token"
                  id="token"
                  value={`Bearer ${session?.token}`}
                />
                <input
                  type="hidden"
                  name="webview"
                  id="webview"
                  value={1}
                />
                <input type="hidden" name="acesso" value={1}></input>
                <input
                  type="hidden"
                  name="produto"
                  id="produto"
                  value={card.productId || ""}
                />
              </form>
              <h1>
                {card?.name?.split(" ")[0]}<br /> {/* Pega somente o primeiro elemento */}
                {card?.name?.split(" ").map((string, idx) => {
                  if (idx > 0) { return string + " " /* Itera os elementos restantes */ }
                })}
              </h1>
              <BoxPrices>
                <strong>
                  {card?.shellCost} {card?.shellCost === 1 ? "concha" : "conchas"} <span className="red">+ </span>{card?.priceShells}{" ou "}
                  <strong className="red">{card?.price}</strong>
                </strong>
                {buttonClickCount < 1 ? (
                  <ButtonQuero
                    className={'disabled'}
                  >
                    <Spinner />
                  </ButtonQuero>
                ) : (
                  <ButtonQuero
                    id={`product-${card?.productId}`}
                    // onClick={() => {
                    //   if (buttonClickCount > 0) {
                    //     handleRedeemSelectedProduct(card?.productId, formElementId);
                    //   }
                    // }}
                    onClick={() => setButtonClickCount(0)}
                    type="submit"
                  >
                    quero!
                  </ButtonQuero>
                )}
              </BoxPrices>
            </>
          )}
        </CardsHighlight>
      ))}

    </Container>
  )
}

const Container = styled.section`
  display: grid;
  grid-template-columns: repeat(1, auto);
  grid-template-rows: repeat(${props => props.gridLines}, 168px);
  grid-gap: 20px;
  
  width: 100%;
  margin-bottom: 1.25rem;
  
  @media (min-width: ${props => props.theme.queries.lg}) {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(${props => Math.ceil(props.gridLines / 2)}, 305px);
  }
`;

const CardsHighlight = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  background-image: url(${props => props.bgImg});
  background-position: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: ${props => props.theme.color.yellow};

  width: 100%;
  height: 100%;
  max-height: 168px;
  
  margin: 0 auto;
  margin-bottom: 1.25rem;
  border-radius: 2px;
  box-shadow: 0px 2px 4px rgba(64, 64, 64, 0.15);
  position: relative;
  
  h1 {
    color: #fff;
    font-family: ${props => props.theme.Shell.Bold};
    font-size: 1.25rem;
    line-height: 110%;
    text-transform: uppercase;
    margin-top: 2.75rem;
    margin-left: 1.125rem;
  }
  
  @media (min-width: ${props => props.theme.queries.small}) {
    max-width: 25rem;
  } 
  @media (min-width: ${props => props.theme.queries.lg}) {
    max-width: 32rem;
    max-height: 19.063rem;

    h1 {
      font-size: 2.188rem;
      margin-top: 5.5rem;
      margin-left: 2.375rem;
    }
  }
`;

const BoxPrices = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  background-color: rgba(250, 229, 157, 0.85);
  width: 100%;
  height: 40px;
  
  padding: 5px;
  box-sizing: border-box;
  position: absolute;
  bottom: 0;
  
  strong {
    font-family: ${props => props.theme.Shell.Bold};
    width: 164px;
    font-size: 0.75rem;
    line-height: 120%;
    text-align: center;
    /* margin-left: 8px; */
    
    color: ${props => props.theme.color.darkGray};

    span {
      font-family: ${props => props.theme.Shell.Medium};
      &.red {
        color: ${props => props.theme.color.red};
      }
    }

    &.red {
      color: ${props => props.theme.color.red};
      margin: unset;
    }
  }

  @media (min-width: ${props => props.theme.queries.lg}) {
    height: 56px;

    strong {
      width: 50%;
      font-size: ${props => props.theme.fontSize.text};
      line-height: 130%;

      margin-left: 1rem;
    }
  }
`;

export const ButtonQuero = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;

  font-family: ${props => props.theme.Shell.Bold};
  font-size: 0.688rem;
  line-height: 100%;
  text-transform: uppercase;

  background-color: ${props => props.theme.color.shellPurple};
  color: #fff;

  border: none;
  border-radius: 2px;
  width: 110px;
  height: 30px;
  cursor: pointer;
  transition: 0.2s;
  box-sizing: border-box;

  &:hover, &:focus {
    background-color: ${props => props.theme.color.red};
    color: ${props => props.theme.color.white};
  }

  &.disabled {
    filter: opacity(0.85);
    background-color: ${props => props.theme.color.shellPurple};
    cursor: default;
  }

  @media (min-width: ${props => props.theme.queries.lg}) {
    width: 218px;
    height: 40px;
    font-size: ${props => props.theme.fontSize.text};
  }
`;