import styled from "styled-components";

export const NumerosDaSorteContainer = styled.section`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 2.5rem;
  overflow-x: hidden;
  animation: fadeIn forwards 200ms;

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`;

export const NumerosDaSorteTabWrapper = styled.div`
  display: flex;
  max-width: 827px;
  gap: 8px;
  margin-top: 1.25rem;
  margin-left: 5vw;
  overflow-x: scroll;

  &::-webkit-scrollbar {
    display: none;
  }

  @media (min-width: 640px) {
    margin: 1.25rem auto 0;
  }
  @media (min-width: ${(props) => props.theme.queries.lg}) {
    margin: 2.75rem auto 0;
    width: 100%;
  }
`;

export const NumerosDaSorteTab = styled.button`
  min-width: 9.5rem;
  display: flex;
  flex-direction: column;
  background: transparent;
  border: none;
  border-bottom: 1px solid ${(props) => props.theme.color.red};
  color: ${(props) => props.theme.color.red};
  cursor: pointer;
  padding: 0;

  font-family: ${(props) => props.theme.Shell.Bold};
  font-size: 0.75rem;
  line-height: 1.2;

  &:not(.disabled)[aria-selected="true"] {
    border-color: ${(props) => props.theme.color.text};
    color: ${(props) => props.theme.color.text};
  }

  &.disabled {
    border-color: ${(props) => props.theme.color.gray600};
    color: ${(props) => props.theme.color.gray600};
  }

  span {
    text-align: center;
    margin: 0 auto;
    &:last-child {
      padding-bottom: 4px;
    }
  }

  @media (min-width: ${(props) => props.theme.queries.lg}) {
    font-size: 1rem;
    min-width: 12.5rem;
  }
`;

export const NumerosDaSorteContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  width: 90%;
  max-width: 540px;

  gap: 1.25rem;
  margin: 0 auto 2rem;

  p {
    font-family: ${(props) => props.theme.Shell.Book};
    font-size: 1rem;
    line-height: 1.3;
    color: ${(props) => props.theme.color.text};
    margin: 0;
  }

  strong {
    width: fit-content;
    font-family: ${(props) => props.theme.Shell.Bold};
  }

  @media (min-width: ${(props) => props.theme.queries.desk}) {
    max-width: 827px;
  }
`;
export const Ticket = styled.article`
  display: flex;
  flex-direction: column;
  max-width: 622px;

  border: 1px solid ${(props) => props.theme.color.lightGray200};
  border-collapse: collapse;

  @media (min-width: ${(props) => props.theme.queries.lg}) {
    flex-direction: row;
    margin: 0 auto;
  }
`;

export const TicketHeader = styled.header`
  display: flex;
  width: 100%;

  @media (min-width: ${(props) => props.theme.queries.lg}) {
    min-width: 170px;
  }
`;

export const TicketNumber = styled.div<{ ticketType: "date" | "code" }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 154px;

  padding: 10px 24px;
  background: ${(props) => props.theme.color.lightGray};

  border-right: 1px solid ${(props) => props.theme.color.lightGray200};
  border-bottom: ${(props) =>
    props.ticketType === "date" &&
    `1px solid ${(props) => props.theme.color.lightGray200}`};
  border-collapse: collapse;

  span {
    font-family: ${(props) => props.theme.Shell.Heavy};
    font-size: 0.875rem;
    font-weight: 600;
    line-height: 1.5;
    color: ${(props) => props.theme.color.gray600};
  }
  strong {
    font-family: ${(props) => props.theme.Shell.Heavy};
    font-size: 1.25rem;
    font-weight: 600;
    line-height: 1.5;
    color: ${(props) => props.theme.color.text};
  }

  @media (min-width: ${(props) => props.theme.queries.desk}) {
    border-bottom: none;
    max-width: 170px;
  }
`;
export const TicketBody = styled.div`
  display: flex;
  width: 100%;
  max-width: 190px;
  gap: 1.875rem;
  padding: 1rem 1.25rem;

  @media (min-width: ${(props) => props.theme.queries.lg}) {
    /* min-width: 452px; */
  }
`;

export const TicketDate = styled.div<{ ticketType: "date" | "code" }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;

  padding: 0.625rem 1rem;
  border-bottom: ${(props) =>
    props.ticketType === "date" &&
    `1px solid ${(props) => props.theme.color.lightGray200}`};

  strong {
    font-family: ${(props) => props.theme.Shell.Heavy};
    font-size: 0.875rem;
    font-weight: 600;
    line-height: 1.5rem;
    color: ${(props) => props.theme.color.text};
  }

  span {
    font-family: ${(props) => props.theme.Shell.Book};
    font-size: 0.913rem;
    line-height: 1.5;
  }

  @media (min-width: ${(props) => props.theme.queries.lg}) {
    span {
      font-size: 1rem;
    }
  }

  @media (min-width: ${(props) => props.theme.queries.desk}) {
    border-bottom: none;
    padding: 0;

    strong {
      white-space: nowrap;
    }
    span {
      white-space: nowrap;
    }
  }
`;

export const TicketLocation = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  width: 100%;

  strong {
    font-family: ${(props) => props.theme.Shell.Heavy};
    font-size: 0.875rem;
    font-weight: 600;
    line-height: 1.25rem;
    color: ${(props) => props.theme.color.text};
    white-space: nowrap;
  }

  span {
    font-family: ${(props) => props.theme.Shell.Book};
    font-size: 1rem;
    line-height: 1.25rem;
  }
`;
