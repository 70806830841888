import { action, Action } from "easy-peasy";

export interface LoadingModel {
  isActive: boolean;
  setActive: Action<LoadingModel, boolean>;
}

const loading: LoadingModel = {
  isActive: false,
  setActive: action((state: { isActive: boolean }, payload: any) => {
    state.isActive = payload;
  }),
};

export default loading;
