import React from "react";
import styled from "styled-components";

// Assets
import IconShell from "assets/icons/shell-icon.svg";

export default function SectionAbastecaDiesel() {
	return (
		<SectionContainer>
			<Title>Abasteça com diesel no app Shell Box</Title>
			<p>E tenha benefícios</p>
			<Content>
				<CardDiesel>
					<h3>Junte conchas com diesel</h3>
					<p>
						A cada R$ 60,00 em abastecimentos com Diesel Evolux,
						Diesel S-10 Aditivado, Diesel S-500
						Aditivado com o app Shell Box, em qualquer Posto
						de Combustível Shell participantes, em um único
						cupom fiscal, você ganha concha.
					</p>
					<strong>
						<img src={IconShell} alt="" />
						1 concha
					</strong>
				</CardDiesel>
				<CardDiesel>
					<h3>Ganhe conchas extras</h3>
					<p>
						A partir de R$120 com Mastercard e
						Shell Evolux, você ganha conchas extras
						para juntar e trocar por produtos
						exclusivos na Junte & Troque.
					</p>
					<strong>
						<img src={IconShell} alt="" />
						1 concha extra
					</strong>
				</CardDiesel>
			</Content>
		</SectionContainer>
	)
}

const SectionContainer = styled.section`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	width: 100%;
	background-color: #F6DB64;
	padding: 2rem 0;
	box-sizing: border-box;

	p {
    font-family: ${props => props.theme.helvetica};
    font-size: 1rem;
    line-height: 1.25rem;
    font-weight: 500;
    margin: 0 auto 1.875rem;
    color: ${props => props.theme.color.shellBlack};
    text-align: center;
    width: 90%;
  }
`;

const Title = styled.h2`
  font-size: 1.563rem;
  line-height: 2.125rem;
  font-family: ${props => props.theme.Shell.Bold};
  color: ${props => props.theme.color.shellPurple};
  margin: 0 auto 0.75rem;
  text-align: center;
	width: 90%;
  
  @media (min-width: ${props => props.theme.queries.small}) {
    line-height: 1.75rem;
    margin: 0 auto 1rem;
    text-align: center;
  }
`;

const Content = styled.div`
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: center;
	
	width: 80%;
	margin: 0 auto;
	max-width: 65rem;
	
	@media (min-width: ${props => props.theme.queries.medium}) {
		flex-direction: row;
	}
`;

const CardDiesel = styled.div`
	display: grid;
	grid-template-columns: auto;
	grid-template-rows: auto 1fr auto;
	width: 100%;
	
	background-color: #FFED9F;
	box-shadow: 0px 2px 4px rgba(64, 64, 64, 0.15);
	border-radius: 4px;
	padding: 1.875rem;
	box-sizing: border-box;
	
	h3 {
		font-size: 1.125rem;
		line-height: 1.5rem;
		color: ${props => props.theme.color.text};
		text-align: center;
		font-family: ${props => props.theme.Shell.Bold};
		margin: 0;
		margin-bottom: 1.5rem;
	}
	p {
		font-family: ${props => props.theme.helvetica};
		font-size: 0.875rem;
		line-height: 140%;
		width: unset;
		text-align: left;
		color: ${props => props.theme.color.darkGray};
		margin: 0;
		
		@media (min-width: ${props => props.theme.queries.medium}) {
			font-size: 1rem;
		}
	}
	strong {
		font-family: ${props => props.theme.Shell.Bold};
		color: ${props => props.theme.color.red};
		text-align: center;

		& > img {
			background-color: ${props => props.theme.color.yellow};
			padding: 6px;
			border-radius: 50%;
			width: 1rem;
			height: 1rem;
			margin-right: 1rem;
			margin-bottom: -0.5rem;
			box-sizing: unset;
		}
	}
		
	&:last-child {
		margin-top: 1.25rem;
	}
	@media (min-width: ${props => props.theme.queries.medium}) {
		height: 17rem;

		&:last-child {
			margin-left: 1.25rem;
			margin-top: 0;
		}
	}
`;