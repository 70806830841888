import React from "react";
import { Link } from "react-router-dom";
import styled from 'styled-components';
import { formatToBRL } from "brazilian-values";

//assets
import alertIcon from "assets/icons/alert-icon.svg";

interface IPromoCard {
  locale: any;
  image: string;
  title: string;
  description: string;
  amount: number;
  isClosed?: boolean;
}

const PromoCard = ({
  locale,
  image,
  title,
  description,
  amount,
  isClosed = false,
}:IPromoCard) => {
  return (
    <CustomLink>
      <Container>
        <BoxImage>
          {
            locale?.find(item => item.id === 1) ? (
              <ImageBadge>{locale?.find(item => item.id === 1)?.nome}</ImageBadge>
            ) : null
          } 
          <img src={image} alt="Promo hits" />
          {
            isClosed ? <ContainerShadow /> : null
          }
          {
            isClosed ? (
              <AlertClosedPromo>
                <img src={alertIcon} alt="alerta" />
                <span>promoção encerrada</span>
              </AlertClosedPromo>
            ) : null
          }
        </BoxImage>
        <BoxContent>
          <div>
            <Title>{title}</Title>
            <Description>{description}</Description>
          </div>
          <BoxAmount>
            <BoxAmountLabel>
              <span>a partir de:</span>
              <div>{amount}</div>
            </BoxAmountLabel>
            <BoxAmountValidDate>*Consulte a validade na loja</BoxAmountValidDate>
          </BoxAmount>
        </BoxContent>
      </Container>
    </CustomLink>
  )
}

export default PromoCard;

const CustomLink = styled.div`
  text-decoration: none;
  color: ${props => props.theme.color.gray};
  background-color: #fff;
`;

const Container = styled.div`
  background-color: #fff;
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const Title = styled.div`
  color: ${props => props.theme.color.gray};
  font-weight: 500;
  font-size: 19px;
  line-height: 14px;
  text-transform: uppercase;
  font-family: "Futura Bold";

  span{
    font-weight: normal !important;
  }

  @media(max-width: ${props => props.theme.queries.small}){
    line-height: 22px;
  }
`;

const Description = styled.p`
  font-family: "Futura LT Regular";
  color: ${props => props.theme.color.gray};
  font-weight: 500;
  font-style: normal;
  font-size: 17px;
  line-height: 130%;

  @media(max-width: ${props => props.theme.queries.small}){
    margin: 13px 0px;
  }
`;

//BoxImage
const BoxImage = styled.div`
  position: relative;
  max-height: 394px;

  img {
    max-height: 394px;
    height: 100%;
    width: 100%;
  }

  @media(max-width: ${props => props.theme.queries.small}){
    max-height: 198px;
    img {
      max-height: 198px;
    }
  }
`;

const ImageBadge = styled.div`
  z-index:1;
  position: absolute;
  top: 0;
  left:0;
  background-color: ${props => props.theme.color.yellow};
  color: ${props => props.theme.color.gray};

  width: 130px;
  height: 29px;
  display: flex;
  align-items: center;
  justify-content: center;

  text-transform: uppercase;
  font-weight: bold;
  font-size: 12px;
  line-height: 14px;
`;

//BoxContent
const BoxContent = styled.div`
  height: 100%;
  padding: 20px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media(max-width: ${props => props.theme.queries.small}){
    padding: 16px 11px;
  }
`;

//BoxAmount

const BoxAmount = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  width: -webkit-fill-available;

  @media(max-width: ${props => props.theme.queries.small}){
    flex-direction: column;
    align-items: flex-start;
  }
`;

const BoxAmountLabel = styled.span`
  display: flex;
  flex-direction: column;

  span {
    font-family: "Futura Bold";
    text-transform: uppercase;
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 14px;
  }

  div {
    margin-top: 10px;
    font-family: "Futura LT Bold";
    color: ${props => props.theme.color.white};
    padding: 8px;
    background-color: ${props => props.theme.color.red};
    text-transform: uppercase;
    font-size: 23px;
    line-height: 14px;
    width: fit-content;
  }

  @media(max-width: ${props => props.theme.queries.small}){
    div {
      line-height: 1.2;
    }
  }
`;

const BoxAmountValidDate = styled.span`
  font-size: 12px;
  line-height: 14px;
  font-family: "Futura LT Regular";
  color: ${props => props.theme.color.gray};

  @media(max-width: ${props => props.theme.queries.small}){
    margin-top: .5rem;
  }
`;

//Container Shadow = 

const ContainerShadow = styled.div`
  position: absolute;
  top: 0;
  left: 0;

  height: 100%;
  width: 100%;
  background: ${props => props.theme.color.gray};;
  opacity: 0.6;
`;

const AlertClosedPromo = styled.div`
  z-index: 1;
  position: absolute;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #000;
  color: #fff;

  width: 100%;
  height: 39px;

  span {
    font-family: "Futura Bold";
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    text-transform: uppercase;
  }

  img {
    width: 20px;
    height: 19px;
    margin-right: 8px;
  }
`;