import React from "react";
import {
  Box,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Drawer,
  DrawerContent,
  DrawerHeader,
  DrawerBody,
} from "@chakra-ui/core";
import styled from "styled-components";
import useWindowSize from "hooks/useWIndowSize";

import Register from "modules/common/Pages/register";

const UpdatePersonalDataModal = (props) => {
  const { width } = useWindowSize();

  return (
    <>
      <MyModal
        blockScrollOnMount={false}
        isOpen={width <= 440 ? false : props.isOpen}
        size={"690px"}
        onClose={props.onClose}
        style={{ border: `10px solid orange` }}
        // scrollBehavior="outside"
      >
        <ModalOverlay />
        <Content id="myRegister">
          <Header>Meu Cadastro</Header>
          <CustomCloseBtn />
          <ModalBody id="modal-update">
            <Register />
          </ModalBody>
        </Content>
      </MyModal>
      <Drawer
        isFullHeight={true}
        onClose={props.onClose}
        isOpen={width <= 440 ? props.isOpen : false}
        size={"full"}
      >
        <DrawerContent>
          <CustomDrawerHeader>
            <Box>Meu Cadastro</Box>
            <CustomCloseBtn />
          </CustomDrawerHeader>
          <CustomDrawerBody id="drawer-update">
            <Register />
          </CustomDrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
};

export default UpdatePersonalDataModal;

const MyModal = styled(Modal)`
  max-width: 680px !important;
`;

const Header = styled(ModalHeader)`
  background-color: ${(props) => props.theme.color.orange};
  font-weight: 400 !important;
  font-size: 1.375rem !important;
  color: ${(props) => props.theme.color.white};
`;

const CustomCloseBtn = styled(ModalCloseButton)`
  border: none;
  background-color: ${(props) => props.theme.color.orange};
  top: 13px !important;

  :hover{
    background-color: #9f8e78 !important;
  }
  svg {
    cursor: pointer;
    width: 22px;
    height: 22px;
    path {
      fill: ${(props) => props.theme.color.white};
    }
  }
`;

const Content = styled(ModalContent)`
  border: 10px solid ${(props) => props.theme.color.orange} !important;
  #modal-update {
    overflow-y: scroll !important;
  }
`;

//HEADER

const CustomDrawerHeader = styled(DrawerHeader)`
  background-color: ${(props) => props.theme.color.orange};
  font-weight: 400 !important;
  font-size: 1.375rem !important;
  color: ${(props) => props.theme.color.white};
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const CustomDrawerBody = styled(DrawerBody)`
  overflow-y: scroll;
`;
