import auth, { AuthModel } from "./auth";
import home, { HomeModel } from "./home";
import profile, { ProfileModel } from "./profile";
import user, { UserModel } from "./user";
import order, { OrderModel } from "./order";
import shell, { ShellModel } from "./shell";
import rule, { RuleModel } from "./rule";
import loading, { LoadingModel } from "./loading";
import hub, { HubModel } from "./hub";
import { shellboxBenefits, ShellboxBenefitsModel } from "./shellboxBenefits";
import anoTodo, { AnoTodoModel } from "./anoTodo";
import grandeGpSp, { GrandeGpModel } from "./grandeGpSp";

import { action } from "easy-peasy";

export type StoreModel = {
  auth: AuthModel;
  home: HomeModel;
  profile: ProfileModel;
  user: UserModel;
  order: OrderModel;
  shell: ShellModel;
  rule: RuleModel;
  loading: LoadingModel;
  setState: any;
  hub: HubModel;
  shellboxBenefits: ShellboxBenefitsModel;
  anoTodo: AnoTodoModel;
  grandeGpSp: GrandeGpModel
};

const model: StoreModel = {
  auth,
  home,
  profile,
  user,
  order,
  shell,
  rule,
  loading,
  setState: action((state, payload) => payload),
  hub,
  shellboxBenefits,
  anoTodo,
  grandeGpSp
};

export default model;
