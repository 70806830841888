import { NavLink } from 'react-router-dom'
import styled, { css } from 'styled-components'

export const PromoHeaderContainer = styled.header`
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  justify-content: center;
  width: 100%;
  
  background: ${props => props.theme.color.red};
  
  @media (min-width:${props => props.theme.queries.small} ) {
    margin-top: auto;
  }
  `

export const PromoHeaderTitle = styled.strong`
  width: 186px;
  font-size: 1rem;
  
  font-family: ${props => props.theme.FuturaFontBold};
  font-weight: 700;
  text-transform: uppercase;
  color: ${props => props.theme.color.yellow};
  padding: 1rem 0;
  
  @media (min-width: ${props => props.theme.queries.medium}) {
    width: 90%;
    font-size: 1.1875rem;
    line-height: 0.68;
    margin: unset;
    padding: 1rem;
  }

  @media(min-width: ${props => props.theme.queries.desk}) {
    padding: 1rem 0;
  }
`

export const PromoHeaderNavWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0 1rem;
  
  overflow: hidden;
  position: relative;
  
  @media (min-width: ${props => props.theme.queries.medium}) {
    height: 86px;
    max-width: 1240px;
    margin: 0 auto;
    overflow: unset;
  }

  @media (min-width: ${props => props.theme.queries.desk}) {
  }  
`

export const PromoHeaderNav = styled.nav`
  display: flex;
  align-items: center;

  max-width: 90%;
  margin: 0 auto;
  
  ${props => props.$isAuth && css`
    max-width: 100vw;
    margin-left: none;
  `}

  position: relative;
  overflow: scroll;
  z-index: 10;
  
  a {
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    width: 100%;
    min-width: 5.5rem;
    ${props => !props.$isAuth && css`
      min-width: unset;
      max-width: 5rem;
    `}
    height: 2.25rem;
    margin: 0 1rem;

    font-family: ${props => props.theme.FuturaFontBold};
    font-size: 0.75rem;
    line-height: 1;
    text-align: center;
    color: ${props => props.theme.color.white};
    text-decoration: none;    
    white-space: pre-wrap;
    position: relative;

    box-sizing: border-box;

    &::after {
      display: block;
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;

      width: 0%;
      height: 3px;
      transition: width .2s ease-in-out;
    }
    
    &:first-child, &:last-child {
      margin: 0;
    }
    
    &.active,&:hover {
      &::after {
        width: 100%;
        background-color: ${props => props.theme.color.white};
      }      
    }
  }
  // Hiding scrollbar
  &::-webkit-scrollbar {
    display: none;
  }

  @media (min-width: ${props => props.theme.queries.small}) {
    padding: 0 1rem;
    justify-content: space-between;
    width: 100%;
    max-width: ${props => props.$isAuth ? "810px" : "540px"};
    gap: 1.875rem;
    margin-left: unset;
    
    a {
      font-size: 0.812rem;
      width: 100%;
      min-width: unset;
      max-width: fit-content;
      margin: 0;
      white-space: nowrap;     
    }
  }
  
  @media (min-width: ${props => props.theme.queries.desk}) {
    justify-content: flex-end;
    overflow: unset;
    gap: 1.5rem;

    a {
      &::after {
        bottom: -0.75rem;
      }
    }
  } 
`
export const PromoHeaderContent = styled.div`

`
export const PromoHeaderHeading = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;
  max-width: 827px;
  height: 176px;
  margin: 0 auto;
  padding: 1.75rem 1.25rem;
  
  ${props => props.$isPostosPage && css`
    height: auto;
    padding-bottom: 3rem;
  `}
  
  h1 {
    font-size: 1.5625rem;
    line-height: 1.3;
    font-weight: 400;
    font-family: ${props => props.theme.Shell.Book};
    color: ${props => props.theme.color.white};
    /* text-align: center; */
    margin: 0;
    margin-bottom: 1rem;
  }
  
  span {
    font-size: 1rem;
    font-family: ${props => props.theme.Shell.Book};
    color: ${props => props.theme.color.white};
    /* text-align: center; */
  }
  
  @media (min-width: ${props => props.theme.queries.lg}) {
    padding: 2.325rem 0;
    height: 146px;

    span {
      font-size: 1.125rem;
    }
    h1 {
      font-size: 1.875rem;
      line-height: 0.6875rem;
    }
  }
`

export const PromoHeaderWebdoor = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;
  max-width: 1240px;
  margin: 0 auto 1.75rem;
  position: relative;

  img.webdoor {
    width: 100%;
    margin: 0 auto;
    max-width: 375px;
  }
  img.shellIcon {
    width: 40px;
    height: 38px;
    position: absolute;
    left: calc(50% + 125px);
    bottom: 310px;
  }

  @media (min-width: ${props => props.theme.queries.desk}) {
    flex-direction: row;
    height: 576px;
    z-index: 0;
    margin-bottom: 0;
    
    img.webdoor {
      position: absolute;
      width: 1600px;
      max-width: unset;
      left: -20vw;
      top: 0;
    }
    img.shellIcon {
      width: 78px;
      height: 73px;
      left: calc(50% + 528px);
      bottom: 80px;
    }
  }
  @media (min-width: 1440px) {
    img.webdoor {
      left: -15vw;
    }
    img.shellIcon {
      left: calc(50% + 620px - 5vw);
    }
  }
  @media (min-width: 1600px) {
    img.webdoor {
      left: -10vw;
    }
    img.shellIcon {
      left: calc(50% + 700px - 5vw);
    }
  }
`

export const PromoHeaderWebdoorLettering = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* align-items: center; */
  width: 90%;
  max-width: 440px;
  z-index: 1;
 
  margin: 0 auto;
  margin-top: -1.5rem;

  img {
    width: 98px;
    height: 53px;
  }
  h1 {
    font-size: 2.1875rem;
    line-height: 1.3;
    font-family: ${props => props.theme.Shell.Heavy};
    font-weight: 600;
    color: ${props => props.theme.color.yellow};

    margin: 0;
    margin-top: 5px;
  }
  p {
    font-size: 1.125rem;
    font-family: ${props => props.theme.Shell.Medium};
    font-weight: 500;
    line-height: 1.2;
    color: ${props => props.theme.color.white};

    margin: 0;
    margin-top: 0.25rem;
  }

  @media (min-width: ${props => props.theme.queries.desk}) {
    max-width: 762px;
    justify-content: center;
    align-items: flex-start;
    margin: 0;

    img {
      width: 154px;
      height: unset;
    }
    h1 {
      font-size: 3.625rem;
      line-height: 1.2;
      text-align: left;
      margin-top: 1rem;
    }
    p {
      font-size: 1.875rem;
      text-align: left;
      margin-top: 1rem;
      width: 90%;
    }
  }
`

export const PromoHeaderWebdoorButtons = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 1.75rem 1.5rem;
  gap: 12px;

  background: ${props => props.theme.color.darkRed};
`

export const PromoActionButton = styled(NavLink)`
  padding: 0.75rem 1.5rem;
  
  border: 1px solid transparent;
  border-radius: 8px;
  background: ${props => props.theme.color.yellow};
  color: ${props => props.theme.color.text};
  
  font-family: ${props => props.theme.FuturaFontBold};
  font-size: 0.875rem;
  line-height: 1;
  font-weight: 700;
  text-transform: uppercase;
  text-decoration: none;
  text-align: center;
  
  cursor: pointer;
  transition: all .1s ease-in;

  &:hover,&:focus {
    background: ${props => props.theme.color.white};
    color: ${props => props.theme.color.red};
  }
  
  @media (min-width: 425px) {
    font-size: 0.9375rem;
  }
  @media (min-width: ${props => props.theme.queries.desk}) {
    padding: 0.75rem 1.75rem;
    width: 18rem;
    height: 2.5rem;
  }
`

export const PromoActionLoginButton = styled.button`
  padding: 0.75rem 1.5rem;

  border: 1px solid transparent;
  border-radius: 8px;

  font-family: ${props => props.theme.FuturaFontBold};
  font-size: 0.875rem;
  line-height: 1;
  font-weight: 700;
  text-transform: uppercase;
  background: ${props => props.theme.color.white};
  color: ${props => props.theme.color.text};
  
  cursor: pointer;
  transition: all .1s ease-in;
  
  &:hover,&:focus {
    background: ${props => props.theme.color.white};
    color: ${props => props.theme.color.red};
  }

  @media (min-width: 425px) {
    font-size: 0.9375rem;
  }
  @media (min-width: ${props => props.theme.queries.desk}) {
    padding: 0.75rem 1.75rem;
    width: 18rem;
    height: 2.5rem;
  }
`