import styled from "styled-components";
import React, { useEffect, useState } from "react";
import { useStore, useActions } from "configureStore";
import { Box, Spinner, Text } from "@chakra-ui/core";
import ShellboxBannerMenu from "modules/common/ShellboxBannerMenu";

const Rules = (props) => {
  const isLoading = useStore((state) => state.rule.isLoading);
  const rules = useStore((state) => state.rule.rules);
  const getRules = useActions((action) => action.rule.getRules);
  const [adBlockEnabled, setAdBlockEnabled] = useState(false);

  useEffect(() => {
    getRules();
    var testAd = document.createElement("div");
    testAd.innerHTML = "&nbsp;";
    testAd.className = "adsbox";
    document.body.appendChild(testAd);

    window.setTimeout(function () {
      if (testAd.offsetHeight === 0) {
        setAdBlockEnabled(true);
      }
      testAd.remove();
    }, 100);
  }, [getRules]);

  return (
    <>
      <Wrapper>
        {isLoading ? (
          <BoxLoading>
            <Spinner />
            <Text>Carregando regulamento...</Text>
          </BoxLoading>
        ) : (
          <>
            {adBlockEnabled && !rules?.regulamento && (
              <BoxLoading>
                <Text>
                  Favor desabilitar AdBlock para carregar o regulamento
                </Text>
              </BoxLoading>
            )}
            <Content
              dangerouslySetInnerHTML={{ __html: rules?.regulamento }}
            ></Content>
            {window.location.search.includes("shellbox") && (
              <ShellboxBannerMenu />
            )}
          </>
        )}
      </Wrapper>
    </>
  );
};

export default Rules;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 90%;
  margin: 0 auto;

  @media (min-width: ${props => props.theme.queries.desk}) {
    width: 100%;
  }
`;

const BoxLoading = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  p {
    text-align: center;
  }
`;

const Content = styled.div`
  font-family: Arial;
  color: #58595b;
  text-align: justify;

  .p1,
  .p2,
  .p3 {
    text-align: center;
  }

  table {
    width: 100%;
    td {
      align-items: center;
      height: 56px;
      padding: 0 25px;
      background-color: #eaeaea;
      font-style: normal;
      font-weight: normal;
      font-size: 1rem;
      color: #58595b;
      border: 1px solid white;
    }
  }
  ul {
    list-style-type: none;
    list-style-position: inside;
    padding-left: 0px;
    li {
      margin-bottom: 25px;
      display: flex;
      align-items: center;
    }
  }

  a {
    color: #58595b;
    text-decoration: underline;
    transition: 0.3s;
    cursor: pointer;
    &:hover {
      color: #e98624;
    }
  }

  h4:first-child {
    margin-top: 0;
  }
`;
