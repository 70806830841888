import { Box } from "@chakra-ui/core";
import React from "react";
import styled from "styled-components";
import { auth as useAuth } from "hooks/auth";
import { NavigationAnoTodo } from "../components/Navigation/NavigationAnoTodo";
import ForgotPassword from "modules/common/Pages/forgotPassword";

export default function RecuperarSenhaJTAnoTodo() {
  const { auth } = useAuth();
  const isAuthenticated = auth.isAuthenticated;
  const isShellbox = window.location.search.includes("shellbox");

  return (
    <Container isAuth={isAuthenticated} isShellbox={isShellbox}>
      <HeaderWrapper>
        <NavigationAnoTodo />
        <h1>Recuperar senha</h1>
      </HeaderWrapper>
      <Box
        as={"main"}
        display={"flex"}
        flexDir={"column"}
        justifyContent={"center"}
        alignItems={"center"}
        w={"100%"}
        p={"38px 0"}
      >
        <ForgotPassword />
      </Box>
    </Container>
  );
}

const Container = styled(Box)`
  margin-top: ${(props) => (!props.isShellbox ? "40px" : "0")};
  background-color: ${(props) => props.theme.color.lightGray50};

  padding-bottom: ${(props) =>
    props.isAuth || props.isShellbox ? "3rem" : "0"};
  margin-bottom: ${(props) => (props.isShellbox ? "2rem" : "0")};
`;

const HeaderWrapper = styled.header`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  box-sizing: border-box;

  background-color: ${(props) => props.theme.color.shellPurple};
  padding: 8px 0 0.75rem;

  h1 {
    font-family: ${(props) => props.theme.Shell.Book};
    font-size: 1.875rem;
    font-weight: 400;
    color: #fff;
    margin: 0;
    margin-top: 1.5rem;
  }

  @media (min-width: ${(props) => props.theme.queries.lg}) {
    padding: 4px 0 1.75rem;

    h1 {
      font-size: 2.25rem;
      margin-top: 3.75rem;
    }
  }
`;
