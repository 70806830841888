import { useEffect } from "react";
import { useHistory } from "react-router-dom";

declare global {
  interface Window {
    gtag?: (
      key: string,
      trackingId: string,
      config: { page_path: string }
    ) => void;
  }
}

export const useTracking = () => {
  const { listen } = useHistory();

  useEffect(() => {
    const unlisten = listen((location) => {
      if (!window.gtag) return;
      if (!process.env.REACT_APP_GA_MEASUREMENT_ID) {
        return;
      }

      window.gtag("config", process.env.REACT_APP_GA_MEASUREMENT_ID, {
        page_path: location.pathname,
      });
    });

    return unlisten;
  }, [listen]);
};
