import React from "react";
import { HeaderNavLink } from "../HeaderNavLinks";
import { getSlug } from "utils/helper";
import { Box, Text, Image } from "@chakra-ui/core";
import { theme } from "theme";
import chevronRight from "assets/icons/chevronRightRed.svg";
import { MenuHamburguerLinksProps } from "./types";

const MenuHamburguerLinks = ({ path, label }: MenuHamburguerLinksProps) => {
  const slug = getSlug().url;

  return (
    <HeaderNavLink url={`${slug + path}`}>
      <Box
        display="flex"
        width="100%"
        alignItems="center"
        justifyContent="space-between"
        padding="0 1.3rem"
        borderBottom={`1px solid ${theme.color.mediumGray}`}
        fontSize="1.125rem"
        textDecoration="none"
        color={theme.color.gray}
      >
        <Text textDecoration="none">{label}</Text>
        <Image src={chevronRight} />
      </Box>
    </HeaderNavLink>
  );
};
export default MenuHamburguerLinks;
