import React from "react";
import styled from "styled-components";
import { Ticket } from "./Ticket";

export function ParticipationPeriod() {
  return (
    <Container>
      <Content>
        <h1>Confira abaixo os períodos de participação</h1>

        <TicketContent>
          <span>
            A promoção inicia em 01/agosto e os sorteios acontecerão nas datas
            abaixo:
          </span>
          <Ticket
            ticketType="date"
            raffleDate="14/08/2024"
            participationPeriod={{
              start: "01/08",
              end: "10/08/2024",
            }}
          />
          <Ticket
            ticketType="date"
            raffleDate="24/08/2024"
            participationPeriod={{
              start: "11/08",
              end: "20/08/2024",
            }}
          />
          <Ticket
            ticketType="date"
            raffleDate="04/09/2024"
            participationPeriod={{
              start: "21/08",
              end: "30/08/2024",
            }}
          />
          <Ticket
            ticketType="date"
            raffleDate="14/09/2024"
            participationPeriod={{
              start: "31/08",
              end: "09/09/2024",
            }}
          />

          <p>
            Confira seus números da sorte através do seu email cadastrado no
            Shell Box e se você for sorteado, nosso time de atendimento entrará
            em contato para as próximas tratativas. Lembrando que a cada
            sorteio, você concorre a 1 par de ingressos, 1 produto exclusivo
            Shell da coleção Ferrari e/ou 1 cupom de abastecimento de R$200,00
            para usar no aplicativo Shell Box. Boa sorte!
          </p>
        </TicketContent>
      </Content>
    </Container>
  );
}

const Container = styled.section`
  display: flex;
  flex-direction: column;
  width: 100vw;
  background-color: ${(props) => props.theme.color.white};

  padding: 60px 0;
`;

const Content = styled.article`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 90%;
  gap: 32px;

  margin: 0 auto;

  & > h1 {
    font-family: ${(props) => props.theme.Shell.Bold};
    font-size: 1.125rem;
    line-height: 1.375;
    color: ${(props) => props.theme.color.text};
    text-align: center;

    @media (min-width: ${(props) => props.theme.queries.lg}) {
      font-size: 1.5rem;
      margin: 0;
    }
  }
`;

const TicketContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 622px;

  gap: 14px;

  & > span {
    font-family: ${(props) => props.theme.Shell.Book};
    font-size: 1rem;
    line-height: 1;
    color: ${(props) => props.theme.color.text};
    text-align: center;

    @media (min-width: ${(props) => props.theme.queries.lg}) {
      font-size: 1.125rem;
      line-height: 1.3;
    }
  }

  & > p {
    font-family: ${(props) => props.theme.Shell.Book};
    font-size: 1rem;
    line-height: 1.25;
    color: ${(props) => props.theme.color.text};
    text-align: left;

    @media (min-width: ${(props) => props.theme.queries.lg}) {
      font-size: 1.125rem;
      line-height: 1.3;
    }
  }
`;
