import React from "react";
import styled from "styled-components";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
} from "@chakra-ui/core";
import useWindowSize from "hooks/useWIndowSize";

const ModalInfo = (props) => {
  const { width } = useWindowSize();
  return (
    <>
      {width > 768 && (
        <Modal
          onClose={props.onClose}
          isOpen={props.isOpen}
          isCentered
          size={"937px"}
        >
          <CustomOverlay />
          <ContainerContent>
            <CustomClose />
            <ContainerInfo>
              <Title>{props.title}</Title>
              <br />
              <br />
              <Description>{props.description}</Description>
            </ContainerInfo>
          </ContainerContent>
        </Modal>
      )}
    </>
  );
};

export default ModalInfo;

const CustomOverlay = styled(ModalOverlay)`
  background-color: ${(props) => props.theme.color.white} !important;
  opacity: 0.6;
`;

const ContainerContent = styled(ModalContent)`
  background-color: ${(props) => props.theme.color.yellow} !important;
  padding: 42px 0 !important;
`;

const CustomClose = styled(ModalCloseButton)`
  z-index: 10;
  margin: 1rem;
  padding: 1rem;
  border: none;
  cursor: pointer;
  background-color: transparent;
  :hover {
    background-color: transparent !important;
  }
  svg {
    padding: 1rem;
    height: 30px;
    width: 30px;
    path {
      fill: ${(props) => props.theme.color.darkGray};
    }
    :hover {
      path {
        fill: ${(props) => props.theme.color.red};
      }
    }
  }
`;

const ContainerInfo = styled.div`
  padding: 2em;
`;

const Title = styled.span`
  font-size: 22px;
`;

const Description = styled.span`
  font-size: 18px;
`;
