import React from "react";
import {
  FooterContainer,
  FooterCopyright,
  FooterInfoWrapper,
  FooterLinkWrapper,
  FooterNotice,
} from "./styles";

import IconConchaShell from "assets/icons/concha-shell.svg";
import { FooterSmartLink } from "./FooterSmartLink";
import { NavLink } from "react-router-dom";

export function Footer() {
  return (
    <FooterContainer>
      <FooterLinkWrapper>
        <FooterSmartLink
          href={`https://www.shell.com.br/termos-e-condicoes.html`}
          isExternal
        >
          Termos de Uso
        </FooterSmartLink>
        <FooterSmartLink
          href={`https://www.shell.com.br/politica-de-privacidade.html`}
          isExternal
        >
          Política de Privacidade
        </FooterSmartLink>
        <FooterSmartLink href={`/regulamento`}>Regulamento</FooterSmartLink>
        <FooterSmartLink href={`/duvidas`}>Dúvidas</FooterSmartLink>
      </FooterLinkWrapper>
      <FooterInfoWrapper>
        <FooterCopyright>
          <img src={IconConchaShell} alt="" />
          <span>Copyright 2023 - Todos os direitos reservados a Shell</span>
        </FooterCopyright>
        <FooterNotice>
          <p>
            De 01/08/2024 a 09/09/2024 troque 20 pontos stix por 1 número da
            sorte no Shell Box Clube e concorra a 1 par de ingressos, 1 produto
            exclusivo Shell da coleção Ferrari e/ou 1 cupom de abastecimento de
            R$200,00 (duzentos reais) para usar no aplicativo Shell Box. O
            resgate de ingressos no Shell Box Clube estará disponível conforme
            disponibilidade de ingressos a partir de setembro. Acesse as regras
            e o regulamento da promoção no aplicativo Shell Box ou no site
            promo.shell.com.br. A marca Shell é licenciada à Raízen S.A.
          </p>
        </FooterNotice>
      </FooterInfoWrapper>
    </FooterContainer>
  );
}
