import { request } from "../utils/api";

export const GetShell = () => {
  return request({
    method: "get",
    baseUrl: "api",
    route: `transaction/balance${"/"}`,
  });
};

export const GetProducts = () => {
  return request({
    method: "get",
    baseUrl: "api",
    route: `oanotodo/produtos/`,
  });
};

export const GetSliderCards = () => {
  return request({
    method: "get",
    baseUrl: "api",
    route: `oanotodo/cards/`
  })
}

export const GetRestrictedSliderCards = () => {
  return request({
    method: "get",
    baseUrl: "api",
    route: `oanotodo/restricted-cards/`
  })
}

export const GetFaq = () => {
  return request({
    method: "get",
    baseUrl: "api",
    route: `oanotodo/perguntas-frequentes/`
  })
}

export const GetCategories = () => {
  return request({
    method: "get",
    baseUrl: "api",
    route: "oanotodo/categorias/"
  })
}