import React from "react"
import styled from 'styled-components'
import { NavLink, useLocation } from "react-router-dom"
import { getSlug } from "utils/helper"
import { useStore } from "configureStore"
import { LinkAccessibility } from "utils/linkAcessibility"
import useScrollToModalLogin from "hooks/useScrollToModalLogin"

export function PromoFooter() {
  const slug = getSlug().url
  const isAuth = useStore((state) => state.auth.isAuthenticated)
  const location = useLocation()
  const handleLoginClick = useScrollToModalLogin()

  if (
    (location.pathname !== `${slug}/gp-sp-2023` && !location.pathname.includes("postos-participantes")) ||
    isAuth
  ) {
    return null
  }

  return (
    <PromoFooterContainer>
      <PromoFooterWrapper>
        <PromoFooterBlock>
          <strong>
            Já sou cadastrado na campanha ou no app Shell Box
          </strong>
          <p>
            Participei de outras campanhas ou já tenho cadastro no App Shell Box e aceitei participar das promoções.
          </p>
          <button onClick={handleLoginClick}>entrar</button>
        </PromoFooterBlock>
      </PromoFooterWrapper>
      <PromoFooterWrapper>
        <PromoFooterBlock>
          <strong>
            Ainda não sou cadastrado
          </strong>
          <p>
            Ainda não tenho cadastro e quero participar da PromoShell. <br />
            Não se esqueça também de {" "}
            <LinkAccessibility
              href="https://shellbox.onelink.me/RT9I/F1ingressos"
              isNewTab
            >
              baixar o app Shell Box
            </LinkAccessibility>.
          </p>
          <NavLink to={`${slug}/gp-sp-2023/cadastro`}>cadastre-se</NavLink>
        </PromoFooterBlock>
      </PromoFooterWrapper>
    </PromoFooterContainer>
  )
}

const PromoFooterContainer = styled.footer`
  display: flex;
  flex-direction: column;
  width: 100%;
  background: ${props => props.theme.color.lightGray200};

  @media (min-width: ${props => props.theme.queries.lg}) {
    flex-direction: row;
  }
`

const PromoFooterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 2.5rem 3.125rem;
  
  &:first-child {
    border-bottom: 1px solid ${props => props.theme.color.white};
  }
  
  @media (min-width: ${props => props.theme.queries.lg}) {
    justify-content: center;
    align-items: flex-end;
    max-width: 660px;

    &:first-child {
      border-right: 1px solid ${props => props.theme.color.white};
      border-bottom: unset;
      margin-left: auto;
    }
    &:last-child {
      margin-right: auto;
    }
  }
`

const PromoFooterBlock = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 275px;
  margin: 0 auto;

  strong {
    font-family: ${props => props.theme.Shell.Bold};
    font-size: 1rem;
    font-weight: 700;
    line-height: normal;
    color: ${props => props.theme.color.text};
    margin-bottom: 0.5rem;
    br {
      display: none;
    }
  }
  p {
    font-family: ${props => props.theme.helvetica};
    font-weight: 400;
    font-size: 0.875rem;
    color: ${props => props.theme.color.text};
    margin: 0 0 4px;

    & > a {
      color: ${props => props.theme.color.red};
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }
  button, & > a {
    width: 183px;
    margin-top: 1.25rem;
    background: ${props => props.theme.color.text};
    border: 2px solid transparent;
    border-radius: 8px;
    padding: 1rem 0;
    
    font-size: 1rem;
    font-weight: 700;
    font-family: ${props => props.theme.Shell.Bold};
    line-height: 68.75%;
    color: ${props => props.theme.color.white};
    text-transform: uppercase;
    text-decoration: none;
    text-align: center;
    cursor: pointer;
    transition: .2s all ease-in-out;
    
    &:hover, &:focus {
      background: ${props => props.theme.color.white};
      border-color: ${props => props.theme.color.text};
      color: ${props => props.theme.color.text};
    }
  }

  @media (min-width: ${props => props.theme.queries.lg}) {
    max-width: 386px;

    strong {
      font-size: 1.125rem;
    }
  }
`