import React from "react";
import styled from "styled-components";
import Carousel from "react-multi-carousel";
import CarouselFirstCard from "./CarouselFirstCard";
import { useState, useEffect } from "react";
import { useActions, useStore } from "configureStore";
import { Box, Spinner, useDisclosure } from "@chakra-ui/core";
import { auth as useAuth } from "hooks/auth";
import useWindowSize from "hooks/useWIndowSize";
import { GetUrlShop } from "services/shell";
import { CarouselModal } from "./CarouselModal";
import { CarouselSmartCard } from "./CarouselSmartCard";
import { getSlug } from "utils/helper";

type CarouselCardProps = {
  descricao: string;
  imagem_fundo: string;
  nova_janela: boolean;
  ordem: number;
  titulo: string;
  url: string;
  sso: boolean;
  imagem_popup_desktop: string;
  imagem_popup_responsiva: string;
  popup: boolean;
  produto_id_sso: number;
};

type CarouselModalProps = {
  imageLarge: string;
  imageMobile: string;
};

type productSSO = {
  produto_id_sso: number;
  isRedirectReady: boolean;
};

export default function WebdoorCarousel() {
  const [cardItems, setCardItems] = useState<CarouselCardProps[]>([]);
  const { auth } = useAuth();
  const { width } = useWindowSize();
  const isAuth = auth.isAuthenticated;
  const slug = getSlug().url;
  const isLoadingCards = useStore((state) => state.anoTodo.isLoadingCards);
  const carouselCards = useStore((state) => state.anoTodo.carouselCards);
  const carouselRestrictCards = useStore(
    (state) => state.anoTodo.carouselRestrictedCards
  );
  const clickCount = useStore((state) => state.anoTodo.buttonClickCount);
  const setClickCount = useActions((action) => action.anoTodo.setButtonClickCount);
  const setActive = useActions((actions) => actions.loading.setActive);
  const [mouseXpos, setMouseXpos] = useState<number>(null);
  const [carouselLength, setCarouselLength] = useState(null);
  const isShellbox = window.location.search.includes("shellbox");
  const [item, setItem] = useState(null);
  const [carouselSSO, setCarouselSSO] = useState<productSSO>();
  const setIsOpen = useActions((action) => action.home.setIsOpen);
  const [modalContent, setModalContent] = useState<CarouselModalProps>();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [responsive, setResponsive] = useState({
    desktopXL: {
      breakpoint: { max: 4000, min: 1900 },
      items: 4,
      slidesToSlide: 2,
      partialVisibilityGutter: 14,
    },
    desktop1440: {
      breakpoint: { max: 1899, min: 1440 },
      items: 3,
      slidesToSlide: 3,
      partialVisibilityGutter: 62,
    },
    desktop1366: {
      breakpoint: { max: 1439, min: 1366 },
      items: 3,
      slidesToSlide: 3,
      partialVisibilityGutter: 44,
    },
    desktop: {
      breakpoint: { max: 1365, min: 1280 },
      items: 3,
      slidesToSlide: 3,
      partialVisibilityGutter: 34,
    },
    mobileMedium: {
      breakpoint: { max: 1279, min: 1025 },
      items: 2,
      slidesToSlide: 1,
      partialVisibilityGutter: 100,
    },
    mobile1024: {
      breakpoint: { max: 1024, min: 769 },
      items: 1,
      slidesToSlide: 1,
      partialVisibilityGutter: 260,
    },
    mobile768: {
      breakpoint: { max: 768, min: 441 },
      items: 1,
      slidesToSlide: 1,
      partialVisibilityGutter: 180,
    },
    mobile440: {
      breakpoint: { max: 440, min: 376 },
      items: 1,
      slidesToSlide: 1,
      partialVisibilityGutter: 115,
    },
    mobile375: {
      breakpoint: { max: 375, min: 321 },
      items: 1,
      slidesToSlide: 1,
      partialVisibilityGutter: 90,
    },
    mobile320: {
      breakpoint: { max: 320, min: 0 },
      items: 1,
      slidesToSlide: 1,
      partialVisibilityGutter: 35,
    },
  });

  useEffect(() => {
    getStorageCarouselSSO();
    setIsOpen(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    switch (carouselLength) {
      case 4:
        setResponsive({
          ...responsive,
          desktopXL: {
            ...responsive.desktopXL,
            items: 4,
            slidesToSlide: 2,
          },
        });
        break;
      case 3:
        setResponsive({
          ...responsive,
          desktopXL: {
            ...responsive.desktopXL,
            items: carouselLength,
            slidesToSlide: 2,
            partialVisibilityGutter: 142,
          },
          desktop1440: {
            ...responsive.desktop1440,
            items: carouselLength,
            slidesToSlide: carouselLength,
            partialVisibilityGutter: 60,
          },
          desktop1366: {
            ...responsive.desktop1366,
            items: carouselLength,
            slidesToSlide: carouselLength,
            partialVisibilityGutter: 48,
          },
          desktop: {
            ...responsive.desktop,
            items: carouselLength,
            slidesToSlide: carouselLength,
            partialVisibilityGutter: 36,
          },
        });
        break;
      case 2:
        setResponsive({
          ...responsive,
          desktopXL: {
            ...responsive.desktopXL,
            items: carouselLength,
            slidesToSlide: carouselLength,
            partialVisibilityGutter: 386,
          },
          desktop1440: {
            ...responsive.desktop1440,
            items: carouselLength,
            slidesToSlide: carouselLength,
            partialVisibilityGutter: 268,
          },
          desktop1366: {
            ...responsive.desktop1366,
            items: carouselLength,
            slidesToSlide: carouselLength,
            partialVisibilityGutter: 254,
          },
          desktop: {
            ...responsive.desktop,
            items: 2,
            slidesToSlide: 2,
            partialVisibilityGutter: 224,
          },
        });
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [carouselLength]);

  /**
   * Função que verifica se o usuário está arrastando o card do carrossel, ou apenas clicando no card.
   * Também verifica se o clique deve chamar o SSO, abrir um modal, ou redirecionar a um link externo ou interno.
   * @param card Recebe o card clicado e suas propriedades.
   * @param mousePos A posição atual do mouse/cursor.
   */
  function handleSlideClick(card: CarouselCardProps, mousePos: number) {
    let xPos = mousePos;

    if (xPos - mouseXpos === 0) {
      if (!card?.sso) {
        if (card?.url && !card.popup && !card?.nova_janela) {
          try {
            // se é um link interno que abre na mesma aba
            if (card.url[0] === "/") {
              window.location.assign(`${slug}${card?.url}`);
              // se é um link externo que abre na mesma aba
            } else {
              window.location.assign(card?.url);
            }
          } catch (err) {
            console.warn(`Erro ao navegar: ${err}`);
          }
        }
        // Se abre em nova aba/janela
        if (card?.url && card?.nova_janela && !card?.popup) {
          try {
            handleWebviewExternalLink(card?.url);
            if (!isShellbox) {
              const anchor = document.createElement('a');
              if (card?.url[0] === '/') {
                anchor.href = `${slug}${card.url}`;
              } else {
                anchor.href = card?.url;
              }
              anchor.target = "_blank";
              anchor.click();
            }
          } catch (err) {
            console.warn(`Erro ao abrir nova janela: ${err}`);
          }
        }
        // Se tiver modal/popup
        if (card?.popup) {
          setModalContent({
            imageLarge: card.imagem_popup_desktop,
            imageMobile: card.imagem_popup_responsiva
          })
          onOpen();
        }
        // Se for SSO
      } else {
        if (clickCount > 0) {
          handleCarouselSSO(card?.produto_id_sso);
          setClickCount(0);
        }
      }
    }
  }

  function handleWebviewExternalLink(url: string) {
    if (isShellbox) {
      try {
        // @ts-ignore
        WebViewJavascriptInterface.openExternalLink(url);
      } catch (_) {
        window.open(url, "_blank", "noopener noreferer");
        window.focus();
      }
      setActive(true);
    }
  }

  useEffect(() => {
    if (isAuth) {
      setCardItems(carouselRestrictCards);
      setCarouselLength(carouselRestrictCards?.length);
    } else {
      setCardItems(carouselCards);
      setCarouselLength(carouselCards?.length);
    }
  }, [carouselCards, carouselRestrictCards, isAuth]);

  function handleCarouselSSO(produto_id_sso: number) {
    sessionStorage.setItem(
      "carouselSSO",
      JSON.stringify({ produto_id_sso, isRedirectReady: true })
    );
    getStorageCarouselSSO();
  }

  /**
   * Busca o id do produto, e se já é possível redirecionar a página para o SSO,
   * Salvando a resposta em um estado.
   */
  async function getStorageCarouselSSO() {
    try {
      const _carouselSSO = await JSON.parse(
        sessionStorage.getItem("carouselSSO")
      );
      setCarouselSSO({
        produto_id_sso: _carouselSSO.produto_id_sso,
        isRedirectReady: _carouselSSO.isRedirectReady,
      });
    } catch (error) {
      // console.error(error);
    }
  }

  async function getSSO(produto_id_sso: number) {
    try {
      setActive(true);
      const res = await GetUrlShop();
      if (res.data) {
        setItem({
          ...res.data.data,
          produto_id_sso,
        });
      }
    } catch (err) {
      console.warn(err);
    }
  }

  useEffect(() => {
    if (isAuth && carouselSSO?.isRedirectReady) {
      getSSO(carouselSSO.produto_id_sso);
    } else if (carouselSSO?.isRedirectReady) {
      setIsOpen(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [carouselSSO]);

  useEffect(() => {
    if (
      isShellbox &&
      item?.redirectURL &&
      carouselSSO?.isRedirectReady === true
    ) {
      const form = document.getElementById("formCarousel") as any;
      form.submit();
      sessionStorage.removeItem("carouselSSO");
      handleWebviewExternalLink(item?.redirectURL);
    } else if (item?.redirectURL && carouselSSO?.isRedirectReady === true) {
      const form = document.getElementById("formCarousel") as any;
      form.submit();
      sessionStorage.removeItem("carouselSSO");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [carouselSSO, isShellbox, item]);

  return (
    <CarouselWrapper>
      <CarouselModal
        isOpen={isOpen}
        onClose={onClose}
        imageLarge={modalContent?.imageLarge}
        imageMobile={modalContent?.imageMobile}
      />
      {Boolean(isLoadingCards) ? (
        <Box
          w={"100%"}
          h={"100%"}
          d={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Spinner
            thickness="4px"
            speed="0.65s"
            emptyColor="gray.200"
            color="yellow.500"
            size="xl"
          />
        </Box>
      ) : (
        // Só renderiza o carousel se tiver slides
        Boolean(cardItems?.length > 0) && (
          <CarouselWD
            responsive={responsive}
            arrows={false}
            keyBoardControl={true}
            showDots={carouselLength <= 3 && width > 1024 ? false : true}
            renderDotsOutside
            partialVisible={true}
            minimumTouchDrag={12}
            dotListClass={"dots-wd"}
            sliderClass={"sliderWd"}
            items={carouselLength}
          >
            {cardItems.map((card, index) => (
              <CarouselSmartCard
                key={'card-generator'}
                card={card}
                index={index}
                handleSlideClick={handleSlideClick}
                setMouseXpos={setMouseXpos}
                cardsLength={cardItems.length}
              />
            ))}
            {/* {cardItems.map((card, index) => (
              <>
                {Boolean(isAuth && index === 0) && <CarouselFirstCard key={'firstcard'} cursor="default" setCursor={setCursor} />}
                {Boolean(index >= 1 || !isAuth) && (
                  <CarouselCards
                    key={index}
                    tabIndex={0}
                    bgImg={card.imagem_fundo}
                    rel={Boolean(card.nova_janela) ? "noopener noreferer" : null}
                    style={{
                      cursor: card.url || card.popup ? "pointer" : cursor,
                    }}
                    onClick={(e) => handleSlideClick(card, e.pageX)}
                    onMouseDown={(e) => {
                      setMouseXpos(e.pageX);
                      setCursor("grabbing");
                    }}
                    onMouseUp={(e) => {
                      handleSlideClick(card, e.pageX);
                      setCursor("grab");
                    }}
                    onKeyPress={(e) =>
                      e.key === "Enter" && handleSlideClick(card, 0)
                    }
                  >
                    <FadeBlock hasDesc={Boolean(card.descricao)}>
                      {card.titulo && <strong>{card?.titulo}</strong>}
                      {card.descricao && <p>{card?.descricao}</p>}
                    </FadeBlock>
                  </CarouselCards>
                )}
              </>
            )
            )} */}
          </CarouselWD>
        )
      )}
      {/* SSO */}
      <form
        action={`https://${item?.redirectURL || "shelljunte-troque.shop"
          }?acesso=1&webview=${window.location.search.includes("shellbox") ? 1 : 0
          }&produto=${item?.produto_id_sso}`}
        style={{ display: "none" }}
        method="post"
        target="_self"
        id="formCarousel"
      >
        <input
          type="hidden"
          name="token"
          id="token"
          value={`Bearer ${item?.token}`}
        ></input>
        <input
          type="hidden"
          name="webview"
          id="webview"
          value={window.location.search.includes("shellbox") ? 1 : 0}
        ></input>
        <input type="hidden" name="acesso" value={1}></input>
        <input
          type="hidden"
          name="produto"
          id="produto"
          value={item?.produto_id_sso || ""}
        ></input>
      </form>
    </CarouselWrapper>
  );
}

const CarouselWrapper = styled.div`
  /* display: flex;
  justify-content: center;
  align-items: flex-end;
  flex-direction: column; */
  width: 100%;
  padding-bottom: 1.75rem;
  padding-left: 2.5rem;
  margin-bottom: 1rem;

  position: relative;
  box-sizing: unset;

  ul.react-multi-carousel-dot-list {
    width: 100%;
    li.react-multi-carousel-dot {
      button {
        background-color: #e2d7e2;
        border: 1px solid #000;
        width: 10px;
        height: 10px;
        &:focus {
          outline: auto;
        }

        @media (min-width: ${(props) => props.theme.queries.medium}) {
          width: 14px;
          height: 14px;
          margin: 0 8px;
        }
      }
    }
    li.react-multi-carousel-dot--active {
      button {
        background-color: ${(props) => props.theme.color.yellow};
      }
    }
  }

  @media (min-width: ${(props) => props.theme.queries.ix}) {
    max-width: 45rem;
    padding-left: 2.75rem;
  }
  @media (min-width: ${(props) => props.theme.queries.small}) {
    padding-left: 5rem;
    max-width: 30rem;
  }
  @media (min-width: ${(props) => props.theme.queries.medium}) {
    /* width: 100%; */
    max-width: 40rem;
    padding-bottom: 2.5rem;
    padding-left: 1rem;
    margin-top: 30px;
  }
  @media (min-width: ${(props) => props.theme.queries.desk}) {
    max-width: 73.5rem;
    padding-left: unset;
  }
  @media (min-width: 1360px) {
    max-width: 76rem;
  }
  @media (min-width: 1400px) {
    max-width: 78rem;
  }
  @media (min-width: 1920px) {
    max-width: 93rem;
  }
`;

export const CarouselCards = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: column;
  text-decoration: none;

  width: 264px;
  height: 120px;

  background: url(${(props) => props.bgImg});
  background-repeat: no-repeat;
  background-size: cover;
  color: #fff;
  border-radius: 2px;

  box-sizing: border-box;

  @media (min-width: ${(props) => props.theme.queries.medium}) {
    width: 339px;
    height: 155px;
  }
`;

export const FadeBlock = styled.div`
  display: ${(props) => (props.hasDesc ? "flex" : "none")};
  flex-direction: column;
  width: 100%;

  padding: 8px 8px 0.75rem;

  box-sizing: border-box;

  strong {
    font-family: ${(props) => props.theme.Shell.Bold};
    font-size: 1.75rem;
    line-height: 90%;
    text-align: center;
  }

  p {
    font-family: ${(props) => props.theme.Shell.Medium};
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 0.75rem;
    margin: 1rem auto 0;

    text-align: center;
  }

  @media (min-width: ${(props) => props.theme.queries.medium}) {
    padding-bottom: 1rem;
    strong {
      font-size: 1.875rem;
    }

    p {
      font-size: 0.938rem;
      line-height: 0.75rem;
    }
  }
`;

const CarouselWD = styled(Carousel)`
  width: 100%;
  a {
    text-decoration: none;
  }
  @media (min-width: ${(props) => props.theme.queries.desk}) {
    transform: translateX(5%);

    ul.sliderWd {
      transform: ${(props) =>
    props.items <= 3 && "translate3d(0px, 0px, 0px) !important"};
    }
  }
  @media (min-width: 1360px) {
    transform: translateX(6.5%);
  }
  @media (min-width: 1400px) {
    transform: translateX(8%);
  }
  @media (min-width: 1900px) {
    transform: translateX(14.5%);
  }
`;
