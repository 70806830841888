import { useEffect, useState } from "react";
import { useRouter } from "./useRouter";

export default function useShellbox() {
  const [isShellbox, setIsShellbox] = useState(window.location.search?.includes('shellbox'));
  const router = useRouter();

  function getPathname() {
    Object.keys(router.query).includes("shellbox") ? setIsShellbox(true) : setIsShellbox(false);
  }

  useEffect(() => {
    getPathname();
  }, [getPathname, router.pathname, router.query])
  
  return isShellbox;
}