import React from "react";
import styled from "styled-components";
import ImageQrCode from "assets/banners/promoGpSP2024/qr-code.png";
import ImageHandMobile from "assets/banners/promoGpSP2024/hand-app.png";
import useWindowSize from "hooks/useWIndowSize";

export function QrCodeSection2024() {
  const { width } = useWindowSize();
  const isMobile = width < 1280;

  if (isMobile) {
    return (
      <QrCodeContainer>
        <QrCodeYellowBlock>
          <p>Baixe o App Shell Box</p>
          <ButtonClickHere
            href="https://shellbox.onelink.me/RT9I/301b26?af_qr=true"
            target="_blank"
            rel="noopener noreferrer"
          >
            clique aqui
          </ButtonClickHere>
        </QrCodeYellowBlock>
        <QrCodeQRBlock>
          <img
            src={ImageHandMobile}
            className="hand-app"
            alt="Ilustração de uma mão segurando um celular, no qual está inserido o logo de Shell Box. Ao lado, o QR Code com link para baixar o aplicativo"
          />
        </QrCodeQRBlock>
      </QrCodeContainer>
    );
  }

  return (
    <QrCodeContainer>
      <QrCodeYellowBlock>
        <p>
          Aponte a câmera do seu celular para o QR Code e baixe o App Shell Box
          clique aqui
        </p>
      </QrCodeYellowBlock>
      <QrCodeQRBlock>
        <img
          src={ImageQrCode}
          alt="Ilustração de uma mão segurando um celular, no qual está inserido o logo de Shell Box. Ao lado, o QR Code com link para baixar o aplicativo"
        />
      </QrCodeQRBlock>
    </QrCodeContainer>
  );
}

const ButtonClickHere = styled.a`
  background-color: ${(props) => props.theme.color.red};
  border-radius: 8px;
  border: none;
  padding: 16px 56px;

  color: ${(props) => props.theme.color.white};
  font-family: ${(props) => props.theme.Shell.Bold};
  font-size: 1rem;
  line-height: 0.9;
  text-transform: uppercase;
  text-decoration: none;
`;

const QrCodeContainer = styled.section`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 425px;

  @media (min-width: ${(props) => props.theme.queries.desk}) {
    max-width: 1190px;
    flex-direction: row;
  }
`;

const QrCodeYellowBlock = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  gap: 24px;
  background: ${(props) => props.theme.color.yellow};
  padding: 50px 16px;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;

  p {
    font-size: 1.5rem;
    font-weight: 700;
    line-height: normal;
    font-family: ${(props) => props.theme.FuturaFontBold};
    color: ${(props) => props.theme.color.gray};
    margin: 0;
  }

  @media (min-width: ${(props) => props.theme.queries.desk}) {
    flex-direction: row;
    border-top-left-radius: 12px;
    border-bottom-left-radius: 12px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    max-width: 595px;

    p {
      font-size: 1.875rem;
      width: 420px;
    }
  }
`;

const QrCodeQRBlock = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  height: 215px;
  background: ${(props) => props.theme.color.lightYellow50};
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;

  img {
    width: 100%;
    max-width: 310px;
    margin-top: auto;
    mix-blend-mode: darken;

    &.hand-app {
      width: auto;
      height: 90%;
    }
  }

  @media (min-width: ${(props) => props.theme.queries.desk}) {
    border-bottom-right-radius: 12px;
    border-top-right-radius: 12px;
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;

    height: 335px;

    img {
      max-width: 370px;
    }
  }
`;
