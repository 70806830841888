import React, { useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import {
  PromoActionButton,
  PromoActionLoginButton,
  PromoHeaderContainer,
  PromoHeaderContent,
  PromoHeaderHeading,
  PromoHeaderNav,
  PromoHeaderNavWrapper,
  PromoHeaderTitle,
  PromoHeaderWebdoor,
  PromoHeaderWebdoorButtons,
  PromoHeaderWebdoorLettering,
} from "./styles";
import useWindowSize from "hooks/useWIndowSize";
import { getSlug } from "utils/helper";
import { useStore } from "configureStore";
import { HeaderNavLink } from "./HeaderNavLinks";
import useScrollToModalLogin from "hooks/useScrollToModalLogin";
import MenuHamburguerMobile from "./MenuHamburguerMobile";
// Assets
import ImageWebdoorMobile from "assets/banners/promoGpSP2024/webdoor-mobile.png";
import ImageWebdoor from "assets/banners/promoGpSP2024/webdoor.png";
import IconShellLarge from "assets/images/grandeGp/shell-white-border-icon.png";
import ImageLettering from "assets/banners/promoGpSP2024/lettering.png";
import LogoVPower from "assets/banners/promoGpSP2024/logo-vpower-new.svg";

export function PromoHeader2024() {
  const [heading, setHeading] = useState<{
    title: string;
    description?: string;
  }>({} as any);
  const { url: slug } = getSlug();
  const { width } = useWindowSize();
  const isMobile = width < 1280;
  const location = useLocation();
  // const handleLoginClick = useScrollToModalLogin();
  const isAuth = useStore((state) => state.auth.isAuthenticated);

  function handleHeadingInfo() {
    if (location.pathname === slug) {
      return;
    }
    if (location.pathname.includes("numeros-da-sorte")) {
      setHeading({
        title: "Meus Números da Sorte",
        description:
          "Selecione a data do sorteio para conferir todos os números acumulados. Boa sorte! ",
      });
    }
    if (location.pathname.includes("ganhadores")) {
      setHeading({
        title: "Ganhadores",
        description: "Acompanhe aqui os sortudos desta promoção",
      });
    }
    if (location.pathname.includes("resgate-de-ingressos")) {
      setHeading({
        title: "Resgate de Ingressos",
        description:
          "Além de participar do sorteio, você poderá resgatar 1 par de ingressos através do Shell Box Clube",
      });
    }
    if (location.pathname.includes("postos-participantes")) {
      setHeading({
        title: "Postos Participantes",
        description:
          "Selecione abaixo Estado, Cidade e Bairro para encontrar os postos e lojas mais próximos de você.",
      });
    }
    if (location.pathname.includes("duvidas")) {
      setHeading({
        title: "Dúvidas",
      });
    }
    if (location.pathname.includes("regulamento")) {
      setHeading({
        title: "Regulamento",
      });
    }
    if (location.pathname.includes("senha")) {
      setHeading({
        title: "Recuperar Senha",
      });
    }
    if (location.pathname.includes("cadastro")) {
      setHeading({
        title: isAuth ? "Dados Cadastrais" : "Meu Cadastro",
      });
    }
  }

  useEffect(() => {
    handleHeadingInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  return (
    <PromoHeaderContainer>
      <PromoHeaderNavWrapper>
        <PromoHeaderTitle>Grande Prêmio de SP 2024</PromoHeaderTitle>
        {isMobile ? (
          <MenuHamburguerMobile />
        ) : (
          <PromoHeaderNav>
            <HeaderNavLink exact url={slug}>
              Como Participar
            </HeaderNavLink>
            {/* {isAuth && (
              <HeaderNavLink url={`/numeros-da-sorte`}>
                Meus Números da Sorte
              </HeaderNavLink>
            )} */}
            <HeaderNavLink url={`${slug}/ganhadores`}>Ganhadores</HeaderNavLink>
            <HeaderNavLink url={`${slug}/resgate-de-ingressos`}>
              Resgate de Ingressos
            </HeaderNavLink>
            <HeaderNavLink url={`${slug}/postos-participantes`}>
              Postos Participantes
            </HeaderNavLink>
          </PromoHeaderNav>
        )}
      </PromoHeaderNavWrapper>
      <PromoHeaderContent>
        {location.pathname !== slug ? (
          <PromoHeaderHeading
            $isPostosPage={window.location.pathname.includes(
              "postos-participantes"
            )}
          >
            <h1>{heading.title}</h1>
            {heading.description && <span>{heading?.description}</span>}
          </PromoHeaderHeading>
        ) : (
          <>
            {/* Home Page */}
            <PromoHeaderWebdoor>
              <img
                className="webdoor"
                src={!isMobile ? ImageWebdoor : ImageWebdoorMobile}
                draggable="false"
                alt="Sob fundo vermelho, carro de Fórmula-1 da Ferrari aparece posicionado sobre uma letra V, em referência a Shell V-Power"
              />
              {isMobile && (
                <>
                  <img
                    className="shellIcon"
                    src={IconShellLarge}
                    draggable="false"
                    alt="Logo da Shell"
                  />
                  <PromoHeaderWebdoorLettering>
                    {/* {!isMobile && <img src={LogoVPower} className="logo" alt="" />} */}

                    <img src={ImageLettering} className="lettering" alt="" />

                    {/* {!isMobile && (
                  <p>A melhor experiência para v-ocê e seu carro.</p>
                )} */}
                  </PromoHeaderWebdoorLettering>
                </>
              )}
            </PromoHeaderWebdoor>
            {/* {!isAuth && (
              <PromoHeaderWebdoorButtons>
                <PromoActionButton to={`${slug}/gp-sp-2023/cadastro`}>
                  cadastre-se
                </PromoActionButton>
                <PromoActionLoginButton onClick={handleLoginClick}>
                  faça login
                </PromoActionLoginButton>
              </PromoHeaderWebdoorButtons>
            )} */}
          </>
        )}
      </PromoHeaderContent>
    </PromoHeaderContainer>
  );
}
