import styled from "styled-components";

export const JunteTroqueContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100vw;
  height: 460px;
  background-color: ${props => props.theme.color.yellow};
  position: fixed;
  top: 2.75rem;
  left: 0;

  animation: fadeIn 150ms forwards ease-in;
  z-index: 10;

  img {
    margin-top: auto;
  }

  @keyframes fadeIn {
    from {opacity: 0}
    to {opacity: 1}
  }
`

export const JunteTroqueContainerMobile = styled.div`
  display: flex;
  justify-content: center;

  width: 100vw;
  /* height: 100vh; */
  position: fixed;
  top: 2.75rem;
  left: 0;
  background-color: ${props => props.theme.color.yellow};
  animation: fadeIn 150ms forwards ease-in;

  img {
    display: block;
    width: 100%;
    /* height: 100%; */
    object-fit: contain;
  }

  @keyframes fadeIn {
    from {opacity: 0}
    to {opacity: 1}
  }

  @media (min-width: ${props => props.theme.queries.medium}) {
    img {
      width: unset;
      height: 100%;
    }
  }
`

export const JunteTroqueLettering = styled.section`
  display: flex;
  flex-direction: column;
  
  h1 {
    all: unset;
    font-family: ${props => props.theme.Shell.Medium};
    font-size: 2.8125rem;
    font-weight: 500;
    line-height: 1;
    color: ${props => props.theme.color.darkGray};
    margin-bottom: 1.75rem;
  }
  
  p {
    all: unset;
    font-family: ${props => props.theme.Shell.Medium};
    font-size: 1rem;
    font-weight: 500;
    line-height: 1.3;
    color: ${props => props.theme.color.darkGray};
  }
`

export const ButtonShellboxDownload = styled.a`
  all: unset;
  width: 13rem;
  height: 4.5rem;
  
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  margin-top: 1.5rem;

  background: ${props => props.theme.color.red};
  color: ${props => props.theme.color.white};
  border: 2px solid transparent;
  text-decoration: none;

  cursor: pointer;
  transition: all 200ms ease-in-out;

  font-family: ${props => props.theme.FuturaFontBold};
  font-size: 0.75rem;
  font-weight: 700;
  line-height: 2.4375rem;

  svg {
    path {
      stroke: ${props => props.theme.color.white};
    }
  }

  &:hover, &:focus {
    background: ${props => props.theme.color.white};
    color: ${props => props.theme.color.red};
    border-color: ${props => props.theme.color.red};

    svg {
      path {
        stroke: ${props => props.theme.color.red};
      }
    }
  }
`
