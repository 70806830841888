import { request } from "../utils/api";
import { getSlug } from "utils/helper";

export const ListCategories = () => {
  return request({
    method: "get",
    baseUrl: "api",
    route: `categorias/`,
  });
};

export const ListLocales = () => {
  return request({
    method: "get",
    baseUrl: "api",
    route: `locais/`,
  });
};

export const ListPromoHits = (query?: string) => {
  return request({
    method: "get",
    baseUrl: "api",
    route: `promohits/?${query}`,
  });
};

/** 
  * Traz os dados do banner principal do hub de promoções
  * @param {number} id O id da promoção que você quer realizar a chamada api
*/
export const GetHubBanner = (id: number) => {
  return request({
    method: "get",
    baseUrl: "api",
    route: `promocoes/${id}/`
  })
}

export const GetWebdoorCarousel = () => {
  return request({
    method: "get",
    baseUrl: "api",
    route: `banners/`
  })
}

export const GetFaq = (promoId: number) => {
  return request({
    method: "get",
    baseUrl: "api",
    route: `perguntas-frequentes/${promoId}/`
  })
}