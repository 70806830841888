import React from "react";
import useWindowSize from "hooks/useWIndowSize";
import {
  Ticket as TicketContainer,
  TicketBody,
  TicketDate,
  TicketHeader,
  TicketLocation,
  TicketNumber,
} from "modules/promoGrandeGP2024/pages/NumerosDaSorte/styles";

type TicketCodeProps = {
  ticketType: "code";
  code: string;
  transactionDate: string;
  merchantName: string;
};
type TicketDateProps = {
  ticketType: "date";
  raffleDate: string;
  participationPeriod: {
    start: string;
    end: string;
  };
};
type TicketProps = TicketCodeProps | TicketDateProps;

/**
 * Componente de Ticket, com duas opções de formato
 * @param ticketType Define as propriedades de acordo com o tipo selecionado. Deve ser a primeira propriedade selecionada
 * @returns
 */
export function Ticket(ticket: TicketProps) {
  const { width } = useWindowSize();
  const isMobile = width < 1280;

  if (ticket.ticketType === "code") {
    return (
      <TicketContainer tabIndex={0}>
        <TicketHeader>
          <TicketNumber>
            <span>Número</span>
            {/* <strong>{formatTicket(code)}</strong> */}
          </TicketNumber>
          {isMobile && (
            <TicketDate>
              <strong>Data de aquisição</strong>
              {/* <span>{transactionDate}</span> */}
            </TicketDate>
          )}
        </TicketHeader>
        <TicketBody>
          {!isMobile && (
            <TicketDate>
              <strong>Data de aquisição</strong>
              {/* <span>{transactionDate}</span> */}
            </TicketDate>
          )}
          <TicketLocation>
            <strong>Local</strong>
            <span>{ticket.merchantName}</span>
          </TicketLocation>
        </TicketBody>
      </TicketContainer>
    );
  }

  return (
    <TicketContainer tabIndex={0}>
      <TicketHeader>
        <TicketNumber type={ticket.ticketType}>
          <span>Sorteio</span>
          <strong>{ticket.raffleDate}</strong>
        </TicketNumber>
        {isMobile && (
          <TicketDate type={ticket.ticketType}>
            <strong>Participação de</strong>
            <span>
              {ticket.participationPeriod.start} a{" "}
              {ticket.participationPeriod.end}
            </span>
          </TicketDate>
        )}
      </TicketHeader>
      {!isMobile && (
        <TicketBody>
          <TicketDate type={ticket.ticketType}>
            <strong>Participação de</strong>
            <span>
              {ticket.participationPeriod.start} a{" "}
              {ticket.participationPeriod.end}
            </span>
          </TicketDate>
        </TicketBody>
      )}
    </TicketContainer>
  );
}
