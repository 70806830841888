
import React from "react";
import styled from "styled-components";

import IconShellVPower from 'assets/images/ourProducts/v-power.png';
import IconMastercard from 'assets/images/onda/logo-mastercard.png';

export default function ConchasExtrasComoFunciona() {
  return (
    <Container>
      <ContentWrapper>
        <Title tabIndex={0}>Conchas Extras</Title>
        <p>
          Veja como é fácil juntar conchas extras a partir de R$ 60.
        </p>
        <CardContainer>
          <ShellCard tabIndex={0}>
            <ImageWrapper>
              <img src={IconShellVPower} alt="shell v power" />
            </ImageWrapper>
            <TextWrapper>
              <p>
                Abasteceu a partir de <br /> R$ 60 com Shell V-Power?
              </p>
              <strong>1 concha extra</strong>
            </TextWrapper>
          </ShellCard>
          <ShellCard tabIndex={0}>
            <ImageWrapper>
              <img src={IconMastercard} alt="mastercard" />
            </ImageWrapper>
            <TextWrapper>
              <p>
                Fez qualquer pagamento <br /> com Mastercard a partir de R$ 60 no app Shell Box?
              </p>
              <strong>1 concha extra</strong>
            </TextWrapper>
          </ShellCard>
          <ShellCard tabIndex={0}>
            <ImageWrapper>
              <img src={IconShellVPower} alt="shell v power" />
              <img src={IconMastercard} alt="mastercard" />
            </ImageWrapper>
            <TextWrapper>
              <p>
                Abasteceu a partir de R$ 60 com Shell V-Power e pagou com Mastercard no <span>Shell Box?</span>
              </p>
              <strong>2 concha extras</strong>
            </TextWrapper>
          </ShellCard>
        </CardContainer>
      </ContentWrapper>
    </Container>
  )
}

const Container = styled.section`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #FFF;
  padding: 42px 0 55px;
  background: ${props => props.theme.color.yellow};
  
  @media (min-width: ${props => props.theme.queries.small}) {
    align-items: center;
    padding-bottom: 50px;
  }
`;

const Title = styled.h2`
  font-size: 1.563rem;
  line-height: 2.125rem;
  font-family: ${props => props.theme.Shell.Bold};
  /* letter-spacing: 1px; */
  color: ${props => props.theme.color.shellPurple};
  margin: 0 auto 0.75rem;
  text-align: center;
  
  @media (min-width: ${props => props.theme.queries.small}) {
    line-height: 1.75rem;
    margin: 0 auto 1rem;
    text-align: center;
  }
`;

const ContentWrapper = styled.div`
  width: 90%;
  max-width: 37rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 0 auto;

  p {
    font-family: ${props => props.theme.helvetica};
    font-size: 1rem;
    line-height: 1.25rem;
    font-weight: 500;
    margin: 0 auto 1.875rem;
    color: ${props => props.theme.color.shellBlack};
    text-align: center;
    width: 90%;
  }
  
  @media (min-width: ${props => props.theme.queries.small}) {
    align-items: center;
    max-width: 65.25rem;
    
    p {
      margin-bottom: 3.5rem;
    }
  }
`;

const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  width: 100%;
  
  @media (min-width: ${props => props.theme.queries.lg}) {
    flex-direction: row;
    margin-bottom: 2rem;
  }
`;

const ShellCard = styled.div`
  display: flex;
  justify-content: space-between;

  width: 289px;
  height: 96px;
  background: ${props => props.theme.color.yellow};
  border-radius: 4px;

  margin: 10px auto;

  @media (min-width: ${props => props.theme.queries.lg}) {
    flex-direction: row;
    margin: 0;
    width: 339px;
    height: 135px;

    & + div {
      margin-left: 25px;
    }
  }
`;

const ImageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  
  width: 90px;
  height: 96px;
  
  background: #F6DB64;
  border-radius: 4px;
  padding: 12px;
  box-sizing: border-box;
  
  img {
    width: 100%;
    
    & + img {
      width: 80%;
    }
  }

  @media (min-width: ${props => props.theme.queries.medium}) {
    width: 126px;
    height: 135px;

    padding: 1.25rem;
  }
`;

const TextWrapper = styled.div`
  width: 187px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  padding: 4px 0 0 12px;
  
  & > p {
    width: 100%;
    font-family: ${props => props.theme.helvetica};
    font-size: 0.875rem;
    line-height: 120%;
    font-weight: 500;
    text-align: unset;
    height: min-content;
    
    color: ${props => props.theme.color.shellBlack};
    margin: 0;

    span {
      white-space: nowrap;
    }
  }
  
  strong {
    font-family: ${props => props.theme.Shell.Bold};
    font-size: 1rem;
    line-height: 1.375rem;
    color: ${props => props.theme.color.shellBlack};
    white-space: nowrap;
    margin-bottom: 1rem;
  }
 
  @media (min-width: ${props => props.theme.queries.medium}) {
    width: 212px;
    padding: 0;
    padding-left: 12px;
    
    & > p {
      font-size: 1rem;
      line-height: 1.25rem;
    }
    strong {
      font-size: 1rem;
      line-height: 1.25rem;
    }
  }
`;