import React from "react";
import styled from "styled-components";

// Componentes
import BannerOffer from "../OffersDetail/Banner/BannerOffer";
import AboutPromo from "../OffersDetail/SobrePromocao/AboutPromo";
import HowToParticipate from "../OffersDetail/ComoParticipar/HowToParticipate";
import Faq from "../OffersDetail/PerguntasFrequentes/Faq";
import ParticipatingStations from "modules/common/ParticipatingStations/ParticipatingStations";
import Navbar from "../OffersDetail/Navegacao/Navbar";

const OffersDetail = () => {
  return (
    <div id="main">
      <Navbar />
      <BannerOffer />
      <Content>
        <AboutPromo />
        <HowToParticipate />
        <Faq />
      </Content>
      <ParticipatingStations />
    </div>
  )
}

export default OffersDetail;

const Content = styled.div`
  max-width: 1400px;
  margin: 0 auto;
  padding: 0 2em;

  @media (max-width: 1200px) {
    padding: 0 1.5em;
  }
`;