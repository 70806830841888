import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import { Rifm } from "rifm";
import ReCAPTCHA from "react-google-recaptcha";
import TextField, { Input } from "@material/react-text-field";
import { useFormik } from "formik";
import { Box, Button } from "@chakra-ui/core";
import { formatToCPF } from "brazilian-values";
import { useStore, useActions } from "configureStore";

import { theme } from "theme";

export default function ForgotPassword() {
  const isLoading = useStore((state) => state.auth.isAuthLoading);
  const intentForgotPassword = useStore(
    (state) => state.auth.intentForgotPassword
  );
  const authError = useStore((state) => state.auth.authError);
  const messageForgotPassword = useStore(
    (state) => state.auth.messageForgotPassword
  );
  const updateError = useActions((state) => state.auth.updateAuthError);
  const forgotPassword = useActions((actions) => actions.auth.forgotPassword);
  const updateMsgForgot = useActions((actions) => actions.auth.updateMsgForgot);
  const recaptchaRef = useRef(null);

  useEffect(() => {
    if (authError) {
      setTimeout(() => {
        updateError("");
      }, 10000);
    }
  }, [authError, updateError]);

  const formik = useFormik({
    initialValues: {
      cpf: "",
      reCaptcha: false,
      reCaptchaValue: "",
    },
    onSubmit: (values, { setSubmitting, setFieldValue }) => {
      const params = new URLSearchParams();
      values.cpf = values.cpf
        .replace(".", "")
        .replace(".", "")
        .replace("-", "");

      params.append("recaptcha", values.reCaptchaValue.toString());

      if (
        !window.location.host.includes("promoshell-dev") &&
        !window.location.host.includes("localhost") &&
        !window.location.host.includes("192")
      ) {
        if (values.reCaptcha) {
          forgotPassword({
            cpf: values.cpf,
            recaptcha: values.reCaptchaValue.toString(),
          } as any);
        }
      } else {
        forgotPassword({
          cpf: values.cpf,
          // recaptcha: values.reCaptchaValue.toString(),
        } as any);
      }
      recaptchaRef.current.reset();
      setFieldValue("reCaptcha", false);
      setSubmitting(false);
    },
    validate: (values) => {
      let errors = {} as any;
      if (!values.cpf) {
        errors.cpf = `O Campo "CPF" é obrigatório`;
      }
      return errors;
    },
  });

  const parseDigits = (string) => (string.match(/\d+/g) || []).join("");

  const formatCpf = (string) => {
    const digits = parseDigits(string).substr(0, 11);
    return formatToCPF(digits);
  };

  return (
    <Wrapper>
      <Form
        onSubmit={formik.handleSubmit}
        style={{ marginTop: "0em" }}
        id="main"
      >
        <ContentForm>
          {authError && (
            <MessageBox bgMessage={theme.color.red}>{authError}</MessageBox>
          )}
          {messageForgotPassword && (
            <MessageBox
              bgMessage={
                intentForgotPassword === "success"
                  ? theme.color.blue
                  : theme.color.red
              }
            >
              {messageForgotPassword}
            </MessageBox>
          )}
          {formik.errors?.cpf && formik.touched.cpf && (
            <MessageBox bgMessage={theme.color.red}>
              {formik.errors?.cpf}
            </MessageBox>
          )}
          <Rifm
            accept={/\d+/g}
            format={formatCpf}
            value={formik.values.cpf}
            onChange={(e) => {
              formik.setFieldValue("cpf", e);
              updateMsgForgot({ message: "", intent: "danger" });
            }}
          >
            {({ value, onChange }) => (
              <CustomTextField
                label="CPF"
                id={formik.errors?.cpf ? "error" : "input"}
              >
                <Input
                  name="cpf"
                  type="text"
                  onChange={onChange}
                  value={value}
                  id={formik.errors?.cpf ? "error" : "input"}
                />
              </CustomTextField>
            )}
          </Rifm>
          {!window.location.host.includes("promoshell-dev") &&
          !window.location.host.includes("192") &&
          !window.location.host.includes("localhost") ? (
            <CustomReCaptcha>
              <ReCAPTCHA
                ref={recaptchaRef}
                sitekey={localStorage.getItem("promo_recaptcha")}
                onChange={(val) => {
                  formik.setFieldValue(
                    "reCaptcha",
                    val && val.length > 0 ? true : false
                  );
                  formik.setFieldValue("reCaptchaValue", val);
                }}
              />
            </CustomReCaptcha>
          ) : null}
        </ContentForm>
        <MyButton
          type="submit"
          isDisabled={
            !window.location.host.includes("promoshell-dev") &&
            !window.location.host.includes("192") &&
            !window.location.host.includes("localhost")
              ? !formik.values.reCaptcha
              : false
          }
          backgroundColor={theme.color.orange}
          rounded={0}
          border={0}
          color={"#fff"}
          fontSize={"14px"}
          fontWeight={500}
          _hover={{ bg: theme.color.orange, opacity: 0.7 }}
          cursor="pointer"
          label={isLoading ? "ENVIANDO" : "ENVIAR E-MAIL"}
        >
          <TextButton>ENVIAR E-MAIL</TextButton>
        </MyButton>
      </Form>
    </Wrapper>
  );
}

const ContentForm = styled.div`
  display: flex;
  margin-bottom: 2em;
  width: 100%;
  flex-direction: column;
  justify-content: center;
`;

const CustomReCaptcha = styled(Box)`
  display: flex;
  justify-content: center;
  margin-top: 50px;
`;

const Wrapper = styled.div`
  min-height: 70vh;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  max-width: 1400px;
  width: 100%;
  margin: 0 auto;

  @media (max-width: ${(props) => props.theme.queries.small}) {
    width: 90%;
  }
`;

const Form = styled.form`
  max-width: 350px !important;
  width: 100%;
`;

const CustomTextField = styled(TextField)`
  input {
    font-size: 18px;
  }
`;

const MyButton = styled(Button)`
  width: 100%;
  background-color: ${theme.color.orange};
  color: white;
  border-radius: 0;
`;

const TextButton = styled.span``;

const MessageBox = styled.p`
  transition: 0.3s;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Arial;
  transition: 0.2s ease-in-out;
  color: ${(props) => props.theme.color.white};
  font-size: 18px;
  line-height: 20px;
  margin-bottom: 20px;
  padding: 8.5px 2.5px;
  background-color: ${(props) => props.bgMessage};
  text-align: center;
  @media (max-width: ${(props) => props.theme.queries.small}) {
    padding: 0rem 1rem;
    margin: 0 0 10px 0;
  }
`;
