import useWindowSize from 'hooks/useWIndowSize';
import React from 'react';
import styled from 'styled-components';

// Imagens Desktop
import ImageConhecaShellboxMobile from 'assets/images/hub/home/BenefitsForYou/conheca-shellbox-mobile.png';
import ImageJuntePontosMobile from 'assets/images/hub/home/BenefitsForYou/junte-pontos-mobile.png';
// Imagens Mobile
import ImageConhecaShellbox from 'assets/images/hub/home/BenefitsForYou/conheca-shellbox.png';
import ImageJuntePontos from 'assets/images/hub/home/BenefitsForYou/junte-pontos.png';

const HowToParticipate = () => {
  const { width } = useWindowSize();

  return(
    <Section id="hub-como-participar">
      <Title>Como participar</Title>
      <Card tabIndex={0}>
        <div className="imgWrapper">
          {
            width < 768 ? (
              <img src={ImageConhecaShellboxMobile} alt="Conheça Shellbox" draggable="false" />
            ) : (
              <img src={ImageConhecaShellbox} alt="Conheça Shellbox" draggable="false" />
            )
          }
        </div>
        <div className="textWrapper">
            <h2>Lorem ipsum dolorem amet aussi consecteur</h2>
            <p>
              Mus lectus lectus tempus lacus, sed tincidunt ipsum vitae. 
              Eget ac tristique amet purus leo enim maecenas. Et non est, 
              ac velit cras cursus. Quisque et, est.
            </p>
        </div>
      </Card>
      <Card tabIndex={0} _ResponsiveFlexDirection={"row-reverse"}>
        <div className="imgWrapper">
          {
            width < 768 ? (
              <img src={ImageJuntePontosMobile} alt="Conheça Shellbox" draggable="false" />
            ) : (
              <img src={ImageJuntePontos} alt="Conheça Shellbox" draggable="false" />
            )
          }
        </div>
        <div className="textWrapper">
            <h2>Lorem ipsum dolorem amet aussi consecteur</h2>
            <p>
              Mus lectus lectus tempus lacus, sed tincidunt ipsum vitae. 
              Eget ac tristique amet purus leo enim maecenas. Et non est, 
              ac velit cras cursus. Quisque et, est.
            </p>
        </div>
      </Card>
    </Section>
  )
}

export default HowToParticipate;

const Section = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  max-width: 25rem;
  margin: 1.5rem auto;


  @media (min-width: 769px) {
    max-width: 100%;
  }
`;

const Title = styled.h1`
  font-size: 1.875rem;
  line-height: 115%;
  font-weight: bold;
  text-transform: uppercase;
  text-align: center;
  
  color: ${props => props.theme.color.text};
  margin: 1.5rem 0;

  span {
    color: ${props => props.theme.color.orange};
    
    @media (min-width: 769px) {
      color: inherit;
    }
  }
`;

const Card = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  max-width: 25rem;

  @media (min-width: 769px) {
    flex-direction: ${
      /* Se a propriedade _ResponsiveFlexDirection não for passada, o valor padrão será "row" */
      props => props._ResponsiveFlexDirection ? props._ResponsiveFlexDirection : "row" 
    };
    justify-content: space-around;
    width: 95%;
    max-width: 90rem;
    /* height: 21rem */
  }

  .imgWrapper {
    @media (min-width: 769px) {
      width: 50%;
      height: 100%;
    }

    img {
      width: 100%;
      height: 100%;
    }
  }

  .textWrapper {
    max-width: 26.5rem;

    @media (min-width: 769px) {
      width: 50%;
      justify-content: center;
      align-items: center;
      margin: 0 auto;
    }

    h2 {
      font-size: 1.563rem;
      line-height: 1.75rem;
      font-weight: 700;
      color: ${props => props.theme.color.red};
    }

    p {
      font-size: 1.125rem;
      line-height: 1.375rem;
      font-weight: 500;
      color: ${props => props.theme.color.text};
    }
  }
`;