import React, { useEffect, useState } from "react";
import { useLocation, useHistory } from "react-router-dom";
import styled from "styled-components";
import queryString from "query-string";
import InfiniteScroll from "react-infinite-scroll-component";
import { uniqBy, orderBy } from "lodash";
import { useActions, useStore } from "configureStore";

//components
import Banner from "../Offers/Banner";
import Header from "../Offers/PromoHits/Header";
import PromoCard from "../Offers/PromoHits/PromoCard";
import OulineButton from "../Offers/OutlineButton";
import BannerFooter from "../Offers/BannerFooter";

//assets
import promoHitsRedBull from "assets/images/promo-hits-red-bull.png";

const Offers = () => {
  const location = useLocation();
  const history = useHistory();
  const query = queryString.parse(location.search);
  const queryPromocao = query.promocao || "";

  //actions
  const listCategories = useActions(action => action.hub.listCategories);
  const listLocales = useActions(action => action.hub.listLocales);
  const listPromoHits = useActions(action => action.hub.listPromoHits);

  //states
  const categories = useStore(store => store.hub.categories);
  const locales = useStore(store => store.hub.locales);
  const promoHits = useStore(store => store.hub.promoHits);
  const [categoryId , setCategoryId] = useState<number|string>("empty");
  const [localeId , setLocaleId] = useState("empty");

  const [ data, setData ] = useState({ct: 0} as any);
  const [ start, setStart ] = useState(0);

  useEffect(() => {
    listCategories();
    listLocales();
  },[]);
  
  const setQueries = () => {
    if(categoryId === "empty" && localeId === "empty"){
      return "";
    } else if(categoryId !== "empty" && localeId !== "empty"){
      return queryString.stringify({ categoria: categoryId, local: localeId });
    } else if(categoryId !== "empty" && localeId === "empty"){
      return queryString.stringify({ categoria: categoryId });
    } else if(categoryId === "empty" && localeId !== "empty"){
      return queryString.stringify({ local: localeId });
    }
  }

  const setQueriesClosedPromo = () => {
    if(categoryId === "empty" && localeId === "empty"){
      return queryString.stringify({ encerrada: 1 });
    } else if(categoryId !== "empty" && localeId !== "empty"){
      return queryString.stringify({ categoria: categoryId, local: localeId, encerrada: 1 });
    } else if(categoryId !== "empty" && localeId === "empty"){
      return queryString.stringify({ categoria: categoryId, encerrada: 1 });
    } else if(categoryId === "empty" && localeId !== "empty"){
      return queryString.stringify({ local: localeId, encerrada: 1 });
    }
  }

  useEffect(() => {
    if(queryPromocao.includes("encerrada")){
      listPromoHits(setQueriesClosedPromo() as any);
    } else {
      listPromoHits(setQueries() as any);
    }
  },[queryPromocao, categoryId, localeId]);
  
  return (
    <div>
      {
        !queryPromocao.includes("encerrada") ? (<Banner/>) : null
      }
      <Container>
        <Header 
          isClosed={queryPromocao.includes("encerrada")}
          locales={locales}
          categories={categories}
          categoryId={categoryId}
          setCategoryId={setCategoryId}
          setLocaleId={setLocaleId}
          localeId={localeId}
        />

        {
          promoHits?.length > 0 ? (
            <InfiniteScroll
              dataLength={promoHits?.length}
              next={() => setStart(start + 20)}
              hasMore={promoHits?.length < data?.ct}
              style={{overflow:"hidden"}}
              loader={
                <Center marginB="52px">
                  <Loading>Carregando ...</Loading>
                </Center>
              }
            >
              <Grid>
                {promoHits?.map((item, idx) => (
                  <PromoCard
                    key={idx}
                    amount={item?.preco}
                    title={item?.nome}
                    description={item?.descricao}
                    image={item?.imagem}
                    locale={item?.categorias}
                    isClosed={queryPromocao.includes("encerrada")}
                  />
                ))}
              </Grid>
            </InfiniteScroll>
          ) : (
            <EmptyState>
              {
                queryPromocao.includes("encerrada") ? "Não há nenhuma promoção encerrada!" : "Não há nenhuma promoção ativa!"
              }
            </EmptyState>
          )
        }
        
        <Center>
          {
            !queryPromocao?.includes("encerrada") ? (
              <OulineButton
                label="ver campanhas encerradas"
                handleButton={() => history.push("/ofertas-shell-select?promocao=encerrada")}
              />
            ) : (
              <OulineButton
                label="ver promoções ativas"
                handleButton={() => history.push("/ofertas-shell-select")}
              />    
            )
          }
        </Center>
      </Container>
      <BannerFooter/>
    </div>
  )
}

export default Offers;

const Container = styled.div`
  max-width: 1400px;
  margin: 0 auto;

  @media (max-width: 1400px) {
    padding: 0 2em;
  }

  @media (max-width: 1200px) {
    padding: 0 1em;
  }
`;

const Grid = styled.div`
  margin-bottom: 50px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 20px;

  @media(max-width: ${props => props.theme.queries.medium}){
    grid-template-columns: repeat(1, 100%);
  }
`;

const Center = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: ${props => props.marginB || "92px"};

  @media(max-width: ${props => props.theme.queries.small}){
    margin-bottom: 61px;
  }
`;

const Loading = styled.div`
  text-transform: uppercase;
  font-family: "Futura Bold";
  font-weight: 500;
  color: ${props => props.theme.color.orange};
  font-size: 15px;
  line-height: 14px;

  display: flex;
  align-items: center;
  text-align: center;
`;

const EmptyState = styled.div`
  height: 200px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;