export const theme = {
  color: {
    red: "#DD1D21",
    darkRed: "#CD1519",
    yellow: "#FBCE07",
    lightYellow50: "#FDF7DC",
    lightYellow: "#F6E494",
    shellYellow: '#F9D536',
    black: "#333",
    shellBlack: "#404040",
    white: "#fff",
    gray: "#58595B",
    gray600: "#7D7D7D",
    mediumGray: "#BDBDBD",
    mediumGray2: "#9A9A9A",
    mediumGray3: "#A6A6A6",
    shellGray: "#7F7F7F",
    darkGray: "#3A3A3A",
    lightGray50: "#F7F7F7",
    lightGray200: "#D8D8D8",
    lightGray: "#EAEAEA",
    text: "#58595B",
    blue: "#3297C9",
    blueOutline: "rgba(50, 151, 201, 0.65)",
    darkBlue: "#1D5D9B",
    orange: "#E98624",
    purple: "#622563",
    grayPurple: "#E2D7E2",
    lighterPurple: "#804480",
    lightPurple: "#A155A1",
    shellPurple: "#641964",
    shellDarkPurple: "#571657",
    darkPurple: "#622563",
    shellGreen: "#008443"
  },
  queries: {
    i5: "320px",
    ix: "375px",
    small: "440px",
    mobileLg: "540px",
    medium: "768px",
    lg: "1024px",
    desk: "1280px",
  },
  fontSize: {
    normal: "0.8125rem", // ~13px
    text: "0.9375rem", // 15px
  },
  FuturaFont: `Futura LT Regular`,
  FuturaFontBold: 'Futura LT Bold',
  helvetica: 'Helvetica Neue',
  Shell: {
    Bold: 'Shell Bold',
    Book: 'Shell Book',
    BookItalic: 'Shell Book Italic',
    ConBold: 'Shell Condensed Bold',
    ConLight: 'Shell Condensed Light',
    ConMedium: 'Shell Condensed Medium',
    Heavy: 'Shell Heavy',
    Light: 'Shell Light',
    LightItalic: 'Shell Light Italic',
    Medium: 'Shell Medium',
    MediumItalic: 'Shell Medium Italic'
  }
};
