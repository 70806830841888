import React, { Dispatch, SetStateAction } from "react";
import styled from "styled-components";
import { Box, Spinner } from "@chakra-ui/core";
import { useStore } from "configureStore";

import ImgShellBg from "assets/images/anoTodo/shell-saldo-bg.png";
import ImgCoin from "assets/images/anoTodo/coin.svg";
import IconShell from "assets/icons/shell-icon.svg";

export default function CarouselFirstCard() {
  const shells = useStore((state) => state.anoTodo.shells?.balance);
  const isLoading = useStore((state) => state.anoTodo.isLoading);

  return (
    <CardContainer
      tabIndex={0}
    >
      <h1>Seu saldo:</h1>
      <BlockShells>
        <BoxShellIcon>
          <Box className="imgBlock">
            <img src={IconShell} alt="" draggable="false" />
          </Box>
        </BoxShellIcon>
        <Box
          d={"flex"}
          flexDir={"column"}
          alignItems={"center"}
          justifyContent={"center"}
          ml={"4px"}
        >
          {isLoading ? (
            <>
              <Spinner size="xs" />
            </>
          ) : (
            <>
              {/*@ts-ignore*/}
              <strong aria-label={shells}>{shells}</strong>
            </>
          )}
          <span>conchas</span>
        </Box>
      </BlockShells>
      <p>Aproveite e resgate agora!</p>
    </CardContainer >
  )
}

const CardContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  
  width: 264px;
  height: 120px;

  border-radius: 2px;
  background: url(${ImgShellBg});
  background-repeat: no-repeat;
  background-size: cover;
  cursor: default;
  
  h1 {
    font-family: ${props => props.theme.Shell.Medium};
    font-weight: 500;
    font-size: 1.25rem;
    line-height: 11px;
    color: ${props => props.theme.color.shellPurple};
    margin: 0;
    letter-spacing: -0.03em;
  }
  
  p {
    font-family: ${props => props.theme.Shell.Medium};
    font-size: ${props => props.theme.fontSize.normal};
    line-height: 11px;
    color: ${props => props.theme.color.shellPurple};

    margin: 0;
    margin-top: 3px;
    text-shadow: 0px 2px 4px rgba(64, 64, 64, 0.15)
  }

  @media (min-width: ${props => props.theme.queries.medium}) {
    width: 339px;
    height: 155px;

    h1 {
      font-size: 1.875rem;
      margin-bottom: 1rem;
    }
    p {
      font-size: ${props => props.theme.fontSize.text};
    }
  }
`;

const BlockShells = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 1rem;

  height: 46px;
  border-radius: 120px;
  background-color: ${props => props.theme.color.lightYellow};
  margin: 12px 4px 8px 0;

  strong {
    font-family: ${props => props.theme.Shell.Bold};
    font-size: 1.5rem;
    line-height: 1.125rem;
    color: ${props => props.theme.color.shellPurple};
    margin-bottom: 5px;
  }

  span {
    font-family: ${props => props.theme.Shell.Medium};
    font-size: 0.563rem;
    line-height: 11px;
    line-height: 95%;
    text-transform: uppercase;
    color: ${props => props.theme.color.shellPurple};
  }

  @media (min-width: ${props => props.theme.queries.medium}) {
    height: 60px;
    margin-top: 6px;

    strong {
      font-size: 1.75rem;
      line-height: 1.25rem;
    }

    span {
      font-size: 0.688rem;
    }
  }
`;

const BoxShellIcon = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 47px;
  height: 47px;
  background: ${props => props.theme.color.yellow};
  border-radius: 50%;

  & > .imgBlock {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 42px;
    height: 42px;
    
    background: ${props => props.theme.color.yellow};
    border-radius: 50%;
    border: 3px solid ${props => props.theme.color.lightYellow};
    box-sizing: border-box;

    img {
      width: 22px;
      height: 22px;
    }
  }

  @media (min-width: ${props => props.theme.queries.medium}) {
    width: 60px;
    height: 60px;
    
    & > .imgBlock {
      width: 56px;
      height: 56px;
      padding: 4px;
  
      img {
        width: 30px;
        height: 30px;
      }
    }
  }
`;