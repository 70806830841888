import styled, { css } from "styled-components";
import Carousel from "react-multi-carousel";

// assets
import ImageFundoCor from "assets/images/onda/fundo-cor.png";
import ImageFundoCor2 from "assets/images/onda/fundo-cor2.png";
import ImageFundoCor3 from "assets/images/onda/fundo-cor3.png";
import BgShape1 from "assets/images/curtaVida/shape1.png";
import BgShape2 from "assets/images/curtaVida/shape2.png";
import BgShape3 from "assets/images/curtaVida/shape3.png";

const Container = styled.section`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-color: ${(props) => props.theme.color.lightGray};
  padding: 2rem 0;

  @media (min-width: ${(props) => props.theme.queries.medium}) {
    padding: 4rem 0;
  }
`;

const Title = styled.h2`
  width: 90%;
  font-family: ${(props) => props.theme.Shell.Bold};
  font-size: 1rem;
  line-height: 1.375rem;
  text-transform: uppercase;
  color: ${(props) => props.theme.color.shellBlack};
  margin: 0 1rem;
  text-align: center;

  @media (min-width: ${(props) => props.theme.queries.medium}) {
    font-size: 1.25rem;
    line-height: 1.625rem;
    margin: 0 0 1.25rem;
  }
`;

const CarouselWrapper = styled.div`
  width: 95%;
  max-width: 25rem;
  margin: 0;
  margin-left: 1rem;
`;

const ProductCard = styled.div`
  width: 90%;
  height: 23.75rem;
  display: grid;
  grid-template-columns: 1;
  grid-template-rows: 4;

  border-radius: 4px;
  background-color: ${(props) =>
    props.special ? props.theme.color.yellow : "#fff"};
  margin: 1rem 0 0;
  padding: 0.5rem;
  text-align: center;
  box-shadow: 0px 2px 4px rgba(64, 64, 64, 0.15);
  box-sizing: border-box;
  position: relative;

  strong.title {
    font-family: ${(props) => props.theme.Shell.Bold};
    font-size: 0.9rem;
    line-height: 1.125rem;
    text-transform: uppercase;
    width: 80%;
    margin: 0 auto;
    color: ${(props) => props.theme.color.text};
  }

  p {
    font-family: Helvetica;
    font-size: 0.75rem;
    line-height: 0.938rem;
    font-weight: 500;
    margin: 0 auto;
    color: ${(props) => props.theme.color.text};
  }

  @media (min-width: ${(props) => props.theme.queries.medium}) {
    width: 100%;
    max-width: 20.75rem;
    height: 26.25rem;
    margin: 0 1.125rem;

    strong.title {
      font-size: 1rem;
      line-height: 1.375rem;
    }
  }

  @media (max-width: 360px) {
    strong.title {
      font-size: 85%;
    }
  }

  li.react-multi-carousel-dot {
    button {
      background: ${(props) => props.theme.color.mediumGray3};
      border: none;
      width: 6.5px;
      height: 6.5px;
      &:focus {
        outline: auto;
      }
    }
  }
  li.react-multi-carousel-dot--active {
    button {
      background: ${(props) => props.theme.color.red} !important;
    }
  }

  ${(props) =>
    props.status === 4 &&
    css`
      border: 1px solid ${(props) => props.theme.color.shellGreen};
      grid-template-rows: auto auto auto;
      height: 23.75rem;

      @media (min-width: ${(props) => props.theme.queries.medium}) {
        padding-bottom: 4rem;
      }
    `}
`;

const Shellcost = styled.div`
  width: 100%;
  height: 4.625rem;
  background: ${(props) =>
    props.special ? "#F6E494" : props.theme.color.lightGray50};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: flex-end;

  margin: 0 auto;
  padding: 1rem;
  border-radius: 4px;
  box-sizing: border-box;
  position: relative;

  img {
    position: absolute;
    top: -0.75rem;
    left: 50%;
    transform: translate(-50%);
    width: 1.875rem;
    height: 1.375rem;
  }

  span {
    font-size: 0.75rem;
    line-height: 0.938rem;
    color: ${(props) => props.theme.color.darkGray};
    font-family: Helvetica;
  }

  p {
    font-family: ${(props) => props.theme.Shell.Heavy};
    font-size: 1rem;
    font-weight: 700;
    line-height: 1.375rem;
    text-transform: uppercase;
    color: ${(props) => props.theme.color.shellPurple};

    b {
      font-family: ${(props) => props.theme.Shell.Bold};
    }
  }

  strong {
    font-family: ${(props) => props.theme.Shell.Bold};
    font-size: 1rem;
    line-height: 1rem;
    color: ${(props) => props.theme.color.red};
  }
`;

const ShellcostDesktop = styled(Shellcost)`
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 5rem;
  padding: 0.875rem;

  img {
    width: 2.125rem;
    height: 1.625rem;
  }

  p {
    display: flex;
    margin: 0;
    margin-right: 0.25rem;

    b {
      margin-right: 0.25rem;
    }
  }

  strong {
    width: unset;
    display: flex;
    justify-self: flex-end;
    margin: 0;
  }
`;

const ProductCardWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 1280px;
  margin: 0 auto;
`;

const DownloadManual = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 1rem auto 0;
  color: ${(props) => props.theme.color.darkBlue};
  visibility: ${(props) => !props.hasLink && "hidden"};
  width: 100%;
  a {
    width: 90%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.75rem;
    line-height: 0.938rem;
    color: ${(props) => props.theme.color.darkBlue};
    text-decoration: underline;
    cursor: pointer;

    img {
      margin-right: 9px;
    }

    svg {
      margin-left: 4px;
    }

    @media (min-width: ${(props) => props.theme.queries.medium}) {
      width: 100%;
    }
  }
`;

const CardsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (min-width: ${(props) => props.theme.queries.medium}) {
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
  }
`;

const ProductCardConchas = styled.div`
  width: 100%;
  max-width: 22.75rem;
  height: 29.5rem;

  display: grid;
  grid-template-columns: 1;
  grid-template-rows: 5;

  background: #fff;
  border: 1px solid #fff;
  padding: 1rem;

  border-radius: 4px;
  box-shadow: 0px 2px 4px rgba(64, 64, 64, 0.15);

  box-sizing: border-box;
  position: relative;

  ${(props) =>
    props.status === 3 &&
    css`
      border: 1px solid ${(props) => props.theme.color.shellPurple};
      grid-template-rows: 4;
      height: 22.75rem;
      padding-bottom: 4rem;
    `}

  ${(props) =>
    props.status === 4 &&
    css`
      border: 1px solid ${(props) => props.theme.color.shellGreen};
      grid-template-rows: auto auto auto;
      height: 23.75rem;
      padding-bottom: 4rem;
    `}
  
  @media (min-width: ${(props) => props.theme.queries.medium}) {
    height: 31rem;
    margin: 0 auto;
    ${(props) =>
      props.status > 2 &&
      css`
        height: 24.75rem;
      `}
  }
`;

const ProductName = styled.strong`
  font-family: "Futura lt bold";
  font-size: 1rem;
  line-height: 1.375rem;
  width: 80%;
  margin: 0.75rem auto 0;
  text-transform: uppercase;
  text-align: center;
`;

const ProductDescription = styled.p`
  font-family: Helvetica;
  font-size: 0.75rem;
  line-height: 0.938rem;
  font-weight: 500;
  text-align: center;
  margin: 0.25rem 0 0;

  color: ${(props) => props.theme.color.text};
`;

const ProductshellCost = styled.div`
  width: 100%;
  height: 5rem;
  background: ${(props) => props.theme.color.lightGray50};

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: flex-end;

  margin-top: 1.25rem;
  padding: 0.75rem;
  box-sizing: border-box;
  border-radius: 4px;
  position: relative;

  img {
    position: absolute;
    top: -0.75rem;
    left: 50%;
    transform: translate(-50%);
    width: 1.875rem;
    height: 1.375rem;
  }

  strong {
    font-family: "Futura lt bold";
  }
  strong.shellPurple {
    margin-right: 4px;
    color: ${(props) => props.theme.color.shellPurple};
  }
  strong.red {
    margin-left: 4px;
    color: ${(props) => props.theme.color.red};
  }

  span.shells {
    text-transform: uppercase;
    font-family: "Futura lt Regular";
    letter-spacing: 1px;
    font-weight: 700;
    color: ${(props) => props.theme.color.shellPurple};
  }
`;

const ProgressBar = styled.div`
  width: 100%;
  height: 8px;
  background: #fff;
  border-radius: 5rem;
  margin: 0.25rem auto;
  position: relative;
`;

const PurpleBar = styled.div`
  height: 8px;
  width: ${(props) => props.width};
  background: ${(props) => props.theme.color.shellPurple};
  border-radius: 5rem;
`;

const TextCheer = styled.span`
  font-family: Helvetica;
  font-size: 0.715rem;
  line-height: 0.875rem;
  color: ${(props) => props.theme.color.darkGray};
  margin: 0.25rem 0;
`;

const ButtonRedeem = styled.button`
  width: 100%;
  height: 2.625rem;
  font-family: Helvetica;
  font-size: 1rem;
  line-height: 1.25rem;
  color: ${(props) => props.theme.color.shellBlack};
  background: ${(props) => props.theme.color.yellow};
  margin-top: 0.5rem;

  border: none;
  border-radius: 2px;
  box-sizing: border-box;
  cursor: pointer;
  transition: 0.2s ease-in-out;

  &.inactive {
    color: ${(props) => props.theme.color.gray};
    filter: grayscale(1);
    cursor: default;
  }
  &:not(.inactive) {
    &:hover,
    &:focus {
      background: ${(props) => props.theme.color.shellPurple};
      color: #fff;
    }
  }
`;

const CardColoredFooter = styled.div`
  font-family: Helvetica;
  font-size: 0.75rem;
  line-height: 1rem;
  text-align: center;

  width: 100%;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  padding: 0.875rem;

  position: absolute;
  bottom: -1px;
  box-sizing: border-box;
  color: #fff;

  ${(props) =>
    props.status === 3 &&
    css`
      background: ${(props) => props.theme.color.shellPurple};
      border-bottom: 1px solid #b99bb9;
    `}
  ${(props) =>
    props.status === 4 &&
    css`
      background: ${(props) => props.theme.color.shellGreen};
      border-bottom: 1px solid ${(props) => props.theme.color.shellGreen};
    `}
`;

const ImageContainer = styled.div`
  width: 100%;
  height: 100%;
  max-width: 8rem;
  max-height: 8rem;
  display: flex;
  justify-content: center;
  position: relative;
  margin: 0 auto;
  margin-bottom: 0.5rem;

  background-size: contain;
  background-repeat: no-repeat;
  background-position: center 50%;
  box-sizing: border-box;

  img {
    width: 100%;
    height: 100%;
    z-index: 2;
    cursor: pointer;
  }

  ul.react-multi-carousel-dot-list {
    bottom: unset;
  }

  ${(props) =>
    props.index === 0 &&
    css`
      background-image: url(${ImageFundoCor});
    `}
  ${(props) =>
    props.index === 1 &&
    css`
      background-image: url(${ImageFundoCor2});
    `}
  ${(props) =>
    props.index === 2 &&
    css`
      background-image: url(${ImageFundoCor3});
    `}
  ${(props) =>
    props.index === 0 &&
    !props.special &&
    css`
      background-image: url(${BgShape1});
    `}
  ${(props) =>
    props.index === 1 &&
    !props.special &&
    css`
      background-image: url(${BgShape2});
    `}
  ${(props) =>
    props.index === 2 &&
    !props.special &&
    css`
      background-image: url(${BgShape3});
    `}
  ${(props) =>
    props.status >= 3 &&
    css`
      div.filter {
        width: 100%;
        height: 100%;
        backdrop-filter: grayscale(1);
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
      }
    `}  
  ${(props) =>
    props.isAnoTodo &&
    css`
      background: none;
      margin-bottom: 1rem;
      padding: 0px 8px;
      max-height: 10rem;

      img {
        height: unset;
      }
      @media (max-width: ${(props) => props.theme.queries.small}) {
        padding: 1rem 0.5rem;
        margin-top: 1rem;
      } ;
    `}

  @media (min-width: ${(props) => props.theme.queries.lg}) {
    max-width: 12rem;
    max-height: 12rem;
  }
`;

const ImageCarousel = styled(Carousel)`
  margin-left: ${(props) => props.isAnoTodo && ""};

  li.react-multi-carousel-dot {
    button {
      background: ${(props) => props.theme.color.mediumGray3};
      border: none;
      width: 6.5px;
      height: 6.5px;
      &:focus {
        outline: auto;
      }
    }
  }
  li.react-multi-carousel-dot--active {
    button {
      background: ${(props) => props.theme.color.yellow} !important;
    }
  }

  @media (min-width: ${(props) => props.theme.queries.medium}) {
    height: 12.5rem;
  }
`;

const DownloadManualLink = styled.div`
  margin: 1rem auto 0;
  color: ${(props) => props.theme.color.darkBlue};
  visibility: ${(props) => !props.hasLink && "hidden"};
  a {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.75rem;
    line-height: 0.938rem;
    color: inherit;
    text-decoration: underline;

    img {
      margin-right: 9px;
    }
  }
`;

const IconOpenWindow = styled.img`
  width: 16px;
  height: 16px;
  margin: 0 0 0 6px;
  /* border-bottom: 1px solid #404040; */
`;

export {
  Container,
  Title,
  CarouselWrapper,
  ProductCard,
  Shellcost,
  ShellcostDesktop,
  ProductCardWrapper,
  ProgressBar,
  DownloadManual,
  PurpleBar,
  ImageCarousel,
  ImageContainer,
  CardColoredFooter,
  CardsContainer,
  ProductCardConchas,
  ProductName,
  ProductDescription,
  ProductshellCost,
  ButtonRedeem,
  TextCheer,
  DownloadManualLink,
  IconOpenWindow,
};
