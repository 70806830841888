import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { Box, Tab, TabList } from "@chakra-ui/core";
import { useActions, useStore } from "configureStore";
import { Rifm } from "rifm";
import { formatToCPF } from "brazilian-values";
import { useFormik } from "formik";
import TextField, { Input } from "@material/react-text-field";
import { NavLink, useLocation } from "react-router-dom";
import { getSlug } from "utils/helper";
import ReCAPTCHA from "react-google-recaptcha";
import {
  CustomReCaptcha,
  ErrorMessage,
  LoginInputWrapper,
  LoginSubmitButton,
  MyCustomTabs,
  NewLoginContainer,
  NewLoginFormWrapper,
  NewLoginMobileWrapper,
  NewLoginSignInWrapper,
  SignInButton,
  WrapTab,
} from "./styles";
import useWindowSize from "hooks/useWIndowSize";
import { ModalFullScreen } from "modules/common/UI";
import { theme } from "theme";
import { JunteTroqueModal } from "./JunteTroqueModal";

export function NewModalLogin() {
  const [currentTab, setCurrentTab] = useState(0);
  const isOpen = useStore((state) => state.home.isOpen);
  const setIsOpen = useActions((action) => action.home.setIsOpen);
  const login = useActions((action) => action.auth.authenticateUser);
  const authError = useStore((state) => state.auth.authError);
  const isAuth = useStore((state) => state.auth.isAuthenticated);
  const loginError = useStore((state) => state.auth.loginError);
  const updateError = useActions((state) => state.auth.updateAuthError);
  const authRecaptchaError = useStore((state) => state.auth.authRecaptchaError);
  const focusRef = useRef<HTMLElement>(null);
  const setUpdateAuthRecaptchaError = useActions(
    (action) => action.auth.updateAuthRecaptchaError
  );
  const location = useLocation();
  const { url: slug } = getSlug();
  const recaptchaRef = useRef(null);
  const { width } = useWindowSize();
  const isGrandeGp = location.pathname.includes("gp-sp-2023");
  const isJunteTroque =
    location.pathname.includes("junte-troque") &&
    !location.pathname.includes("gp-sp-2023");

  const parseDigits = (string) => (string.match(/\d+/g) || []).join("");
  const formatCpf = (string) => {
    const digits = parseDigits(string).substr(0, 11);
    return formatToCPF(digits);
  };
  const formik = useFormik({
    initialValues: {
      cpf: "",
      password: "",
      reCaptcha: false,
      reCaptchaValue: "",
    },
    onSubmit: (values: any, { setSubmitting, setFieldValue }) => {
      values.cpf = values.cpf
        .replace(".", "")
        .replace(".", "")
        .replace("-", "");

      const params = new URLSearchParams();
      params.append("username", values.cpf.toString());
      params.append("password", values.password.toString());
      params.append("recaptcha", values.reCaptchaValue.toString());
      if (
        !window.location.host.includes("promoshell-dev") &&
        !window.location.host.includes("192") &&
        !window.location.host.includes("localhost")
      ) {
        if (values.reCaptcha) {
          login(params as any);
        }
      } else {
        login(params as any);
      }
      setSubmitting(false);
      recaptchaRef.current?.reset();
      setFieldValue("reCaptcha", false);
    },
    validate: (values) => {
      let errors = {} as any;
      if (!values.cpf) {
        errors.cpf = "Obrigatório";
      }
      if (!values.password) {
        errors.password = "Obrigatório";
      }
      return errors;
    },
  });

  function closeLoginDialog() {
    setIsOpen(false);
  }

  useEffect(() => {
    if (
      !window.location.host.includes("promoshell-dev") &&
      !window.location.host.includes("192") &&
      !window.location.host.includes("localhost")
    ) {
      if (authRecaptchaError && recaptchaRef?.current) {
        recaptchaRef.current?.reset();
        setUpdateAuthRecaptchaError("");
        formik.setFieldValue("reCaptcha", false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authRecaptchaError, setUpdateAuthRecaptchaError]);

  useEffect(() => {
    if (loginError) {
      setTimeout(() => {
        updateError("");
      }, 30000);
    }
  }, [loginError, updateError]);

  useEffect(() => {
    closeLoginDialog();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  useEffect(() => {
    const focusInput = document.getElementsByName("cpf");
    if (!!focusInput[0]) {
      focusInput[0].focus();
    }
  }, [isOpen]);

  if (!isOpen) {
    return null;
  }

  if (isJunteTroque) {
    return <JunteTroqueModal />;
  }

  if (isOpen && width < 1024) {
    return (
      <ModalFullScreen onClose={closeLoginDialog} isOpen={isOpen}>
        <NewLoginMobileWrapper>
          <WrapTab>
            <MyCustomTabs defaultIndex={currentTab}>
              <TabList>
                <Tab
                  tabIndex={0}
                  textTransform={"uppercase"}
                  onClick={() => {
                    setCurrentTab(0);
                  }}
                >
                  entrar
                </Tab>
                <Tab
                  tabIndex={0}
                  textTransform={"uppercase"}
                  onClick={() => {
                    setCurrentTab(1);
                  }}
                >
                  cadastre-se
                </Tab>
              </TabList>
            </MyCustomTabs>
          </WrapTab>
          {currentTab === 0 ? (
            <NewLoginFormWrapper>
              {loginError && <ErrorMessage>{loginError}</ErrorMessage>}
              <p>Já está cadastrado na PromoShell?</p>
              <p>Entre com seu CPF e senha:</p>
              <form onSubmit={formik.handleSubmit}>
                <LoginInputWrapper>
                  <Rifm
                    aria-label="CPF"
                    accept={/\d+/g}
                    format={formatCpf}
                    value={formik.values.cpf}
                    onChange={(e) => {
                      formik.setFieldValue("cpf", e);
                    }}
                  >
                    {({ value, onChange }) => (
                      <TextField
                        aria-label="CPF"
                        label="CPF"
                        id={
                          authError && authError.length > 0 ? "error" : "input"
                        }
                      >
                        <Input
                          name="cpf"
                          type="text"
                          onChange={onChange}
                          value={value}
                          id={
                            authError && authError.length > 0
                              ? "login-error"
                              : "login-input"
                          }
                        />
                      </TextField>
                    )}
                  </Rifm>
                </LoginInputWrapper>
                <LoginInputWrapper>
                  <TextField
                    aria-label="SENHA"
                    label="SENHA"
                    id={authError && authError.length > 0 ? "error" : "input"}
                  >
                    <Input
                      name="password"
                      type="password"
                      onChange={formik.handleChange}
                      value={formik.values.password}
                      autoComplete="off"
                      id={
                        authError && authError.length > 0
                          ? "pwd-error"
                          : "pwd-input"
                      }
                    />
                  </TextField>
                </LoginInputWrapper>
                <NavLink
                  to={
                    isGrandeGp
                      ? `${slug}/gp-sp-2023/esqueceu-a-senha`
                      : `${slug}/esqueceu-a-senha`
                  }
                >
                  Esqueci a senha
                </NavLink>
                {!window.location.host.includes("promoshell-dev") &&
                  !window.location.host.includes("192") &&
                  !window.location.host.includes("localhost") && (
                    <CustomReCaptcha>
                      <ReCAPTCHA
                        ref={recaptchaRef}
                        sitekey={localStorage.getItem("promo_recaptcha")}
                        onChange={(val) => {
                          formik.setFieldValue(
                            "reCaptcha",
                            val && val.length > 0 ? true : false
                          );
                          formik.setFieldValue("reCaptchaValue", val);
                        }}
                      />
                    </CustomReCaptcha>
                  )}
                <LoginSubmitButton
                  disabled={
                    !window.location.host.includes("localhost") &&
                    !window.location.host.includes("promoshell-dev.homolog")
                      ? !formik.values.reCaptcha
                      : false
                  }
                  type="submit"
                >
                  entrar
                </LoginSubmitButton>
              </form>
            </NewLoginFormWrapper>
          ) : (
            <NewLoginSignInWrapper>
              <h2>Ainda não tem cadastro?</h2>
              <p>
                Para participar da PromoShell, basta você fazer <br /> o seu
                cadastro aqui no site!
              </p>
              <SignInButton
                to={
                  isGrandeGp
                    ? `${slug}/gp-sp-2023/cadastro`
                    : `${slug}/cadastro`
                }
              >
                cadastre-se
              </SignInButton>
            </NewLoginSignInWrapper>
          )}
        </NewLoginMobileWrapper>
      </ModalFullScreen>
    );
  }

  return (
    <NewLoginContainer
      onKeyDown={(ev) => {
        if (ev.key === "Escape") {
          closeLoginDialog();
        }
      }}
    >
      <NewLoginFormWrapper>
        {loginError && <ErrorMessage>{loginError}</ErrorMessage>}
        <p>Já está cadastrado na PromoShell?</p>
        <p>Entre com seu CPF e senha:</p>
        <form onSubmit={formik.handleSubmit}>
          <LoginInputWrapper>
            <Rifm
              aria-label="CPF"
              accept={/\d+/g}
              format={formatCpf}
              value={formik.values.cpf}
              onChange={(e) => {
                formik.setFieldValue("cpf", e);
              }}
            >
              {({ value, onChange }) => (
                <TextField
                  aria-label="CPF"
                  label="CPF"
                  id={authError && authError.length > 0 ? "error" : "input"}
                >
                  <Input
                    name="cpf"
                    type="text"
                    onChange={onChange}
                    value={value}
                    id={
                      authError && authError.length > 0
                        ? "login-error"
                        : "login-input"
                    }
                  />
                </TextField>
              )}
            </Rifm>
          </LoginInputWrapper>
          <LoginInputWrapper>
            <TextField
              aria-label="SENHA"
              label="SENHA"
              id={authError && authError.length > 0 ? "error" : "input"}
            >
              <Input
                name="password"
                type="password"
                onChange={formik.handleChange}
                value={formik.values.password}
                autoComplete="off"
                id={
                  authError && authError.length > 0 ? "pwd-error" : "pwd-input"
                }
              />
            </TextField>
          </LoginInputWrapper>
          <NavLink
            to={
              isGrandeGp
                ? `${slug}/gp-sp-2023/esqueceu-a-senha`
                : `${slug}/esqueceu-a-senha`
            }
          >
            Esqueci a senha
          </NavLink>
          {!window.location.host.includes("promoshell-dev") &&
            !window.location.host.includes("192") &&
            !window.location.host.includes("localhost") && (
              <CustomReCaptcha>
                <ReCAPTCHA
                  ref={recaptchaRef}
                  sitekey={localStorage.getItem("promo_recaptcha")}
                  onChange={(val) => {
                    formik.setFieldValue(
                      "reCaptcha",
                      val && val.length > 0 ? true : false
                    );
                    formik.setFieldValue("reCaptchaValue", val);
                  }}
                />
              </CustomReCaptcha>
            )}
          <LoginSubmitButton
            disabled={
              !window.location.host.includes("localhost") &&
              !window.location.host.includes("promoshell-dev.homolog")
                ? !formik.values.reCaptcha
                : false
            }
            type="submit"
          >
            entrar
          </LoginSubmitButton>
        </form>
      </NewLoginFormWrapper>
      <NewLoginSignInWrapper>
        <h2>Ainda não tem cadastro?</h2>
        <p>
          Para participar da PromoShell, basta você fazer o seu cadastro aqui no
          site!
        </p>
        <SignInButton
          to={isGrandeGp ? `${slug}/gp-sp-2023/cadastro` : `${slug}/cadastro`}
        >
          cadastre-se
        </SignInButton>
      </NewLoginSignInWrapper>
    </NewLoginContainer>
  );
}
