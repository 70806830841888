import React, { useCallback, useEffect, useState } from "react";
import useWindowSize from "hooks/useWIndowSize";
import {
  ImageCarousel,
  ImageContainer,
} from "modules/common/ConhecaProdutoStyles";
import { Box, Spinner } from "@chakra-ui/core";
import { ProductCardLabel } from "./ProductCardLabel";
import { useActions, useStore } from "configureStore";
import { GetUrlShop } from "services/shell";
import { ProductsProps } from "models/anoTodo";
import { pushDataLayer } from "utils/pushDataLayer";
import {
  BoxPriceParagraph,
  BoxQuero,
  ButtonQueroProducts,
  ContainerCategory,
  ContainerSection,
  ProductCard,
} from "./SectionProductCardsStyles";
// import { useSSO } from "hooks/useSSO";
import { get } from "lodash";
import { auth as useAuth } from "hooks/auth";
import Cookies from "js-cookie";

import IconDownload from "assets/images/anoTodo/icons/download-icon.svg";
import ProductsCategory from "./ProductsCategory";

type ModalContentProps = {
  title: string;
  description: string;
  status?: number;
  shellCost: number;
  imgArray: Array<any>;
  especial?: boolean;
  index?: number;
};
let count = 0;
let calledLoad = false;
const cookieTokenName = "shell-sso-token";
const cookieURLName = "shell-sso-redirectURL";

export default function SectionProductCards() {
  const [modalContent, setModalContent] = useState<ModalContentProps>();
  const [cardList, setCardList] = useState<ProductsProps[]>();
  const [gridLines, setGridLines] = useState<number>(1);
  const { width } = useWindowSize();
  const products = useStore((state) => state.anoTodo.products);
  const isLoading = useStore((state) => state.anoTodo.isProductLoading);
  const setActive = useActions((action) => action.loading.setActive);
  const [mouseXpos, setMouseXpos] = useState<number>(null);
  const buttonClickCount = useStore((state) => state.anoTodo.buttonClickCount);
  const setButtonClickCount = useActions((action) => action.anoTodo.setButtonClickCount);
  const categoryProducts = useStore((state) => state.anoTodo.categoryProducts);
  const shells = useStore((state) => state.anoTodo.shells?.balance);
  const forceUpdate = React.useReducer(() => ({}), {})[1] as () => void;
  // const { handleRedeemSelectedProduct, responseSSO } = useSSO();
  const { auth, cachedAuth } = useAuth(true);
  const isAuth =
    (!!get(auth, "token", false) || !!get(cachedAuth, "token", false)) &&
    !window.location.pathname.includes("login") &&
    !window.location.pathname.includes("register") &&
    !window.location.pathname.includes("resetPassword");
  const isShellbox = window.location.search.includes("shellbox");
  const isLoadingCatProd = useStore(
    (state) => state.anoTodo.isLoadingCatProducts
  );
  const [session, setSession] = useState<any>(null);

  const imgResponsive = {
    desktop: {
      breakpoint: { max: 4000, min: 1024 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 1023, min: 0 },
      items: 1,
    },
  };

  // function handleOpenModalContent(
  //   title: string,
  //   description: string,
  //   shells: number,
  //   imgLarge: {
  //     arquivo: string;
  //     descricao: string;
  //   }[]
  // ) {
  //   setModalContent({
  //     title: title,
  //     description: description,
  //     shellCost: shells,
  //     imgArray: imgLarge,
  //   });
  //   onOpen();
  // }

  // function handleWebviewExternalLink(url: string) {
  //   if (isShellbox) {
  //     try {
  //       // @ts-ignore
  //       WebViewJavascriptInterface.openExternalLink(url);
  //     } catch (_) {
  //       window.open(url, "_blank", "noopener noreferer");
  //       window.focus();
  //     }
  //     setActive(true);
  //   }
  // }

  // function handleSlideClick(card: ProductsProps, mousePos: number) {
  //   let xPos = mousePos;
  //   if (xPos - mouseXpos === 0) {
  //     handleRedeemSelectedProduct(card?.productId, formElementId);
  //   }
  // }

  useEffect(() => {
    setCardList(products);
  }, [products]);

  useEffect(() => {
    if (width < 1024) {
      setGridLines(Math.ceil(cardList?.length / 2));
    } else {
      setGridLines(Math.ceil(cardList?.length / 4));
    }
  }, [cardList, width]);

  useEffect(() => {
    if (cardList && cardList.length > 0) {
      let itemsProducts = [];

      for (let i = 0; i < cardList.length; i++) {
        const card = cardList[i];
        itemsProducts.push({
          name: card.name, // Nome do produto
          id: card.productId, // Id do produto
          price: card.price, // Preço do produto
          brand: "Shell", // Marca do produto
          category: card.tag?.[0]?.titulo, // Categoria do produto
          variant: "", // Variação do produto
          list: "Home", //Nome da lista
          position: i, // Posição do produto na lista
        });
      }

      pushDataLayer({ ecommerce: null });

      pushDataLayer({
        event: "productImpression",
        ecommerce: {
          currencyCode: "BRL",
          impressions: itemsProducts,
        },
      });
    }
  }, [cardList]);

  useEffect(() => {
    async function load() {
      const cookieToken = Cookies.get(cookieTokenName);
      const cookieURL = Cookies.get(cookieURLName);

      if (
        cookieToken !== "undefined" &&
        cookieToken !== "" &&
        Boolean(!!cookieToken) &&
        cookieURL !== "undefined" &&
        cookieURL !== "" &&
        Boolean(!!cookieURL)
      ) {
        setSession({
          token: cookieToken,
          redirectURL: cookieURL,
        });
      } else {
        while (count < 3) {
          try {
            if (session) {
              count = 3;
              break;
            }
            const res = await GetUrlShop();
            setSession(res?.data?.data);
            count = 3;

            Cookies.set(cookieTokenName, res?.data?.data?.token, {
              expires: res.data.data.expiresIn / 60 / 1440,
            });
            Cookies.set(cookieURLName, res?.data?.data?.redirectURL, {
              expires: res.data.data.expiresIn / 60 / 1440,
            });
          } catch (error) {
            count++;
          }
          await new Promise((resolve) => setTimeout(resolve, 1000));
        }
      }
    }

    if (
      isAuth &&
      !isLoadingCatProd &&
      (cardList?.length > 0 || categoryProducts?.length > 0)
    ) {
      calledLoad = true;
      load();
    }

    if (!calledLoad) {
      forceUpdate();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isAuth,
    shells,
    isShellbox,
    isLoadingCatProd,
    cardList,
    categoryProducts,
  ]);

  const getActionForm = useCallback(
    (productId: any) => {
      return `https://${
        session?.redirectURL || "shelljunte-troque.shop"
      }?acesso=1&webview=1&produto=${productId}`;
    },
    [session]
  );

  function formSubmitting() {
    setButtonClickCount(0)
    setActive(true)
  }

  return (
    <ContainerCategory>
      <ProductsCategory session={session} />
      <ContainerSection>
        {cardList?.map((card, index) => (
          <form
            id={`prd-${card?.productId}`}
            action={`${getActionForm(card?.productId)}`}
            onSubmit={formSubmitting}
            method="post"
            target="_self"
          >
            <input
              type="hidden"
              name="token"
              id="token"
              value={`Bearer ${session?.token}`}
            ></input>
            <input
              type="hidden"
              name="webview"
              id="webview"
              value={1}
            ></input>
            <input type="hidden" name="acesso" value={1}></input>
            <input
              type="hidden"
              name="produto"
              id="produto"
              value={card?.productId || ""}
            ></input>
            <ProductCard id={`product-${card?.productId}`} key={card.productId}>
              {isLoading ? (
                <Box
                  w={"100%"}
                  d={"flex"}
                  justifyContent={"center"}
                  alignItems={"center"}
                >
                  <Spinner
                    thickness="4px"
                    speed="0.65s"
                    emptyColor="gray.200"
                    color="yellow.500"
                    size="xl"
                  />
                </Box>
              ) : (
                <>
                  <ProductCardLabel
                    title={card?.tag[0]?.titulo}
                    bgColor={card?.tag[0]?.cor_fundo}
                    titleColor={card?.tag[0]?.cor_titulo}
                  />

                  <ImageCarousel
                    responsive={imgResponsive}
                    showDots
                    infinite
                    arrows={false}
                    renderDotsOutside
                    isAnoTodo={true}
                  >
                    {card.imgSmall.map(({ arquivo, descricao }) => (
                      <ImageContainer
                        shellCost={card.shellCost}
                        status={null}
                        index={index}
                        key={card.productId}
                        isAnoTodo
                      >
                        <img
                          src={arquivo}
                          alt={`Imagens do Produto ${card.name}`}
                          draggable="false"
                          onClick={(e) => {
                            // handleSlideClick(card, e.pageX);
                          }}
                          onMouseDown={(e) => {
                            setMouseXpos(e.pageX);
                          }}
                          onMouseUp={(e) => {
                            // handleSlideClick(card, e.pageX);
                          }}
                        />
                      </ImageContainer>
                    ))}
                  </ImageCarousel>

                  <Box
                    d={"flex"}
                    flexDir={"column"}
                    w={"95%"}
                    m={"auto"}
                    position={"relative"}
                  >
                    <h2>{card.name}</h2>
                    {width >= 1024 && (
                      <Box>
                        <p>{card.description}</p>
                        <Box>
                          {Boolean(card.manual) && (
                            <a
                              href={card.manual}
                              target="_blank"
                              rel="noopener noreferrer"
                              aria-label="Ao clicar no link será aberto uma nova aba com um arquivo no formato PDF."
                              download
                            >
                              <img
                                className="dlicon"
                                src={IconDownload}
                                alt=""
                                draggable="false"
                              />
                              Baixar manual
                            </a>
                          )}
                        </Box>
                      </Box>
                    )}
                  </Box>

                  <BoxQuero>
                    <BoxPriceParagraph indisponivel={card.indisponivel}>
                      {card.shellCost}{" "}
                      <b>{card.shellCost === 1 ? "concha" : "conchas"}</b>{" "}
                      <span className="red">+</span> <br />{" "}
                      <strong>{card.priceShells}</strong>
                      {card.price && (
                        <Box mt={"4px"} mb={["6px", "6px", "8px"]}>
                          {" "}
                          <span>ou {card.price}</span>
                        </Box>
                      )}
                    </BoxPriceParagraph>
                    {card.indisponivel === false &&
                      (buttonClickCount < 1 ? (
                        <ButtonQueroProducts className={"disabled"}>
                          <Spinner />
                        </ButtonQueroProducts>
                      ) : (
                        <ButtonQueroProducts
                        // onClick={() =>
                        //   handleRedeemSelectedProduct(
                        //     card.productId,
                        //     formElementId
                        //   )
                        // }
                        type="submit"
                        >
                          quero!
                        </ButtonQueroProducts>
                      ))}
                  </BoxQuero>
                </>
              )}
            </ProductCard>
          </form>
        ))}
      </ContainerSection>
      {/* <CustomProductModal
        isOpen={isOpen}
        onClose={onClose}
        modalContent={modalContent}
      /> */}

      {/* SSO */}
      {/* <form
        action={`https://${
          responseSSO?.data?.redirectURL || "shelljunte-troque.shop"
        }?acesso=1&webview=${
          window.location.search.includes("shellbox") ? 1 : 0
        }&produto=${responseSSO?.productId}`}
        style={{ display: "none" }}
        method="post"
        target="_self"
        id={formElementId}
      >
        <input
          type="hidden"
          name="token"
          id="token"
          value={`Bearer ${responseSSO?.data?.token}`}
        ></input>
        <input
          type="hidden"
          name="webview"
          id="webview"
          value={window.location.search.includes("shellbox") ? 1 : 0}
        ></input>
        <input type="hidden" name="acesso" value={1}></input>
        <input
          type="hidden"
          name="produto"
          id="produto"
          value={responseSSO?.productId || ""}
        ></input>
      </form> */}
    </ContainerCategory>
  );
}
