import { Text } from "@chakra-ui/core";
import { Link } from "react-router-dom";
import styled from "styled-components";

export const Container = styled.main`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 100%;

  margin-top: 2.5rem;

  @media (min-width: ${(props) => props.theme.queries.lg}) {
    margin-top: 5.5rem;
  }
`;

export const HeaderSection = styled.header`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => props.theme.color.yellow};
  width: 100%;
  position: relative;

  @media (min-width: ${(props) => props.theme.queries.lg}) {
    align-items: flex-start;

    & > img {
      position: absolute;
      right: calc(0px + 2vw);
      top: 25px;
      z-index: 3;
    }
  }

  @media (min-width: 1600px) {
    & > img {
      right: 175px;
    }
  }
`;

export const HeaderContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  gap: 20px;

  & > img {
    width: 100%;
    max-width: 942px;
  }

  @media (min-width: ${(props) => props.theme.queries.lg}) {
    justify-content: flex-end;
    /* align-items: flex-end; */
    width: 50%;
    height: 590px;
    gap: 0;

    & > img {
      height: 590px;
      position: absolute;
      right: calc(0px - 942px + 55vw);
      z-index: 1;
    }
  }

  @media (min-width: 1600px) {
    & > img {
      right: 0;
    }
  }
`;

export const ButtonCTA = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.theme.color.red};
  padding: 22px 36px;
  border: 2px solid ${(props) => props.theme.color.red};
  margin: 12px auto 0;

  color: ${(props) => props.theme.color.white};
  font-family: ${(props) => props.theme.FuturaFontBold};
  font-size: 1.25rem;
  line-height: 1.125rem;
  text-transform: uppercase;

  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }

  &.variant {
    background-color: #fff;
    color: ${(props) => props.theme.color.red};
    margin: 0;
  }

  @media (min-width: ${(props) => props.theme.queries.lg}) {
    width: 302px;

    margin: 0;
  }
`;

export const GrayBlock = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-end;

  width: 100%;
  height: 78px;
  background-color: #6c747b;
  margin-top: 4rem;

  img {
    width: 312px;
    margin: 0 auto;
  }

  @media (min-width: ${(props) => props.theme.queries.lg}) {
    width: 100vw;
    height: 92px;

    justify-content: flex-start;

    img {
      width: 470px;
    }
  }
`;

export const LetteringContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 100%;
  gap: 20px;

  @media (min-width: ${(props) => props.theme.queries.lg}) {
    position: relative;
    width: 50vw;
    align-items: center;
    gap: 48px;
  }

  @media (min-width: ${(props) => props.theme.queries.desk}) {
    margin-left: -2vw;
  }

  @media (min-width: 1600px) {
    margin-left: -4vw;
  }
`;
// Main section
export const MainSection = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 3.875rem;
  gap: 48px;
  padding-bottom: 4.25rem;

  article + article {
    margin-top: 3rem;
  }

  @media (min-width: ${(props) => props.theme.queries.lg}) {
    gap: 64px;
    article + article {
      margin-top: unset;
    }
  }
`;

export const InfoColoredText = styled.article`
  display: flex;
  flex-direction: column;

  p {
    font-family: ${(props) => props.theme.Shell.Heavy};
    font-size: 1.75rem;
    font-weight: bold;
    line-height: 1.2;
    text-align: center;
    color: ${(props) => props.theme.color.gray};
    margin: 0;

    img {
      margin-right: 1.5rem;
    }
  }

  @media (min-width: ${(props) => props.theme.queries.lg}) {
    p {
      font-size: 2.25rem;

      img {
        width: 84px;
      }
    }
  }
`;

export const InfoRedText = styled.span`
  font-family: ${(props) => props.theme.Shell.Heavy};
  font-size: 2rem;
  line-height: 1;
  color: ${(props) => props.theme.color.red};

  &.xl {
    font-size: 6.25rem;
  }

  &.large {
    font-size: 5.5rem;
    line-height: 1;
  }

  @media (min-width: ${(props) => props.theme.queries.lg}) {
    font-size: 3rem;
  }
`;
