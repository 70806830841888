import styled from "styled-components"
import IconChevronRight from "assets/icons/arrow-footer.svg";

export const FooterContainer = styled.footer`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-bottom: 1rem;

  color: ${props => props.theme.color.text};

  @media (min-width: ${props => props.theme.queries.lg}) {
    border-top: 1px solid ${props => props.theme.color.lightGray};
  }
`
export const FooterLinkWrapper = styled.section`
  display: flex;
  flex-direction: column;
  width: 100%;

  border-top: 1px solid ${props => props.theme.color.mediumGray};

  a, button {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    font-family: ${props => props.theme.FuturaFont};
    font-size: 1.25rem;
    line-height: 70%;
    font-weight: 500;
    text-decoration: none;
    color: ${props => props.theme.color.text};
    
    background: transparent;
    border: none;
    border-bottom: 1px solid ${props => props.theme.color.mediumGray};
    
    padding: 1.325rem 1.25rem;
    transition: all .2s ease-in-out;
    position: relative;
    cursor: pointer;

    &::after {
      content: url(${IconChevronRight});
      position: absolute;
      right: 5%;
      top: 50%;
      transform: translateY(-50%);
    }

    &:hover, &:focus {
      color: ${props => props.theme.color.red};
      text-decoration: underline;
    }

    svg {
      margin-left: 4px;
      line-height: 0;
    }
  }

  @media (min-width: ${props => props.theme.queries.lg}) {
    flex-direction: row;
    gap: 8rem;

    padding: 1.375rem 5vw;
    border: none;

    a, button {
      border: none;
      font-size: 0.875rem;
      font-weight: 700;
      font-family: ${props => props.theme.FuturaFontBold};
      padding: 0.25rem 0;

      &::after {
        content: "";
      }
    }
  }
`

export const FooterInfoWrapper = styled.section`
  display: flex;
  flex-direction: column-reverse;
  width: 100%;
  margin: 0 auto;

  @media (min-width: ${props => props.theme.queries.lg}) {
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    border-top: 1px solid ${props => props.theme.color.lightGray};
    gap: 2rem;

    padding: 1.5rem 5vw;
  }
`

export const FooterCopyright = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  padding: 0.625rem 1rem;

  img {
    width: 35px;

  }
  span {
    font-family: ${props => props.theme.FuturaFont};
    font-size: 0.75rem;
    line-height: 0.875rem;
    font-weight: 500; 
  }

  @media (min-width: ${props => props.theme.queries.lg}) {
    width: 100%;
    max-width: fit-content;
    gap: 26px;
    padding: 0;

    span {
      font-size: ${props => props.theme.fontSize.text};
    }
  }
`

export const FooterNotice = styled.div`
  display: flex;
  width: 100%;
  border-bottom: 1px solid ${props => props.theme.color.mediumGray};
  padding: 1.5rem 1.25rem;

  p {
    font-family: ${props => props.theme.Shell.Medium};
    font-size: 0.6875rem;
    line-height: 0.75rem;
    font-style: normal;
    font-weight: 500;
    margin: 0;

    a {
      color: ${props => props.theme.color.red};
      text-decoration: none;
      
      &:hover {
        text-decoration: underline;
      }
    }
  }

  @media (min-width: ${props => props.theme.queries.lg}) {
    border: none;
    margin-left: auto;
    width: 100%;
    max-width: 965px;
    padding: 0;

    p {
      font-size: 0.75rem;
      line-height: 0.875rem;
    }
  }
`