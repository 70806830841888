import { useActions } from 'configureStore';
import { useRouter } from 'hooks/useRouter';
import React, { ReactNode } from 'react';
import { FiExternalLink } from 'react-icons/fi';
import styled from 'styled-components';

type LinkAccessibilityProps = {
  // ariaLabelText: string,
  href: string,
  children: ReactNode,
  isNewTab?: boolean
}

export function LinkAccessibility({ children, href, isNewTab }: LinkAccessibilityProps) {
  const isShellbox = window.location.search.includes("shellbox");
  const setActive = useActions((actions) => actions.loading.setActive);
  const router = useRouter();

  if (isShellbox) {
    return (
      <Link
        onClick={() => {
          if (isNewTab) {
            try {
              // @ts-ignore
              WebViewJavascriptInterface.openExternalLink(href);
            } catch (_) {
              window.open(
                href,
                "_blank",
                "noopener"
              );
              window.focus();
            }
            setActive(true)
          } else {
            router.push(href);
          }
        }}
      >
        {children}<FiExternalLink size={16} />
      </Link>
    )
  }

  return (
    <Link
      href={href}
      aria-label={`${children}, abre em uma nova guia`}
      target={isNewTab ? "_blank" : ""}
      rel="noopener noreferer"
    >
      {children}<FiExternalLink size={16} />
    </Link>
  )
}

const Link = styled.a`
  display: inline-block;

  & > svg {
    margin-left: 2px;
  }
`;