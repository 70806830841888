import { request } from "../utils/api";

export const GetOrder = () => {
  return request({
    method: "get",
    baseUrl: "api",
    route: "order/history"
  });
};

export const GetOrderDetail = ({id}) => {
  return request({
    method: "get",
    baseUrl: "api",
    route: `order/${id}`
  });
};

export const GetOrderTracking = ({id}) => {
  return request({
    method: "get",
    baseUrl: "api",
    route: `order/${id}/tracking`
  });
};

export const GetOrdersJunteGanhe = () => {
  return request({
    method: "get",
    baseUrl: "api",
    route: `transaction/history?offset=0&limit=200`
  });
};