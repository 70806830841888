import { thunk, action, Action, Thunk, debug } from "easy-peasy";
import { GetTickets } from "services/grandeGpSp";

interface TicketProps {
  transactionDate: string
  promotionalCodes: string[]
  merchantId: string
  merchantName: string
}

export interface GrandeGpModel {
  isTicketLoading: boolean
  tickets: TicketProps[]
  filteredTickets: TicketProps[]

  setTickets: Action<GrandeGpModel, TicketProps[]>
  setTicketLoading: Action<GrandeGpModel, boolean>
  setTicketsByDate: Action<GrandeGpModel, { firstDate: string, secondDate: string }>

  getAllTickets: Thunk<GrandeGpModel, void>
  // getTicketsOnDateRange: Thunk<GrandeGpModel, { firstDate: string, secondDate: string }>
}

const grandeGpSp: GrandeGpModel = {
  isTicketLoading: false,
  tickets: [],
  filteredTickets: [],

  setTicketLoading: action((state, payload) => {
    state.isTicketLoading = payload
  }),
  setTickets: action((state, payload) => {
    state.filteredTickets = payload
    state.tickets = payload
  }),
  setTicketsByDate: action((state, payload) => {
    const dateFormatter = new Intl.DateTimeFormat('pt-br')

    const firstDate = new Date(`${payload.firstDate}T00:00:00`)
    const secondDate = new Date(`${payload.secondDate}T23:59:59`)
    const userTickets = state.tickets

    const ticketsOnDateRange = userTickets.filter((ticket) =>
      // se a data for maior que a primeira data fornecida
      new Date(ticket.transactionDate).getTime() >= firstDate.getTime() &&
      // se a data for menor que a segunda data fornecida
      new Date(ticket.transactionDate).getTime() <= secondDate.getTime()
    )

    const validTickets = ticketsOnDateRange.map((ticket) => {
      return {
        ...ticket,
        promotionalCodes: ticket.promotionalCodes.filter((code) => code.length === 7),
        transactionDate: dateFormatter.format(new Date(ticket.transactionDate))
      }
    })

    state.filteredTickets = validTickets
  }),

  getAllTickets: thunk(async (action, payload) => {
    try {
      action.setTicketLoading(true)
      const response = await GetTickets()
      const tickets: TicketProps[] = response.data?.data?.items
      action.setTickets(tickets)
      action.setTicketsByDate({ firstDate: "2023-08-15", secondDate: "2023-09-07" })
      // A linha abaixo é para visualização de todos os tickets durante desenvolvimento
      // action.setTicketsByDate({ firstDate: "2023-08-01", secondDate: "2023-09-30" })
      action.setTicketLoading(false)
    } catch (err) {
      console.error(err)
      action.setTicketLoading(false)
    }
  }),
}

export default grandeGpSp