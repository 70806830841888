import React from "react";
import styled from "styled-components";
import { HiOutlineArrowRight, HiOutlineArrowLeft } from "react-icons/hi";

const CustomButtonGroup = ({ next, previous, carouselState }) => {
  const { totalItems, currentSlide } = carouselState;
  const totalSlideIndex = totalItems - 1;

  const totalElements =
    document.getElementsByClassName("react-multi-carousel-track")[0]
      .childElementCount - 1;

  return (
    <div className="custom-button-group">
      <ShellCarouselButton
        onClick={() => previous()}
        className={`custom-left-arrow ${currentSlide <= 0 ? "disabled" : ""}`}
      >
        <HiOutlineArrowLeft color={"#FFF"} size={24} />
      </ShellCarouselButton>
      <ShellCarouselButton
        onClick={() => next()}
        className={`custom-right-arrow ${
          currentSlide >= totalSlideIndex ? "disabled" : ""
        }`}
      >
        <HiOutlineArrowRight color={"#FFF"} size={24} />
      </ShellCarouselButton>
    </div>
  );
};

export { CustomButtonGroup };

const ShellCarouselButton = styled.button`
  width: 2.5rem;
  height: 2.5rem;

  font-size: 0;
  background: ${(props) => props.theme.color.red};
  color: #fff;
  border: 0;

  cursor: pointer;
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  z-index: 10;

  transition: 0.2s;

  &.disabled {
    background: ${(props) => props.theme.color.lightGray};
    cursor: default;
  }

  &.custom-left-arrow {
    left: 5%;
    @media (min-width: 768px) {
      left: 0%;
    }
  }
  &.custom-right-arrow {
    right: 5%;
    @media (min-width: 768px) {
      right: 0%;
    }
  }

  &:hover:not(.disabled),
  &:focus:not(.disabled) {
    background: ${(props) => props.theme.color.orange};
  }
`;
