import { useActions, useStore } from "configureStore";

export default function useScrollToModalLogin() {
  const setIsOpen = useActions((action) => action.home.setIsOpen)
  const isOpen = useStore((state) => state.home.isOpen)
  const isAuthenticated = useStore((state) => state.auth.isAuthenticated)

  function handleLoginClick() {
    if (isAuthenticated) {
      return
    }

    if (!isOpen) {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      })
      const timeout = setTimeout(() => {
        setIsOpen(true)
      }, 600);

      return () => clearTimeout(timeout)
    }
  }

  return handleLoginClick
}