import React from "react"
import styled from "styled-components"

//Assets
import IconWpp from "assets/images/grandeGp/whatsapp.svg"
import IconWppWord from "assets/images/grandeGp/whatsapp-word.png"
import { useActions } from "configureStore"

export function ButtonWhatsApp() {
  const phoneNumber = "551934012343"
  const isShellbox = window.location.search.includes("shellbox")
  const setActive = useActions((action) => action.loading.setActive)

  function handleShellboxLink() {
    try {
      // @ts-ignore
      WebViewJavascriptInterface.openExternalLink(
        "https://www.shell.com.br/termos-e-condicoes.html"
      );
    } catch (_) {
      window.open(
        "https://www.shell.com.br/termos-e-condicoes.html",
        "_blank",
        "noopener"
      );
      window.focus();
    }
    setActive(true);
  }

  if (isShellbox) {
    return (
      <LinkWhatsApp
        onClick={handleShellboxLink}
        aria-label={`whatsapp ${phoneNumber}, abre em uma nova guia`}
      >
        <Content>
          <img src={IconWpp} alt="" draggable="false" />
          <img src={IconWppWord} alt="" draggable="false" />
        </Content>
      </LinkWhatsApp>
    )
  }

  return (
    <a
      aria-label={`whatsapp ${phoneNumber}, abre em uma nova guia`}
      href={`https://wa.me/${phoneNumber}`}
      target="_blank"
      rel="noopener noreferrer"
    >
      <Content>
        <img src={IconWpp} alt="" draggable="false" />
        <img src={IconWppWord} alt="" draggable="false" />
      </Content>
    </a>
  )
}

const LinkWhatsApp = styled.button`
  border: none;
  background: transparent;
  cursor: pointer;
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 6px;

  width: 72px;
  height: 56px;
  
  padding: 0.5rem 0.75rem;
  
  background: #00C938;
  box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.20);
  border-top-left-radius: 13px;
  border-bottom-left-radius: 13px;

  position: fixed;
  right: 0;
  top: 75%;
  
  z-index: 999;
  
  img:first-child {
    width: 28px;
  }
  img:last-child {
    width: 45px;
  }

  @media (min-width: ${props => props.theme.queries.desk}) {
    width: 106px;
    height: 83px;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;

    img:first-child {
      width: 42px;
    }
    img:last-child {
      width: 68px;
    }
  }
`