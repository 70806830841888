import React from "react";
import { Box } from "@chakra-ui/core";
import styled from "styled-components";
import queryString from "query-string";
import { useLocation } from "react-router-dom";
import useShellbox from "hooks/useShellbox";

const TopBar = ({ title }) => {
  const location = useLocation();
  const query = queryString.parse(location.search);
  const queryshellBox = query.shellbox;
  const isShellbox = useShellbox();
  const isPasswordRecover = Boolean(
    window.location.pathname.includes("esqueceu-a-senha")
  );

  return (
    <>
      {queryshellBox?.includes("hiddenComponent") ? (
        <CustomBoxHiddenComponent
          backgroundColor="#E98624"
          isPasswordRecover={isPasswordRecover}
        >
          <Box
            as="h2"
            fontSize="24px"
            color="white"
            fontWeight="500"
            margin="0 auto"
            maxWidth="1490px"
            fontFamily="Futura LT Regular"
          >
            {title}
          </Box>
        </CustomBoxHiddenComponent>
      ) : (
        <CustomBox
          isShellbox={isShellbox}
          backgroundColor="#E98624"
          isPasswordRecover={isPasswordRecover}
        >
          <Box
            as="h2"
            fontSize="24px"
            color="white"
            fontWeight="500"
            margin="0 auto"
            maxWidth="1490px"
            fontFamily="Shell Medium"
          >
            {title}
          </Box>
        </CustomBox>
      )}
    </>
  );
};

export default TopBar;

const CustomBox = styled(Box)`
  padding: 1rem;
  font-family: ${(props) => props.theme.Shell.Medium};

  margin-top: ${(props) => (!props.isPasswordRecover ? "6.875rem" : "45px")};

  @media (max-width: ${(props) => props.theme.queries.medium}) {
    margin-top: ${(props) =>
      props.isShellbox ? "0" : !props.isPasswordRecover ? "110px" : "48px"};
  }
  @media (max-width: ${(props) => props.theme.queries.small}) {
    padding: 0.91rem;
    font-size: 22px;
  }
`;

const CustomBoxHiddenComponent = styled(Box)`
  font-family: ${(props) => props.theme.Shell.Medium};
  padding: 1rem;
  @media (max-width: ${(props) => props.theme.queries.small}) {
    padding: 0.91rem;
  }
`;
