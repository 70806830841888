import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { sortBy, uniqBy } from "lodash";
import useDebounce from "hooks/useDebounce";
import ReactSelect from "react-select";
import useWindowSize from "hooks/useWIndowSize";
import { useRouter } from "hooks/useRouter";
import { removeAccents } from "utils/helper";
import { Box } from "@chakra-ui/core";
// Json
import postosJson from "utils/postos-junte-troque-oanotodo.json";
import estados from "utils/estados.json";
// assets
import shellStation from "assets/images/menos15/posto-shell.png";
import shellStationMobile from "assets/images/menos15/posto-shell-mobile.png";
import IconBombaColorida from "assets/images/anoTodo/icons/posto-bomba.svg";
import { RiTreasureMapFill } from "react-icons/ri";

interface IParticipatingStations {
  data?: any[];
}

export function PostosParticipantesAnoTodo({ data }: IParticipatingStations) {
  const cityRef = useRef<any>(null);
  const districtRef = useRef<any>(null);
  const postos = data || postosJson;
  const states = sortBy(estados, "nome", "desc");
  const { width } = useWindowSize();

  const [statesFiltered, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [district, setDistrict] = useState([]);

  const [stateValue, setStateValue] = useState("");
  const debouncedState = useDebounce(statesFiltered, 200);

  const [cityValue, setCityValue] = useState("");
  const debouncedCity = useDebounce(cities, 200);

  const [districtValue, setDistrictValue] = useState("");
  const debouncedDistrict = useDebounce(district, 200);

  const router = useRouter();

  const [suggestionsValues, setSuggestionsValues] = useState([]);
  const suggestionsRef = useRef(null);
  const [showSuggestions, setShowSuggestions] = useState(false);

  useEffect(() => {
    if (debouncedState) {
      setStates(debouncedState)
    }
  }, [debouncedState]);

  useEffect(() => {
    if (debouncedCity) {
      setCities(debouncedCity);
    }
  }, [debouncedCity]);

  useEffect(() => {
    if (debouncedDistrict) {
      setDistrict(debouncedDistrict);
    }
  }, [debouncedDistrict]);

  const getSuggestionsStations = () => {
    const stationsFiltered = postos?.filter(
      (item) =>
        removeAccents(item["Cidade"]?.toString()?.toLowerCase()) ===
        removeAccents(cityValue?.toLowerCase())
    );

    const _station = stationsFiltered?.filter(
      (item) =>
        removeAccents(
          item["Bairro"]?.toLowerCase()?.slice(0, districtValue?.length)
        ) === removeAccents(districtValue?.toLowerCase())
    );

    if (stateValue && cityValue && districtValue) {
      setSuggestionsValues(_station);
      setShowSuggestions(true);
    }
  };

  function filterCities() {
    setShowSuggestions(false)
    setSuggestionsValues([]);
    const stateNome = Boolean(stateValue) && states.find(
      (item) => stateValue.split("-")[0].includes(item.sigla))?.sigla //encontra o estado pela sigla.
    console.log(stateNome);
    const stationsFiltered = Boolean(stateNome) && postos.filter(
      (item) =>
        item["Estado"]?.toString()?.toLowerCase() === stateNome?.toLowerCase()
    ); //filtra os postos pelo estado
    const uniqStations = uniqBy(stationsFiltered, (i) =>
      removeAccents(i["Cidade"]?.toLowerCase())
    ); //remove os nomes duplicados das cidades.

    const citiesFiltered = sortBy(uniqStations, "Cidade", "asc").map((station) => {
      return { value: station.Cidade, label: station.Cidade }
    })

    setCities(citiesFiltered);
  }

  function filterDistricts() {
    setShowSuggestions(false)
    setSuggestionsValues([]);

    const stationsFiltered = Boolean(cityValue) && postos.filter(
      (item) =>
        item["Cidade"]?.toString()?.toLowerCase() === cityValue?.toLowerCase()
    ); //filtra os postos pelo estado

    const uniqDistricts = uniqBy(stationsFiltered, (i) =>
      removeAccents(i["Bairro"]?.toLowerCase())
    );

    const districstFiltered = sortBy(uniqDistricts, "Bairro", "asc").map((station) => {
      return { value: station.Bairro, label: station.Bairro }
    })

    setDistrict(districstFiltered);
  }

  const searchStyles = {
    option: (provided, state) => ({
      ...provided,
    }),
    control: () => ({
      backgroundColor: "#fff",
      display: "flex",
      borderRadius: "4px",
      width: "16rem",
    }),
    singleValue: (provided, state) => ({
      ...provided,

    }),
    menu: (provided, state) => ({
      ...provided,
      zIndex: 5
    })
  }

  const isSearchable = () => {
    if (width <= 440) {
      return false
    } else {
      return RiTreasureMapFill
    }
  }

  return (
    <Section>
      <ContainerImage>
        <ImageFilter isOpen={showSuggestions} />
        <BoxSelections ref={suggestionsRef}>
          <WrapInput>
            <CustomSelect
              classNamePrefix="react-select"
              isSearchable={isSearchable}
              options={statesFiltered}
              placeholder="Estado"
              onChange={(e) => {
                setStateValue(e.value);
                if (cityValue) {
                  cityRef.current.clearValue();
                }
              }}
              onFocus={() => {
                setStates(
                  sortBy(states, "Estado", "asc")?.map((item, idx) => {
                    return {
                      value: `${item?.sigla} - ${item?.nome}`,
                      label: `${item?.sigla} - ${item?.nome}`
                    }
                  })
                )
                setShowSuggestions(false);
              }}
            />
            <CustomSelect
              classNamePrefix="react-select"
              isSearchable={isSearchable}
              ref={cityRef}
              options={cities}
              placeholder="Cidade"
              onChange={(e) => {
                setCityValue(e?.value)
                if (districtValue) {
                  districtRef.current.clearValue()
                }
              }}
              onFocus={() => filterCities()}
              noOptionsMessage={() => "Nenhum resultado encontrado."}
            />
            <CustomSelect
              classNamePrefix="react-select"
              isSearchable={isSearchable}
              ref={districtRef}
              options={district}
              placeholder="Bairro"
              onChange={(e) => {
                setDistrictValue(e?.value)
              }}
              onFocus={() => filterDistricts()}
              noOptionsMessage={() => "Nenhum resultado encontrado."}
            />
          </WrapInput>
          <ButtonOk
            onClick={() => getSuggestionsStations()}
          >
            ok
          </ButtonOk>
        </BoxSelections>
        {/* suggestions */}
        <BoxSuggestions
          show={showSuggestions}
          id="suggestions"
        >
          {
            sortBy(
              suggestionsValues,
              (i) => removeAccents(i["Razão Social"]?.toLowerCase()),
              "asc"
            )?.map((item, idx) => (
              <SuggestionItem
                tabIndex={0}
                key={idx}
                // onClick={() => setShowSuggestions(false)}
                onKeyPress={(e) => e.key.toLowerCase() === "enter" && setShowSuggestions(false)}
              >
                <Box
                  d={"flex"}
                >
                  <img src={IconBombaColorida} alt="" draggable="false" />
                </Box>
                <Box
                  d={"flex"}
                  flexDir={"column"}
                  ml={["0.75rem", "0.75rem", "0.75rem", "38px"]}
                >
                  <strong aria-label={item["Razão Social"]}>{item["Razão Social"]}</strong>
                  <span aria-label={item["Endereço"]}>{item["Endereço"]}</span>
                  <span aria-label={`${item["Cidade"]} - ${item["Estado"]}`}>
                    {item["Cidade"]} - {item["Estado"]}
                  </span>
                </Box>
              </SuggestionItem>
            ))
          }
        </BoxSuggestions>
      </ContainerImage>
    </Section>
  )
}

const Section = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

const ContainerImage = styled.div`
  width: 100%;
  min-height: 486px;
  background-image: url(${shellStationMobile});
  background-size: cover;
  background-position: left;
  background-repeat: no-repeat;
  padding-bottom: 1rem;
  
  //center content ⬇️
  display: flex;
  flex-direction: column;
  align-items: center;
  
  position: relative;
  
  @media (min-width: ${(props) => props.theme.queries.lg}) {
    height: 486px;
    background-image: url(${shellStation});
    padding-bottom: unset;
  }
`;

const ImageFilter = styled.div`
  display: block;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,0.5);
  position: absolute;

  opacity: ${props => props.isOpen ? "1" : "0"};
  z-index: 0;
  transition: 0.3s;
`;

const BoxSelections = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 90%;
  margin-top: -28px;
  
  @media (min-width: ${(props) => props.theme.queries.medium}) {
    max-width: 1054px;
    flex-direction: row;
  }
`;

const WrapInput = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;

  position: relative;
  cursor: pointer;

  img {
    position: absolute;
    right: 15px;
    width: 24px;
    height: 12px;
    top: 40%;
  }

  @media (min-width: ${props => props.theme.queries.lg}) {
    flex-direction: row;
  }
`;

const CustomSelect = styled(ReactSelect)`
  display: flex;

  height: 53px;

  box-sizing: border-box;
  box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.15);
  margin-bottom: 1.25rem;
  
  font-family: helvetica;
  font-weight: bold;
  font-size: 1rem;
  line-height: 100%;
  color: ${(props) => props.theme.color.text};
  text-indent: 12px;
  position: relative;
  cursor: pointer;
  
  ::placeholder {
    font-family: Arial;
    font-weight: bold;
    font-size: 20px;
    color: ${(props) => props.theme.color.text};
  } 

  div[class*="value-container"], div[class^="value-container"] {
    width: 16rem !important;
    div[class*="-singleValue"], div[class^="-singleValue"] {
      font-size: 1.125rem !important;
      overflow: unset !important;
      color: ${(props) => props.theme.color.text} !important;
    }
  }

  div[class*="indicators"], div[class^="indicators"] {
    width: 48px;
    border: 1px solid ${(props) => props.theme.color.mediumGray};
    background: ${(props) => props.theme.color.lightGray};
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;

    svg {
      margin: 0 auto;
      path {
        fill: ${(props) => props.theme.color.shellPurple};
      }
    }

    div[class*="indicator"], div[class^="indicator"] {
      margin: 0 auto;   
    }
    span[class*="indicator-separator"], div[class^="indicator-separator"] {
      display: none;
    }
  }

  div[class*="menu"], div[class^="menu"] {
    color: ${(props) => props.theme.color.text};
    z-index: 4 !important;
    
    div[class*="option"] {
      width: 100%;
      overflow-x: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
  
  @media (min-width: ${(props) => props.theme.queries.medium}) {
    width: ${(props) => props.width};
    margin-bottom: 0;
    margin-right: 20px;
  }
`;

const ButtonOk = styled.button`
  width: 100%;
  max-width: 19rem;
  height: 53px;
  background: ${(props) => props.theme.color.red};
  color: #fff;
  border: none;
  border-radius: 3px;
  text-transform: uppercase;
  box-sizing: border-box;

  margin: 0 auto;

  font-size: 0.875rem;
  line-height: 0.688rem;
  font-family: ${(props) => props.theme.Shell.Bold};
  cursor: pointer;
  transition: 0.2s;

  &:hover, :focus {
    background: ${(props) => props.theme.color.purple};
  }

  @media (min-width: ${(props) => props.theme.queries.lg}) {
    width: 53px;
  }
`;

const BoxSuggestions = styled.div`
  display: flex;
  margin-top: 2.5rem;
  width: 90%;
  max-width: 20rem;
  background-color: transparent;
  
  display: ${(props) => (props.show ? "block" : "none")};
  border: 1px solid transparent;
  border-radius: 3px;
  
  position: relative;
  box-sizing: border-box;
  z-index: 1;
  
  @media (min-width: ${props => props.theme.queries.medium}) {
    overflow-y: auto;
    max-height: 384px;
    max-width: 1054px;
    border-color: #eaeaea;
    padding: 34px 27px;

    &::-webkit-scrollbar {
      width: 18px;
      border: 1px solid ${props => props.theme.color.lightGray};
    }
    
    &::-webkit-scrollbar-track {
      background: ${props => props.theme.color.lightGray};
    }
    
    &::-webkit-scrollbar-thumb {
      width: 8px;
      border-radius: 8px;
      border: 5px solid ${props => props.theme.color.lightGray};
      background: ${props => props.theme.color.mediumGray2};
    }
    
    &::-webkit-scrollbar-thumb:hover {
      filter: brightness(0.5);
    }
  }
`;

const SuggestionItem = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  margin-bottom: 45px;
  
  img {
    width: 40px;
    margin-top: 8px;
  }

  strong {
    font-family: ${props => props.theme.Shell.Bold};
    font-size: 1.125rem;
    line-height: 120%;
    color: ${props => props.theme.color.yellow};
    margin-bottom: 1.125rem;
  }

  span {
    font-family: helvetica;
    font-size: 1rem;
    line-height: 90%;
    color: #fff;

    &:last-child {
      margin-top: 9px;
    }
  }

  &::before {
    background: url(${IconBombaColorida});
  }

  @media (min-width: ${(props) => props.theme.queries.lg}) {
    margin-bottom: 48px;

    &:last-child {
      margin-bottom: 0;
    }
    img {
      width: 71px;
      margin-top: 0;
    }
    strong {
      font-size: 1.375rem;
      line-height: 0.688rem;
    }
    span {
      font-size: 1rem;
      line-height: 0.688rem;
    }
  }
`;