import styled from "styled-components";

export const Container = styled.section`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-x: hidden;
`;

export const ContainerTickets = styled.div`
  background-color: ${(props) => props.theme.color.lightGray};
  padding: 1.25rem 1rem;
  margin: 2.75rem 1rem;

  @media (min-width: ${(props) => props.theme.queries.desk}) {
    margin: 2.75rem 0;
  }
`;

export const LabelTickets = styled.h4`
  margin: 0;
  max-width: 956px;

  strong {
    font-size: 1rem;
    line-height: 0.75;
    color: ${(props) => props.theme.color.text};
    font-family: ${(props) => props.theme.Shell.Bold};
  }
  p {
    font-size: 1rem;
    line-height: 1;
    font-weight: normal;
    color: ${(props) => props.theme.color.text};
    font-family: ${(props) => props.theme.Shell.Book};

    b {
      font-weight: bold;
    }
  }
`;

export const DescriptionTickets = styled.p`
  font-size: 1rem;
  line-height: 1.4rem;
  color: ${(props) => props.theme.color.gray};
  font-weight: 400;
  margin: 0.625rem 0 0 0;
`;

export const Bold = styled.span`
  font-weight: 600;
  margin-left: 0.4rem;
`;

export const ContainerSteps = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => props.theme.color.lightGray};
  width: 100%;
  padding: 3.5rem 1rem;
`;

export const WrapperSteps = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 1190px;
  gap: 4rem;
`;
