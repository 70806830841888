import { useStoreState as store } from "easy-peasy";
import { initialState } from "../models/auth";

interface AuthProps {
  isAuthenticated: boolean
  token: string
  user: {
    [".document"]: string
    [".expires"]: string
    [".external"]: false
    [".first_access"]: boolean
    [".is_optin"]: true
    [".issued"]: string
    [".name"]: string
    access_token: string
    expires_in: number
    token_type: string
  }
}

export const auth = (withHook: boolean = true) => {
  const authStorage = localStorage.getItem("Hub-auth");
  let auth: AuthProps;
  if (withHook) {
    // @ts-ignore
    auth = store(state => state.auth);
  }
  // @ts-ignore
  const cachedAuth = JSON.parse(authStorage) || {
    auth: initialState
  };

  return {
    auth,
    cachedAuth
  };
};
