import { thunk, action, Action, Thunk } from "easy-peasy";
import {
  GetOrder,
  GetOrderDetail,
  GetOrderTracking,
  GetOrdersJunteGanhe,
} from "../services/order";
import { get } from "lodash";

export interface OrderModel {
  isLoading: boolean;
  messageError: string;

  orders: Object[];
  ordersJunteGanhe: Object[];
  order: Object;
  trackingOrder: Object[];

  getOrders: Thunk<OrderModel, void>;
  getOrdersJunteGanhe: Thunk<OrderModel, void>;
  getTracking: Thunk<OrderModel, void>;
  setOrders: Action<OrderModel, any>;
  setOrdersJunteGanhe: Action<OrderModel, any>;
  setTrackingOrders: Action<OrderModel, any>;

  setLoading: Action<OrderModel, string>;
  setError: Action<OrderModel, string>;
}

const order: OrderModel = {
  isLoading: false,
  messageError: "",
  orders: [],
  order: {},
  ordersJunteGanhe: [],
  trackingOrder: [],
  getOrders: thunk(async (action: any, payload: any) => {
    action.setLoading({ loading: true });
    try {
      const res = await GetOrder();
      const promise = [];
      for (const z of res?.data?.data) {
        promise.push(GetOrderDetail({ id: z?.id }));
      }

      const response = await Promise.all(promise);
      for (const z of res?.data?.data) {
        for (const item of response) {
          if (z?.id === item?.data?.data?.id) {
            z.detail = item?.data?.data;
            break;
          }
        }
      }

      // console.log(res.data.data);
      action.setOrders(res.data);
      action.setLoading({ loading: false });
    } catch (error) {
      action.setError({
        message: "Não foi possivel obter os dados, tente novamente mais tarde!",
      });
      action.setLoading({ loading: false });
    }
  }),
  getOrdersJunteGanhe: thunk(async (action: any, payload: any) => {
    action.setLoading({ loading: true });
    try {
      const res = await GetOrdersJunteGanhe();
      action.setOrdersJunteGanhe(res.data);
      action.setLoading({ loading: false });
    } catch (error) {
      action.setError({
        message: "Não foi possivel obter os dados, tente novamente mais tarde!",
      });
      action.setLoading({ loading: false });
    }
  }),
  getTracking: thunk(async (action: any, id: any) => {
    try {
      const tracking: any = await GetOrderTracking({ id });
      action.setTrackingOrders({ tracking, id });
    } catch (error) {
      action.setError({
        message: "Não foi possivel obter os dados, tente novamente mais tarde!",
      });
      action.setLoading({ loading: false });
    }
  }),
  setOrders: action((state: any, payload: any) => {
    state.orders = payload.data;
  }),
  setOrdersJunteGanhe: action((state: any, payload: any) => {
    state.ordersJunteGanhe = payload.data.items;
  }),
  setTrackingOrders: action((state: any, payload: any) => {
    const item = payload.tracking.data.data;
    for (let i = 0; i < state.orders.length; i++) {
      if (state.orders[i].id === payload.id) {
        state.orders[i].tracking = item;
      }
    }
  }),
  setLoading: action((state: { isLoading: boolean }, payload: any) => {
    const loading = get(payload, "loading", false);
    state.isLoading = loading;
  }),
  setError: action((state: { messageError: string }, payload: any) => {
    const message = get(payload, "message", false);
    state.messageError = message;
  }),
};

export default order;
