import React from "react";
import styled from "styled-components";
import { Box, Drawer, DrawerContent, DrawerCloseButton } from "@chakra-ui/core";
import { theme } from "theme";
import useWindowSize from "hooks/useWIndowSize";

const ModalAjudaMobile = (props) => {
  const { width } = useWindowSize();

  const handleLink = (url) => {
    if (window.location.search.includes("shellbox")) {
      try {
        // @ts-ignore
        WebViewJavascriptInterface.openExternalLink(url);
      } catch (_) {
        window.open(
          url,
          "_blank",
          "noopener"
        );
        window.focus();
      }
    } else {
      window.open(url);
    }
  }

  return (
    <>
      {width <= 768 && (
        <Container>
          <CustomDrawer
            onClose={props.onClose}
            isOpen={props.isOpen}
            blockScrollOnMount={true}
            size={"full"}
          >
            <DrawerContent backgroundColor={theme.color.white}>
              <CustomClose onClick={props.onClose} />
              <ContainerInfo>
                {
                  window.location.search.includes("shellbox") ? (
                    <TitleShellbox>
                      Dúvidas
                    </TitleShellbox>
                  ) : (
                    <Title>
                      Dúvidas
                    </Title>
                  )
                }
                <Description>
                  <Paragraph tabIndex={0}>{props.children}</Paragraph>
                </Description>
              </ContainerInfo>
            </DrawerContent>
          </CustomDrawer>
        </Container>
      )}
    </>
  );
};

export default ModalAjudaMobile;

const Container = styled(Box)`
  display: none !important;
  @media (max-width: ${(props) => props.theme.queries.medium}) {
    display: block;
  }
  width: 100%;
`;

const CustomDrawer = styled(Drawer)``;

const CustomClose = styled(DrawerCloseButton)`
  background-color: transparent !important;
  position: fixed;
  z-index: 99999;
  border: none !important;
  margin-top: 1rem;
  margin-right: 1rem;
  cursor: pointer;
  button {
    position: fixed !important;
    z-index: 99999 !important;
  }
  svg {
    width: 22px;
    height: 22px;
    path {
      fill: ${(props) => props.theme.color.red};
    }
  }
  :focus {
    box-shadow: none !important;
  }
`;

const ContainerInfo = styled.div`
  padding: 2em;
  padding-top: 1em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Title = styled.h1`
  font-weight: 700;
  font-size: 24px;
  color: ${props => props.theme.color.gray};
  text-transform: uppercase;
  margin: 0;
`;

const TitleShellbox = styled.h1`
  font-weight: 700;
  font-size: 24px;
  color: ${props => props.theme.color.gray};
  text-transform: uppercase;
  margin-top: 4.5rem;
`;

const Description = styled.div`
  margin: 36px 0;
  overflow-y: auto;
  max-height: 400px;
`;

const Paragraph = styled.p`
  width: 100%;
  font-size: 16px;
  font-weight: normal;
  line-height: 20px;
  margin: 0;

  p {
    margin: 0;
    margin-bottom: 30px;
    font-family: Arial, Helvetica, sans-serif;
  }

  h3 {
    margin-bottom: 25px;
    font-weight: 700;
    color: ${props => props.theme.color.gray};
    font-size: 1.1rem;
    line-height: 1.2;
  }
`;