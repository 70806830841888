import React from "react";
import styled from "styled-components";
import { auth as useAuth } from "hooks/auth";
import { Box } from "@chakra-ui/core";
import { NavigationAnoTodo } from "../components/Navigation/NavigationAnoTodo";
import { PostosParticipantesAnoTodo } from "../components/PostosParticipantes/PostosParticipantesAnoTodo";
import postosAnoTodo from "utils/postos-junte-troque-oanotodo.json";
import FooterAnoTodo from "../components/FooterAnoTodo";

export default function PostosParticipantes() {
  const { auth } = useAuth();
  const isAuthenticated = auth.isAuthenticated;
  const isShellbox = window.location.search.includes("shellbox");

  return (
    <Container
      isAuth={isAuthenticated}
      isShellbox={isShellbox}
    >
      <HeaderWrapper>
        <NavigationAnoTodo />
        <Box
          display={"flex"}
          flexDir={"column"}
          w={"85%"}
          h={"100%"}
          mt={["24px","24px","24px","60px"]}
          mb={["60px","60px","60px","70px"]}
        >
          <Title tabIndex={0}>
            Postos e Lojas Participantes
          </Title>
          <p tabIndex={0}>
            Selecione abaixo Estado, Cidade e Bairro para encontrar os postos e lojas mais próximos de você.
          </p>
        </Box>
      </HeaderWrapper>
      <Box
        as={"main"}
        display={"flex"}
        flexDir={"column"}
        justifyContent={"center"}
        alignItems={"flex-start"}
        w={"100%"}
      >
        <PostosParticipantesAnoTodo data={postosAnoTodo} />
      </Box>
    </Container>
  )
}

const Container = styled(Box)`
  margin-top: ${(props) => (!props.isShellbox ? "40px" : "0")};
  background-color: ${props => props.theme.color.lightGray50};
  
  /* padding-bottom: ${props => props.isAuth || props.isShellbox ? "3rem" : "0"}; */
  margin-bottom: ${props => props.isShellbox ? "2rem" : "0"};
`;

const HeaderWrapper = styled.header`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  
  background-color: ${props => props.theme.color.shellPurple};
  padding-top: 8px;
  box-sizing: border-box;
  
  p {
    font-family: ${props => props.theme.Shell.Book};
    color: ${props => props.theme.color.grayPurple};
    font-size: 1rem;
    line-height: 130%;
    font-weight: 400;
    text-align: center;
    margin: 0;
    margin-top: 20px;
  }

  @media (min-width: ${props => props.theme.queries.lg}) {
    /* height: 340px; */
    padding-top: 4px;
  }
`;

const Title = styled.h1`
  font-family: ${props => props.theme.Shell.Book};
  font-weight: 400;
  font-size: 1.875rem;
  line-height: 100%;
  text-align: center;
  color: ${(props) => props.theme.color.white};
  width: 80%;
  margin: 0 auto;

  @media (min-width: ${props => props.theme.queries.lg}) {
    font-size: 2.25rem;
  }
`;