import React from "react";
import styled from 'styled-components';
import { Select } from "evergreen-ui";

//assets
import chevronDown from "assets/icons/chevron-down.svg";

interface IHeader {
  isClosed?: boolean;
  categories: any[];
  locales: any[];  
  localeId?: string|number;
  setLocaleId: any;
  categoryId?: string|number;
  setCategoryId: any;
}

const Header = ({
  isClosed = false,
  categories,
  locales,
  localeId,
  setLocaleId,
  categoryId,
  setCategoryId
}: IHeader) => {
  return (
    <Container marginT={isClosed ? "87px" : "0px"} marginTopMobile={isClosed ? "40px" : "0px"} id="main">
      {
        isClosed ? (
          <Title>promo hits encerradas</Title>
        ) : (
          <Title>promohits</Title>
        )
      }
      {
        !isClosed ? (<Subtitle>A cada mês ofertas especiais para você nas lojas Shell Select</Subtitle>) : null
      }
      <InlineSelects>
        <BoxSelect>
          <Select height="59px" onChange={(e) => setCategoryId(e.target.value)}>
            <option value={"empty"}>Todas as Categorias</option>
            {
              categories?.map((item) => (
                <option key={item?.id} value={item?.id} selected={item?.id === categoryId}>{item?.nome}</option>
              ))
            }
          </Select>
        </BoxSelect>
        <BoxSelect>
          <Select height="59px" onChange={(e) => setLocaleId(e.target.value)}>
            <option value={"empty"}>Todos os Locais</option>
            {
              locales?.map((item) => (
                <option key={item?.id} value={item?.id} selected={item?.id === localeId}>{item?.nome}</option>
              ))
            }
          </Select>
        </BoxSelect>
      </InlineSelects>
    </Container>
  )
}

export default Header;

const Container = styled.div`
  margin-top: ${props => props.marginT};
  padding: 40px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media(max-width: ${props => props.theme.queries.small}){
    margin-top: ${props => props.marginTopMobile};
  }
`;

const Title = styled.h2`
  margin: 0;
  padding: 0;
  color: ${props => props.theme.color.gray};
  font-size: 30px;
  line-height: 40px;
  text-transform: uppercase;
  font-family: "Futura Bold";

  span{
    color: ${props => props.theme.color.orange};
  }

  @media(max-width: ${props => props.theme.queries.small}){
    text-align: center;
  }
`;

const Subtitle = styled.p`
  margin: 10px 0 0 0;
  padding: 0;

  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  color: ${props => props.theme.color.gray};
  text-align: center;
  font-family: "Futura LT Regular";
`;

const InlineSelects = styled.div`
  max-width: 688px;
  width: 100%;
  margin-top: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  grid-gap: 20px;

  svg {
    display: none !important;
  }

  div {
    width: 100%;
  }

  select {
    width: 100% !important;
    text-indent: 15px;
    background-image: none;
    border: 1px solid #BDBDBD;
    border-radius: 0;
    color: ${props => props.theme.color.gray};
    font-size: 20px !important;
    line-height: normal !important;
    font-weight: 500;
    font-family: "Futura";

    -webkit-appearance: none;
    -moz-appearance: none;
    background: #fff;
    background-image: url(${chevronDown});
    background-repeat: no-repeat;
    background-position-x: 96%;
    background-position-y: 22px;

    :hover{
      background-image: url(${chevronDown}) !important;
    }
  }

  @media(max-width: ${props => props.theme.queries.medium}){
    flex-direction: column;
  }

  @media(max-width: ${props => props.theme.queries.small}){
    margin-top: 40px;
  }
`;

const BoxSelect = styled.div`
  position: relative;
  width: 100%;

  img {
    position: absolute;
    right: 15px;
    top: 23px;
    width: 16px;
    height: 16px;
  }
`;